import { ActionType, getType } from 'typesafe-actions';
import * as urlShortenerActions from './UrlShortenerActions';
import { RootState } from '../RootReducer';

// Creates a type from the URL shortener actions
export type UrlShortenerAction = ActionType<typeof urlShortenerActions>;

export interface UrlShortenerState {
  urls: { [longUrl: string]: string | undefined };
}

// This extends RootState because it is lazily loaded, and we need to reference the
// RootState + urlShortenerModuleState state in this feature.
export interface UrlShortenerModuleState extends RootState {
  urlShortener: UrlShortenerState;
}

export const initialUrlShortenerState: UrlShortenerState = {
  urls: {},
};

export default function urlShortenerReducer(
  state: UrlShortenerState = initialUrlShortenerState,
  action: UrlShortenerAction
): UrlShortenerState {
  switch (action.type) {
    case getType(urlShortenerActions.receivedShortUrl):
      return {
        ...state,
        urls: { ...state.urls, [action.payload.longUrl]: action.payload.shortUrl },
      };

    default:
      return state;
  }
}
