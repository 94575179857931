// This file houses functions for making network requests, and for working with network requests.

import { ApiRequest, ApiResource } from '../../common-types';
import { EMPTY, Observable, Subject, throwError, concat, of } from 'rxjs';
import { catchError, expand, reduce, switchMap, takeUntil, tap } from 'rxjs/operators';
import { RootState } from '../RootReducer';
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import apiClient from './api-client';
import { hasPermission } from './permissions';
import { Store } from 'redux';
import log from './log';
import {
  assocIcopParams,
  buildApiPathFromResource,
  getResourceAndValidateRequest,
} from './api-resources';
import { MobileApiPageEnvelope } from '../../mobile-api-types';
import { unlockPermissions } from '../permissions/PermissionsActions';
import { PermissionDeniedError } from '../../common-types';

export type ApiServiceRequestTransformFn = (
  config: AxiosRequestConfig,
  request: ApiRequest
) => AxiosRequestConfig;

export interface SinglewireHttpRequestOptions extends AxiosRequestConfig {
  transforms?: ApiServiceRequestTransformFn[];
  skipPermissionCheck?: boolean;
  // Previous to adding this request option, we had no way to specify what fusion server we want to make a
  // request to, instead choosing to use the currently-selected server. There have been some recent asks from product
  // to make it so that we can make requests to specific/all servers, and so we need a means of requesting to other
  // servers. This option does that in the `req` method, first checking for this param, and, if not passed, falling
  // back to the currently active fusion server.
  icopServerId?: string;
}

// The default limit when doing pagination
const DEFAULT_LIMIT = 100;

// Our three primary request types represented as Symbols

// Use this if the task should never be canceled due to a route change
export const BackgroundRef = Symbol.for('background');
// Use this if a request should be canceled due to a route change w/o prompting the user
export const CancellableRef = Symbol.for('cancellable');
// Use this if a request should be canceled but only after prompting the user
export const ConfirmRef = Symbol.for('confirmation-required');

export interface RefRequest {
  count: number;
  stop$: Subject<boolean>;
  cancelToken: CancelTokenSource;
}

/**
 * A map of components managing a request with this service to a subject that can be used to cancel the request(s).
 * Note that this is placed out of the store as this information isn't serializable and not really a part of UI state.
 * @type {Map<any, Subject<boolean>>}
 */
export const refRequests = new Map<symbol, RefRequest>();

/**
 * A function to initiate network requests
 * @param request the endpoint the request will be sent to
 * @param store the redux store
 * @param ref a symbol to identify the type of request
 */
export function req<T>(
  request: ApiRequest,
  store: Store<RootState>,
  ref: symbol
): Observable<AxiosResponse<T>> {
  const state = store.getState();
  const session = state.session;
  const [, pathParams, requestMethod, options = {}] = request;
  const selectedServerId = options.icopServerId || state.icopServers.selectedICOPServerId;
  const resource: ApiResource = getResourceAndValidateRequest(request);

  if (!session) {
    log.warn("Can't make API request as we are unauthorized.", request, resource);
    return EMPTY;
  }

  const apiPath = buildApiPathFromResource(resource, pathParams, selectedServerId);

  // Add our request to our tracking map if it doesn't exist
  if (!refRequests.has(ref)) {
    refRequests.set(ref, {
      count: 0,
      stop$: new Subject<boolean>(),
      cancelToken: axios.CancelToken.source(),
    });
  }
  const { stop$, cancelToken } = refRequests.get(ref)!;

  const requestObservable = (
    options.skipPermissionCheck ? of(true) : hasPermission(request, store)
  ).pipe(
    switchMap(doesHavePermission => {
      if (doesHavePermission) {
        // Transform the options with our list of transformers
        const { transforms, ...requestOptions } = options;
        const transformedOptions = (transforms || []).reduce(
          (acc, transform) => transform(acc, request),
          { ...requestOptions, headers: requestOptions.headers || {} } as AxiosRequestConfig
        );
        const finalOptions =
          resource.isIcop || resource.isProxy
            ? assocIcopParams(transformedOptions)
            : transformedOptions;
        return apiClient.request<T>({
          ...finalOptions,
          url: apiPath,
          method: requestMethod,
          cancelToken: cancelToken.token,
        });
      } else {
        // If debugging has brought you to this error, these are some steps that might be helpful:
        // 1. figure out what the uri for this request is by looking it up api-resources.ts
        // 2. check that there exists a corresponding permission to use this uri in PermissionsList.ts (uri and verb)
        return throwError(new PermissionDeniedError('No permission to execute request', request));
      }
    })
  );

  return concat(
    // When the request is subscribed to, increment our request counter
    incrementRequestCounter(ref),
    requestObservable.pipe(
      // When the request is finished, decrement our request counter. If the request is a POST
      // and returns an id in the response payload, we'll "unlock" the permissions such that
      // we can query the server again for user permissions (i.e. creator permissions).
      tap(resp => {
        decrementRequestCounter(ref);
        if (requestMethod === 'POST' && (resp.data as any)?.id) {
          store.dispatch(unlockPermissions());
        }
      }),
      // If there was an error, decrement our request counter and rethrow the error
      catchError(error => {
        decrementRequestCounter(ref);
        return throwError(error);
      }),
      // If our stop$ subject is emitted to, stop producing events on the pipeline thus cancelling the request
      takeUntil(stop$)
    )
  );
}

/**
 * Helper function to splice in a new request params for limit and next
 * @param request the original request
 * @param limit the new limit
 * @param start the new next token
 */
const updatedRequestForPagination = (
  request: ApiRequest,
  limit: number,
  start: string | null
): ApiRequest => {
  const newRequestOpts: SinglewireHttpRequestOptions = {
    ...(request[3] || {}),
    params: { ...((request[3] && request[3].params) || {}), limit, ...(start ? { start } : {}) },
  };
  return [request[0], request[1], request[2], newRequestOpts];
};

/**
 * A helper function to recursively paginate over the pages of results from the Mobile REST API and return a
 * final observable emitting the concatted results.
 * @param request the initial request. Note if you want to control the limit you may pass the limit param to the
 * params object in this request
 * @param store the redux store
 * @param ref a symbol to identify the type of request
 */
export function reqAll<T>(
  request: ApiRequest,
  store: Store<RootState>,
  ref: symbol
): Observable<T[]> {
  const limit = parseInt(
    (request[3] && request[3].params && request[3].params.limit) || DEFAULT_LIMIT,
    10
  );

  const getPage = (nextToken: string | null) =>
    req<MobileApiPageEnvelope<T>>(
      updatedRequestForPagination(request, limit, nextToken),
      store,
      ref
    );

  return getPage(null).pipe(
    expand(response => {
      return response.data.next ? getPage(response.data.next) : EMPTY;
    }),
    reduce<AxiosResponse<MobileApiPageEnvelope<T>>, T[]>(
      (acc, response) => [...acc, ...(response.data.data || [])],
      []
    )
  );
}

/**
 * Constructs an observable that when subscribed to, increments our request count
 * @param ref the ref to lookup
 */
const incrementRequestCounter = (ref: symbol): Observable<any> => {
  return Observable.create((observer: Subject<any>) => {
    const currentRefRequest = refRequests.get(ref);
    if (currentRefRequest) {
      const { count, stop$, cancelToken } = currentRefRequest;
      refRequests.set(ref, { count: count + 1, stop$, cancelToken });
    }
    observer.complete();
  });
};

/**
 * Decrements our current request count
 * @param ref the ref to lookup
 */
const decrementRequestCounter = (ref: symbol) => {
  const currentRefRequest = refRequests.get(ref);
  if (currentRefRequest) {
    const { count, stop$, cancelToken } = currentRefRequest;
    refRequests.set(ref, { count: count - 1, stop$, cancelToken });
  }
};

/**
 * Given a ref, cancel any active RxJS subscription and actually cancel the inflight network request
 * @param ref the ref to lookup. If no ref is supplied, cancel everything
 */
export function cancelRequests(ref: symbol | null): void {
  if (!ref) {
    Array.from(refRequests.keys()).forEach(x => cancelRequests(x));
  } else {
    const currentRefRequest = refRequests.get(ref);
    if (currentRefRequest) {
      currentRefRequest.stop$.next(true);
      currentRefRequest.cancelToken.cancel();
      refRequests.delete(ref);
    }
  }
}

/**
 * Checks if there are any active requests for a given ref
 * @param ref
 */
export const hasRequestsForRef = (ref: symbol) => {
  const currentRefRequest = refRequests.get(ref);
  if (currentRefRequest) {
    return currentRefRequest.count > 0;
  } else {
    return false;
  }
};
