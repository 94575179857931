import omit from 'lodash/omit';
import * as TabsActions from './TabsActions';
import { ActionType, getType } from 'typesafe-actions';

import { buildTabUrl } from './TabsUtils';

export type TabsAction = ActionType<typeof TabsActions>;

interface ActiveTabLookup {
  [url: string]: number;
}

export interface TabsState {
  [tabsId: string]: {
    lookup: ActiveTabLookup;
    visitedTabs: number[];
    active: number;
  };
}

export const initialTabsState: TabsState = {};

export default function tabsReducer(
  state: TabsState = initialTabsState,
  action: TabsAction
): TabsState {
  switch (action.type) {
    case getType(TabsActions.registerTabs): {
      const lookup: { [url: string]: number } = action.payload.tabs.reduce(
        (acc, tab, idx) => ({
          ...acc,
          [buildTabUrl(action.payload.baseUrl, tab.tabRouteSuffix)]: idx,
          // add the additional routes from the additionalRoutes object
          ...(action.payload.additionalRoutes?.[tab.tabRouteSuffix] || []).reduce(
            (acc, route) => ({
              ...acc,
              [route]: idx,
            }),
            {}
          ),
        }),
        {}
      );

      const defaultTabRoute = action.payload.tabs.find(tab => tab.defaultTab)?.tabRouteSuffix;
      const defaultTabUrl =
        defaultTabRoute !== undefined ? buildTabUrl(action.payload.baseUrl, defaultTabRoute) : '';

      const active = lookup[action.payload.currentUrl] ?? lookup[defaultTabUrl];

      return {
        ...state,
        [action.payload.id]: { lookup, active, visitedTabs: [active] },
      };
    }
    case getType(TabsActions.setActiveTab): {
      const { lookup, visitedTabs } = state[action.payload.id];
      const newActive = lookup[action.payload.currentUrl];
      // Only add to the visitedTabs if it's not already there.
      const newVisitedTabs = !visitedTabs.includes(newActive)
        ? [...visitedTabs, newActive]
        : visitedTabs;
      return {
        ...state,
        [action.payload.id]: {
          lookup,
          active: newActive,
          visitedTabs: newVisitedTabs,
        },
      };
    }
    case getType(TabsActions.selectTab): {
      const { lookup, visitedTabs } = state[action.payload.id];
      const newActive = action.payload.active;
      // Only add to the visitedTabs if it's not already there.
      const newVisitedTabs = !visitedTabs.includes(newActive)
        ? [...visitedTabs, newActive]
        : visitedTabs;
      return {
        ...state,
        [action.payload.id]: {
          lookup,
          visitedTabs: newVisitedTabs,
          active: newActive,
        },
      };
    }

    case getType(TabsActions.cleanupTabs): {
      return omit(state, action.payload.id);
    }
    default:
      return state;
  }
}
