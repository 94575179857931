import { ActionType, getType } from 'typesafe-actions';
import * as TTSActions from './TtsActions';
import { ValidationError } from 'yup';

export type TTSAction = ActionType<typeof TTSActions>;

type TtsState = 'none' | 'fetching' | 'ready' | 'error';
//            -create->                  [none]
//            -edit->                    [ready]
// [none]     -click->                   [fetching]
// [fetching] -success->                 [ready]
// [fetching] -failure->                 [error]
// [error]    -change-any-params->       [none]
// [ready]    -change-any-params->       [none]

export interface TTSState {
  [previewId: string]: {
    state: TtsState;
    errors?: ValidationError[];
    preview?: { hash: string; size: number };
  };
}

export const initialTTSState: TTSState = {};

export default function TtsReducer(state: TTSState = initialTTSState, action: TTSAction): any {
  switch (action.type) {
    case getType(TTSActions.ttsNone):
      return { ...state, [action.payload.previewId]: { state: 'none' } };
    case getType(TTSActions.ttsFetching):
      return { ...state, [action.payload.previewId]: { state: 'fetching' } };
    case getType(TTSActions.ttsReady):
      return {
        ...state,
        [action.payload.previewId]: { state: 'ready', preview: action.payload.preview },
      };
    case getType(TTSActions.ttsError):
      return {
        ...state,
        [action.payload.previewId]: { state: 'error', errors: action.payload.errors },
      };
    case getType(TTSActions.ttsClear):
      const { [action.payload.previewId]: _, ...nextState } = state;
      return nextState;

    default:
      return state;
  }
}
