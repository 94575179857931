import omit from 'lodash/omit';
import { ActionType, getType } from 'typesafe-actions';
import { DEFAULT_PAGER_LIMIT } from '../../constants';
import { MobileApiPageEnvelope } from '../../mobile-api-types';
import { RootState } from '../RootReducer';
import * as searchPager from './SearchPagerActions';

export type SearchPagerAction = ActionType<typeof searchPager>;

export interface SearchPagerState<T> {
  q: string | null | { and: string[] };
  filters: null | { [key: string]: any };
  start: string | null;
  limit: number;
  lastPageLimit: number | null;
  previousFromLastPage: boolean;
  data: MobileApiPageEnvelope<T> | null;
  isSearching: boolean;
  error: any | null;
  pagerUserQuery?: string; // The user's inputted text. Having this information in an arbitrary location in the q param makes it difficult to use programatically.
  customLimits: [number, number, number] | null;
  route: string;
}

export const initialSearchPagerState: { [key: string]: SearchPagerState<any> } = {};

export default function searchPagerReducer(
  state: { [key: string]: SearchPagerState<any> } = initialSearchPagerState,
  action: SearchPagerAction
): { [key: string]: SearchPagerState<any> } {
  switch (action.type) {
    case getType(searchPager.searchPagerCreated):
      if (state[action.payload.pagerId]) {
        return state;
      } else {
        const [, , , opts = {}] = action.payload.apiRequest;
        return {
          ...state,
          [action.payload.pagerId]: {
            q: (opts.params && opts.params.q) || '',
            filters: (action.payload.options && action.payload.options.filters) || null,
            customLimits: (action.payload.options && action.payload.options.customLimits) || null,
            start: null,
            limit: (opts.params && opts.params.limit) || DEFAULT_PAGER_LIMIT,
            lastPageLimit: null,
            previousFromLastPage: false,
            isSearching: false,
            error: null,
            data: null,
            route: action.payload.route,
          },
        };
      }

    case getType(searchPager.searchPagerCreate):
      if (!state[action.payload.pagerId]) {
        return state;
      } else {
        return {
          ...state,
          [action.payload.pagerId]: {
            ...state[action.payload.pagerId],
          },
        };
      }

    case getType(searchPager.searchPagerChangeQuery):
      if (!state[action.payload.pagerId]) {
        return state;
      } else {
        return {
          ...state,
          [action.payload.pagerId]: {
            ...state[action.payload.pagerId],
            pagerUserQuery: action.payload.userQuery,
          },
        };
      }

    case getType(searchPager.searchPagerDestroyed):
      return omit(state, [action.payload.pagerId]);

    case getType(searchPager.searchPagerUpdated):
      return {
        ...state,
        // action.payload.state is of type Partial<SearchPagerState<any>>
        [action.payload.pagerId]: { ...state[action.payload.pagerId], ...action.payload.state },
      };

    default:
      return state;
  }
}

export const checkPagerLimits = (
  limit: number | undefined,
  level: string,
  customLimits: [number, number, number] | null
) => {
  switch (level) {
    case 'low':
      return customLimits ? customLimits[0] : 10;
    case 'medium':
      return customLimits ? customLimits[1] : 20;
    case 'high':
      return customLimits ? customLimits[2] : 50;
    default:
      return customLimits ? customLimits[0] : 10;
  }
};

// Returns the search pager state from the store
export function getPager<T>(state: RootState, pagerId: string): SearchPagerState<T> | undefined {
  return state.searchPagers[pagerId];
}

// Returns the pager data from the search pager state or an empty array
export function getPagerData<T>(pager: SearchPagerState<T> | undefined): T[] {
  return pager?.data?.data || [];
}

/**
 *  Replace the item at the given index in the search pager data.data with the given item.
 */
export function replaceItemInPager<T>(
  pager: SearchPagerState<T>,
  index: number,
  updatedItem: T
): SearchPagerState<T> {
  const envelope = pager.data!;
  const resources = [...envelope.data];
  resources[index] = updatedItem;
  return { ...pager, data: { ...envelope, data: resources } };
}
