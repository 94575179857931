import { createAction } from 'typesafe-actions';

// Used in: Epic
// Set the global history.scrollRestoration to 'manual'.
export const setManualScrollRestoration = createAction('[Scroll] Set Scroll Restoration Manual')();

// Used in: Epic
// Scroll the page to the top.
export const restoreScroll = createAction('[Scroll] Restore Scroll', (location: string) => ({
  location,
}))();
