// Handled in: Epic

import { createAction } from 'typesafe-actions';

// Handled in: Reducer
// Sets state to true for disabling cloning buttons while submitting
export const startCloning = createAction('[Cloning] Start Cloning')();

// Handled in: Reducer
// Sets state to false for disabling cloning buttons while submitting
export const endCloning = createAction('[Cloning] End Cloning')();
