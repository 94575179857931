import {
  MobileApiMessageTemplate,
  MobileApiTextToSpeechVoice,
  TtsType,
} from '../../mobile-api-types';

// FormTtsType consolidates the 'custom-text' and 'custom-ssml' types into 'custom', for ui convenience
export type FormTtsType = 'subject' | 'body' | 'subject-and-body' | 'custom' | 'none';

// For notifications and message template forms
export type AudioType = 'recorded' | 'tts';

// convert FormTtsType to TtsType
// tslint:disable-next-line:no-shadowed-variable
export const ttsType = (
  formTtsType: FormTtsType,
  ssml: boolean,
  removeAudio?: boolean
): TtsType => {
  if (removeAudio) {
    return 'none';
  }

  return formTtsType === 'custom'
    ? ssml
      ? ('custom-ssml' as TtsType)
      : ('custom-text' as TtsType)
    : (formTtsType as TtsType);
};

// convert TtsType to FormTtsType
// tslint:disable-next-line:no-shadowed-variable
export const formTtsType = (ttsType: TtsType): FormTtsType =>
  ttsType === 'custom-ssml' || ttsType === 'custom-text' ? 'custom' : ttsType;

// calculate AudioType of a message template
export const audioType = (mt: MobileApiMessageTemplate): AudioType => {
  if (mt.ttsType === 'none') {
    return 'recorded';
  } else {
    return 'tts';
  }
};

// handle switching voice on language switch for select components
const getVoiceLangForDefault = (
  voiceId: string,
  allVoices: MobileApiTextToSpeechVoice[]
): string => {
  const find = allVoices.find(voice => voice.id === voiceId);
  return find ? find.language : '';
};

/* If possible, use the default voice, if it's not the correct language, pick an arbitrary (the first)
 * voice for the given language
 *
 * @return the correct voiceId, see SW-13523
 */
export const handleLanguageChange = (
  defaultVoiceId: string,
  allVoices: MobileApiTextToSpeechVoice[],
  newLanguage: string
): string => {
  return newLanguage === getVoiceLangForDefault(defaultVoiceId, allVoices)
    ? defaultVoiceId
    : allVoices.find(voice => voice.language === newLanguage)!.id;
};
