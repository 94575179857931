import { BrandingState } from './BrandingReducer';
import { core_t } from '../CoreLocale';
import { createContext } from 'react';
import { COLORS } from 'singlewire-components';

export const defaultBranding = {
  primaryColor: window.branding?.primaryColor ?? COLORS.PRIMARY,
  secondaryColor: window.branding?.primaryColor ?? COLORS.GRAY_10,
  logoPath: window.branding?.logoImageFile?.url ?? 'assets/images/Singlewire-InformaCast-White.svg',
  logoAltText: core_t(['logoAltText']),
  customHelpUrl: null,
};

export const BrandingContext = createContext<Partial<BrandingState>>(defaultBranding);
