import { hashHistory } from '../core/RootReducer';
import { safeJsonStringify } from '../core/utils/json';
import Log, { LogLevel } from '../core/utils/log';

export function initLoggingInterceptor() {
  interface LevelDefinition {
    limit: number;
    priority: number;
  }

  interface LoggingOptions {
    loggingLevel: string;
    flushIntervalMs: number;
    fatal: LevelDefinition;
    error: LevelDefinition;
    warn: LevelDefinition;
    info: LevelDefinition;
    log: LevelDefinition;
    debug: LevelDefinition;
  }

  interface LoggingBuffers {
    fatal: ReadonlyArray<any>;
    error: ReadonlyArray<any>;
    warn: ReadonlyArray<any>;
    info: ReadonlyArray<any>;
    debug: ReadonlyArray<any>;
  }

  const DEFAULT_OPTS = {
    loggingLevel: 'info',
    flushIntervalMs: 5000,
    fatal: { limit: 25, priority: 4 },
    error: { limit: 25, priority: 3 },
    warn: { limit: 25, priority: 2 },
    info: { limit: 25, priority: 1 },
    log: { limit: 25, priority: 1 },
    debug: { limit: 25, priority: 0 },
  };

  /**
   * Given a key and value that can be used as an argument to JSON.stringify, if the key
   * is 'Authorization', replace the value with '[removed]' so that it is not sent to our
   * express app for logging.
   * @param key the key to potentially sanitize
   * @param value the value to keep or sanitize
   * @returns {any} either the original value or '[removed]'
   */
  function JSONSanitizer(key: any, value: any): any {
    return key.toString().toLowerCase() === 'authorization' ? '[removed]' : value;
  }

  class LoggingInterceptor {
    opts: LoggingOptions;
    buffers: LoggingBuffers;
    shipLogsTimeout?: any;

    /**
     * Configure the logging capturer with optional options
     * @param {Object} [opts] - The logging capturer's options
     */
    constructor(opts = {}) {
      this.opts = Object.assign({}, DEFAULT_OPTS, opts);
      this.buffers = {
        fatal: [],
        error: [],
        warn: [],
        info: [],
        debug: [],
      };
      this.shipLogsTimeout = null;
    }

    /**
     * Flushes the buffer for either all logging levels or just the one specified
     * @param {string} [level] - An optional logging level to flush
     */
    flush(level: string) {
      if (level) {
        this.buffers[level] = [];
      } else {
        Object.keys(this.buffers).forEach(k => this.flush(k));
      }
    }

    /**
     * If the priority of the supplied logging level is lower than the configured logging
     * level, skip the log entirely. Otherwise, add the val to the correct buffer and clamp
     * it to the buffers max size.
     * @param {*} val - The val to add to the logging level's buffer
     * @param {string} [level] - The logging level. Defaults to the configured default.
     */
    log(val: any, level = this.opts.loggingLevel) {
      if (DEFAULT_OPTS[level].priority >= DEFAULT_OPTS[this.opts.loggingLevel].priority) {
        const limit = this.opts[level].limit;
        const localStorageSession = window.localStorage['admin-webapp.session'];
        const parsedSession = localStorageSession && JSON.parse(localStorageSession);
        this.buffers[level] = Object.freeze(
          this.buffers[level].concat([
            {
              createdAt: new Date(),
              user: parsedSession
                ? {
                    providerId: parsedSession.providerId,
                    id: parsedSession.userId,
                  }
                : undefined,
              value: val,
              location: hashHistory?.location?.pathname,
            },
          ])
        );
        if (this.buffers[level].length > limit) {
          this.buffers[level] = Object.freeze(
            this.buffers[level].slice(this.buffers[level].length - limit)
          );
        }
      }
    }

    /**
     * Sets the logging level for this capturer
     * @param {string} level - The logging level
     */
    setLoggingLevel(level: string) {
      this.opts.loggingLevel = level;
    }

    /**
     * Sets the max buffer size for the supplied logging level
     * @param {string} level - The logging level
     * @param {Number} limit - The limit of the buffer size
     */
    setLimit(level: string, limit: number) {
      this.opts[level].limit = limit;
    }

    /**
     * If the logging level is supplied, checks the specific buffer to see if there are any
     * log messages. If no level is supplied, check if any of the buffers contain log data.
     * @param {string} level - The logging level
     * @returns {boolean} whether there are logs in the buffer
     */
    isEmpty(level: string | null = null) {
      if (level) {
        return this.buffers[level].length === 0;
      } else {
        return Object.keys(this.buffers).every(key => this.buffers[key].length === 0);
      }
    }

    /**
     * Gets the logs for the specified logging level. If no level is supplied, an object containing
     * all of the logs is given back. The underlying buffers are not mutated so this should be safe.
     * @param {string} [level] - the logging level
     * @returns {{fatal: Array, error: Array, warn: Array, info: Array, debug: Array}|Array} the logs
     */
    logs(level: string) {
      if (level) {
        return this.buffers[level];
      } else {
        return this.buffers;
      }
    }

    /**
     * Listens for any global error and registers it as a fatal log message to add to the buffer
     * @param {Window} [win] - An optional window to override onerror on
     */
    registerGlobalErrorHandler(win: any = window) {
      win.onerror = (msg: any, url: any, line: any, col: any, error: any) => {
        this.log({ msg, url, line, col, error }, 'fatal');
        return false;
      };
    }

    /**
     * Starts piping the logs to our express server. Every flushIntervalMs when we have logs available,
     * issues a POST request to our express server that ships the logs up. Note that this is using
     * XMLHttpRequest instead of React/Axios because we want to be bootstrapped before everything else
     * and I don't want any more polyfills or external dependencies at this point.
     */
    startShippingLogsToServer() {
      this.shipLogsTimeout = setTimeout(() => {
        if (!this.isEmpty()) {
          const payload = { buffers: this.buffers };
          const req = new XMLHttpRequest();
          req.open('POST', 'log');
          req.onreadystatechange = () => {
            if (req.readyState === (XMLHttpRequest.DONE || 4)) {
              if (req.status === 200) {
                Object.keys(payload.buffers).forEach(key => {
                  this.buffers[key] = this.buffers[key].filter(
                    (log: any) => !payload.buffers[key].includes(log)
                  );
                });
              } else {
                window.console.error(
                  'There was a problem flushing the logs',
                  req.status,
                  req.responseText
                );
              }
              this.startShippingLogsToServer();
            }
          };
          req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
          req.send(safeJsonStringify(payload, JSONSanitizer));
        } else {
          this.startShippingLogsToServer();
        }
      }, this.opts.flushIntervalMs);
    }

    /**
     * Stops piping logs to our express server
     */
    stopShippingLogsToServer() {
      if (this.shipLogsTimeout) {
        window.clearTimeout(this.shipLogsTimeout);
      }
    }
  }

  const logInterceptor = new LoggingInterceptor();
  logInterceptor.registerGlobalErrorHandler();
  Log.addMiddleware((level, message, optionalParams) => {
    const args = optionalParams ? [message].concat(optionalParams) : [message];
    if (level >= LogLevel.Debug && level < LogLevel.Info) {
      logInterceptor.log(args, 'debug');
    } else if (level >= LogLevel.Info && level < LogLevel.Warn) {
      logInterceptor.log(args, 'info');
    } else if (level >= LogLevel.Warn && level < LogLevel.Error) {
      logInterceptor.log(args, 'warn');
    } else if (level >= LogLevel.Error && level < LogLevel.Fatal) {
      logInterceptor.log(args, 'error');
    } else {
      logInterceptor.log(args, 'fatal');
    }
    return true;
  });

  window.LoggingInterceptor = LoggingInterceptor;
  window.logInterceptor = logInterceptor;
}
