// Handle state related to icop servers.

import { Action, createAction } from 'typesafe-actions';
import { MobileApiIcopServer } from '../../mobile-api-types';

// Handled in: Reducer
// Add icop server to store.
export const updateIcopServers = createAction(
  '[ICOP Servers] Set Servers',
  (servers: MobileApiIcopServer[]) => ({ servers })
)();

// Handled in: Epic
// Check our ICOP extension endpoint and set our active ICOP server in store.
export const refreshIcopServers = createAction(
  '[ICOP Servers] Refresh Servers',
  ({ cb }: { cb?: (error?: any) => Action[] }) => ({ cb })
)();

// Handled in: Reducer, Epic
// Set ICOP server id in localStorage.
export const setIcopServerId = createAction(
  '[ICOP Servers] Set Server Id',
  (id: string, withNotice: boolean) => ({
    id,
    withNotice, // Whether to growl a notice when we've succesfully set the new id.
  })
)();

// Handled in: Epic
// Asks the user to confirm a server change (if dirty form or ongoing operation)
export const confirmFusionServerChange = createAction(
  '[ICOP Servers] Ask User to Change Fusion Server',
  (id: string) => ({ id })
)();

// Handled in: Epic
// Periodically check that our ICOP server is still reachable.
export const startMonitoringApplicationHealth = createAction(
  '[Health] Start Monitoring Application Health'
)();

// Handled in: Epic
// Periodically check which ICOP servers have live CallAware calls.
export const startMonitoringLiveCallAwareCalls = createAction(
  '[CallAware] Start Monitoring Live CallAware Calls'
)();
