import React from 'react';
import styled from 'styled-components';

const SkipLinkContainer = styled.div`
  .skip-link {
    top: 1rem;
    left: -15rem;
    position: absolute;
    z-index: 50;

    &:focus {
      left: 1rem;
    }
  }
`;

interface SkipLinkProperties {
  className?: string;

  children: React.ReactElement;

  skipTo: string;
}

const SkipLink: React.FC<SkipLinkProperties> = props => {
  const onClick = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const container: HTMLElement | null = document.querySelector(props.skipTo);

    if (container) {
      container.tabIndex = -1;
      container.focus();
      setTimeout(() => container.removeAttribute('tabindex'), 1000);
    }
  };

  return (
    <SkipLinkContainer>
      {React.cloneElement(props.children, { onClick, className: props.className })}
    </SkipLinkContainer>
  );
};

SkipLink.defaultProps = {
  className: 'skip-link',
};

export default SkipLink;
