import i18next from 'i18next';
import { Box, Typography } from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import Routes from '../../../core/route/Routes';
import { parseQueryParams } from '../../../core/utils/querystring';
import { shared_t } from '../../shared/SharedLocale';
import { SinglewireIcon } from '../../shared/SinglewireIcon';
import { Alert } from '../../shared/components/Alert';
import { LinkButton } from '../../shared/components/Button';
import { Page } from '../../shared/components/Page';
import { Paper } from '../../shared/components/Paper';
import { ExceptionProps } from './ExceptionContainer';
import { exception_t } from './ExceptionLocale';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const RightContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 70px;
`;

const PaperContainer = styled.div`
  display: block;
`;

export const Exception = ({ isLoggedIn, location, navigateTo }: ExceptionProps) => {
  const params = parseQueryParams(location.search.substr(1));
  const title =
    params?.error && i18next.exists(`exception:${params.error}`)
      ? exception_t([params.error as any])
      : shared_t(['exception', 'title']);
  const exceptionMessage =
    (params && params.error_description) ||
    (params && params.exceptionMessage) ||
    shared_t(['exception', 'message']);

  return (
    <>
      {isLoggedIn && (
        <Page title={title} id="expection-page" pageTitle={title}>
          <Paper>
            <Alert
              color="error"
              primaryButton={{
                label: common_t(['button', 'home']),
                onClick: () => navigateTo(Routes.Home),
              }}>
              {exceptionMessage}
            </Alert>
          </Paper>
        </Page>
      )}

      {!isLoggedIn && (
        <Container id="one">
          <RightContainer id="two">
            <PaperContainer id="four">
              <Paper>
                <Box isFlex={false}>
                  <Typography variant="h1">Whoops!</Typography>
                  <Box my="lg">
                    <Typography variant="helptext">{exceptionMessage}</Typography>
                  </Box>

                  <Box isFlex={false}>
                    <LinkButton
                      id="link-home-button"
                      to={Routes.Home}
                      startIcon={<SinglewireIcon icon="home" />}
                      label={shared_t(['exception', 'homeButton'])}
                    />
                  </Box>
                </Box>
              </Paper>
            </PaperContainer>
          </RightContainer>
        </Container>
      )}
    </>
  );
};
