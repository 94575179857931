import { Action } from 'redux';
import { createAction } from 'typesafe-actions';
import { ApiPermission, ApiRequest } from '../../common-types';

// Handled in: Epic
// Monitor all permission requests and add them to localStorage.
export const startPermissionsWatchers = createAction('[Permissions] Start Watchers')();

// Handled in: Epic
// Stop writing new permissions to localStorage.
export const stopPermissionsWatchers = createAction('[Permissions] Stop Watchers')();

// Handled in: Reducer, Epic
// Always clear pending checks
// Clear our permissions localStorage cache when no opts or opts.request
// With opts, clean a specific permission out of the caches (localStorage and store).
export const clearPermissions = createAction('[Permissions] Clear')();

// Handled in: Epic
// Refreshes the user's permissions and updates the cache
export const refreshPermissions = createAction(
  '[Permissions] Refresh',

  (payload: { cb?: (error?: any) => Action[]; useCacheIfAvailable?: boolean }) => payload
)();

// Handled in: Epic
// Notifies any listeners that permissions refresh has been completed.
export const refreshPermissionsComplete = createAction('[Permissions] Refresh Complete')();

// Handled in: Epic
// Load permissions from localStorage.
export const initializePermissions = createAction('[Permissions] Initialize')();

// Handled in: Reducer
// Update permissions in store.
export const updatePermissions = createAction(
  '[Permissions] Update Permissions',
  (permissions: ApiPermission[]) => ({ permissions })
)();

// Handled in: Reducer
// Add permission to store.
export const addPermission = createAction(
  '[Permissions] Add Permission',
  (permission: ApiPermission) => ({ permission })
)();

// Handled in: Epic
// Check a permission, making a network request if the permissionLookupResult isn't
// in the cache.
export const checkPermission = createAction(
  '[Permissions] Check Permission',
  (
    apiRequest: ApiRequest,
    cb: ((hasPermission: boolean, err?: any) => Action[]) | null = null
  ) => ({ apiRequest, cb })
)();

// Handled in: Reducer
// Unlocks the permissions so things like creator permissions can be checked again
export const unlockPermissions = createAction('[Permissions] Unlock Permissions')();
