import { showModal } from './../../core/modal/ModalActions';
import { MobileApiIncident } from './../../mobile-api-types';
import { navigateTo } from './../../core/navigation/NavigationActions';
import { connect } from 'react-redux';
import { IncidentsListItem } from './IncidentsListItem';
import { bindActionCreators, Dispatch } from 'redux';

interface ExternalProps {
  incident: MobileApiIncident;
  canEndIncidents?: boolean;
}

export type IncidentsListItemProps = ExternalProps & ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ navigateTo, showModal }, dispatch);

export const IncidentsListItemContainer = connect(null, mapDispatchToProps)(IncidentsListItem);
