import { registerResource } from '../../../core/utils/localization';

export const ExceptionLocale = {
  en: {
    oauth: {
      error: {
        title: 'Login Failed',
      },
    },
  },
};

export const exception_t = registerResource<typeof ExceptionLocale.en>(
  'exception',
  ExceptionLocale
);
