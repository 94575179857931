import * as brandingActions from './BrandingActions';
import { ActionType, getType } from 'typesafe-actions';
import { core_t } from '../CoreLocale';

export type BrandingAction = ActionType<typeof brandingActions>;

export interface BrandingState {
  primaryColor: string | null;
  secondaryColor: string | null;
  logoPath: string | null;
  logoAltText: string;
  customHelpUrl: string | null;
  fetchingBranding: boolean;
}

export const initialBrandingState: BrandingState = {
  primaryColor: window.branding?.primaryColor ?? null,
  secondaryColor: window.branding?.secondaryColor ?? null,
  logoPath: window.branding?.logoImageFile?.url ?? null,
  logoAltText: core_t(['logoAltText']),
  customHelpUrl: null,
  fetchingBranding: true,
};

export default (
  state: BrandingState = initialBrandingState,
  action: BrandingAction
): BrandingState => {
  switch (action.type) {
    case getType(brandingActions.setBranding):
      return { ...state, ...action.payload.state };

    case getType(brandingActions.fetchBranding):
      return { ...state, fetchingBranding: true };

    default:
      return state;
  }
};
