// Handle state related generic network actions

import { AxiosError, AxiosResponse } from 'axios';
import { Action } from 'redux';
import { createAction } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';

export interface MakeReqOptions {
  successActions?: (response: AxiosResponse<any>) => Action[];
  failureActions?: (error: AxiosError | any) => Action[];
  reqRef?: symbol;
}

// Handled in: Epic
// Send an api request to create or edit a thing.
export const makeReq = createAction(
  '[Network] Make Req',
  (apiRequest: ApiRequest, opts?: MakeReqOptions) => ({ apiRequest, opts })
)();
