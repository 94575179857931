/**
 * This utility function solely exists to help the type-checker enforce that developers handle
 * every possible case in a switch statement.
 *
 * Example:
 *   type AB = 'a' | 'b';
 *   const _ = (x: AB) => {
 *     switch (x) {
 *       case 'a':
 *         return 'a';
 *       default:
 *         // Compilation error: there's no `case` for 'b', so `x` is not type `never`.
 *         return exhaustivePatternMatchGuard(x);
 *     }
 *   };
 * @param _ the expression used in the switch to be checked.
 */
export const exhaustivePatternMatchGuard = (_: never): never => {
  throw new Error(`Error: Missed condition: ${_}.`);
};

/**
 * Same as `exhaustivePatternMatchGuard`, but allows for a sane default value instead of an error.
 * @param _
 * @param defaultValue
 */
export const exhaustivePatternMatchGuardReturns = <T>(_: never, defaultValue: T): T => {
  return defaultValue;
};

/**
 * Assert a variable is of a specific type.
 * Useful for asserting that variable declared w/ `as const` also conform to a specific type.
 * @param _
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const typeAssert = <T>(_: T): void => {};

/**
 * Spread operator + ternaries wreak havoc on type inference.
 * Instead of spreading Arrays within Arrays like:
 *   `[...(pred ? [x, y, z] : []), ...]`
 * you can simply construct an Array _of_ Array like:
 *   `[(pred ? [x, y, z] : [], ...]`
 * and then flatten it, preserving types.
 * @param arr
 */
export const flatten = <T>(arr: T[][]): T[] => arr.flat();
