import * as grid from './GridActions';
import { ActionType, getType } from 'typesafe-actions';

export type GridAction = ActionType<typeof grid>;

export interface GridState {
  activeKey: string;
}

export interface GridsState {
  grids: Map<string, GridState>;
}

export const initialGridsState: GridsState = {
  grids: new Map<string, GridState>(),
};

export default function gridReducer(
  state: GridsState = initialGridsState,
  action: GridAction
): GridsState {
  switch (action.type) {
    case getType(grid.makeActive):
      return {
        ...state,
        grids: new Map<string, GridState>(
          Array.from(state.grids.entries()).concat([
            [action.payload.gridId, { activeKey: action.payload.activeKey } as GridState],
          ])
        ),
      };

    default:
      return state;
  }
}
