import { connect } from 'react-redux';
import { Exception } from './Exception';
import { RootState } from '../../../core/RootReducer';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { navigateTo } from '../../../core/navigation/NavigationActions';

export type ExternalProps = Pick<RouteComponentProps<any>, 'location'>;
export type ExceptionProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigateTo,
    },
    dispatch
  );

export const mapStateToProps = (state: RootState) => ({
  isLoggedIn: !!state.session,
});

export const ExceptionContainer = connect(mapStateToProps, mapDispatchToProps)(Exception);
