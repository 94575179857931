import { LOCATION_CHANGE } from 'connected-react-router';
import { History } from 'history';
import { createAction } from 'typesafe-actions';

// Handled in: Epic
// Cancel all in-progress requests.
export const cancelRequests = createAction(
  '[Navigation] Cancel Requests',
  (requestTokenRef: any) => ({ requestTokenRef })
)();

// Handled in: Reducer, Epic
// Force a reload of the current page.
export const reloadCurrentPage = createAction('[Navigation] Reload Current Page')();

// Handled in: Epic
// Force all modals, dropdowns, and tooltips to close.
export const closeDismissibleComponents = createAction(
  '[Navigation] Close Dismissible Components'
)();

// Handled in: Epic
// Change the route location to the given location.
export const navigateTo = createAction('[Navigation] Navigate', (location: History.Path) => ({
  location,
}))();

// This is an action from connected-react-router, we shouldn't be dispatching it.
// It is triggered every time a route change is complete.
export const locationChangeComplete = createAction(
  LOCATION_CHANGE,
  (location: { pathname: string; hash: string; search: string }, action: string) => ({
    location,
    action,
  })
)();
