import { createAction } from 'typesafe-actions';
import { BrandingState } from './BrandingReducer';

/**
 * Handled in: Epic
 */
export const fetchBranding = createAction('[Branding] Fetch Branding')();

/**
 * Handled in: Reducer
 */
export const setBranding = createAction('[Branding] Set Branding', (state: BrandingState) => ({
  state,
}))();
