import isEmpty from 'lodash/isEmpty';
import { ActionType, getType } from 'typesafe-actions';
import { MobileApiIcopServer } from '../../mobile-api-types';
import * as icopServers from './ICOPServersActions';

export type IcopServerAction = ActionType<typeof icopServers>;

export interface IcopServerState {
  servers: MobileApiIcopServer[];
  selectedICOPServerId: string | null;
  selectedICOPServer: MobileApiIcopServer | null;
  speakerMigrationStatus?: 'none' | 'some' | 'all';
  dialcastMigrationStatus?: 'none' | 'some' | 'all';
}

export const initialICOPServerState: IcopServerState = {
  servers: [],
  selectedICOPServerId: null,
  selectedICOPServer: null,
  speakerMigrationStatus: 'none',
  dialcastMigrationStatus: 'none',
};

export default function icopServersReducer(
  state: IcopServerState = initialICOPServerState,
  action: IcopServerAction
): IcopServerState {
  switch (action.type) {
    case getType(icopServers.updateIcopServers):
      return {
        ...state,
        servers: action.payload.servers,
        speakerMigrationStatus: action.payload.servers.every(server => server.hasMigratedSpeakers)
          ? 'all'
          : action.payload.servers.some(server => server.hasMigratedSpeakers)
            ? 'some'
            : 'none',
        dialcastMigrationStatus: action.payload.servers.every(server => server.hasMigratedDialcast)
          ? 'all'
          : action.payload.servers.some(server => server.hasMigratedDialcast)
            ? 'some'
            : 'none',
      };

    case getType(icopServers.setIcopServerId):
      const selectedICOPServerId = action.payload.id;
      return {
        ...state,
        selectedICOPServerId,
        selectedICOPServer:
          !isEmpty(state.servers) && selectedICOPServerId
            ? state.servers.find(x => x.id === selectedICOPServerId) || null
            : state.selectedICOPServer,
      };

    default:
      return state;
  }
}
