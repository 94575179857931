import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../../core/RootReducer';
import store from '../../core/store';
import { BackgroundRef, reqAll, req } from '../../core/utils/api';
import { getAllReports, setReportsData, deleteAnonymousUsers } from './ReportsActions';
import { Action } from 'redux';
import { common_t } from '../../CommonLocale';
import { showConfirmationModal } from '../modal/ModalEpics';
import { forkJoin, of } from 'rxjs';
import { reports_t } from './ReportsLocale';
import { searchPagerRefresh } from '../search-pager/SearchPagerActions';
import { hideModal } from '../modal/ModalActions';

export const DEFAULT_REPORTS_LIMIT = 1000;

export const fetchAllReportsEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(getAllReports)),
    mergeMap(action =>
      reqAll(action.payload.request, store, BackgroundRef).pipe(
        switchMap(response => [setReportsData(action.payload.reportId, response)]),
        catchError(error => [])
      )
    )
  );

export const deleteAnonymousUsersEpic: Epic<any, any, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(deleteAnonymousUsers)),
    switchMap(action => {
      return showConfirmationModal(
        action$,
        {
          title: common_t(['resourceTitle', 'delete'], {
            resource: common_t(['resource', 'anonymousUsers']),
          }),
          message: reports_t(['anonymous', 'deleteText']),
        },
        () =>
          forkJoin(
            action.payload.userIds.map(userId =>
              req(['users', [userId], 'DELETE'], store, BackgroundRef).pipe(
                catchError(val => of(val))
              )
            )
          ).pipe(
            switchMap(() => {
              action.payload.cb(); // Used to clear local state in the anonymous users report
              return [searchPagerRefresh(action.payload.pagerId), hideModal()];
            }),
            catchError(error => [])
          )
      );
    })
  );

export default combineEpics(fetchAllReportsEpic, deleteAnonymousUsersEpic);
