import { useEffect } from 'react';
import { useOverlay } from './Overlay';
import { shared_t } from './SharedLocale';

interface PageTitleProps {
  title: string;
}

const setTitle = (newTitle: string) => {
  const titleElement = document?.head && (document.head.querySelector('title') as HTMLElement);

  if (titleElement) {
    titleElement.innerText = `${newTitle} - ${shared_t(['page', 'title'])}`;
  }
};

// Allows us to specify a page's title inside a React component. Just a side-effect, no display.
export const PageTitle = ({ title }: PageTitleProps) => {
  const overlayContext = useOverlay();

  useEffect(() => {
    setTitle(title);

    // Update page title back to parent when overlay unmounts if necessary.
    return () => {
      if (overlayContext?.parentPageTitle) {
        setTitle(overlayContext.parentPageTitle);
      }
    };
  }, [overlayContext, title]);

  return <></>;
};
