import {
  startMonitoringIncidents,
  updateIncidents,
  fetchOngoingIncidents,
  fetchOngoingIncidentsBackground,
} from './IncidentsActions';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EMPTY, timer } from 'rxjs';
import { RootState } from '../RootReducer';
import { isBrowserVisible } from '../utils/common';
import { BackgroundRef, CancellableRef, req } from '../utils/api';
import { PermissionDeniedError } from '../../common-types';
import { MobileApiIncident, MobileApiPageEnvelope } from '../../mobile-api-types';
import store from '../store';
import log from '../utils/log';
import { logout } from '../session/SessionActions';
import {
  IgnoreLoadingBarTransform,
  IgnoreSessionExtendTransform,
} from '../utils/http-transformers';
import { ApiRequest } from '../../common-types';
import { AxiosRequestConfig } from 'axios';

/**
 * @param cancelTypeRef indicate request cancel behavior
 * @param transforms override default request behavior
 * @returns observable request result
 */
const buildFetchOngoingIncidents =
  (
    cancelTypeRef: symbol,
    transforms?: ((options: AxiosRequestConfig, req: ApiRequest) => AxiosRequestConfig)[]
  ) =>
  () =>
    req<MobileApiPageEnvelope<MobileApiIncident>>(
      [
        'incidents',
        [],
        'GET',
        {
          params: { ongoingOnly: true },
          transforms,
        },
      ],
      store,
      cancelTypeRef
    ).pipe(
      map(response => updateIncidents(response.data)),
      catchError(error => {
        if (!(error instanceof PermissionDeniedError)) {
          log.error('Failed to determine ongoing incidents', error);
        }
        return EMPTY;
      })
    );

// Periodically check for ongoing incidents every 30s starting immediately
export const incidentPollingEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(startMonitoringIncidents)),
    switchMap(() =>
      timer(0, 30000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(
          buildFetchOngoingIncidents(BackgroundRef, [
            IgnoreSessionExtendTransform,
            IgnoreLoadingBarTransform,
          ])
        ),
        takeUntil(action$.pipe(ofType(logout)))
      )
    )
  );

export const fetchOngoingIncidentsEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(fetchOngoingIncidents)),
    switchMap(buildFetchOngoingIncidents(CancellableRef))
  );

export const fetchOngoingIncidentsBackgroundEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(fetchOngoingIncidentsBackground)),
    switchMap(
      buildFetchOngoingIncidents(BackgroundRef, [
        IgnoreSessionExtendTransform,
        IgnoreLoadingBarTransform,
      ])
    )
  );

export default combineEpics(
  incidentPollingEpic,
  fetchOngoingIncidentsEpic,
  fetchOngoingIncidentsBackgroundEpic
);
