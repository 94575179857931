import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { TextField as RegistryTextField } from 'singlewire-components';
import { getErrorContainerId } from '../../../../core/forms/FormUtils';
import { shared_t } from '../../SharedLocale';
import { getErrors, parseErrors } from './utils';

interface ExternalProps {
  /** Whether or not we show an info icon when text contains template variables */
  shouldShowTemplateInfo?: boolean;
  helperText?: string[];
  multiline?: boolean;
  resizable?: boolean;
}

const hasVariables = (text: string) => {
  if (!isNil(text) && typeof text === 'string') {
    let delimStart = text.indexOf('{{');
    let delimEnd = text.indexOf('}}');
    if (delimStart === -1 || delimEnd === -1) {
      delimStart = text.indexOf('${');
      delimEnd = text.indexOf('}');
    }
    return delimStart > -1 && delimEnd > -1 && delimEnd > delimStart;
  }
  return false;
};

export const TextField = (
  props: Partial<WrappedFieldProps> &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    ExternalProps
) => {
  const errors = getErrors(props);
  const errorContainerId = getErrorContainerId(props.meta!.form, props.input!.name);
  const { ref, shouldShowTemplateInfo, ...rest } = props;

  return (
    <RegistryTextField
      {...rest}
      {...props.input}
      value={props.input?.value || props.value || ''}
      id={props.input!.name}
      color={'primary' as any}
      error={props.meta?.error && props.meta?.submitFailed}
      helperText={!isEmpty(errors) ? parseErrors(errors) : rest.helperText}
      showVariablesIcon={shouldShowTemplateInfo && hasVariables(props.input?.value)}
      variablesTooltip={shared_t(['characterCounter', 'templateVarWarning'], {
        fieldDescription: shared_t(['characterCounter', 'defaultFieldDesc']),
        maxCount: rest.maxLength,
      })}
      helperTextId={errorContainerId}
      helperTextClassName="field-errors-container"
      aria-describedby={errorContainerId}
    />
  );
};
