import * as ruleBuilderActions from './RuleBuilderActions';
import { ActionType, getType } from 'typesafe-actions';

export type RuleBuilderAction = ActionType<typeof ruleBuilderActions>;

export interface RuleBuilderState {
  ruleFilterErrors?: Array<{ field: string; fieldType: string; message: string }>;
  isFilterEditMode: boolean;
}

export default function ruleBuilderReducer(
  state: RuleBuilderState = { isFilterEditMode: false },
  action: RuleBuilderAction
): RuleBuilderState {
  switch (action.type) {
    case getType(ruleBuilderActions.setRuleFilterErrors):
      return {
        ...state,
        ruleFilterErrors: action.payload.errors,
      };
    case getType(ruleBuilderActions.setEditMode):
      return {
        ...state,
        isFilterEditMode: action.payload.isEditing,
      };
    default:
      return state;
  }
}
