import { AxiosResponse } from 'axios';
import { Action } from 'redux';
import { createAction } from 'typesafe-actions';
import { MobileApiSession } from '../../mobile-api-types';
import { LogoutReason } from '../login/LoginReducer';

// Handled in: Epic
// Periodically check localStorage and make sure that the store is the same.
export const startSessionWatchers = createAction('[Session] Start Session Watchers')();

// Handled in: Epic
// Stop checking localStorage.
export const stopSessionWatchers = createAction('[Session] Stop Session Watchers')();

interface RefreshSessionParams {
  skipPermissionCheck?: boolean;
  successActions?: (actions: AxiosResponse<any>) => Action[];
}

// Handled in: Epic
// Request a fresh session from the server and update the store with it, optionally
// dispatching actions after successful completion.
export const refreshSession = createAction(
  '[Session] Refresh Session',
  (refreshSessionParams: RefreshSessionParams = {}) => ({ ...refreshSessionParams })
)();

// Handled in: Reducer, Epic
// Update the session in the store, add session to localStorage.
export const updateSession = createAction(
  '[Session] Update Session',
  (session: MobileApiSession | null) => ({ session })
)();

// Handled in: Epic
// Everytime we make a network request, extend the session expiration timeout.
export const bumpSessionExpiration = createAction('[Session] Bump Session Expiration')();

// Handled in: Epic
// Removes the session and dispatches cleanup actions.
export const logout = createAction(
  '[Session] Logout',
  (reason: LogoutReason = LogoutReason.Default) => ({ reason })
)();

// Handled in: Epic
// Asks the user if he/she wishes to log out only if there is a dirty form
export const askUserToLogOut = createAction('[Session] Ask to Log Out')();

// Handled in: Epic
// Opens a tab for the IDP in the browser
export const sendUserToIdp = createAction('[Session] Send user to IDP')();
