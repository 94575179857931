import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'redux';
import { RootState } from '../RootReducer';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  withLatestFrom,
  startWith,
} from 'rxjs/operators';
import { refreshSideBar, setMenuElements, updateSearch, updateActiveRoute } from './SideBarActions';
import { isActionOf } from 'typesafe-actions';
import { SideBarAction } from './SideBarReducer';
import { concat, forkJoin, from, Observable, of } from 'rxjs';
import {
  ElementLink,
  ElementMenu,
  getMenuElements,
  shouldShowElementLink,
} from '../utils/side-bar';
import { refreshPermissionsComplete } from '../permissions/PermissionsActions';
import { licenseChanged } from '../license/LicenseActions';
import store from '../store';
import { locationChangeComplete } from '../navigation/NavigationActions';
import { setIcopServerId } from '../icop-servers/ICOPServersActions';

export function filterNode(
  element: any,
  parentMatches = false,
  search: string
): Observable<any | null> {
  // if search matches a parent node, we want search to act as if it has matched every child of that node.
  if (search && !parentMatches) {
    const regexp = new RegExp(search.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'), 'i');
    if (element.title.match(regexp)) {
      parentMatches = true;
    } else if (!element.children) {
      return of(null);
    }
  }

  if (!element.children) {
    const link: ElementLink = element;
    return from(shouldShowElementLink(link, store)).pipe(
      map(shouldDisplay => (shouldDisplay ? link : null))
    );
  } else {
    const menu: ElementMenu = element;
    return forkJoin(menu.children.map(child => filterNode(child, parentMatches, search))).pipe(
      map(shouldDisplayChildren => {
        const displayedNode = cloneDeep(menu);
        displayedNode.children = shouldDisplayChildren.filter(child => !!child);
        if (!isEmpty(displayedNode.children)) {
          return displayedNode;
        }
        return null;
      })
    );
  }
}

// Updates sidebar after search query has changed.
export const searchEpic: Epic<SideBarAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(updateSearch)),
    debounceTime(200),
    map(action => action.payload.query),
    distinctUntilChanged(),
    map(() => refreshSideBar())
  );

// Updates active side menu element after route change.
export const activeRouteEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(locationChangeComplete)),
    map(action => updateActiveRoute(action.payload.location.pathname)),
    startWith(updateActiveRoute(window.location.hash.substr(1))),
    filter(x => x.payload.url !== '/reload')
  );

// Re-evaluate sidebar menu elements to see if they should be displayed.
export const refreshEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([refreshSideBar, licenseChanged, setIcopServerId, refreshPermissionsComplete])
    ),
    withLatestFrom(state$.pipe(map(state => state.sideBar.search))),
    switchMap(([, searchQuery]) =>
      concat(
        of(setMenuElements([])),
        forkJoin(getMenuElements().map(element => filterNode(element, false, searchQuery))).pipe(
          map(result => setMenuElements(result.filter(elem => !!elem)))
        )
      )
    )
  );

export default combineEpics(searchEpic, refreshEpic, activeRouteEpic);
