import i18next, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import FormLocale from '../forms/FormLocale';
import log from './log';

i18next.on('languageChanged', (lng: keyof typeof FormLocale) => {
  setLocale(FormLocale[lng]);
});

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false, skipOnVariables: false },
  lng: 'en', // language to use
  resources: {},
  initImmediate: false,
  saveMissing: true,
  missingKeyHandler(lngs, ns, key, fallbackValue) {
    log.warn(
      `Failed to localize string for key: ${
        ns ? `${ns}:` : ''
      }${key}. Using fallback: ${fallbackValue} for languages: ${JSON.stringify(lngs)}`
    );
  },
});

const SUPPORTED_TRANSLATIONS = ['en'];

export const registerLocalizationResource = (
  namespace: string,
  localizationResource: Record<string, unknown>
) => {
  const localeKeys = Object.keys(localizationResource);
  localeKeys.forEach(localeKey => {
    // If the language key isn't supported we throw an error
    if (SUPPORTED_TRANSLATIONS.indexOf(localeKey) === -1) {
      throw Error(`Language key '${localeKey}' not supported`);
    }

    if (i18next.hasResourceBundle(localeKey, namespace)) {
      i18next.removeResourceBundle(localeKey, namespace);
    }
    i18next.addResourceBundle(localeKey, namespace, localizationResource[localeKey]);
  });
};

// Registering initial localization files for views
export const initLocalizations = () => {
  registerLocalizationResource('form', FormLocale);
};

/**
 * Use when rendering interpolated i18n text with ReactDOM dangerouslySetInnerHTML
 * Makes it easier to do the right thing!
 * NB: Ensure that registerLocalizationResource has been called before using this
 * @param key The i18n key
 * @param options i18next TranslationOptions
 */
export const localizeHTMLString = <
  TInterpolationMap extends Record<string, unknown> = Record<string, unknown>
>(
  key: string,
  options?: TOptions<TInterpolationMap>
) => ({
  __html: i18next.t(`${key}__HTML__`, {
    ...(options || ({} as TOptions<TInterpolationMap>)),
    interpolation: { ...((options && options.interpolation) || {}), escapeValue: true },
  }),
});

type Dictionary = string | DictionaryObject;
interface DictionaryObject {
  [K: string]: Dictionary;
}

export interface TypedTranslationFunction<
  D extends Dictionary,
  TInterpolationMap extends Record<string, unknown> = Record<string, unknown>
> {
  <K extends keyof D>(args: [K], options?: TOptions<TInterpolationMap>): D[K];
  <K extends keyof D, K1 extends keyof D[K]>(
    args: [K, K1],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1];
  <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1]>(
    args: [K, K1, K2],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1][K2];
  <
    K extends keyof D,
    K1 extends keyof D[K],
    K2 extends keyof D[K][K1],
    K3 extends keyof D[K][K1][K2]
  >(
    args: [K, K1, K2, K3],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1][K2][K3];
  <
    K extends keyof D,
    K1 extends keyof D[K],
    K2 extends keyof D[K][K1],
    K3 extends keyof D[K][K1][K2],
    K4 extends keyof D[K][K1][K2][K3]
  >(
    args: [K, K1, K2, K3, K4],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1][K2][K3][K4];
  <
    K extends keyof D,
    K1 extends keyof D[K],
    K2 extends keyof D[K][K1],
    K3 extends keyof D[K][K1][K2],
    K4 extends keyof D[K][K1][K2][K3],
    K5 extends keyof D[K][K1][K2][K3][K4]
  >(
    args: [K, K1, K2, K3, K4, K5],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1][K2][K3][K4][K5];
  <
    K extends keyof D,
    K1 extends keyof D[K],
    K2 extends keyof D[K][K1],
    K3 extends keyof D[K][K1][K2],
    K4 extends keyof D[K][K1][K2][K3],
    K5 extends keyof D[K][K1][K2][K3][K4],
    K6 extends keyof D[K][K1][K2][K3][K4][K5]
  >(
    args: [K, K1, K2, K3, K4, K5, K6],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1][K2][K3][K4][K5][K6];
  <
    K extends keyof D,
    K1 extends keyof D[K],
    K2 extends keyof D[K][K1],
    K3 extends keyof D[K][K1][K2],
    K4 extends keyof D[K][K1][K2][K3],
    K5 extends keyof D[K][K1][K2][K3][K4],
    K6 extends keyof D[K][K1][K2][K3][K4][K5],
    K7 extends keyof D[K][K1][K2][K3][K4][K5][K6]
  >(
    args: [K, K1, K2, K3, K4, K5, K6, K7],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1][K2][K3][K4][K5][K6][K7];
  <
    K extends keyof D,
    K1 extends keyof D[K],
    K2 extends keyof D[K][K1],
    K3 extends keyof D[K][K1][K2],
    K4 extends keyof D[K][K1][K2][K3],
    K5 extends keyof D[K][K1][K2][K3][K4],
    K6 extends keyof D[K][K1][K2][K3][K4][K5],
    K7 extends keyof D[K][K1][K2][K3][K4][K5][K6],
    K8 extends keyof D[K][K1][K2][K3][K4][K5][K6][K7]
  >(
    args: [K, K1, K2, K3, K4, K5, K6, K7, K8],
    options?: TOptions<TInterpolationMap>
  ): D[K][K1][K2][K3][K4][K5][K6][K7][K8];
}

export const registerResource = <
  T extends Dictionary,
  TInterpolationMap extends Record<string, unknown> = Record<string, unknown>
>(
  namespace: string,
  locales: any
): TypedTranslationFunction<T, TInterpolationMap> => {
  registerLocalizationResource(namespace, locales);
  return (keys: string[], options?: TOptions<TInterpolationMap>) =>
    i18next.t(`${namespace}:${keys.join('.')}`, options);
};
