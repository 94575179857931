import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import { ActionType, getType } from 'typesafe-actions';
import { LOWER_48_BOUNDS } from '../../constants';
import { MobileApiTrackingEvent, MobileApiTrackingEventActivity } from '../../mobile-api-types';
import { GoogleMapsLatLngBoundsMock } from '../utils/IPAWSUtils';
import * as TrackingEventsActions from './TrackingEventsActions';

export type TrackingEventsAction = ActionType<typeof TrackingEventsActions>;

export interface TrackingEventsState {
  bounds:
    | google.maps.LatLngBounds
    | google.maps.LatLngBoundsLiteral
    | GoogleMapsLatLngBoundsMock
    | null;
  events: MobileApiTrackingEvent[];
  activities: MobileApiTrackingEventActivity[];
  activeEvents: MobileApiTrackingEvent[];
}

export const initialTrackingEventsState: TrackingEventsState = {
  bounds: null,
  events: [],
  activities: [],
  activeEvents: [],
};

export default function reducer(
  state: TrackingEventsState = initialTrackingEventsState,
  action: TrackingEventsAction
): TrackingEventsState {
  switch (action.type) {
    case getType(TrackingEventsActions.boundsChanged):
      return { ...state, bounds: action.payload.bounds };

    case getType(TrackingEventsActions.setTrackingEvents):
      return {
        ...state,
        events: action.payload.events,
        bounds: getMarkerBounds(
          action.payload.events
            .map(event => event.summary || [])
            .reduce((arr, val) => arr.concat(val), [])
        ),
      };

    case getType(TrackingEventsActions.setActiveTrackingEvents):
      return {
        ...state,
        activeEvents: action.payload.events,
      };

    case getType(TrackingEventsActions.setActivities):
      return {
        ...state,
        activities: action.payload.activities,
        bounds: getMarkerBounds(
          action.payload.activities.map(activity => {
            const [lng, lat] = activity.geometryValue.geometry.coordinates;
            return { lat, lng };
          })
        ),
      };

    default:
      return state;
  }
}

export const getMarkerBounds = (
  activities: Array<{
    lat: number;
    lng: number;
  }>
) => {
  const latLonPairs = activities
    .filter(activity => !!activity.lat && !!activity.lng)
    .map(activity => {
      const { lat, lng } = activity;
      return { lat, lng };
    });

  if (latLonPairs.length === 0) {
    return LOWER_48_BOUNDS;
  }

  return {
    south: minBy(latLonPairs, (pair: { lat: number; lng: number }) => pair.lat)!.lat - 0.0005,
    north: maxBy(latLonPairs, (pair: { lat: number; lng: number }) => pair.lat)!.lat + 0.0005,
    west: minBy(latLonPairs, (pair: { lat: number; lng: number }) => pair.lng)!.lng - 0.0005,
    east: maxBy(latLonPairs, (pair: { lat: number; lng: number }) => pair.lng)!.lng + 0.0005,
  };
};
