import omit from 'lodash/omit';
import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '../RootReducer';
import * as route from './RouteActions';

export type RouteAction = ActionType<typeof route>;

export interface RouteState {
  data: { [key: string]: any };
  dependents: { [key: string]: number };
  errors?: { [key: string]: any };
}

export const initialRouteState: RouteState = {
  data: {},
  dependents: {},
};

const decrementDependentsOrRemoveKeys = (state: RouteState, keys: string[]): RouteState => {
  return keys.reduce((acc, key) => {
    const numberOfDependents = state.dependents[key] || 0;
    if (numberOfDependents <= 1) {
      return { ...acc, data: omit(acc.data, [key]), dependents: omit(acc.dependents, [key]) };
    } else {
      return { ...acc, dependents: { ...acc.dependents, [key]: numberOfDependents - 1 } };
    }
  }, state);
};

const incrementDependentsKeys = (state: RouteState, keys: string[]): RouteState => {
  return keys.reduce((acc, key) => {
    const numberOfDependents = state.dependents[key] || 0;
    return { ...acc, dependents: { ...acc.dependents, [key]: numberOfDependents + 1 } };
  }, state);
};

export default function routeReducer(
  state: RouteState = initialRouteState,
  action: RouteAction
): RouteState {
  switch (action.type) {
    case getType(route.resolveRouteCleanup):
      return decrementDependentsOrRemoveKeys(state, action.payload.keys);
    case getType(route.resolveRouteData):
      return incrementDependentsKeys(state, Object.keys(action.payload.data));
    case getType(route.resolveRouteDataSuccess):
      return {
        ...state,
        data: Object.keys(action.payload.data).reduce(
          (data, key) =>
            (state.dependents[key] || 0) > 0 ? { ...data, [key]: action.payload.data[key] } : data,
          state.data
        ),
      };
    case getType(route.resolveRouteUpdateData):
      // replace state.data keys with payload.data.key iff it exists in the state already
      return {
        ...state,
        data: Object.keys(action.payload.data).reduce(
          (data, key) =>
            data.hasOwnProperty(key) ? { ...data, [key]: action.payload.data[key] } : data,
          state.data
        ),
      };
    case getType(route.resolveDataError):
      return {
        ...state,
        errors: { ...state.errors, [JSON.stringify(action.payload.data)]: action.payload.error },
      };
    case getType(route.resolveErrorCleanup):
      if (state?.errors) {
        const { [action.payload.resolveErrorKey]: toRemove, ...restErrors } = state.errors;
        return { ...state, errors: restErrors };
      } else {
        return state;
      }
    default:
      return state;
  }
}

// Returns the resolved route data from the store. Note this will crash if the key doesn't exist!
export function getResolveData<T>(state: RootState, key: string): T {
  return state.route.data![key];
}
