// This file concerns actions relating to changing the window.location

import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { hideModal } from '../modal/ModalActions';
import { hashHistory, RootState } from '../RootReducer';
import { cancelRequests as apiCancelRequests } from '../utils/api';
import { stringifyQueryParams } from '../utils/querystring';
import {
  cancelRequests,
  closeDismissibleComponents,
  navigateTo,
  reloadCurrentPage,
} from './NavigationActions';

export const cancelRequestsEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(cancelRequests)),
    switchMap(action => {
      apiCancelRequests(action.payload.requestTokenRef);
      return [];
    })
  );

export const reloadCurrentPageEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(reloadCurrentPage)),
    withLatestFrom(state$.pipe(map(state => state.router.location))),
    map(([, location]) =>
      navigateTo(
        `/reload?${stringifyQueryParams({
          destination: `${location.pathname}${location.search || ''}${location.hash || ''}`,
        })}`
      )
    )
  );

export const navigateEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(navigateTo)),
    switchMap(action => {
      hashHistory.push(action.payload.location);
      return [];
    })
  );

//  Several legacy jQuery components need to be removed upon navigation such as modals, tooltips,
//  select2 inputs, etc.
export const closeDismissibleComponentsEpic: Epic<Action, Action, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(closeDismissibleComponents)),
    withLatestFrom(state$.pipe(map(state => state.modal))),
    switchMap(([, { modal }]) => {
      return [...(modal && !modal.dontCloseOnNavigate ? [hideModal()] : [])];
    })
  );

export default combineEpics(
  cancelRequestsEpic,
  reloadCurrentPageEpic,
  closeDismissibleComponentsEpic,
  navigateEpic
);
