export function initPendo() {
  (function(p: Window, e: Document, n: string, d: string, o?: any) {
    o = p[d] = p[d] || {};
    o._q = o._q || [];
    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (let w = 0, x = v.length; w < x; ++w)
      (function(m) {
        o[m] =
          o[m] ||
          function(...rest: any[]) {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(rest, 0)));
          };
      })(v[w]);
    const y = e.createElement(n) as HTMLScriptElement;
    y.async = !0;
    y.src = 'assets/scripts/pendo.xhr.js';
    const z = e.getElementsByTagName(n)[0] as HTMLScriptElement;
    z.parentNode?.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
}
