import { createAction } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';
import { ResolveApiAdvancedConfig } from '../../views/shared/ResolveDataSafely';

/**
 * Handled in: Epic
 * Make a network request, then add the response to the store.
 */
export const resolveRouteData = createAction(
  '[Route] Resolve Route Data',
  (data: { [key: string]: ApiRequest | ResolveApiAdvancedConfig }) => ({ data })
)();

/**
 * Handled in: Epic
 * Refresh mounted route data, then add the response to the store.
 */
export const refreshRouteData = createAction(
  '[Route] Refresh Route Data',
  (data: { [key: string]: ApiRequest | ResolveApiAdvancedConfig }) => ({ data })
)();

/**
 * Handled in: Reducer
 * Removes resolved data from the store.
 */
export const resolveRouteCleanup = createAction(
  '[Route] Resolve Route Data Cleanup',
  (keys: string[]) => ({ keys })
)();

/**
 * Handled in: Reducer
 * Adds resolved data to the store.
 */
export const resolveRouteDataSuccess = createAction(
  '[Route] Resolve Route Data Success',
  (data: { [key: string]: any }) => ({ data })
)();

/**
 * Handled in: Reducer
 * Replace the supplied keys with new values in the resolve data.
 */
export const resolveRouteUpdateData = createAction(
  '[Route] Update Resolve Data',
  (data: { [key: string]: any }) => ({ data })
)();

export const resolveDataError = createAction(
  '[Route] Resolve Data Error',
  (data: { [key: string]: ApiRequest | ResolveApiAdvancedConfig }, error: any) => ({ data, error })
)();

export const resolveErrorCleanup = createAction(
  '[Resolve] Resolve Error Cleanup',
  (resolveErrorKey: string) => ({ resolveErrorKey })
)();
