import { FiltersAsQueryParams, SearchAndPaginationFilters } from './SearchPagerActions';

/**
 * Filters that allow multiselect behave differently than filters that only permit one selection.
 * Because of that we have a `type` key on filters {@see SearchAndPaginationFilters}
 * However the API does not care about this key and needs the data in a different form.
 * This function transforms the filters to a format the API can interpret.
 * @param filters
 */
export const transformFiltersToQueryParams = (
  filters: SearchAndPaginationFilters | null
): FiltersAsQueryParams | null => {
  return (
    filters &&
    Object.entries(filters).reduce((acc, [key, filter]) => {
      if (filter.type === 'multi') {
        acc[key] = filter.transformFilterValue
          ? filter.transformFilterValue(filter.value)
          : filter.value;
      } else {
        acc[key] = filter.value;
      }
      return acc;
    }, {} as FiltersAsQueryParams)
  );
};
