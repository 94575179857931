// Handle the state for our sidebars.

import { createAction } from 'typesafe-actions';
import { GrowlServiceOptions } from '../utils/growl';
import { ToastContent, ToastOptions } from 'react-toastify';

export enum LayoutType {
  BoxedLayout = 0,
  StretchedLayout = 1,
}

export enum RightSidebarType {
  NONE = 0,
  SEND = 1,
  CANCEL = 2,
}

export enum SidebarType {
  Left = 0,
  Right = 1,
}

// Handled in: Epic
// Fetch layout preference from localStorage and set store accordingly.
export const initializeLayout = createAction('[Layout] Initialize')();

// Handled in: Reducer
// Toggle layout type between streched and boxed.
export const toggleLayout = createAction('[Layout] Toggle Layout')();

// Handled in: Reducer, Epic
// Set layout type in store, and set layout type in localStorage.
export const setLayout = createAction('[Layout] Set Layout', (layoutType: LayoutType) => ({
  layoutType,
}))();

export type GrowlType = typeof growl;

// Handled in: Epic
// Presents a growl message to the user
export const growl = createAction(
  '[Layout] Growl',
  (
    content: string | ToastContent | null,
    opts: GrowlServiceOptions = {},
    toastOverrides?: ToastOptions
  ) => ({ content, opts, toastOverrides })
)();

// Handled in: Reducer
// Close given sidebar.
export const closeSidebar = createAction('[Layout] Close Sidebar', (side: SidebarType) => ({
  side,
}))();

// Handled in: Reducer
// Close all sidebars.
export const closeSidebars = createAction('[Layout] Close Sidebars')();

// Handled in: Reducer
// Toggle given sidebar.
export const toggleSidebar = createAction('[Layout] Toggle Sidebar', (side: SidebarType) => ({
  side,
}))();

// Handled in: Reducer
// Toggle right sidebar type.
export const toggleRightSidebarType = createAction(
  '[Layout] Toggle Right Sidebar',
  (type: RightSidebarType) => ({ type })
)();

/**
 * Handled in: Epic
 * Initiates a GET request to /notifications to get the latest 20 notifications.
 * Used for polling for latest changes to latest 20 notifications
 */
export const startNotificationsPolling = createAction('[Layout] Start Notifications Polling')();

// Handled in: Reducer
// Set notifications in store.
export const updateNotifications = createAction('[Layout] Update Notifications', (data: any) => ({
  data,
}))();

/**
 * Handled in: Epic
 * Stop a GET request to /notifications to get the latest 20 notifications.
 */
export const stopNotificationsPolling = createAction('[Layout] Stop Notifications Polling')();

/**
 * Handled in: Epic
 * Initiates a GET request to /notifications to the most recentqq    active notifications.
 */
export const startActiveNotificationsPolling = createAction(
  '[Layout] Start Active Notifications Polling'
)();

// Handled in: Reducer
// Set hasActiveNotifications in store.
export const updateActiveNotifications = createAction(
  '[Layout] Update Active Notifications',
  (data: any) => ({ data })
)();

// Handled in: Reducer
// Toggle showResourceCenter in store.
export const toggleResourceCenter = createAction('[Layout] Toggle Resource Center Button')();
