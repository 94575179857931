import { Location } from 'history';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { noop } from '../utils/common';

export interface FormMeta {
  skipDirtyFormChecking?: (location: Location) => boolean;
}

export interface ISinglewireFormsContext {
  forms: Record<string, FormMeta>;
}

export interface ISinglewireFormsRegistrationContext {
  registerForm: (formId: string, form: FormMeta) => void;
  unregisterForm: (formId: string) => void;
}

const DEFAULT_FORMS_CONTEXT: ISinglewireFormsContext = {
  forms: {},
};

const DEFAULT_FORMS_REGISTRATION_CONTEXT: ISinglewireFormsRegistrationContext = {
  registerForm: noop,
  unregisterForm: noop,
};

const SinglewireFormsContext = createContext<ISinglewireFormsContext>(DEFAULT_FORMS_CONTEXT);
const SinglewireFormsRegistrationContext = createContext<ISinglewireFormsRegistrationContext>(
  DEFAULT_FORMS_REGISTRATION_CONTEXT
);

export const SinglewireFormsContextProvider = ({
  children,
}: PropsWithChildren<Record<string, unknown>>) => {
  const [forms, setForms] = useState<Record<string, FormMeta>>({});
  const registerForm = useCallback(
    (formId: string, form: FormMeta) => {
      setForms(currentForms => {
        return { ...currentForms, [formId]: form };
      });
    },
    [setForms]
  );
  const unregisterForm = useCallback(
    (formId: string) => {
      setForms(currentForms => {
        const { [formId]: toRemove, ...remainingForms } = currentForms;
        return toRemove ? remainingForms : currentForms;
      });
    },
    [setForms]
  );

  const formsValue = useMemo<ISinglewireFormsContext>(() => ({ forms }), [forms]);
  const formsRegistrationValue = useMemo<ISinglewireFormsRegistrationContext>(
    () => ({ registerForm, unregisterForm }),
    [registerForm, unregisterForm]
  );
  return (
    <SinglewireFormsContext.Provider value={formsValue}>
      <SinglewireFormsRegistrationContext.Provider value={formsRegistrationValue}>
        <>{children}</>
      </SinglewireFormsRegistrationContext.Provider>
    </SinglewireFormsContext.Provider>
  );
};

export const useSinglewireForms = () => useContext<ISinglewireFormsContext>(SinglewireFormsContext);
export const useSinglewireFormsRegistration = () =>
  useContext<ISinglewireFormsRegistrationContext>(SinglewireFormsRegistrationContext);

export default SinglewireFormsContextProvider;
