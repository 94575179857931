import { common_t } from '../../CommonLocale';
import { ROOT_DOMAIN_ID } from '../../constants';
import {
  DomainedResource,
  DomainedResourceSpec,
  DomainedResourceSpecKey,
  MobileApiDomain,
} from '../../mobile-api-types';
import { DomainIncidentsListItem } from '../../views/shared/DomainsIncidentListItem';
import { DomainNotificationsListItem } from '../../views/shared/NotificationsListItem';
import Routes from '../route/Routes';
import { exhaustivePatternMatchGuard } from '../utils/typeUtils';

/** Format a domain name for display, special-casing the root domain. */
export const formatDomainName = (domain: MobileApiDomain) =>
  domain.id === ROOT_DOMAIN_ID ? `/ (${domain.name})` : domain.name;

/** Format a domain name path for display, special-casing the root domain. */
export const formatDomainNamePath = (domain: MobileApiDomain) =>
  domain.namePath + (domain.id === ROOT_DOMAIN_ID ? ` (${domain.name})` : '');

/** Check whether the specified resource is a member of the specified domain. */
export const isResourceInDomain = (resource: DomainedResource) => (domain: MobileApiDomain) =>
  resource.domains
    ? resource.domains.filter(candidate => candidate.id === domain.id).length > 0
    : false;

/** Find all the domains which are ancestors of a specific domain. Will not include the domain itself. */
export const getAncestors = (allDomains: MobileApiDomain[]) => (domain: MobileApiDomain) =>
  allDomains.filter(
    candidate => domain.path.startsWith(candidate.path) && domain.path !== candidate.path
  );

/** Find all the domains which are descendants of a specific domain. Will not include the domain itself. */
export const getDescendants = (allDomains: MobileApiDomain[]) => (domain: MobileApiDomain) =>
  allDomains.filter(
    candidate => candidate.path.startsWith(domain.path) && candidate.path !== domain.path
  );

/** Determines if domainA is an ancestor of domainB. Does not include the domain itself */
export const isAncestorOf = (domainA: MobileApiDomain, domainB: MobileApiDomain) =>
  domainB.path.startsWith(domainA.path) && domainB.path !== domainA.path;

/**
 * Determines if domainB can be seen when acting in domainA. Is true if domainA is
 * an ancestor of domainB, or if they are the same domain.
 */
export const isVisibleFrom = (domainA: MobileApiDomain, domainB: MobileApiDomain) =>
  domainB.path.startsWith(domainA.path);

/**
 * For every resource that can belong in domains, identifies the information needed to
 * build the UI pages for displaying and managing them. The keys are a string identifying
 * the resource, and which is present in the DomainedResource interface (which extends
 * ApiResource), and the value is the pieces of information needed to build and run the UI.
 */
export const buildResourceSpec = (
  resourceKey: DomainedResourceSpecKey,
  oauthAppsDisabled?: boolean
): DomainedResourceSpec => {
  const appLocaleSection = oauthAppsDisabled ? 'oauthAppsDisabled' : 'oauthAppsEnabled';
  switch (resourceKey) {
    case '/anonymous-users':
      return {
        resourceKey: '/anonymous-users',
        singularName: common_t(['resource', 'anonymousUser']),
        pluralName: common_t(['resource', 'anonymousUsers']),
        routes: {
          DomainsTab: Routes.AnonymousUsers.DomainsTab,
          Edit: Routes.AnonymousUsers.Edit,
          List: Routes.AnonymousUsers.List,
          ManageDomains: Routes.AnonymousUsers.ManageDomains,
          ManageResource: Routes.Domains.ManageAnonymousUsers,
          ResourcesTab: Routes.Domains.AnonymousUsersTab,
        },
        joinInverseApiKey: 'usersDomains',
        joinApiKey: 'domainsUsers',
        resourceApiKey: 'users',
        resourceRouteDataKey: 'user',
        filter: { type: 'anonymous' },
      };

    case '/service-accounts':
      return {
        resourceKey: '/service-accounts',
        singularName: common_t(['resource', appLocaleSection, 'serviceAccount']),
        pluralName: common_t(['resource', appLocaleSection, 'serviceAccounts']),
        routes: {
          DomainsTab: Routes.ServiceAccounts.DomainsTab,
          Edit: Routes.ServiceAccounts.Edit,
          List: Routes.ServiceAccounts.List,
          ManageDomains: Routes.ServiceAccounts.ManageDomains,
          ManageResource: Routes.Domains.ManageServiceAccounts,
          ResourcesTab: Routes.Domains.ServiceAccountsTab,
        },
        joinInverseApiKey: 'usersDomains',
        joinApiKey: 'domainsUsers',
        resourceApiKey: 'users',
        resourceRouteDataKey: 'user',
        filter: { type: 'application', excludeScim: true },
      };

    case '/bell-schedules':
      return {
        resourceKey: '/bell-schedules',
        singularName: common_t(['resource', 'bellSchedule']),
        pluralName: common_t(['resource', 'bellSchedules']),
        routes: {
          DomainsTab: Routes.Bells.Schedules.DomainsTab,
          Edit: Routes.Bells.Schedules.Edit,
          List: Routes.Bells.Schedules.List,
          ManageDomains: Routes.Bells.Schedules.ManageDomains,
          ManageResource: Routes.Domains.ManageBellSchedules,
          ResourcesTab: Routes.Domains.BellSchedulesTab,
        },
        joinInverseApiKey: 'bellSchedulesDomains',
        joinApiKey: 'domainsBellSchedules',
        resourceApiKey: 'bellSchedules',
        resourceRouteDataKey: 'bellSchedule',
        featuresRequired: ['fusion'],
      };

    case '/cisco-webex-teams':
      return {
        resourceKey: '/cisco-webex-teams',
        singularName: common_t(['resource', 'ciscoWebexTeamsSpace']),
        singularNameLower: common_t(['resource', 'ciscoWebexTeamsSpaceLower']),
        pluralName: common_t(['resource', 'ciscoWebexTeamsSpaces']),
        pluralNameLower: common_t(['resource', 'ciscoWebexTeamsSpacesLower']),
        routes: {
          DomainsTab: Routes.CiscoWebexTeams.DomainsTab,
          Edit: Routes.CiscoWebexTeams.Edit,
          List: Routes.CiscoWebexTeams.List,
          ManageDomains: Routes.CiscoWebexTeams.ManageDomains,
          ManageResource: Routes.Domains.ManageCiscoWebexTeams,
          ResourcesTab: Routes.Domains.CiscoWebexTeamsTab,
        },
        joinInverseApiKey: 'collaborationGroupsDomains',
        joinApiKey: 'domainsCollaborationGroups',
        resourceApiKey: 'collaborationGroups',
        resourceRouteDataKey: 'ciscoWebexTeam',
        filter: { type: 'cisco-spark' },
        featuresRequired: ['webexTeams'],
      };

    case '/confirmation-requests':
      return {
        resourceKey: '/confirmation-requests',
        singularName: common_t(['resource', 'confirmationRequest']),
        pluralName: common_t(['resource', 'confirmationRequests']),
        routes: {
          DomainsTab: Routes.ConfirmationRequests.DomainsTab,
          Edit: Routes.ConfirmationRequests.Edit,
          List: Routes.ConfirmationRequests.List,
          ManageDomains: Routes.ConfirmationRequests.ManageDomains,
          ManageResource: Routes.Domains.ManageConfirmationRequests,
          ResourcesTab: Routes.Domains.ConfirmationRequestsTab,
        },
        joinInverseApiKey: 'confirmationRequestsDomains',
        joinApiKey: 'domainsConfirmationRequests',
        resourceApiKey: 'confirmationRequests',
        resourceRouteDataKey: 'confirmationRequest',
      };

    case '/device-groups':
      return {
        resourceKey: '/device-groups',
        singularName: common_t(['resource', 'deviceGroup']),
        pluralName: common_t(['resource', 'deviceGroups']),
        routes: {
          DomainsTab: Routes.DeviceGroups.DomainsTab,
          Edit: Routes.DeviceGroups.Edit,
          List: Routes.DeviceGroups.List,
          ManageDomains: Routes.DeviceGroups.ManageDomains,
          ManageResource: Routes.Domains.ManageDeviceGroups,
          ResourcesTab: Routes.Domains.DeviceGroupsTab,
        },
        joinInverseApiKey: 'deviceGroupsDomains',
        joinApiKey: 'domainsDeviceGroups',
        resourceApiKey: 'deviceGroups',
        resourceRouteDataKey: 'deviceGroup',
        featuresRequired: ['fusion'],
      };

    case '/distribution-lists':
      return {
        resourceKey: '/distribution-lists',
        singularName: common_t(['resource', 'distributionList']),
        pluralName: common_t(['resource', 'distributionLists']),
        routes: {
          DomainsTab: Routes.DistributionLists.DomainsTab,
          Edit: Routes.DistributionLists.Edit,
          List: Routes.DistributionLists.List,
          ManageDomains: Routes.DistributionLists.ManageDomains,
          ManageResource: Routes.Domains.ManageDistributionLists,
          ResourcesTab: Routes.Domains.DistributionListsTab,
        },
        joinInverseApiKey: 'distributionListsDomains',
        joinApiKey: 'domainsDistributionLists',
        resourceApiKey: 'distributionLists',
        resourceRouteDataKey: 'distributionList',
      };

    case '/incident-plans':
      return {
        resourceKey: '/incident-plans',
        singularName: common_t(['resource', 'incidentPlan']),
        pluralName: common_t(['resource', 'incidentPlans']),
        routes: {
          DomainsTab: Routes.CommandCenter.IncidentPlans.DomainsTab,
          Edit: Routes.CommandCenter.IncidentPlans.Edit,
          List: Routes.CommandCenter.IncidentPlans.List,
          ManageDomains: Routes.CommandCenter.IncidentPlans.ManageDomains,
          ManageResource: Routes.Domains.ManageIncidentPlans,
          ResourcesTab: Routes.Domains.IncidentPlansTab,
        },
        joinInverseApiKey: 'incidentPlansDomains',
        joinApiKey: 'domainsIncidentPlans',
        resourceApiKey: 'incidentPlans',
        resourceRouteDataKey: 'incidentPlan',
      };

    case '/incidents':
      return {
        resourceKey: '/incidents',
        singularName: common_t(['resource', 'incident']),
        pluralName: common_t(['resource', 'incidents']),
        routes: {
          DomainsTab: () => '',
          Edit: Routes.CommandCenter.Incidents.Details,
          List: Routes.CommandCenter.Incidents.List,
          ManageDomains: () => '',
          ManageResource: () => '',
          ResourcesTab: Routes.Domains.IncidentsTab,
        },
        joinInverseApiKey: 'incidentsDomains',
        joinApiKey: 'domainsIncidents',
        resourceApiKey: 'incidents',
        resourceRouteDataKey: 'incident',
        customDomainResourceItem: DomainIncidentsListItem,
        customResourceColName: common_t(['label', 'details']),
        readOnly: true,
      };

    case '/security-groups':
      return {
        resourceKey: '/security-groups',
        singularName: common_t(['resource', 'securityGroup']),
        pluralName: common_t(['resource', 'securityGroups']),
        routes: {
          DomainsTab: Routes.SecurityGroups.DomainsTab,
          Edit: Routes.SecurityGroups.Edit,
          List: Routes.SecurityGroups.List,
          ManageDomains: Routes.SecurityGroups.ManageDomains,
          ManageResource: Routes.Domains.ManageSecurityGroups,
          ResourcesTab: Routes.Domains.SecurityGroupsTab,
        },
        joinInverseApiKey: 'securityGroupsDomains',
        joinApiKey: 'domainsSecurityGroups',
        resourceApiKey: 'securityGroups',
        resourceRouteDataKey: 'securityGroup',
      };

    case '/load-definitions':
      return {
        resourceKey: '/load-definitions',
        singularName: common_t(['resource', 'userLoader']),
        pluralName: common_t(['resource', 'userLoaders']),
        routes: {
          DomainsTab: Routes.UserLoaders.DomainsTab,
          Edit: Routes.UserLoaders.Edit,
          List: Routes.UserLoaders.List,
          ManageDomains: Routes.UserLoaders.ManageDomains,
          ManageResource: Routes.Domains.ManageUserLoaders,
          ResourcesTab: Routes.Domains.UserLoadersTab,
        },
        joinInverseApiKey: 'loadDefinitionsDomains',
        joinApiKey: 'domainsLoadDefinitions',
        resourceApiKey: 'loadDefinitions',
        resourceRouteDataKey: 'userLoader',
        filter: { includedTypes: 'manual' },
      };

    case '/message-templates':
      return {
        resourceKey: '/message-templates',
        singularName: common_t(['resource', 'messageTemplate']),
        pluralName: common_t(['resource', 'messageTemplates']),
        routes: {
          DomainsTab: Routes.MessageTemplates.DomainsTab,
          Edit: Routes.MessageTemplates.Edit,
          List: Routes.MessageTemplates.List,
          ManageDomains: Routes.MessageTemplates.ManageDomains,
          ManageResource: Routes.Domains.ManageMessageTemplates,
          ResourcesTab: Routes.Domains.MessageTemplatesTab,
        },
        joinInverseApiKey: 'messageTemplatesDomains',
        joinApiKey: 'domainsMessageTemplates',
        resourceApiKey: 'messageTemplates',
        resourceRouteDataKey: 'messageTemplate',
        filter: { followUp: false },
      };

    case '/msteams-channels':
      return {
        resourceKey: '/msteams-channels',
        singularName: common_t(['resource', 'msteamsChannel']),
        singularNameLower: common_t(['resource', 'msteamsChannelLower']),
        pluralName: common_t(['resource', 'msteamsChannels']),
        pluralNameLower: common_t(['resource', 'msteamsChannelsLower']),
        routes: {
          DomainsTab: Routes.TeamsGroup.ChannelsTab.DomainsTab,
          Edit: Routes.TeamsGroup.ChannelsTab.Edit,
          List: Routes.TeamsGroup.ChannelsTab.List,
          ManageDomains: Routes.TeamsGroup.ChannelsTab.ManageDomains,
          ManageResource: Routes.Domains.ManageMSTeamsChannels,
          ResourcesTab: Routes.Domains.MSTeamsChannelsTab,
        },
        joinInverseApiKey: 'collaborationGroupsDomains',
        joinApiKey: 'domainsCollaborationGroups',
        resourceApiKey: 'collaborationGroups',
        resourceRouteDataKey: 'msteamsChannel',
        filter: { type: 'msteams-channel' },
        featuresRequired: ['msteamsChannel'],
      };

    case '/notification-profiles':
      return {
        resourceKey: '/notification-profiles',
        singularName: common_t(['resource', 'notificationProfile']),
        pluralName: common_t(['resource', 'notificationProfiles']),
        routes: {
          DomainsTab: Routes.NotificationProfiles.DomainsTab,
          Edit: Routes.NotificationProfiles.Edit,
          List: Routes.NotificationProfiles.List,
          ManageDomains: Routes.NotificationProfiles.ManageDomains,
          ManageResource: Routes.Domains.ManageNotificationProfiles,
          ResourcesTab: Routes.Domains.NotificationProfilesTab,
        },
        joinInverseApiKey: 'notificationProfilesDomains',
        joinApiKey: 'domainsNotificationProfiles',
        resourceApiKey: 'notificationProfiles',
        resourceRouteDataKey: 'notificationProfile',
      };

    case '/notifications':
      return {
        resourceKey: '/notifications',
        singularName: common_t(['resource', 'notification']),
        pluralName: common_t(['resource', 'notifications']),
        routes: {
          DomainsTab: Routes.Notifications.DomainsTab,
          Edit: Routes.Notifications.Details,
          List: Routes.Notifications.List,
          ManageDomains: () => '',
          ManageResource: () => '',
          ResourcesTab: Routes.Domains.NotificationsTab,
        },
        joinInverseApiKey: 'notificationsDomains',
        joinApiKey: 'domainsNotifications',
        resourceApiKey: 'notifications',
        resourceRouteDataKey: 'notification',
        customDomainResourceItem: DomainNotificationsListItem,
        customResourceColName: common_t(['label', 'details']),
        readOnly: true,
      };

    case '/outbound-cap':
      return {
        resourceKey: '/outbound-cap',
        singularName: common_t(['resource', 'outboundCap']),
        singularNameLower: common_t(['resource', 'outboundCapLower']),
        pluralName: common_t(['resource', 'outboundCapPlural']),
        pluralNameLower: common_t(['resource', 'outboundCapPluralLower']),
        routes: {
          DomainsTab: Routes.OutboundCap.DomainsTab,
          Edit: Routes.OutboundCap.Edit,
          List: Routes.OutboundCap.List,
          ManageDomains: Routes.OutboundCap.ManageDomains,
          ManageResource: Routes.Domains.ManageOutboundCap,
          ResourcesTab: Routes.Domains.OutboundCapTab,
        },
        joinInverseApiKey: 'collaborationGroupsDomains',
        joinApiKey: 'domainsCollaborationGroups',
        resourceApiKey: 'collaborationGroups',
        resourceRouteDataKey: 'outboundCap',
        filter: { type: 'outbound-cap' },
      };

    case '/outbound-rss':
      return {
        resourceKey: '/outbound-rss',
        singularName: common_t(['resource', 'outboundRss']),
        singularNameLower: common_t(['resource', 'outboundRssLower']),
        pluralName: common_t(['resource', 'outboundRssPlural']),
        pluralNameLower: common_t(['resource', 'outboundRssPluralLower']),
        routes: {
          DomainsTab: Routes.OutboundRss.DomainsTab,
          Edit: Routes.OutboundRss.Edit,
          List: Routes.OutboundRss.List,
          ManageDomains: Routes.OutboundRss.ManageDomains,
          ManageResource: Routes.Domains.ManageOutboundRss,
          ResourcesTab: Routes.Domains.OutboundRssTab,
        },
        joinInverseApiKey: 'collaborationGroupsDomains',
        joinApiKey: 'domainsCollaborationGroups',
        resourceApiKey: 'collaborationGroups',
        resourceRouteDataKey: 'outboundRss',
        filter: { type: 'outbound-rss' },
      };

    case '/conference-call':
      return {
        resourceKey: '/conference-call',
        singularName: common_t(['resource', 'conferenceCall']),
        pluralName: common_t(['resource', 'conferenceCallPlural']),
        routes: {
          DomainsTab: Routes.ConferenceCall.DomainsTab,
          Edit: Routes.ConferenceCall.Edit,
          List: Routes.ConferenceCall.List,
          ManageDomains: Routes.ConferenceCall.ManageDomains,
          ManageResource: Routes.Domains.ManageConferenceCall,
          ResourcesTab: Routes.Domains.ConferenceCallTab,
        },
        joinInverseApiKey: 'collaborationGroupsDomains',
        joinApiKey: 'domainsCollaborationGroups',
        resourceApiKey: 'collaborationGroups',
        resourceRouteDataKey: 'conferenceCall',
        filter: { type: 'conference-call' },
        featuresRequired: ['conferenceCall'],
      };

    case '/ring-lists':
      return {
        resourceKey: '/ring-lists',
        singularName: common_t(['resource', 'ringList']),
        pluralName: common_t(['resource', 'ringLists']),
        routes: {
          DomainsTab: Routes.Bells.RingLists.DomainsTab,
          Edit: Routes.Bells.RingLists.Edit,
          List: Routes.Bells.RingLists.List,
          ManageDomains: Routes.Bells.RingLists.ManageDomains,
          ManageResource: Routes.Domains.ManageRingLists,
          ResourcesTab: Routes.Domains.RingListsTab,
        },
        joinInverseApiKey: 'ringListsDomains',
        joinApiKey: 'domainsRingLists',
        resourceApiKey: 'ringLists',
        resourceRouteDataKey: 'ringList',
        featuresRequired: ['fusion'],
      };

    case '/scenarios':
      return {
        resourceKey: '/scenarios',
        singularName: common_t(['resource', 'scenario']),
        pluralName: common_t(['resource', 'scenarios']),
        routes: {
          DomainsTab: Routes.CommandCenter.Scenarios.DomainsTab,
          Edit: Routes.CommandCenter.Scenarios.Edit,
          List: Routes.CommandCenter.Scenarios.List,
          ManageDomains: Routes.CommandCenter.Scenarios.ManageDomains,
          ManageResource: Routes.Domains.ManageScenarios,
          ResourcesTab: Routes.Domains.ScenariosTab,
        },
        joinInverseApiKey: 'scenariosDomains',
        joinApiKey: 'domainsScenarios',
        resourceApiKey: 'scenarios',
        resourceRouteDataKey: 'scenario',
      };

    case '/site-roles':
      return {
        resourceKey: '/site-roles',
        singularName: common_t(['resource', 'siteRole']),
        pluralName: common_t(['resource', 'siteRoles']),
        routes: {
          DomainsTab: Routes.CommandCenter.SiteRoles.DomainsTab,
          Edit: Routes.CommandCenter.SiteRoles.Edit,
          List: Routes.CommandCenter.SiteRoles.List,
          ManageDomains: Routes.CommandCenter.SiteRoles.ManageDomains,
          ManageResource: Routes.Domains.ManageSiteRoles,
          ResourcesTab: Routes.Domains.SiteRolesTab,
        },
        joinInverseApiKey: 'siteRolesDomains',
        joinApiKey: 'domainsSiteRoles',
        resourceApiKey: 'siteRoles',
        resourceRouteDataKey: 'siteRole',
      };

    case '/sites':
      return {
        resourceKey: '/sites',
        singularName: common_t(['resource', 'site']),
        pluralName: common_t(['resource', 'sites']),
        routes: {
          DomainsTab: Routes.CommandCenter.Sites.DomainsTab,
          Edit: Routes.CommandCenter.Sites.Edit,
          List: Routes.CommandCenter.Sites.List,
          ManageDomains: Routes.CommandCenter.Sites.ManageDomains,
          ManageResource: Routes.Domains.ManageSites,
          ResourcesTab: Routes.Domains.SitesTab,
        },
        joinInverseApiKey: 'sitesDomains',
        joinApiKey: 'domainsSites',
        resourceApiKey: 'sites',
        resourceRouteDataKey: 'site',
      };

    case '/twitter':
      return {
        resourceKey: '/twitter',
        singularName: common_t(['resource', 'twitterAccount']),
        singularNameLower: common_t(['resource', 'twitterAccountLower']),
        pluralName: common_t(['resource', 'twitterAccounts']),
        pluralNameLower: common_t(['resource', 'twitterAccountsLower']),
        routes: {
          DomainsTab: Routes.Twitter.DomainsTab,
          Edit: Routes.Twitter.Edit,
          List: Routes.Twitter.List,
          ManageDomains: Routes.Twitter.ManageDomains,
          ManageResource: Routes.Domains.ManageTwitter,
          ResourcesTab: Routes.Domains.TwitterTab,
        },
        joinInverseApiKey: 'collaborationGroupsDomains',
        joinApiKey: 'domainsCollaborationGroups',
        resourceApiKey: 'collaborationGroups',
        resourceRouteDataKey: 'twitter',
        filter: { type: 'twitter' },
        featuresRequired: ['twitter'],
      };

    case '/users':
      return {
        resourceKey: '/users',
        singularName: common_t(['resource', 'user']),
        pluralName: common_t(['resource', 'users']),
        routes: {
          DomainsTab: Routes.Users.DomainsTab,
          Edit: Routes.Users.Edit,
          List: Routes.Users.List,
          ManageDomains: Routes.Users.ManageDomains,
          ManageResource: Routes.Domains.ManageUsers,
          ResourcesTab: Routes.Domains.UsersTab,
        },
        joinInverseApiKey: 'usersDomains',
        joinApiKey: 'domainsUsers',
        resourceApiKey: 'users',
        resourceRouteDataKey: 'user',
        filter: { type: 'regular' },
      };

    case '/rostered-users':
      return {
        resourceKey: '/rostered-users',
        singularName: common_t(['resource', 'rollCallUser']),
        pluralName: common_t(['resource', 'rollCallUsers']),
        routes: {
          DomainsTab: Routes.RosteredUsers.DomainsTab,
          Edit: Routes.RosteredUsers.Edit,
          List: Routes.RosteredUsers.List,
          ManageDomains: Routes.Users.ManageDomains,
          ManageResource: Routes.Domains.ManageRosteredUsers,
          ResourcesTab: Routes.Domains.RosteredUsersTab,
        },
        joinInverseApiKey: 'usersDomains',
        joinApiKey: 'domainsUsers',
        resourceApiKey: 'users',
        resourceRouteDataKey: 'user',
        filter: { type: 'rostered' },
      };

    case '/desktop-users':
      return {
        resourceKey: '/desktop-users',
        singularName: common_t(['resource', 'user']),
        pluralName: common_t(['resource', 'users']),
        routes: {
          DomainsTab: Routes.Users.DomainsTab,
          Edit: Routes.Users.Edit,
          List: Routes.Users.List,
          ManageDomains: Routes.Users.ManageDomains,
          ManageResource: Routes.Domains.ManageUsers,
          ResourcesTab: Routes.Domains.UsersTab,
        },
        joinInverseApiKey: 'usersDomains',
        joinApiKey: 'domainsUsers',
        resourceApiKey: 'users',
        resourceRouteDataKey: 'user',
        filter: { type: 'regular' },
      };

    default:
      return exhaustivePatternMatchGuard(resourceKey);
  }
};
