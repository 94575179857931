// This file handles the side effects for getting data about our license.

import {
  IgnoreLoadingBarTransform,
  IgnoreSessionExtendTransform,
} from '../utils/http-transformers';
import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'redux';
import moment from 'moment-timezone';
import { RootState } from '../RootReducer';
import { req, BackgroundRef } from '../utils/api';
import { MobileApiSession } from '../../mobile-api-types';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { licenseChanged, updateLicense, checkLicense } from './LicenseActions';
import { updateSession } from '../session/SessionActions';
import store from '../store';

const requestOptions = { transforms: [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform] };

// Check if license is valid and appropriately follow up if it isn't.
export const licenseCheckEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([checkLicense, licenseChanged])),
    withLatestFrom(state$.pipe(map(s => [s.session, s.icopServers.selectedICOPServerId] as const))),
    switchMap(([, [currentSession, selectedICOPServerId]]) =>
      // Every time we get a licenseChanged action, we want to recheck right away, not wait for the next interval
      req<MobileApiSession>(['session', [], 'GET', requestOptions], store, BackgroundRef).pipe(
        switchMap(({ data: session }) => {
          // If our current license status doesn't match our new license status, clear the permissions
          const isExpired = !!(
            session.providerExpiration && moment(session.providerExpiration).isBefore(moment())
          );
          const additionalActions = [
            updateSession({
              ...currentSession,
              ...session,
            }),
          ];

          return [
            ...additionalActions,
            updateLicense({ expired: isExpired, valid: session.isLicenseValid }),
          ];
        }),
        catchError(error => EMPTY)
      )
    )
  );

export default combineEpics(licenseCheckEpic);
