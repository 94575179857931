export enum LogLevel {
  Debug = 0,
  Info = 10,
  Warn = 20,
  Error = 30,
  Fatal = 40,
}

// return true to continue, false to stop
export type LogMiddleware = (level: number, message?: any, ...optionalParams: any[]) => boolean;

/**
 * A simpler logger interface to proxy console.<debug, log, info, warn, error>. Middleware can be added
 * and logging levels can be adjusted. Use this AND NOT console directly. This is most useful so we can
 * ship logs to the server in the logging interceptor file
 */
export class Logger {
  constructor(
    private loggingLevel: LogLevel = LogLevel.Info,
    private middleware: LogMiddleware[] = []
  ) {}

  setLevel(level: number | LogLevel) {
    this.loggingLevel = level;
  }

  addMiddleware(middleware: LogMiddleware) {
    this.middleware = [...this.middleware, middleware];
  }

  removeMiddleware(middleware: LogMiddleware) {
    this.middleware = this.middleware.filter(m => m === middleware);
  }

  logWithLevel(
    level: number,
    browserLogFn?: 'debug' | 'info' | 'warn' | 'error',
    message?: any,
    ...optionalParams: any[]
  ) {
    if (level >= this.loggingLevel) {
      const performConsoleLog = this.middleware.reduce(
        (acc, middleware) => acc && middleware(level, message, ...optionalParams),
        true
      );
      if (performConsoleLog && window.console) {
        if (browserLogFn && window.console[browserLogFn]) {
          window.console[browserLogFn](message, ...optionalParams);
        } else {
          window.console.log(message, ...optionalParams);
        }
      }
    }
  }

  debug = (message?: any, ...optionalParams: any[]) =>
    this.logWithLevel(LogLevel.Debug, 'debug', message, ...optionalParams);

  info = (message?: any, ...optionalParams: any[]) =>
    this.logWithLevel(LogLevel.Info, 'info', message, ...optionalParams);

  log = (message?: any, ...optionalParams: any[]) =>
    this.logWithLevel(LogLevel.Info, 'info', message, ...optionalParams);

  warn = (message?: any, ...optionalParams: any[]) =>
    this.logWithLevel(LogLevel.Warn, 'warn', message, ...optionalParams);

  error = (message?: any, ...optionalParams: any[]) => {
    if (optionalParams[0] && optionalParams[0].message === 'Network Error') {
      this.logWithLevel(LogLevel.Warn, 'warn', message, ...optionalParams);
    } else {
      this.logWithLevel(LogLevel.Error, 'error', message, ...optionalParams);
    }
  };

  fatal = (message?: any, ...optionalParams: any[]) =>
    this.logWithLevel(LogLevel.Fatal, 'error', message, ...optionalParams);
}

export default new Logger();
