import truncate from 'lodash/truncate';
import React, { PropsWithChildren } from 'react';
import { Box, TableCell, Typography } from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';

const StyledTableCell = styled(TableCell)`
  min-width: 12rem;
`;

export const ActionsCell: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <StyledTableCell align="right">
    <Box align="end" alignItems="center">
      {children}
    </Box>
  </StyledTableCell>
);

export const ActionsHeader: React.FC<PropsWithChildren<{}>> = () => (
  <Typography variant="helptext" srOnly>
    {common_t(['label', 'actions'])}
  </Typography>
);

// Only require a defaultTxt value, if txt can be undefined.
type TextOrDefault =
  | { txt: string; defaultTxt?: undefined }
  | { txt: string | undefined; defaultTxt: string };

export const TruncTableCell = ({
  txt,
  defaultTxt,
  length,
  ...props
}: React.ComponentProps<typeof TableCell> & { length: number } & TextOrDefault) => {
  const text = (txt || defaultTxt) as string;
  return (
    <TableCell title={text} {...props}>
      {truncate(text, { length })}
    </TableCell>
  );
};
