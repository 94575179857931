import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { MobileApiMSTeamsBot } from '../../mobile-api-types';
import { RootState } from '../RootReducer';
import store from '../store';
import { CancellableRef, req } from '../utils/api';
import { fetchBotStatus, updateMSTeamsState } from './MSTeamsActions';

export const fetchBotEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(fetchBotStatus)),
    switchMap(() =>
      req<MobileApiMSTeamsBot>(['extensionsTeamsStatus', [], 'GET'], store, CancellableRef).pipe(
        switchMap(response => [updateMSTeamsState({ bot: response.data, status: 'ok' })]),
        catchError(err => [updateMSTeamsState({ status: 'error' })])
      )
    )
  );

export default combineEpics(fetchBotEpic);
