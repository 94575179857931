import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '../../core/RootReducer';
import * as reports from './ReportsActions';

export type ReportsAction = ActionType<typeof reports>;

export type ReportsState = Record<string, any>;

export interface ReportsModuleState extends RootState {
  reports: ReportsState;
}

export const initialReportsState: ReportsState = {};

export default function reportsReducer(
  state: ReportsState = initialReportsState,
  action: ReportsAction
): ReportsState {
  switch (action.type) {
    case getType(reports.setReportsData):
      return { ...state, [action.payload.reportId]: action.payload.data };

    default:
      return state;
  }
}
