import { connect } from 'react-redux';
import { GLOBAL_PAGER_ID, QuickSend, PAGER_ID } from './QuickSend';
import { toggleRightSidebarType } from '../../core/layout/LayoutActions';
import { RootState } from '../../core/RootReducer';
import { getPager } from '../../core/search-pager/SearchPagerReducer';
import { MobileApiMessageTemplate } from '../../mobile-api-types';
import { searchPagerRefresh } from '../../core/search-pager/SearchPagerActions';
import { isFeatureEnabled } from '../../core/utils/session';

export type QuickSendProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export const mapDispatchToProps = {
  searchPagerRefresh,
  toggleRightSidebarType,
};

export const mapStateToProps = (state: RootState) => ({
  rightSidebarOpened: state.layout.rightSidebarOpened,
  rightSidebarType: state.layout.rightSidebarType,
  pager: getPager<MobileApiMessageTemplate>(state, PAGER_ID),
  globalPager: getPager<MobileApiMessageTemplate>(state, GLOBAL_PAGER_ID),
  facilitiesEnabled:
    isFeatureEnabled('facilitiesEnabled', state.session) &&
    !isFeatureEnabled('domains', state.session),
  actingFacilityId: state.facilities.actingFacilityId,
});

export const QuickSendContainer = connect(mapStateToProps, mapDispatchToProps)(QuickSend);
