import { RedirectRouteProps } from './RedirectRouteContainer';

import { Redirect } from 'react-router';
import { parseQueryParams } from '../../core/utils/querystring';

export const RedirectRoute = ({ currentSearch }: RedirectRouteProps) => {
  const destination = parseQueryParams((currentSearch || '').substring(1)).destination;
  const redirectLocation = (Array.isArray(destination) ? destination[0] : destination) || '/';
  return <Redirect to={redirectLocation} />;
};
