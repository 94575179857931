import React from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs, PageProps, Page as RegistryPage } from 'singlewire-components';
import styled from 'styled-components';
import { ApiRequest } from '../../../common-types';
import { refLookup } from '../../../constants';
import { RootState } from '../../../core/RootReducer';
import { DomainVisibilityProtectorContainer } from '../Domains/DomainVisibilityProtector/DomainVisibilityProtectorContainer';
import { FailoverEditingBackupInfoBannerContainer } from '../FailoverEditingBackupInfoBannerContainer';
import { FusionEndpointSelectContainer } from '../FusionEndpointSelectContainer';
import { getLink } from '../HelpIcon';
import { PageTitle } from '../PageTitle';
import { BackLink, OverlayAwareBackLink } from './Backlink';

const BreadcrumbsContainer = styled.div`
  margin-left: 1rem;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 0.375rem;
`;

interface HelpLinkItem {
  ref: string;
  mode?: keyof typeof refLookup;
}

interface ExternalProps {
  help?: HelpLinkItem;
  noBorder?: boolean;
  backLink?: {
    label: string;
    to: string;
    rightLabel?: string;
    hidden?: boolean;
    overlayAware?: boolean;
    onClick?: () => void;
  };
  breadcrumbs?: Array<React.ReactNode>;
  includeFusionEndpointSelector?: boolean;
  noFailoverChangesAllowed?: boolean;
  children?: React.ReactNode;
  pageTitle: string;
  resourceRequest?: ApiRequest;
  recoveryRoute?: string;
}

// Exported for testing
export const PageComponent = (
  props: PageProps & ExternalProps & ReturnType<typeof mapStateToProps>
) => {
  const {
    help,
    session,
    backLink,
    breadcrumbs,
    includeFusionEndpointSelector,
    noFailoverChangesAllowed = false,
    actions,
    pageTitle,
    children,
    resourceRequest,
    recoveryRoute,
    ...rest
  } = props;

  return (
    <div>
      <PageTitle title={pageTitle} />
      {recoveryRoute && resourceRequest && (
        <DomainVisibilityProtectorContainer
          resourceRequest={resourceRequest}
          recoveryRoute={recoveryRoute}
        />
      )}
      {includeFusionEndpointSelector && (
        <FailoverEditingBackupInfoBannerContainer
          noFailoverChangesAllowed={noFailoverChangesAllowed}
        />
      )}
      {breadcrumbs && (
        <BreadcrumbsContainer>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </BreadcrumbsContainer>
      )}
      {backLink && !backLink.hidden && !backLink.overlayAware && <BackLink {...backLink} />}
      {backLink && !backLink.hidden && backLink.overlayAware && (
        <OverlayAwareBackLink {...backLink} />
      )}
      <RegistryPage
        {...rest}
        helpLink={help && getLink(session, help?.ref, help?.mode)}
        actions={
          <ActionsContainer>
            {actions}
            {includeFusionEndpointSelector && <FusionEndpointSelectContainer />}
          </ActionsContainer>
        }>
        {children}
      </RegistryPage>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({ session: state.session });

export const Page = connect(mapStateToProps)(PageComponent);
