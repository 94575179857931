import * as incidentsActions from './IncidentsActions';
import { ActionType, getType } from 'typesafe-actions';
import { MobileApiIncident } from '../../mobile-api-types';

export type IncidentsAction = ActionType<typeof incidentsActions>;

export interface IncidentsState {
  ongoingIncidents: MobileApiIncident[];
  ongoingIncidentsCount: number;
  siteAlreadySelectedStatuses?: { [key: string]: boolean };
}

export const initialIncidentsState: IncidentsState = {
  ongoingIncidents: [],
  ongoingIncidentsCount: 0,
};

const incidentsReducer = (
  state: IncidentsState = initialIncidentsState,
  action: IncidentsAction
): IncidentsState => {
  switch (action.type) {
    case getType(incidentsActions.updateIncidents):
      return {
        ...state,
        ongoingIncidents: action.payload.data.data,
        ongoingIncidentsCount: action.payload.data.total,
      };
    default:
      return state;
  }
};

export default incidentsReducer;
