import { DetailedHTMLProps, HTMLAttributes, useContext } from 'react';
import classNames from 'classnames';
import { ZmdiIcon } from '../../common-types';
import { BrandingContext } from '../../core/branding/BrandingContext';
import { getContrastYIQ } from '../../core/utils/color-representation';

export interface ExternalProps extends DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> {
  icon: ZmdiIcon;
  applyContrast?: boolean;
}

export const SinglewireIcon = ({ icon, className, applyContrast, ...rest }: ExternalProps) => {
  const { primaryColor } = useContext(BrandingContext);

  return (
    <i
      {...rest}
      className={classNames('zmdi', `zmdi-${icon}`, className)}
      style={
        applyContrast
          ? { ...rest.style, color: getContrastYIQ(primaryColor || undefined) }
          : rest.style
      }
    />
  );
};
