import { ActionType, getType } from 'typesafe-actions';
import * as loginActions from './LoginActions';

export type LoginAction = ActionType<typeof loginActions>;

export interface LoginState {
  logoutReason?: LogoutReason;
  logoutMessage?: string;
  loginDestination?: string;
}

export enum LogoutReason {
  UserInitiated = 'UserInitiated',
  SessionExpired = 'SessionExpired',
  Default = 'Default',
}

export const initialLoginState = {};

export default function reducer(
  state: LoginState = initialLoginState,
  action: LoginAction
): LoginState {
  switch (action.type) {
    case getType(loginActions.setLogoutInfo):
      return {
        ...state,
        logoutMessage: action.payload.message,
        logoutReason: action.payload.logoutReason,
        loginDestination: action.payload.loginDestination,
      };
    case getType(loginActions.updateLoginDestination):
      const loginDestination = action.payload.loginDestination;
      if (state.logoutMessage) {
        return { ...state };
      } else {
        return { ...state, loginDestination };
      }
    default:
      return state;
  }
}
