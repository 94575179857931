// Set up our client->server communications.

import axios from 'axios';
import {
  defaultHttpRequestInterceptor,
  defaultHttpResponseInterceptor,
  defaultHttpResponseErrorInterceptor,
} from './default-http-interceptors';

const instance = axios.create({
  baseURL: window.apiRoot || '',
  withCredentials: true,
});

instance.interceptors.request.use(defaultHttpRequestInterceptor);
instance.interceptors.response.use(
  defaultHttpResponseInterceptor,
  defaultHttpResponseErrorInterceptor
);

export default instance;
