import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import integrationImportEpics from '../views/shared/integration/import/ImportEpics';
import brandingEpics from './branding/BrandingEpics';
import domainsEpics from './domains/DomainsEpics';
import facilitiesEpics from './facilities/FacilitiesEpics';
import formEpics from './forms/FormEpics';
import syncStateEpics from './fusion-sync-state/FusionSyncStateEpics';
import icopServerEpics from './icop-servers/ICOPServersEpics';
import incidentsEpics from './incidents/IncidentsEpics';
import initializeEpics from './initialized/InitializedEpics';
import layoutEpics from './layout/LayoutEpics';
import licenseEpics from './license/LicenseEpics';
import loginEpics from './login/LoginEpics';
import modalEpics from './modal/ModalEpics';
import msteamsEpics from './ms-teams/MSTeamsEpics';
import navigationEpics from './navigation/NavigationEpics';
import networkEpics from './network/NetworkEpics';
import permissionEpics from './permissions/PermissionsEpics';
import phoneCacheEpics from './phone-cache/PhoneCacheEpics';
import reportsEpics from './reports/ReportsEpics';
import routeEpics from './route/RouteEpics';
import ruleBuilderEpic from './rule-builder/RuleBuilderEpics';
import scrollEpics from './scroll/ScrollEpics';
import searchPagerEpics from './search-pager/SearchPagerEpics';
import sessionEpics from './session/SessionEpics';
import sideBarEpics from './side-bar/SideBarEpics';
import systemHealthEpic from './system-health/SystemHealthEpic';
import trackingEventsEpics from './tracking-events/TrackingEventsEpics';
import ttsEpics from './tts/TtsEpics';
import urlShortenerEpics from './url-shortener/UrlShortenerEpics';
import { Logger } from './utils/log';

const logger = new Logger();

// This file is for combining all of our core epics into one big epic that
// handles our root state. All of these epics are loaded on the initial page load.
// Lazily-loaded features with their own epics are not handled here, but registered
// with that feature with `registerLazyEpics`.

const initialEpics: Epic[] = [
  brandingEpics,
  domainsEpics,
  facilitiesEpics,
  formEpics,
  icopServerEpics,
  incidentsEpics,
  initializeEpics,
  integrationImportEpics,
  layoutEpics,
  licenseEpics,
  loginEpics,
  modalEpics,
  msteamsEpics,
  navigationEpics,
  networkEpics,
  permissionEpics,
  phoneCacheEpics,
  reportsEpics,
  routeEpics,
  ruleBuilderEpic,
  scrollEpics,
  searchPagerEpics,
  sessionEpics,
  sideBarEpics,
  syncStateEpics,
  systemHealthEpic,
  trackingEventsEpics,
  ttsEpics,
  urlShortenerEpics,
];

const epic$ = new BehaviorSubject<Epic<Action, Action, void, any>>(combineEpics(...initialEpics));

export const rootEpic: Epic<Action, Action, any, any> = (action$, state$, services) =>
  epic$.pipe(mergeMap(epic => epic(action$, state$, services)));

const registeredEpics = new Set([...initialEpics]);

export const registerLazyEpics = (epic: Epic) => {
  if (!registeredEpics.has(epic)) {
    registeredEpics.add(epic);
    epic$.next(epic);
  } else {
    logger.warn(
      'You are registering multiple epics with the same name, this is a code smell and might cause actions to fire multiple times'
    );
  }
};

export default rootEpic;
