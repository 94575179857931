import * as systemHealthActions from './SystemHealthActions';
import { ActionType, getType } from 'typesafe-actions';
import { AlarmId, MobileApiAlarm } from '../../mobile-api-types';

export type SystemHealthAction = ActionType<typeof systemHealthActions>;

export interface SystemHealthState {
  activeAlarms: MobileApiAlarm[] | null;
  defaultAlarm: AlarmId | null;
}

export const initalSystemHealthState: SystemHealthState = {
  activeAlarms: null,
  defaultAlarm: null,
};

const systemHealthReducer = (
  state: SystemHealthState = initalSystemHealthState,
  action: SystemHealthAction
): SystemHealthState => {
  switch (action.type) {
    case getType(systemHealthActions.updateSystemHealth):
      return {
        ...state,
        activeAlarms:
          action.payload.data.data?.filter((alarm: MobileApiAlarm) => alarm.status === 'ACTIVE') ||
          null,
        ...('defaultAlarm' in action.payload.data
          ? { defaultAlarm: action.payload.data.defaultAlarm }
          : {}),
      };

    default:
      return state;
  }
};

export default systemHealthReducer;
