/**
 * Manage state related to the acting domain, and perform common actions on
 * domained resources.
 */

import { push } from 'connected-react-router';
import { Action } from 'redux';
import { createAction } from 'typesafe-actions';
import { common_t } from '../../CommonLocale';
import { ApiRequest } from '../../common-types';
import { ROOT_DOMAIN_ID } from '../../constants';
import { DomainedResource, DomainedResourceSpec, MobileApiDomain } from '../../mobile-api-types';
import { DomainVisibilityProtection } from '../../views/shared/Domains/DomainVisibilityProtector/DomainVisibilityProtectorContainer';
import { commonSave } from '../forms/FormActions';
import { reloadCurrentPage } from '../navigation/NavigationActions';
import { refreshPermissions } from '../permissions/PermissionsActions';
import { refreshSession } from '../session/SessionActions';

/**
 * Handled by: Epic.
 * Turns on the Domains feature by creating the root Domain.
 */
export const initializeDomains = createAction('[Domains] Initialize')();

/**
 * Handled by: Epic.
 * Moves the session into a different acting Domain and reloads the
 * page to reflect the changes.
 */
export const updateActingDomain = createAction(
  '[Domains] Handle New Acting Domain',
  (id: string | null, successActions?: Action[]) => ({ id, successActions })
)();

/**
 * Handled by: Reducer.
 * Puts the current acting domain ID in the store.
 */
export const setActingDomainId = createAction(
  '[Domains] Set Acting Domain Id',
  (id: string | null) => ({ id })
)();

/**
 * Handled by: Epic.
 * Turns on the Domains feature by creating the root Domain.
 */
export const enableDomains = () => {
  const formData: {
    name: string;
    parentId: string;
    domain?: MobileApiDomain;
  } = {
    name: 'Root',
    parentId: '',
  };
  return commonSave({
    subType: '[Domains Config] Enable',
    formId: 'root-domain-create',
    formData,
    request: ['domains', [], 'POST', { data: formData }],
    isUpdate: false,
    options: {
      resourceName: common_t(['resource', 'domainLower']),
      successActions: () => [
        updateActingDomain(ROOT_DOMAIN_ID, [
          refreshSession({
            successActions: () => [
              refreshPermissions({
                cb: error => (error ? [] : [push('/domains')]),
              }),
            ],
          }),
        ]),
      ],
      failureActions: () => [reloadCurrentPage()],
    },
  });
};

/**
 * Handled by: Epic.
 * Turn Domains off if they are on, and vice versa.
 * @param isCurrentlyEnabled The current state of the Domains feature
 */
export const toggleDomainsEnabled = (isCurrentlyEnabled: boolean) =>
  isCurrentlyEnabled ? disableDomains() : enableDomains();

/**
 * Handled by: Epic.
 * Turns off the Domains feature by deleting the root Domain.
 */
export const disableDomains = createAction('[Domains] Disable')();

/**
 * Handled by: Epic.
 * Remove a domain from a resource. Must not require promotion or demotion.
 * Perform the API request, then return the supplied action to update the store
 * to reflect the change.
 */
export const removeDomainResource = createAction(
  '[Domains] Remove Resource Domain',
  (
    resource: DomainedResource,
    spec: DomainedResourceSpec,
    domain: MobileApiDomain,
    updateStoreAction: (updatedResource: DomainedResource) => Action
  ) => ({ resource, spec, domain, updateStoreAction })
)();

/**
 * Handled by: Epic.
 * Add a domain to a resource. Must not require promotion or demotion.
 * Perform the API request, then return the supplied action to update the store
 * to reflect the change.
 */
export const addDomainResource = createAction(
  '[Domains] Add Resource Domain',
  (
    resource: DomainedResource,
    spec: DomainedResourceSpec,
    domain: MobileApiDomain,
    updateStoreAction: (updatedResource: DomainedResource) => Action
  ) => ({ resource, spec, domain, updateStoreAction })
)();

/**
 * Handled by: Epic.
 * Shows a confirmation modal to verify the user wants to demote a
 * resource to a descendant domain. If they confirm, perform the API
 * request, then return the supplied action to update the store
 * to reflect the change.
 */
export const confirmDemoteDomainResource = createAction(
  '[Domains] Confirm Demote Domain Resource',
  (
    resource: DomainedResource,
    spec: DomainedResourceSpec,
    fromDomain: MobileApiDomain,
    toDomain: MobileApiDomain,
    updateStoreAction: (updatedResource: DomainedResource) => Action
  ) => ({
    resource,
    spec,
    fromDomain,
    toDomain,
    updateStoreAction,
  })
)();

/**
 * Handled by: Epic.
 * Performs an API request to demote a resource to a descendant
 * domain, then return the supplied action to update the store
 * to reflect the change.
 */
export const demoteDomainResource = createAction(
  '[Domains] Demote Domain Resource',
  (
    resource: DomainedResource,
    spec: DomainedResourceSpec,
    toDomain: MobileApiDomain,
    updateStoreAction: (updatedResource: DomainedResource) => Action
  ) => ({ resource, spec, toDomain, updateStoreAction })
)();

/**
 * Handled by: Epic.
 * Shows a confirmation modal to verify the user wants to promote a
 * resource to an ancestor domain. If they confirm, perform the API
 * request, then return the supplied action to update the store
 * to reflect the change.
 */
export const confirmPromoteDomainResource = createAction(
  '[Domains] Confirm Promote Domain Resource',
  (
    resource: DomainedResource,
    spec: DomainedResourceSpec,
    fromDomains: MobileApiDomain[],
    toDomain: MobileApiDomain,
    updateStoreAction: (updatedResource: DomainedResource) => Action
  ) => ({
    resource,
    spec,
    fromDomains,
    toDomain,
    updateStoreAction,
  })
)();

/**
 * Handled by: Epic.
 * Performs an API request to promote a resource to an ancestor
 * domain, then return the supplied action to update the store
 * to reflect the change.
 */
export const promoteDomainResource = createAction(
  '[Domains] Promote Domain Resource',
  (
    resource: DomainedResource,
    spec: DomainedResourceSpec,
    toDomain: MobileApiDomain,
    updateStoreAction: (updatedResource: DomainedResource) => Action
  ) => ({ resource, spec, toDomain, updateStoreAction })
)();

/**
 * Handled by: Reducer.
 * Indicates whether the search pager data in the picker is up-to-date, it is set to false
 * when creating, editing, or deleting a domain.
 */
export const setDomainSelectValidity = createAction(
  '[Domains] Set Domain Select Validity',
  (valid: boolean) => ({ valid })
)();

/**
 * Handled by: Reducer.
 * Indicates that the current page can only render properly if at least one of the specified
 * domains is visible from the acting domain. If the user navigates to another domain, the
 * domain select component will recover by navigating to the supplied recovery route, which will
 * generally be the list page corresponding to the no-longer visible resource.
 */
export const protectVisibleDomains = createAction(
  '[Domains] Protect Visible Domains',
  (resourceRequest: ApiRequest, recoveryRoute: string) => ({ resourceRequest, recoveryRoute })
)();

/**
 * Handled by: Reducer.
 * Removes the protection that was established by protectVisibleDomains. Domain selection can
 * proceed with no special care.
 */
export const endVisibleDomainProtection = createAction(
  '[Domains: End Visible Domain Protection]'
)();

/**
 * Handled in: Epic
 * Asks the user to confirm an acting domain change if there is a dirty form.
 * If domain visibility protection is in effect, and the user has asked to
 * move to a domain where the current page will not work, a recovery action will
 * be posted instead of the reload-current-page action, which will most likely
 * navigate the user to the closest safe page (such as the list page).
 */
export const askUserToChangeActingDomain = createAction(
  '[Domains] Ask to Change Acting Domain',
  (domain: MobileApiDomain, fromLogout = false, protection: DomainVisibilityProtection | null) => ({
    domain,
    fromLogout,
    protection,
  })
)();

/**
 * Handled in: Epic
 * Fetches acting domain and all descendants, unrolling the pagination if necessary
 */
export const fetchAllVisibleDomains = createAction(
  '[Domains] Fetch All Visible Domains',
  (start: string | null) => ({ start })
)();

/**
 * Handled in: Reducer
 * Replaces the array of visible domains in the store
 */
export const updateVisibleDomains = createAction(
  '[Domains] Update Visible Domains',
  (domains: MobileApiDomain[]) => ({ domains })
)();
/**
 * Handled by: Epic.
 * Get All Domains.
 */
export const getAllDomains = createAction('[Domains] Get All Domains')();

// Handled in: Reducer
// Add all domains to store for domains select
export const updateAllDomains = createAction(
  '[Domains] Update All Domains',
  (domains: MobileApiDomain[]) => ({ domains })
)();
