import { AxiosError } from 'axios';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { catchError, filter, startWith, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { MobileApiDevice, MobileApiPageEnvelope } from '../../mobile-api-types';
import { core_t } from '../CoreLocale';
import { RootState } from '../RootReducer';
import { growl } from '../layout/LayoutActions';
import store from '../store';
import { CancellableRef, req } from '../utils/api';
import { setRuleFilterErrors, validateLogicalExpression } from './RuleBuilderActions';

type MobileApiDeviceValidationErrorResponse = {
  // I don't think `fieldType` is right? So putting here instead of in mobile-api-types.
  // I think it's `type`, but not sure. It's never used, afaict.
  reasons: Array<{ field: string; fieldType: string; message: string }>;
};

/**
 * Make a POST request to /devices in order to validate the provided logical expression.
 */
export const validateLogicalExpressionEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(validateLogicalExpression)),
    switchMap(action =>
      req<MobileApiPageEnvelope<MobileApiDevice>>(
        action.payload.apiRequest,
        store,
        CancellableRef
      ).pipe(
        switchMap(() => []),
        catchError((error: AxiosError<MobileApiDeviceValidationErrorResponse>) => {
          const reasons = error?.response?.data?.reasons;
          return reasons && reasons.length > 0
            ? [setRuleFilterErrors(reasons)]
            : [growl(core_t(['ruleBuilder', 'filterTestFailed']), { type: 'danger' })];
        }),
        startWith(setRuleFilterErrors([]))
      )
    )
  );

export default validateLogicalExpressionEpic;
