import { Epic } from 'redux-observable';
import { EMPTY, concat, from, interval } from 'rxjs';
import {
  concatAll,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { Action, isActionOf } from 'typesafe-actions';
import { MobileApiSyncState } from '../../mobile-api-types';
import { getOverallState } from '../../views/shared/components/FusionSyncState';
import { RootState } from '../RootReducer';
import { searchPagerRefresh } from '../search-pager/SearchPagerActions';
import { getPager, getPagerData } from '../search-pager/SearchPagerReducer';
import { isBrowserVisible } from '../utils/common';
import { pollFusionSyncState, stopPollFusionSyncState } from './FusionSyncStateActions';

interface FusionSyncableObject {
  syncState?: MobileApiSyncState[] | null;
}

export const pollSyncStateEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(pollFusionSyncState)),
    mergeMap(action =>
      concat(
        from([3, 10])
          .pipe(map(val => interval(val * 1000).pipe(take(1))))
          .pipe(concatAll()),
        interval(15000)
      ).pipe(
        filter(() => isBrowserVisible()),
        withLatestFrom(state$),
        switchMap(([val, state]) => {
          const pager = getPager<FusionSyncableObject>(state, action.payload.pagerId);
          // Pager may not have been initialized when this is called from componentDidMount.
          if (pager) {
            const data = getPagerData<FusionSyncableObject>(pager);
            // Stop polling if there's nothing to poll
            // i.e search pager is empty or all statuses are set to complete
            if (
              (pager.data && pager.data.total === 0) ||
              (data &&
                data.length > 0 &&
                !data.some(value => getOverallState(value.syncState) !== 'complete'))
            ) {
              return [stopPollFusionSyncState()];
            }
            return [searchPagerRefresh(action.payload.pagerId, 0, { silentRefresh: true })];
          }
          return EMPTY;
        }),
        takeUntil(action$.pipe(filter(isActionOf(stopPollFusionSyncState))))
      )
    )
  );

export default pollSyncStateEpic;
