import { createAction } from 'typesafe-actions';
import { MobileApiIncident, MobileApiPageEnvelope } from '../../mobile-api-types';

/**
 * Handled in IncidentsEpics
 * Periodically check for ongoing incidents
 */
export const startMonitoringIncidents = createAction(
  '[Incidents] Start Monitoring Ongoing Incidents'
)();

/**
 * Handled in IncidentsEpics
 * Fetch ongoing incidents
 */
export const fetchOngoingIncidents = createAction('[Incidents] Fetch Ongoing Incidents')();

/**
 * Handled in IncidentsEpics
 * Fetch ongoing incidents in background
 */
export const fetchOngoingIncidentsBackground = createAction(
  '[Incidents] Background Fetch Ongoing Incidents'
)();

/**
 * Handled in IncidentsReducer
 * Add ongoing incidents to store
 */
export const updateIncidents = createAction(
  '[Incidents] Set Ongoing Incidents',
  (data: MobileApiPageEnvelope<MobileApiIncident>) => ({ data })
)();
