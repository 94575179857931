import cloneDeep from 'lodash/cloneDeep';
import forIn from 'lodash/forIn';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import {
  APNS_DESKTOP_EXTENSION_ID,
  EMAIL_EXTENSION_ID,
  ICOP_DEVICE_EXTENSION_ID,
  IOS_EXTENSION_ID,
  PHONE_CALL_EXTENSION_ID,
  SMS_EXTENSION_ID,
  TEAMS_CHAT_EXTENSION_ID,
} from '../../../constants';
import { ttsType } from '../../../core/utils/TtsUtils';
import { NotificationFormState } from './send/SendNotificationFormContainer';
import {
  BroadcastNotificationActivity,
  BroadcastNotificationActivityType,
  BroadcastNotificationActivityTypes,
  MobileApiNotificationActivity,
} from '../../../mobile-api-types';

/**
 * Utility function for converting notification data to FormData.
 * @param formState
 */
export const formatMessagePayload = (formState: NotificationFormState) => {
  const payload = cloneDeep(formState);

  // if formState.audio is a number, it means it already had attached audio and we don't want to update it
  if (formState.audio === undefined || typeof formState.audio === 'number') {
    delete payload.audio;
  } else if (formState.audio === null) {
    payload.audio = '';
  }

  // if formState.image is a number, it means it already had an attached image and we don't want to update it
  if (formState.image === undefined || typeof formState.image === 'number') {
    delete payload.image;
  } else if (formState.image === null) {
    payload.image = '';
  }

  if (!payload.expirationInclude) {
    payload.expiresAt = '';
  } else if (payload.expirationMode === 'CUSTOM') {
    payload.expiresAt = moment(payload.customExpiration).toISOString();
  } else if (payload.expirationMode === 'DEFAULT') {
    delete payload.expiresAt;
  } else {
    payload.expiresAt = '';
  }

  forIn(payload, (value: any, key: any) => {
    if (isArray(value)) {
      payload[key] = value && value.length ? value : '';
    } else {
      payload[key] = value === null ? '' : value;
    }
  });

  if (payload.extensionSettingsOverrides.all.enabled) {
    payload.extensionSettingsOverrides = '';
  } else {
    const disabledOverrides = Object.entries(payload.extensionSettingsOverrides).filter(
      ([extensionKey, override]: any) => {
        // If override isn't shown it was disabled at notification profile
        // so don't include it as part of extensions disabled at send time
        return !override.enabled && override.show;
      }
    );

    const extensionsDisabledAtSend = disabledOverrides.reduce(
      (acc, [extensionId, override]: any) => {
        if (extensionId === 'all') {
          return acc;
        }

        return {
          ...acc,
          [extensionId]: { disabled: !override.enabled },
        };
      },
      {}
    );

    payload.extensionSettingsOverrides = extensionsDisabledAtSend;
  }

  const fieldsToOmit = [
    'customExpiration',
    'expirationMode',
    'users',
    'distributionLists',
    'deviceGroups',
    'confirmationRequest',
    'collaborationGroups',
    'areasOfInterest',
    'messageTemplate',
    'ssml',
    'audioType',
    'voiceLanguage',
    'formTtsType',
    'incidentPlan',
    'distributionListIdsInclude',
    'deviceGroupIdsInclude',
    'userIdsInclude',
    'collaborationGroupIdsInclude',
    'areaOfInterestIdsInclude',
    'imageInclude',
    'audioInclude',
    'confirmationRequestIdInclude',
    'incidentPlanIdInclude',
    'expirationInclude',
  ];

  const type = ttsType(formState.formTtsType, formState.ssml, !formState.audioInclude);

  return omit(
    {
      ...payload,
      ttsType: type,
      ttsSpeed: type === 'none' ? undefined : payload.ttsSpeed,
      ttsVoiceId: type === 'none' ? undefined : payload.ttsVoiceId,
    },
    fieldsToOmit
  );
};

export const shouldShowAll = (overrides: any) =>
  [
    overrides[PHONE_CALL_EXTENSION_ID].show,
    overrides[EMAIL_EXTENSION_ID].show,
    overrides[SMS_EXTENSION_ID].show,
    overrides[ICOP_DEVICE_EXTENSION_ID].show,
    overrides[APNS_DESKTOP_EXTENSION_ID].show,
    overrides[IOS_EXTENSION_ID].show,
    overrides[TEAMS_CHAT_EXTENSION_ID].show,
  ].filter(x => x === true).length > 1;

export const shouldShowNoChannelAlert = (overrides: any, allTileEnabled: boolean) =>
  !(
    overrides[PHONE_CALL_EXTENSION_ID].enabled ||
    overrides[EMAIL_EXTENSION_ID].enabled ||
    overrides[SMS_EXTENSION_ID].enabled ||
    overrides[ICOP_DEVICE_EXTENSION_ID].enabled ||
    overrides[APNS_DESKTOP_EXTENSION_ID].enabled ||
    overrides[IOS_EXTENSION_ID].enabled ||
    overrides[TEAMS_CHAT_EXTENSION_ID].enabled ||
    allTileEnabled
  );

export const isaBroadcastNotificationActivity = (
  notifAct: MobileApiNotificationActivity
): notifAct is BroadcastNotificationActivity => {
  return BroadcastNotificationActivityTypes.includes(
    notifAct.type as BroadcastNotificationActivityType
  );
};
