import entries from 'lodash/entries';
// @ts-ignore
import { ActionsObservable, combineEpics, Epic, StateObservable } from 'redux-observable';
import { Action } from 'redux';
import { RootState } from '../RootReducer';
import {
  catchError,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  resolveRouteCleanup,
  resolveRouteData,
  resolveRouteDataSuccess,
  resolveDataError,
  refreshRouteData,
} from './RouteActions';
import { ApiRequest } from '../../common-types';
import { forkJoin, of } from 'rxjs';
import { BackgroundRef, req, reqAll } from '../utils/api';
import store from '../store';
import log from '../utils/log';
import { ResolveApiAdvancedConfig } from '../../views/shared/ResolveDataSafely';
import { AxiosError } from 'axios';

// Makes a request to resolve the entry but if we receive a cleanup action and there are no longer any
// dependents, kill the request.
const makeRequestKillingOnCleanup = (
  entry: [string, ApiRequest | ResolveApiAdvancedConfig],
  action$: ActionsObservable<Action>,
  state$: StateObservable<RootState>
) =>
  !Array.isArray(entry[1]) && entry[1].reqAll
    ? reqAll(entry[1].request, store, BackgroundRef).pipe(
        takeUntil(
          action$.pipe(
            filter(isActionOf(resolveRouteCleanup)),
            withLatestFrom(state$),
            filter(([, newState]) => (newState.route.dependents[entry[0]] || 0) === 0)
          )
        ),
        startWith(undefined)
      )
    : req(Array.isArray(entry[1]) ? entry[1] : entry[1].request, store, BackgroundRef).pipe(
        takeUntil(
          action$.pipe(
            filter(isActionOf(resolveRouteCleanup)),
            withLatestFrom(state$),
            filter(([, newState]) => (newState.route.dependents[entry[0]] || 0) === 0)
          )
        ),
        startWith(undefined)
      );

// For all results in which we have data, add it to our resolveRouteDataSuccess action
const getRequestsCompleteAction = (
  results: ReadonlyArray<{ data: any }>,
  apiEntries: ReadonlyArray<[string, ApiRequest | ResolveApiAdvancedConfig]>
) =>
  resolveRouteDataSuccess(
    results.reduce(
      (acc, result, idx) =>
        result
          ? {
              ...acc,
              [apiEntries[idx][0]]: Array.isArray(apiEntries[idx][1]) ? result.data : result,
            }
          : acc,
      {}
    )
  );

// For each resolve key and resolve apiRequest, make that request and add the
// result to the store under that key.
export const resolveRouteDataEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(resolveRouteData)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const resolveEntries: ReadonlyArray<[string, ApiRequest | ResolveApiAdvancedConfig]> =
        entries(action.payload.data);
      return forkJoin(
        resolveEntries.map(entry => {
          const previousResult = state.route.data[entry[0]];
          if (previousResult) {
            return of({ data: previousResult });
          } else {
            return makeRequestKillingOnCleanup(entry, action$, state$);
          }
        })
      ).pipe(
        map((results: any) => getRequestsCompleteAction(results, resolveEntries)),
        catchError((error: AxiosError | any) => {
          log.error('Failed to resolve route data', action, error);
          return [resolveDataError(action.payload.data, error)];
        })
      );
    })
  );

// For each resolve key and resolve apiRequest, re-make that request and add the
// result to the store under that key.
export const refreshRouteDataEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(refreshRouteData)),
    switchMap(action => {
      return [
        resolveRouteCleanup(Object.keys(action.payload.data)),
        resolveRouteData(action.payload.data),
      ];
    })
  );

export default combineEpics(resolveRouteDataEpic, refreshRouteDataEpic);
