// This component is a group of links in the side bar, e.g. 'Recipients'.

import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { closeSidebar } from '../../core/layout/LayoutActions';
import { ElementMenu } from '../../core/utils/side-bar';
import { expandElement } from '../../core/side-bar/SideBarActions';
import { SideBarNavMenuElement } from './SideBarNavMenuElement';

interface ExternalProps {
  element: ElementMenu;
}

export type SideBarNavMenuElementProps = ExternalProps & ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ closeSidebar, expandElement }, dispatch);

export const SideBarNavMenuElementContainer = connect(
  null,
  mapDispatchToProps
)(SideBarNavMenuElement);
