import { FunctionComponent } from 'react';
import { Redirect } from 'react-router';

import Routes from '../../core/route/Routes';
import { FeatureCheckProps } from './FeatureCheckContainer';

export const FeatureCheck: FunctionComponent<FeatureCheckProps> = ({
  children,
  redirectToAccessDenied,
  renderWhenDenied,
  allowed,
}) => {
  if (allowed) {
    return <>{children}</>;
  } else if (renderWhenDenied) {
    return <>{renderWhenDenied()}</>;
  } else if (redirectToAccessDenied) {
    return <Redirect to={Routes.AccessDenied} />;
  } else {
    return <></>;
  }
};
