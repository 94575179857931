// Wrap the Link component with some custom logic that if the user is attempting to navigate to the same
// route, we prevent the default action and instead dispatch a reloadCurrentPage action

import { Link } from '../shared/components/Link';
import { ForceReloadLinkProps } from './ForceReloadLinkContainer';

export const ForceReloadLink = ({
  currentLocation,
  reloadCurrentPage,
  onClick,
  ...rest
}: ForceReloadLinkProps) => (
  <Link
    {...rest}
    inheritColor={true}
    onClick={event => {
      if (currentLocation.pathname === rest.to) {
        event.preventDefault();
        reloadCurrentPage();
      }
      if (onClick) {
        onClick(event);
      }
    }}
  />
);
