import { ActionType, getType } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';
import { MobileApiDomain } from '../../mobile-api-types';
import * as domains from './DomainsActions';

export type DomainAction = ActionType<typeof domains>;

interface DomainVisibilityProtection {
  resourceRequest: ApiRequest;
  recoveryRoute: string;
}

interface DomainsState {
  actingDomainId: string | null;
  domainSelectValid: boolean;
  visibilityProtection: DomainVisibilityProtection | null;
  visibleDomains: MobileApiDomain[];
  allDomains: MobileApiDomain[];
}

const initialDomainsState: DomainsState = {
  actingDomainId: null,
  domainSelectValid: false,
  visibilityProtection: null,
  visibleDomains: [],
  allDomains: [],
};

export default function domainsReducer(
  state: DomainsState = initialDomainsState,
  action: DomainAction
): DomainsState {
  switch (action.type) {
    case getType(domains.setActingDomainId):
      return { ...state, actingDomainId: action.payload.id };
    case getType(domains.setDomainSelectValidity):
      return { ...state, domainSelectValid: action.payload.valid };
    case getType(domains.protectVisibleDomains):
      return { ...state, visibilityProtection: action.payload };
    case getType(domains.endVisibleDomainProtection):
      return { ...state, visibilityProtection: null };
    case getType(domains.updateVisibleDomains):
      return { ...state, visibleDomains: action.payload.domains };
    case getType(domains.updateAllDomains):
      return { ...state, allDomains: action.payload.domains };
    default:
      return state;
  }
}
