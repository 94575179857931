// Handles the hiding and showing of our two types of modals: Confirmation Modals
// and Response Error Modals.

import { AxiosError, AxiosResponse } from 'axios';
import { ReactNode } from 'react';
import { Action } from 'redux';
import { createAction } from 'typesafe-actions';

export enum ModalTypes {
  CONFIRM,
  INFO,
  REGISTRY,
}

export interface ModalState {
  id: string;
  title?: string;
  message?: ReactNode;
  rawMessage?: string;
  messageHtml?: string;
  response?: AxiosError | AxiosResponse | null;
  failureMessage?: string;
  errorOverride?: (error: any) => string[];
  dontCloseOnNavigate?: boolean;
  isSaving?: boolean;
  componentProps?: any;
  textConfirm?: string;
  textConfirmLabel?: string;
  isDestructive?: boolean;
  confirmTitle?: string;
  triggeringElementId?: string;
}
// Handled in: Epic
// Shows the user a dirty form/active operation confirmation modal. If they choose to navigate away/logout,
// mark the forms as pristine and cancel those requests and continue.
export const showDirtyFormOrActiveOperationModal = createAction(
  '[Form] Show Dirty Form Or Active Operation Confirmation Modal',
  (
    title: string,
    message: string,
    formIdsToSkip: string[],
    confirmCb: () => Action[],
    triggeringElementId?: string
  ) => ({
    title,
    message,
    formIdsToSkip,
    confirmCb,
    triggeringElementId,
  })
)();

/* Handled in: Reducer
 * Set the active modal state to the given state. */
export const showModal = createAction('[Modal] Modal Show', (state: ModalState) => ({ state }))();

// Handled in Epic:
// Allows you show one of the standard modals (currently just info and confirm) from any where
export const showGlobalModal = createAction(
  '[Modal] Global Modal Show',
  (type: ModalTypes, state: Partial<ModalState>, confirmCb?: () => Action[]) => ({
    state,
    type,
    confirmCb,
  })
)();

/* Handled in: Reducer, Epic
 * Dispatched when "OK" button is clicked on confirmation modal. */
export const confirmModal = createAction(
  '[Modal] Modal Confirm',
  (confirmCb?: (props?: any) => Action[], options?: { props?: any }) => ({ confirmCb, options })
)();

/* Handled in: Reducer
 * Set isSaving to true while waiting for the confirm callback to complete. */
export const setModalSave = createAction('[Modal] Modal Save Status', (isSaving: boolean) => ({
  isSaving,
}))();

/* Handled in: Reducer
 * Set active modal to null. */
export const hideModal = createAction('[Modal] Modal Hide')();
