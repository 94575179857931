// Wrap the Link component with some custom logic that if the user is attempting to navigate to the same
// route, we prevent the default action and instead dispatch a reloadCurrentPage action

import { RootState } from '../../core/RootReducer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { reloadCurrentPage } from '../../core/navigation/NavigationActions';
import { ForceReloadLink } from './ForceReloadLink';
import { LinkProps } from 'react-router-dom';

export type ForceReloadLinkProps = LinkProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: RootState) => ({
  currentLocation: state.router.location,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ reloadCurrentPage }, dispatch);

export const ForceReloadLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForceReloadLink);
