import { common_t } from '../../../CommonLocale';
import { MobileApiFacility } from '../../../mobile-api-types';
import { FacilityNameTagProps } from './FacilityNameTagContainer';

// Accepts any resource and returns a context dependent tag describing the Facility Access State
export const getFacilityNameTag = (
  facilitiesEnabled: boolean,
  actingFacilityId: string | null,
  userFacilities: MobileApiFacility[] | null,
  resource: any
): string => {
  //Get the resource fields that are important for displaying a facility tag
  const { facilityId, isShared } = resource;

  //If facilities are not enabled, there is no tag to return
  if (!facilitiesEnabled) return '';

  //If the resource is shared, this implies Global ownership, just return the shared tag
  //If the resource is owned by the acting facility, we only have a shared tag to consider
  if (facilityId === actingFacilityId || isShared)
    return isShared ? common_t(['facilities', 'facilityNameTag'], { name: 'Shared' }) : '';

  //If the resource is managed by a different facility,
  // check that the user can see it and return a tag with those details
  const resourceFacility =
    userFacilities && userFacilities.find((f: MobileApiFacility) => f.id === facilityId);
  return resourceFacility
    ? common_t(['facilities', 'facilityNameTag'], { name: resourceFacility.name })
    : '';
};

export const FacilityNameTag = ({
  facilitiesEnabled,
  actingFacilityId,
  userFacilities,
  resource,
}: FacilityNameTagProps) => {
  return <>{getFacilityNameTag(facilitiesEnabled, actingFacilityId, userFacilities, resource)}</>;
};
