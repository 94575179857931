import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { timer } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { PermissionDeniedError } from '../../common-types';
import { DISTRIBUTED_ACTIVATION_FEATURE } from '../../constants';
import { MobileApiIcopServer, MobileApiIcopServerPhoneCacheData } from '../../mobile-api-types';
import { core_t } from '../CoreLocale';
import { growl } from '../layout/LayoutActions';
import { RootState } from '../RootReducer';
import { searchPagerRefresh } from '../search-pager/SearchPagerActions';
import store from '../store';
import { BackgroundRef, req, reqAll } from '../utils/api';
import { isBrowserVisible } from '../utils/common';
import {
  IgnoreLoadingBarTransform,
  IgnoreSessionExtendTransform,
} from '../utils/http-transformers';
import { isFeatureSupported } from '../utils/icop-server';
import log from '../utils/log';
import {
  fetchPhoneCacheData,
  refreshPhoneCache,
  refreshRequest,
  setLastPhoneCacheUpdateAndDistribute,
  setPhoneCacheError,
  stopFetchPhoneCacheData,
} from './PhoneCacheAction';
import { PhoneCacheData, PhoneCacheModuleState, PhoneCacheState } from './PhoneCacheReducer';

const lastClickedUpdateDevices = (userId: string) =>
  `admin-webapp.${userId}.lastClickedUpdateDevice`;

/**
 * Given phone cache data and data currently saved in the redux store,
 * build the message object which will be saved in the store and displayed
 * to the user in the Phone Cache Update Results card.
 *
 * @param activity the lastPhoneCacheUpdate property of the server object
 * @param currPhoneCacheUpdateState the current state of the phone cache update data in the store
 */
export const buildPhoneCacheUpdateMessage = (
  activity: MobileApiIcopServerPhoneCacheData | null,
  currPhoneCacheUpdateState: PhoneCacheData,
  userId: string
): PhoneCacheData & { shouldRefreshPager?: boolean } => {
  const refreshLastClicked = window.localStorage.getItem(lastClickedUpdateDevices(userId));

  // Avoid deprecation warning by creating Date obj then passing that to moment.
  // We need moment obj so we can use .isAfter() fn
  const refreshLastClickedTime = refreshLastClicked
    ? moment(new Date(window.localStorage.getItem(lastClickedUpdateDevices(userId))!))
    : null;

  const noPhoneCacheUpdateData = isEmpty(activity) && !currPhoneCacheUpdateState.createdAt;

  const loadingPhoneCacheUpdateData =
    refreshLastClickedTime &&
    (noPhoneCacheUpdateData ||
      (activity &&
        currPhoneCacheUpdateState.createdAt &&
        refreshLastClickedTime.isAfter(activity.createdAt) &&
        refreshLastClickedTime.isAfter(currPhoneCacheUpdateState.createdAt)));

  if (loadingPhoneCacheUpdateData) {
    return {
      message: core_t(['phoneCache', 'loading']),
      type: 'warn',
      state: 'reloading',
      createdAt: refreshLastClicked!,
    };
  } else if (noPhoneCacheUpdateData) {
    return {
      message: core_t(['phoneCache', 'neverUpdated']),
      type: 'error',
      state: 'never-updated',
    };
  } else if (activity) {
    const activityMomentObj = moment(activity.createdAt);
    const formattedTimestamp = activityMomentObj.format('MMMM Do YYYY, h:mm:ss a');

    const newServerDataAvailable =
      !currPhoneCacheUpdateState.createdAt ||
      activityMomentObj.isSameOrAfter(currPhoneCacheUpdateState.createdAt);

    const newServerPhoneCacheDataPending = activity.state === 'pending';
    const newServerPhoneCacheDataCompleted = activity.state === 'success' && newServerDataAvailable;
    const newServerPhoneCacheDataFailed = activity.state === 'fail' && newServerDataAvailable;

    const shouldRefreshPager =
      currPhoneCacheUpdateState.state !== 'success' &&
      currPhoneCacheUpdateState.state !== 'loading';

    switch (true) {
      case newServerPhoneCacheDataPending:
        return {
          message: core_t(['phoneCache', 'pending'], {
            formattedTimestamp,
          }),
          type: 'warn',
          state: 'pending',
          createdAt: activity.createdAt,
        };

      case newServerPhoneCacheDataCompleted:
        return {
          message: core_t(['phoneCache', 'updateCompleted'], {
            formattedTimestamp,
          }),
          type: 'info',
          state: 'success',
          createdAt: activity.createdAt,
          shouldRefreshPager,
        };

      case newServerPhoneCacheDataFailed:
        return {
          message: core_t(['phoneCache', 'failedToUpdate'], {
            formattedTimestamp,
          }),
          type: 'error',
          state: 'fail',
          createdAt: activity.createdAt,
          shouldRefreshPager,
        };

      default:
        throw Error('Unable to resolve phone cache update results');
    }
  }
  return {
    message: core_t(['phoneCache', 'neverUpdated']),
    type: 'error',
    state: 'never-updated',
  };
};

/**
 * Create a sync failure message for a given fusion server
 *
 * @param activity the server lastPhoneCacheDistribute value
 * @param currPhoneCacheUpdateState
 * @param serverName
 */
export const buildPhoneCacheDistributeMessage = (
  activity: MobileApiIcopServerPhoneCacheData | null,
  currPhoneCacheUpdateState: PhoneCacheData | null,
  serverName: string
): PhoneCacheData | null => {
  const newServerPhoneCacheDistributeFailed =
    activity &&
    activity.state === 'failed' &&
    (!currPhoneCacheUpdateState ||
      (currPhoneCacheUpdateState.createdAt &&
        moment(activity.createdAt).isSameOrAfter(currPhoneCacheUpdateState.createdAt)));

  if (newServerPhoneCacheDistributeFailed) {
    const formattedTimestamp = moment(activity!.createdAt).format('MMMM Do YYYY, h:mm:ss a');

    return {
      name: serverName,
      message: core_t(['phoneCache', 'distributeFailed'], { formattedTimestamp }),
      type: 'error',
      state: 'fail',
    };
  }
  return null;
};

/**
 * Determine the new state of our phone cache by comparing the values of
 * lastPhoneCacheUpdate and lastPhoneCacheDistribute (that belong to the server)
 * with the current values in the phoneCacheState of the store
 *
 * @param server current Fusion Server
 * @param phoneCacheState
 */
export const processServerPhoneCacheData = (
  server: MobileApiIcopServer,
  phoneCacheState: PhoneCacheState,
  userId: string
) => {
  // Get the latest phone cache state from the redux store (if any)
  const currPhoneCacheState = phoneCacheState.phoneCacheData[server.id];

  const phoneCacheUpdateState: PhoneCacheData = currPhoneCacheState
    ? currPhoneCacheState.lastPhoneCacheUpdate
    : {
        message: core_t(['phoneCache', 'loading']),
        type: 'warn',
        state: 'loading',
      };

  // Get the latest phone cache update state from the server (if any)
  // and convert createdAt into moment obj so we can compare with state createdAt
  const serverPhoneCacheUpdateState = server.lastPhoneCacheUpdate || null;

  const lastPhoneCacheUpdateResult: PhoneCacheData & {
    shouldRefreshPager?: boolean;
  } = buildPhoneCacheUpdateMessage(serverPhoneCacheUpdateState, phoneCacheUpdateState, userId);

  // Get the latest phone cache distribute state from the server (if any).
  // Note that we only use this data if the server lastPhoneCacheDistribute state = 'failed'
  const serverPhoneCacheDistributeState = server.lastPhoneCacheDistribute || null;

  const lastPhoneCacheDistributeResult: PhoneCacheData | null = buildPhoneCacheDistributeMessage(
    serverPhoneCacheDistributeState,
    phoneCacheUpdateState.createdAt ? phoneCacheUpdateState : null,
    server.name
  );

  return { lastPhoneCacheUpdateResult, lastPhoneCacheDistributeResult };
};

/**
 * Periodically check and update fusion servers (every 10s)
 */
export const fetchPhoneCacheDataEpic: Epic<Action, Action, PhoneCacheModuleState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(fetchPhoneCacheData)),
    withLatestFrom(state$.pipe(map(state => state))),
    switchMap(([action, state]) =>
      timer(0, 15000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(() =>
          reqAll<MobileApiIcopServer>(
            [
              'fusionExtensionEndpoints',
              [],
              'GET',
              {
                params: {
                  'include-recent-phone-cache-and-distribute-activities': true,
                  skipEndpointHealthAssessment: true,
                },
                transforms: [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform],
              },
            ],
            store,
            BackgroundRef
          ).pipe(
            switchMap(servers => [
              ...servers.reduce((actionAcc, currServer) => {
                const { lastPhoneCacheUpdateResult, lastPhoneCacheDistributeResult } =
                  processServerPhoneCacheData(currServer, state.phoneCache, state.session?.userId!);

                // Some paths in the buildPhoneCacheUpdateMessage function require a pager refresh.
                // This variable allows us to determine if we need to dispatch a searchPagerRefresh action
                const { shouldRefreshPager } = lastPhoneCacheUpdateResult;

                return [
                  ...actionAcc,
                  setLastPhoneCacheUpdateAndDistribute(
                    currServer.id,
                    omit(lastPhoneCacheUpdateResult, ['shouldRefreshPager']),
                    lastPhoneCacheDistributeResult
                  ),
                  ...(shouldRefreshPager && action.payload.pagerId
                    ? [searchPagerRefresh(action.payload.pagerId)]
                    : []),
                ];
              }, [] as Action[]),
              setPhoneCacheError(false),
            ]),
            catchError(error => {
              if (!(error instanceof PermissionDeniedError)) {
                log.error('Failed to load endpoints for phone cache activities', error);
              }
              return [setPhoneCacheError(true)];
            })
          )
        ),
        takeUntil(action$.pipe(ofType(stopFetchPhoneCacheData)))
      )
    )
  );

/**
 * Make request to one of two possible endpoints to update phone cache
 */
export const refreshRequestEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(refreshRequest)),
    switchMap(action =>
      // make appropriate request based on if DA is enabled
      (isFeatureSupported(DISTRIBUTED_ACTIVATION_FEATURE)
        ? req(
            [
              'extensionsICOPEndpointsActivities',
              [action.payload.serverId],
              'POST',
              {
                data: {
                  endpointId: action.payload.serverId,
                  severity: 'info',
                  state: 'success',
                  type: 'phone-cache-rebuild',
                },
              },
            ],
            store,
            BackgroundRef
          )
        : req(
            [
              'devicePhoneRefresh',
              [],
              'PUT',
              {
                params: {
                  sync: true,
                  runInBackground: true,
                },
              },
            ],
            store,
            BackgroundRef
          )
      ).pipe(
        switchMap(() => [
          setLastPhoneCacheUpdateAndDistribute(
            action.payload.serverId,
            {
              message: core_t(['phoneCache', 'loading']),
              type: 'warn',
              state: 'reloading',
            },
            null
          ),
          setPhoneCacheError(false),
        ]),
        catchError(error => {
          if (!(error instanceof PermissionDeniedError)) {
            log.error('Failed to refresh phone cache', error);
          }
          return [setPhoneCacheError(true)];
        })
      )
    )
  );

/**
 * Update phone cache results by taking the following 3 steps:
 * 1. Update lastClickedUpdateDevices local storage
 * 2. Create actions that clear out distributes state for each fusion server in store
 * 3. Make request to refresh the phone cache via refreshRequestEpic
 */
export const refreshPhoneCacheEpic: Epic<Action, Action, PhoneCacheModuleState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(refreshPhoneCache)),
    withLatestFrom(state$.pipe(map(state => state))),
    switchMap(([action, state]) => {
      localStorage.setItem(lastClickedUpdateDevices(state.session?.userId!), moment().toString());

      // create an action for each fusion server to clear out distributes
      const updatePhoneCacheDistributesActions = Object.entries(
        state.phoneCache.phoneCacheData
      ).map(([key, value]) =>
        setLastPhoneCacheUpdateAndDistribute(
          key,
          // set current server's update status to loading
          key === action.payload.serverId
            ? {
                message: core_t(['phoneCache', 'loading']),
                type: 'warn',
                state: 'reloading',
              }
            : value.lastPhoneCacheUpdate,
          null
        )
      );

      return [
        ...updatePhoneCacheDistributesActions,
        growl(core_t(['phoneCache', 'rediscoverSubmitted'])),
        refreshRequest(action.payload.serverId),
      ];
    })
  );

export default combineEpics(fetchPhoneCacheDataEpic, refreshRequestEpic, refreshPhoneCacheEpic);
