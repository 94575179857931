import { Action } from 'redux';
import { Epic, combineEpics } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ApiPermission, ApiRequestMethod } from '../../common-types';
import { RootState } from '../RootReducer';
import store from '../store';
import { BackgroundRef, req } from '../utils/api';
import { getLocalStorage } from '../utils/common';
import log from '../utils/log';
import { clearPendingPermissionChecks, hasPermission, specToRegex } from '../utils/permissions';
import {
  checkPermission,
  clearPermissions,
  refreshPermissions,
  refreshPermissionsComplete,
  startPermissionsWatchers,
  stopPermissionsWatchers,
  updatePermissions,
} from './PermissionsActions';
import { PermissionAction } from './PermissionsReducer';

export const LOCAL_STORAGE_PERMISSIONS_KEY = (userId: string) =>
  `admin-webapp.${userId}.new.permissions`;

const localStorage = getLocalStorage()!;

export const Utils = {
  writeToLocalStorage: (permissionsToWrite: ApiPermission[] | null, userId: string): void => {
    if (permissionsToWrite) {
      try {
        localStorage.setItem(
          LOCAL_STORAGE_PERMISSIONS_KEY(userId),
          JSON.stringify(
            permissionsToWrite.map((permission: ApiPermission) => ({
              spec: permission.spec,
              verbs: permission.verbs,
            }))
          )
        );
      } catch (error) {
        log.error('Failed to write permissions to local storage', error);
      }
    } else {
      localStorage.removeItem(LOCAL_STORAGE_PERMISSIONS_KEY(userId));
    }
  },

  formatPermissions: (
    unformattedPermissions: Array<Pick<ApiPermission, 'spec' | 'verbs'>>
  ): ApiPermission[] =>
    unformattedPermissions.map(({ spec, verbs }: { spec: string; verbs: ApiRequestMethod[] }) => ({
      spec,
      specRegex: specToRegex(spec),
      verbs: verbs.map(verb => verb.toUpperCase() as ApiRequestMethod),
    })),

  // Load all permissions from localStorage
  loadPermissionsFromLocalStorage: (
    userId: string
  ): Array<Pick<ApiPermission, 'spec' | 'verbs'>> => {
    const permissionsString = localStorage.getItem(LOCAL_STORAGE_PERMISSIONS_KEY(userId));
    return permissionsString && JSON.parse(permissionsString);
  },
};

// Start adding new permissions to localStorage with every new check.
export const localStorageEpic: Epic<PermissionAction, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([startPermissionsWatchers, stopPermissionsWatchers])),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([action, session]) => {
      if (isActionOf(startPermissionsWatchers, action)) {
        return state$.pipe(map(state => ({ permissions: state.permissions.permissions, session })));
      } else {
        return of({ session, permissions: [] });
      }
    }),
    debounceTime(250),
    switchMap(state => {
      if (state.permissions.length > 0) {
        Utils.writeToLocalStorage(state.permissions, state.session?.userId!);
      }
      return [];
    })
  );

// Clear out permissions caches: localStorage
export const clearPermissionsEpic: Epic<PermissionAction, Action, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(clearPermissions)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([_, session]) => {
      Utils.writeToLocalStorage(null, session?.userId!);
      clearPendingPermissionChecks();
      return [];
    })
  );

// Refreshes the user's permissions and updates the cache
export const refreshPermissionsEpic: Epic<PermissionAction, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(refreshPermissions)),
    withLatestFrom(state$.pipe(map(s => s.session?.userId!))),
    switchMap(([action, userId]) => {
      clearPendingPermissionChecks();
      if (action.payload.useCacheIfAvailable) {
        const currentPermissions = Utils.loadPermissionsFromLocalStorage(userId);
        if (currentPermissions?.length > 0) {
          return [
            updatePermissions(Utils.formatPermissions(currentPermissions)),
            refreshPermissionsComplete(),
            ...(action.payload.cb ? action.payload.cb() : []),
          ];
        }
      }
      return req<Array<Pick<ApiPermission, 'spec' | 'verbs'>>>(
        ['userAllPermissions', [userId], 'GET', { skipPermissionCheck: true }],
        store,
        BackgroundRef
      ).pipe(
        switchMap(response => {
          return [
            updatePermissions(Utils.formatPermissions(response.data)),
            refreshPermissionsComplete(),
            ...(action.payload.cb ? action.payload.cb() : []),
          ];
        }),
        catchError(error => {
          log.error('Failed to refresh permission', error);
          if (action.payload.cb) {
            return from(action.payload.cb(error));
          } else {
            return EMPTY;
          }
        })
      );
    })
  );

// Figure out if a user has the given permission.
export const checkPermissionEpic: Epic<PermissionAction, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(checkPermission)),
    mergeMap(checkPermissionAction =>
      hasPermission(checkPermissionAction.payload.apiRequest, store).pipe(
        switchMap(isAllowed => {
          const { cb } = checkPermissionAction.payload;
          return [...(cb ? cb(isAllowed, null) : [])];
        }),
        catchError(error => {
          log.error('Failed to check permission', error);
          if (checkPermissionAction.payload.cb) {
            return from(checkPermissionAction.payload.cb(false, error));
          } else {
            return EMPTY;
          }
        })
      )
    )
  );

export default combineEpics(
  localStorageEpic,
  clearPermissionsEpic,
  refreshPermissionsEpic,
  checkPermissionEpic
);
