import { Component } from 'react';

// Load the files for a component and then render them when they arrive.
export const asyncWrapper = (importComponent: () => Promise<{ default: any }>) => {
  class AsyncComponent extends Component<{}, { component: any | null }> {
    mounted = false;

    constructor(props: {}) {
      super(props);
      this.state = { component: null };
    }

    async componentDidMount() {
      this.mounted = true;
      const { default: component } = await importComponent();

      if (this.mounted) {
        this.setState({ component });
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const C = this.state.component;
      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
};
