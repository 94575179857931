// This component redirect to the location set in our store under navigation

import { RootState } from '../../core/RootReducer';
import { connect } from 'react-redux';
import { RedirectRoute } from './RedirectRoute';

export type RedirectRouteProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => ({
  currentSearch: state.router.location.search,
});

export const RedirectRouteContainer = connect(mapStateToProps)(RedirectRoute);
