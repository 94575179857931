import { RootState } from '../../core/RootReducer';
import { connect } from 'react-redux';
import { Feature } from '../../mobile-api-types';
import { ReactNode } from 'react';
import { isFeatureEnabled } from '../../core/utils/session';
import { FeatureCheck } from './FeatureCheck';

interface ExternalProps {
  features?: Feature[];
  // If supplied, this function will be called to render alernate content when
  // the children are being suppressed because the check failed. If absent,
  // nothing will be rendered when the check fails.
  renderWhenDenied?: () => ReactNode;
  redirectToAccessDenied?: boolean;
  children?: ReactNode;
}

export type FeatureCheckProps = ReturnType<typeof mapStateToProps> & ExternalProps;

export const mapStateToProps = (state: RootState, ownProps: ExternalProps) => ({
  allowed:
    !ownProps.features ||
    ownProps.features.length === 0 ||
    ownProps.features.every(feature => isFeatureEnabled(feature, state.session)),
});

export const FeatureCheckContainer = connect(mapStateToProps)(FeatureCheck);
