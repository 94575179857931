import { push } from 'connected-react-router';
import { createAction } from 'typesafe-actions';
import { commonDelete, commonSave } from '../../../../core/forms/FormActions';
import { growl } from '../../../../core/layout/LayoutActions';
import { ModalTypes, showGlobalModal } from '../../../../core/modal/ModalActions';
import { shared_t } from '../../SharedLocale';
import { integrations_t } from '../IntegrationLocale';
import { FormState } from './ImportFormContainer';

const formatImportFormData = (formData: FormState) => {
  const externalSites = formData.externalSites
    .filter(site => site.importing && !site.imported)
    .map(site => {
      const { existing, imported, importing, ...rest } = site;
      return rest;
    });
  return {
    action: 'import',
    externalSites,
  };
};

const doSitesImport = (
  formId: string,
  formData: FormState,
  integrationName: string,
  mgmtRoute: string
) =>
  commonSave({
    subType: '[Sites Management] Import',
    formId,
    formData,
    request: ['sitesManagement', [], 'POST', { data: formatImportFormData(formData) }],
    isUpdate: false,
    options: {
      successActions: () => {
        return [
          push(mgmtRoute),
          growl(shared_t(['integration', 'import', 'growl', 'importSuccess'], { integrationName })),
        ];
      },
    },
  });

export const importSites = (
  formId: string,
  formData: FormState,
  integrationName: string,
  mgmtRoute: string
) => {
  if (formData.externalSites.find(ex => ex.importing && ex.existing && !ex.imported)) {
    return showGlobalModal(
      ModalTypes.CONFIRM,
      {
        title: shared_t(['integration', 'import', 'importExistingSitesConfirm', 'title']),
        message: shared_t(['integration', 'import', 'importExistingSitesConfirm', 'message'], {
          integrationName,
          noun: integrations_t(['resources', 'sitesLowercase']),
        }),
      },
      () => [doSitesImport(formId, formData, integrationName, mgmtRoute)]
    );
  } else {
    return doSitesImport(formId, formData, integrationName, mgmtRoute);
  }
};

export const unlinkSiteSources = (integrationName: string, sources: string[]) =>
  commonDelete(
    '[Sites Management] Unlink',
    ['sitesManagement', [], 'POST', { data: { action: 'unlink-sources', sources } }],
    {
      successActions: () => {
        return [refreshSitesManagement()];
      },
      title: shared_t(['integration', 'import', 'unlinkSitesDialog', 'title']),
      message: shared_t(['integration', 'import', 'unlinkSitesDialog', 'message'], {
        integrationName,
      }),
      failureMessage: shared_t(['integration', 'import', 'unlinkSitesDialog', 'failure'], {
        integrationName,
      }),
      successMessage: shared_t(['integration', 'import', 'unlinkSitesDialog', 'success'], {
        integrationName,
      }),
    }
  );

// Handled in: Epic
export const refreshSitesManagement = createAction('[Sites Management] Refresh status')();
