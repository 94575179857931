import { createAction } from 'typesafe-actions';

/**
 * FetchShortUrl resolves a promise that is described in 'UrlShortenerEpics'.
 * It Kicks off an API request to determine the shortened url for a given long url.
 * Include this action in mapDispachToProps to make it available in your container props.
 * Handled in: Epic.
 */
export const fetchShortUrl = createAction('[Short URL] Fetch Short URL', (longUrl: string) => ({
  longUrl,
}))();

/**
 * ReceivedShortUrl is resolved by fetchShortUrl in the epic. In UrlShortenerReducer,
 * receivedShortUrl maps its values to state.
 * It updates the state to reflect the shortened url we received from the server.
 * Handled in: Reducer.
 */
export const receivedShortUrl = createAction(
  '[Short URL] Received Short URL',
  (longUrl: string, shortUrl?: string) => ({ longUrl, shortUrl })
)();

/**
 * Cleans up any state that was used in this route when we are leaving it.
 * Handled in: Reducer.
 */
export const destroy = createAction('[Short URL] Destroy')();
