import { createAction } from 'typesafe-actions';
import { ElementMenu } from '../utils/side-bar';

// Handled in: Reducer
// Toggles the profile info dropdown.
export const toggleProfileInfoExpanded = createAction('[Side Bar] Toggle Profile Info Expanded')();

// Handled in: Reducer
// Sets the expand or collapse state of menu elements.
export const setMenuElements = createAction('[Side Bar] Set Menu Elements', (elements: any[]) => ({
  elements,
}))();

// Handled in: Reducer, Epic
// Sets the search query in the store, filters the sidebar elements.
export const updateSearch = createAction('[Side Bar] Update Search', (query: string) => ({
  query,
}))();

// Handled in: Epic
// Refreshes sidebar honoring the search query.
export const refreshSideBar = createAction('[Side Bar] Refresh')();

// Handled in: Reducer
// Activates selected sidebar link.
export const updateActiveRoute = createAction('[Side Bar] Update Active Route', (url: string) => ({
  url,
}))();

// Handled in: Reducer
// Expands a sidebar element.
export const expandElement = createAction(
  '[Side Bar] Expand Element',
  (menuElement: ElementMenu) => ({ menuElement })
)();
