import { ActionType, getType } from 'typesafe-actions';
import { MobileApiNotification } from '../../mobile-api-types';
import * as layout from './LayoutActions';
import { LayoutType, RightSidebarType, SidebarType } from './LayoutActions';

export type LayoutAction = ActionType<typeof layout>;

export interface LayoutState {
  layoutType: LayoutType;
  leftSidebarOpened: boolean;
  rightSidebarOpened: boolean;
  rightSidebarType: RightSidebarType;
  notifications: MobileApiNotification[];
  hasActiveNotification: boolean;
  showResourceCenter: boolean;
}

// Exported for testing
export const initialLayoutState: LayoutState = {
  layoutType: LayoutType.StretchedLayout,
  leftSidebarOpened: false,
  rightSidebarOpened: false,
  rightSidebarType: RightSidebarType.NONE,
  notifications: [],
  hasActiveNotification: false,
  showResourceCenter: false,
};

export default function layoutReducer(
  state: LayoutState = initialLayoutState,
  action: LayoutAction
): LayoutState {
  switch (action.type) {
    case getType(layout.setLayout):
      return { ...state, layoutType: action.payload.layoutType };

    case getType(layout.closeSidebar):
      if (action.payload.side === SidebarType.Left) {
        return { ...state, leftSidebarOpened: false };
      } else {
        return { ...state, rightSidebarOpened: false };
      }

    case getType(layout.closeSidebars):
      return { ...state, leftSidebarOpened: false, rightSidebarOpened: false };

    case getType(layout.toggleSidebar):
      if (action.payload.side === SidebarType.Left) {
        return { ...state, leftSidebarOpened: !state.leftSidebarOpened };
      } else {
        return { ...state, rightSidebarOpened: !state.rightSidebarOpened };
      }

    case getType(layout.toggleRightSidebarType): {
      return {
        ...state,
        rightSidebarType: action.payload.type,
        rightSidebarOpened: action.payload.type !== RightSidebarType.NONE,
      };
    }

    case getType(layout.updateNotifications): {
      return {
        ...state,
        notifications: action.payload.data.data || null,
      };
    }

    case getType(layout.updateActiveNotifications): {
      return {
        ...state,
        hasActiveNotification: !!action.payload.data.data[0],
      };
    }

    case getType(layout.toggleResourceCenter): {
      return {
        ...state,
        showResourceCenter: !state.showResourceCenter,
      };
    }

    default:
      return state;
  }
}
