import { Dispatch, bindActionCreators } from 'redux';
import { navigateTo } from '../../../core/navigation/NavigationActions';
import { connect } from 'react-redux';
import { AccessDenied } from './AccessDenied';

export type AccessDeniedProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigateTo,
    },
    dispatch
  );

export const AccessDeniedContainer = connect(null, mapDispatchToProps)(AccessDenied);
