import { createAction } from 'typesafe-actions';
import { ValidationError } from 'yup';
import { MobileApiMessageTemplate } from '../../mobile-api-types';

type PreviewProps = Pick<
  MobileApiMessageTemplate,
  'ttsCustomContent' | 'ttsType' | 'ttsSpeed' | 'subject' | 'body' | 'ttsVoiceId'
> & { ignoreLexicon?: boolean };

export const ttsNone = createAction('[TTS] Transition TTS State to None', (previewId: string) => ({
  previewId,
}))();

export const ttsFetching = createAction(
  '[TTS] Transition TTS State to Fetching',
  (previewId: string, previewProps: PreviewProps) => ({ previewId, previewProps })
)();

export const ttsReady = createAction(
  '[TTS] Transition TTS State to Ready',
  (previewId: string, preview?: { hash: string; size: number }) => ({ previewId, preview })
)();

export const ttsError = createAction(
  '[TTS] Transition TTS State to Error',
  (previewId: string, errors: ValidationError[]) => ({ previewId, errors })
)();

export const ttsClear = createAction('[TTS] Clear TTS Preview', (previewId: string) => ({
  previewId,
}))();
