import { ReactNode } from 'react';
import { ApiRequest } from '../../common-types';
import { isArrayOfPermissions } from '../../core/utils/permissions';
import { PermissionCheckContainer } from './PermissionCheckContainer';
import { ResolveDataContainer, ExternalProps } from './ResolveDataContainer';

export interface ResolveApiAdvancedConfig {
  request: ApiRequest;
  reqAll?: boolean;
}

export interface ResolveDataSafelyProps<T> {
  resolve: { [key in keyof T]: ApiRequest | ResolveApiAdvancedConfig };
  permissions?: ApiRequest | ReadonlyArray<ApiRequest>;
  render: (data: { [key in keyof T]: unknown extends T[key] ? any : T[key] }) => ReactNode;
  // Don't show a loading indicator while resolving data or permissions even
  // if there is a `renderWhenDenied` function.
  dontShowLoadingIndicator?: boolean;
  // If supplied, this function will be called to render alternate content when
  // the children are being suppressed because the check failed. Its argument
  // will be true if the failure was due to permissions (rather than domain
  // visibility). If absent, nothing will be rendered when the check fails.
  renderWhenDenied?: (dueToPermissions: boolean) => ReactNode;

  renderWhenFailed?: () => ReactNode;
}

export const ResolveDataSafely = <T = any,>({
  resolve,
  render,
  dontShowLoadingIndicator,
  renderWhenDenied,
  renderWhenFailed,
  permissions,
}: ResolveDataSafelyProps<T>) => {
  const reqs = Object.values(resolve).map(x =>
    Array.isArray(x) ? x : (x as ResolveApiAdvancedConfig).request
  ) as Array<ApiRequest>;

  const consolidatedPermissions = permissions
    ? isArrayOfPermissions(permissions)
      ? [...permissions, ...reqs]
      : [permissions, ...reqs]
    : reqs;

  return (
    <PermissionCheckContainer
      permissions={consolidatedPermissions}
      renderWhenDenied={renderWhenDenied}
      opts={{ showLoadingIndicator: !dontShowLoadingIndicator && !!renderWhenDenied }}>
      <ResolveDataContainer
        resolve={resolve}
        render={render as ExternalProps['render']}
        dontShowLoadingIndicator={dontShowLoadingIndicator}
        renderWhenFailed={renderWhenFailed}
      />
    </PermissionCheckContainer>
  );
};
