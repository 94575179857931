// A simple back button, mainly used on 'edit' pages to return to a list view.

import { Link } from 'react-router-dom';
import { Box, Icon, Typography } from 'singlewire-components';
import { useOverlay } from '../Overlay';
import { Button } from './Button';

export type BackLinkProps = {
  label: string;
  rightLabel?: string | null;
  to: string;
  onClick?: () => void;
};

export const BackLink = ({ label, rightLabel, to, onClick }: BackLinkProps) => (
  <Box align="between">
    <Box>
      <Button
        onClick={onClick}
        variant="text"
        id="back-link"
        color="gray"
        startIcon={<Icon.PreviousPage />}
        label={label}
        asChild>
        <Link to={to}>{label}</Link>
      </Button>
    </Box>
    {rightLabel && (
      <Box mt="sm">
        <Typography variant="body" color="inherit">
          {rightLabel}
        </Typography>
      </Box>
    )}
  </Box>
);

export const OverlayAwareBackLink = (props: BackLinkProps) => {
  const overlayContext = useOverlay();
  return (
    <BackLink
      {...props}
      label={overlayContext?.backRouteLabel || props.label}
      to={overlayContext?.backRoute || props.to}
    />
  );
};
