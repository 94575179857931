// Middleware that operates on every http request/response FROM REACT

import { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import store from '../../core/store';
import { bumpSessionExpiration, logout } from '../session/SessionActions';
import {
  augmentFeatureHeader,
  containsFeatureHeader,
  HttpHeaders,
  IGNORE_LOADING_BAR_HEADER,
  IGNORE_RATE_LIMITS_HEADER,
  SKIP_SESSION_EXTENSION_HEADER,
} from './http-transformers';
import log from './log';
import { LogoutReason } from '../login/LoginReducer';

let requestCount = 0;

const clientVersionHeader = { 'X-Singlewire-Client': `admin-webapp/${window.buildTag}` };

const shouldIgnoreRateLimits = () => {
  return window.ignoreRateLimits || window.localStorage?.getItem('ignoreRateLimits') === 'true';
};

export const defaultHttpRequestInterceptor = (
  req: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  const { domains, facilities } = store.getState();
  const actingDomainId = domains.actingDomainId;
  const actingFacilityId = facilities.actingFacilityId;

  // Check if we have should ignore the loading bar
  const ignoreLoadingBar = containsFeatureHeader(IGNORE_LOADING_BAR_HEADER, req.headers);

  if (!ignoreLoadingBar) {
    requestCount++;
    if (requestCount === 1) {
      // TODO - start loading bar
    }
  }

  const headers = {
    // If the user requested specific features, make sure to remove those
    ...augmentFeatureHeader(
      (req.headers || {}) as HttpHeaders,
      [IGNORE_LOADING_BAR_HEADER],
      'remove'
    ),
    // If the user is logged in, the auth token will be provided via cookie
    // If the user is part of a domain, be sure to add the X-Singlewire-Domain header accordingly, likewise with facilities
    ...(actingDomainId ? { 'X-Singlewire-Domain': actingDomainId } : {}),
    ...(actingFacilityId ? { 'X-Singlewire-Facility': actingFacilityId } : {}),
    // Always append our client version header to make tracking requests easier in the REST API
    ...clientVersionHeader,
  } as HttpHeaders;

  const axioHeaders = (
    shouldIgnoreRateLimits()
      ? augmentFeatureHeader(headers, [IGNORE_RATE_LIMITS_HEADER], 'append')
      : headers
  ) as AxiosRequestHeaders;

  return {
    ...req,
    headers: axioHeaders,
  };
};

export const defaultHttpResponseInterceptor = (resp: AxiosResponse<any>): AxiosResponse<any> => {
  // Check if we should skip extending our session for this request
  const requestHeaders = resp.config.headers;
  const skipSessionExtension = containsFeatureHeader(SKIP_SESSION_EXTENSION_HEADER, requestHeaders);
  const ignoreLoadingBar = containsFeatureHeader(IGNORE_LOADING_BAR_HEADER, requestHeaders);

  if (!skipSessionExtension) {
    store.dispatch(bumpSessionExpiration());
  }

  if (!ignoreLoadingBar) {
    requestCount--;
    if (requestCount === 0) {
      // TODO - Loading Bar Complete
    }
  }

  return resp;
};

export const defaultHttpResponseErrorInterceptor = (error: any): any => {
  if (error.response && error.response.status === 401 && store.getState().session) {
    // If we are now unauthorized and have a session, log the user out
    log.info('Request to API was unauthorized. Sign out.');
    store.dispatch(logout(LogoutReason.SessionExpired));
  }

  throw error;
};

// TODO - add tests
