import { Action } from 'redux';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { EMPTY, timer } from 'rxjs';
import { RootState } from '../RootReducer';
import { isBrowserVisible } from '../utils/common';
import { BackgroundRef, req } from '../utils/api';
import { PermissionDeniedError } from '../../common-types';
import store from '../store';
import log from '../utils/log';
import { logout } from '../session/SessionActions';
import {
  startMonitoringSystemHealthAlarms,
  systemHealthChanged,
  updateSystemHealth,
  updateSystemHealthAndNavigate,
} from './SystemHealthActions';
import {
  IgnoreLoadingBarTransform,
  IgnoreSessionExtendTransform,
} from '../utils/http-transformers';
import Routes from '../route/Routes';
import { navigateTo } from '../navigation/NavigationActions';

// Periodically check for active alarms every 30s starting immediately
export const systemHealthPollingEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(startMonitoringSystemHealthAlarms)),
    switchMap(() =>
      timer(0, 30000).pipe(
        filter(() => isBrowserVisible()),
        switchMap(() =>
          req(
            [
              'systemHealth',
              [],
              'GET',
              { transforms: [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform] },
            ],
            store,
            BackgroundRef
          ).pipe(
            map(response => updateSystemHealth(response.data)),
            catchError(error => {
              if (!(error instanceof PermissionDeniedError)) {
                log.error('Failed to determine active alarms', error);
              }
              return EMPTY;
            })
          )
        ),
        takeUntil(action$.pipe(ofType(logout)))
      )
    )
  );

// Accutely update system health
export const refreshSystemHealthEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(systemHealthChanged)),
    switchMap(() =>
      req(
        [
          'systemHealth',
          [],
          'GET',
          { transforms: [IgnoreSessionExtendTransform, IgnoreLoadingBarTransform] },
        ],
        store,
        BackgroundRef
      ).pipe(
        map(response => updateSystemHealth(response.data)),
        catchError(error => {
          if (!(error instanceof PermissionDeniedError)) {
            log.error('Failed to determine active alarms', error);
          }
          return EMPTY;
        })
      )
    )
  );

// update system health and navigate to dashboard
export const updateSystemHealthAndNavigateEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(updateSystemHealthAndNavigate)),
    switchMap(action => [
      updateSystemHealth(action.payload.data),
      navigateTo(Routes.SystemHealth.Dashboard),
    ])
  );

export default combineEpics(systemHealthPollingEpic, updateSystemHealthAndNavigateEpic);
