import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { askUserToChangeActingFacility } from '../../core/facilities/FacilitiesActions';
import { navigateTo } from '../../core/navigation/NavigationActions';
import { checkPermission } from '../../core/permissions/PermissionsActions';
import { hasCachedPermission } from '../../core/permissions/PermissionsSelectors';
import { RootState } from '../../core/RootReducer';
import { isFeatureEnabled } from '../../core/utils/session';
import { MobileApiFacility } from '../../mobile-api-types';
import { FacilitySelect, VIEW_FACILITIES, VIEW_SITES } from './FacilitySelect';

// This component allows a user to switch between acting domains.
export interface ExternalProps {
  xSmallScreen?: boolean;
}

export type FacilitySelectProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: RootState) => ({
  facilitiesEnabled: state.session! && isFeatureEnabled('facilitiesEnabled', state.session),
  userIsGlobal: state.session!.userIsGlobal,
  hasFacilitiesPermissions: hasCachedPermission(state, VIEW_FACILITIES),
  hasSitesPermissions: hasCachedPermission(state, VIEW_SITES),
  // This is still labeled as a domain on state because of backend complexity but it is the facility
  actingFacility: state.session!.domain as MobileApiFacility,
  facilities: state.facilities.facilities,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ askUserToChangeActingFacility, navigateTo, checkPermission }, dispatch);

export const FacilitySelectContainer = connect(mapStateToProps, mapDispatchToProps)(FacilitySelect);
