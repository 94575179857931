import { ActionType, getType } from 'typesafe-actions';
import { MobileApiMSTeamsBot } from '../../mobile-api-types';
import * as msteamsConfigActions from './MSTeamsActions';

export type MSTeamsConfigAction = ActionType<typeof msteamsConfigActions>;

export interface MSTeamsConfigState {
  bot?: MobileApiMSTeamsBot;
  status: 'ok' | 'error';
}

export const initialMSTeamsConfigState: MSTeamsConfigState = {
  status: 'ok',
};

export interface MSTeamsConfigModuleState {
  msteamsConfig: MSTeamsConfigState;
}

export default function msteamsReducer(
  state: MSTeamsConfigState = initialMSTeamsConfigState,
  action: MSTeamsConfigAction
) {
  switch (action.type) {
    case getType(msteamsConfigActions.updateMSTeamsState):
      return { ...state, ...action.payload.state };

    default:
      return state;
  }
}
