import { registerResource } from '../../../core/utils/localization';

export const AccessDeniedLocale = {
  en: {
    title: 'Access Denied',
    message: 'You are not authorized to access this page.',
  },
};

export default AccessDeniedLocale;
export const accessDenied_t = registerResource<typeof AccessDeniedLocale.en>(
  'accessDenied',
  AccessDeniedLocale
);
