import axios from 'axios';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { EMPTY, from, Observable } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import log from '../utils/log';
import { fetchShortUrl, receivedShortUrl } from './UrlShortenerActions';
import { common_t } from '../../CommonLocale';
import { UrlShortenerModuleState } from './UrlShortenerReducer';

interface UrlShortenerShortUrl {
  shortUrl: string;
}

/**
 * Fetch the shortened URL from the urlShortener if needed helper and cache it.
 */
export const fetchShortUrlEpic: Epic<Action, Action, UrlShortenerModuleState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(fetchShortUrl)),
    withLatestFrom(state$),
    switchMap(
      ([action, state]): Observable<Action> => {
        const longUrl = action.payload.longUrl;
        if (!state.urlShortener.urls[longUrl]) {
          return from(
            axios.post<UrlShortenerShortUrl>('urlShortener', { longUrl })
          ).pipe(
            switchMap(resp => {
              return [receivedShortUrl(longUrl, resp.data.shortUrl)];
            }),
            catchError((error): [Action] => {
              log.error('Failed to fetch short url for', longUrl, error);
              return [receivedShortUrl(longUrl, common_t(['notifications', 'requestFailed']))];
            })
          );
        } else {
          return EMPTY;
        }
      }
    )
  );

export default combineEpics(fetchShortUrlEpic);
