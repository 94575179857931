import { common_t } from '../../../CommonLocale';
import { GLOBAL_FACILITY_ID } from '../../../constants';
import {
  MobileApiApiDevice,
  MobileApiBellSchedule,
  MobileApiCollaborationGroup,
  MobileApiConfirmationRequest,
  MobileApiDeviceGroup,
  MobileApiDialingConfigV2,
  MobileApiDistributionList,
  MobileApiFacility,
  MobileApiIncident,
  MobileApiIncidentPlan,
  MobileApiMessageTemplate,
  MobileApiNotification,
  MobileApiNotificationProfile,
  MobileApiPhoneExceptionV2,
  MobileApiRingList,
  MobileApiScenario,
  MobileApiScheduledNotification,
  MobileApiSecurityGroup,
  MobileApiSite,
  MobileApiSiteRole,
  MobileApiSpeakerV2,
  MobileApiUserNotification,
} from '../../../mobile-api-types';
import Routes from '../../../core/route/Routes';
import { SearchPagerAttributeFilter } from '../../shared/components/TableContainer';
import { registerResource } from '../../../core/utils/localization';
import {
  searchPagerFirstPage,
  searchPagerRefresh,
} from '../../../core/search-pager/SearchPagerActions';
import { SearchPagerState } from '../../../core/search-pager/SearchPagerReducer';

export type FacilityShareableResourceType =
  | MobileApiBellSchedule
  | MobileApiCollaborationGroup
  | MobileApiRingList
  | MobileApiScenario
  | MobileApiIncidentPlan
  | MobileApiConfirmationRequest
  | MobileApiDeviceGroup
  | MobileApiDistributionList
  | MobileApiMessageTemplate
  | MobileApiNotificationProfile
  | MobileApiSiteRole;

export type FacilityUnshareableResourceType =
  | MobileApiSite
  | MobileApiSecurityGroup
  | MobileApiNotification
  | MobileApiUserNotification
  | MobileApiScheduledNotification
  | MobileApiIncident
  | MobileApiSpeakerV2
  | MobileApiApiDevice
  | MobileApiPhoneExceptionV2
  | MobileApiDialingConfigV2;

export const FACILITY_RESOURCE_BASE_ROUTES = [
  Routes.Bells.Index,
  Routes.ConfirmationRequests.Index,
  Routes.DeviceGroups.Index,
  Routes.DistributionLists.Index,
  Routes.CommandCenter.Incidents.Index,
  Routes.CommandCenter.IncidentPlans.Index,
  Routes.IPSpeakerV2.Index,
  Routes.MessageTemplates.Index,
  Routes.Notifications.List,
  Routes.ScheduledNotifications.List,
  Routes.NotificationProfiles.Index,
  Routes.Bells.RingLists.Index,
  Routes.CommandCenter.Scenarios.Index,
  Routes.SecurityGroups.Index,
  Routes.CommandCenter.Sites.Index,
  Routes.CommandCenter.SiteRoles.Index,
  Routes.Users.Index,
  Routes.DialCastV2.DialingConfigurations.Index,
  Routes.DialCastV2.PhoneExceptions.Index,
  // collaboration groups
  Routes.CiscoWebexTeams.Index,
  Routes.ConferenceCall.Index,
  Routes.OutboundCap.Index,
  Routes.OutboundRss.Index,
  Routes.TeamsGroup.Index,
  Routes.Twitter.Index,
  Routes.Devices.Index,
];

const relocationRouteCompareFn = (a: string, b: string) =>
  // reverse sort by length so longest/closest path matches first
  a.length > b.length ? -1 : b.length > a.length ? 1 : 0;

/**
 * Returns the new route path to redirect to (if applicable), when switching facilities from a certain location (route).
 * @param location current location
 * @param oldActingFacilityId the facility we are coming <em>from</em> prior to switch
 */
export const findRelocationRoute = (location: string, oldActingFacilityId: string) => {
  const newLocation = [...FACILITY_RESOURCE_BASE_ROUTES]
    .sort(relocationRouteCompareFn)
    .find(r => location.startsWith(r));

  if (!newLocation && oldActingFacilityId === GLOBAL_FACILITY_ID) {
    // this isn't a facility resource that we are on as we switch away from global
    return Routes.Home;
  } else if (newLocation !== location) {
    // we are on some specific facility resource path and need to reroute to the base route on switch for sanity/clarity
    return Routes.Bells.Index === newLocation ? Routes.Bells.Schedules.Index : newLocation;
  } else {
    // stay put
    return undefined;
  }
};

/**
 * Returns the actions required to re-execute active searchPagers when switching facilities.
 * @param searchPagers state of current search pagers
 */
export const buildPagerRefreshActions = (searchPagers: { [key: string]: SearchPagerState<any> }) =>
  Object.entries(searchPagers).map(([pagerId, pagerState]) =>
    pagerState.start
      ? //If the pager is not at the start: return to start -> execute pager
        searchPagerFirstPage(pagerId)
      : //If the pager is at the start: execute pager
        searchPagerRefresh(pagerId)
  );

const isSharedSearchPagerFilter: SearchPagerAttributeFilter = {
  label: common_t(['facilities', 'sharedStatus']),
  name: 'isShared',
  type: 'attribute',
  localizeName: () => common_t(['facilities', 'sharedStatus']),
  localizeOption: option =>
    option === 'true' ? common_t(['facilities', 'shared']) : common_t(['facilities', 'notShared']),
  options: ['true', 'false'],
};

const buildManagingFacilitySearchPagerFilter = (
  actingFacilityId: string,
  facilities: MobileApiFacility[]
) => {
  const facilityIds =
    actingFacilityId === GLOBAL_FACILITY_ID
      ? facilities.map(f => f.id)
      : [GLOBAL_FACILITY_ID, actingFacilityId];

  const facilityOptionsLocale = {
    en: Object.assign({}, ...facilities.map(x => ({ [x.id]: x.name }))),
  };

  const facilityOptions_t = registerResource<typeof facilityOptionsLocale.en>(
    'facility',
    facilityOptionsLocale
  );

  return {
    label: common_t(['facilities', 'managingSite']),
    name: 'facilityId',
    type: 'attribute',
    localizeName: () => common_t(['facilities', 'managingSite']),
    localizeOption: (option: (typeof facilityIds)[number]) => facilityOptions_t([option]),
    options: facilityIds,
  } as SearchPagerAttributeFilter;
};

export const facilityShareableSearchPagerFilter = (
  actingFacilityId: string | null,
  facilities: MobileApiFacility[] | null
) =>
  actingFacilityId === GLOBAL_FACILITY_ID && facilities
    ? [
        buildManagingFacilitySearchPagerFilter(actingFacilityId, facilities),
        isSharedSearchPagerFilter,
      ]
    : [isSharedSearchPagerFilter];

export const facilityUnshareableSearchPagerFilter = (
  actingFacilityId: string | null,
  facilities: MobileApiFacility[] | null
) =>
  actingFacilityId === GLOBAL_FACILITY_ID && facilities
    ? [buildManagingFacilitySearchPagerFilter(actingFacilityId, facilities)]
    : [];

const isSharedTablePagerFilter = {
  label: common_t(['facilities', 'sharedStatus']),
  attribute: 'isShared',
  options: [
    { label: common_t(['facilities', 'shared']), value: 'true' },
    { label: common_t(['facilities', 'notShared']), value: 'false' },
  ],
};

const buildManagingFacilityTablePagerFilter = (
  actingFacilityId: string,
  facilities: MobileApiFacility[]
) => {
  const validFacilities =
    actingFacilityId === GLOBAL_FACILITY_ID
      ? facilities
      : facilities.filter(
          facility => facility.id === GLOBAL_FACILITY_ID || facility.id === actingFacilityId
        );
  return {
    label: common_t(['facilities', 'managingSite']),
    attribute: 'facilityId',
    type: 'attribute',
    options: [
      ...validFacilities.map(facility => {
        return {
          label: facility.name,
          value: facility.id,
        };
      }),
    ],
  };
};

export const facilityShareableTablePagerFilter = (
  actingFacilityId: string | null,
  facilities: MobileApiFacility[] | null
) =>
  actingFacilityId === GLOBAL_FACILITY_ID && facilities
    ? [
        buildManagingFacilityTablePagerFilter(actingFacilityId, facilities),
        isSharedTablePagerFilter,
      ]
    : [isSharedTablePagerFilter];

export const facilityUnshareableTablePagerFilter = (
  actingFacilityId: string | null,
  facilities: MobileApiFacility[] | null
) =>
  actingFacilityId === GLOBAL_FACILITY_ID && facilities
    ? [buildManagingFacilityTablePagerFilter(actingFacilityId, facilities)]
    : [];

export const facilityShareableInfoColumn = (resource: FacilityShareableResourceType) =>
  resource.isShared
    ? common_t(['facilities', 'shared'])
    : resource.facilityId === GLOBAL_FACILITY_ID
      ? common_t(['facilities', 'global'])
      : resource.facility?.name || common_t(['resource', 'site']);

export const facilityUnshareableInfoColumn = (resource: FacilityUnshareableResourceType) =>
  resource.facilityId === GLOBAL_FACILITY_ID
    ? common_t(['facilities', 'global'])
    : resource.facility?.name || common_t(['resource', 'site']);
