import { PureComponent } from 'react';
import { Trans } from 'react-i18next';
import { MobileApiIcopServerFailoverTypeEnum } from '../../mobile-api-types';
import { Alert } from '../shared/components/Alert';
import { Link } from '../shared/components/Link';
import { FailoverEditingBackupInfoBannerProps } from './FailoverEditingBackupInfoBannerContainer';

export class FailoverEditingBackupInfoBanner extends PureComponent<FailoverEditingBackupInfoBannerProps> {
  render() {
    const { setIcopServerId, selectedICOPServer, noFailoverChangesAllowed = false } = this.props;
    const failoverReadOnly =
      selectedICOPServer &&
      selectedICOPServer.failoverType === MobileApiIcopServerFailoverTypeEnum.Backup;
    const primaryServerId = selectedICOPServer?.failoverPrimary?.id;
    return (
      <>
        {failoverReadOnly && (
          <Alert id="editingFailoverBackupBanner" color="primary" slim>
            {primaryServerId && (
              <>
                {noFailoverChangesAllowed ? (
                  <Trans i18nKey="shared:failoverEditingBackupInfoBanner.changeToPrimary">
                    {/* eslint-disable jsx-a11y/anchor-has-content */}
                    <Link
                      onClick={e => {
                        e.preventDefault(); // Need this because linter doesn't like href=javascript:void(0)
                        setIcopServerId(primaryServerId, true);
                      }}
                      to="/#"
                    />
                  </Trans>
                ) : (
                  <Trans i18nKey="shared:failoverEditingBackupInfoBanner.makeChangesToBackup">
                    {/* eslint-disable jsx-a11y/anchor-has-content */}
                    <Link
                      onClick={e => {
                        e.preventDefault(); // Need this because linter doesn't like href=javascript:void(0)
                        setIcopServerId(primaryServerId, true);
                      }}
                      to="/#"
                    />
                  </Trans>
                )}
              </>
            )}
          </Alert>
        )}
      </>
    );
  }
}
