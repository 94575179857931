import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import first from 'lodash/first';
import find from 'lodash/find';
import { RootState } from '../RootReducer';
import { createSelector, OutputSelector } from 'reselect';
import QuickLRU from 'quick-lru';
import { ApiPermission, ApiRequest, ApiResource } from '../../common-types';
import { pendingPermissionChecks$ } from '../utils/permissions';
import { PermissionLookupResult } from './PermissionsReducer';
import { buildApiPathFromResource, getResourceAndValidateRequest } from '../utils/api-resources';

const isLoggedInSelector = (state: RootState) => !!state.session;
const selectedICOPServerIdSelector = (state: RootState) => state.icopServers.selectedICOPServerId;
const permissionsSelector = (state: RootState) => state.permissions.permissions;
const unlockedSelector = (state: RootState) => state.permissions.unlocked;

type PermissionOutputSelector = OutputSelector<RootState, PermissionLookupResult, any>;

// Creating a cache of selectors as they don't support dynamic variables w/o memoization
const cachedPermissionsSelectorLRU = new QuickLRU<string, PermissionOutputSelector>({
  maxSize: 100,
});

// Builds a permission selector that only re-runs when either log out, get a new icop server id, or the
// store's permissions change
const buildPermissionSelector = (request: ApiRequest): PermissionOutputSelector => {
  const [, pathParams, requestMethod] = request;
  const resource: ApiResource = getResourceAndValidateRequest(request);
  return createSelector<
    RootState,
    boolean,
    ApiPermission[],
    boolean,
    string | null,
    PermissionLookupResult
  >(
    isLoggedInSelector,
    permissionsSelector,
    unlockedSelector,
    selectedICOPServerIdSelector,
    (isLoggedIn, permissions, unlocked, selectedIcopServerId) => {
      // Validate the request
      if (!isLoggedIn) {
        return PermissionLookupResult.NOT_ALLOWED;
      }

      // Build our api path/permission spec
      const apiPath = buildApiPathFromResource(resource, pathParams, selectedIcopServerId);

      // Filter our existing permissions into exact and wildcard matches
      const matches = groupBy(permissions, ({ spec, specRegex }: ApiPermission) => {
        if (spec === apiPath) {
          return 'exact';
        } else if (apiPath.match(specRegex)) {
          return 'wildcard';
        } else {
          return 'none';
        }
      });

      // If we have an exact match, just return a whether we are allowed or not allowed
      const exactMatch: ApiPermission = first(matches.exact)!;
      if (exactMatch) {
        return includes(exactMatch.verbs, requestMethod)
          ? PermissionLookupResult.ALLOWED
          : PermissionLookupResult.NOT_ALLOWED;
      }

      // If there is an exact match but pending, return loading
      const pendingExactMatch = pendingPermissionChecks$
        .getValue()
        .find(permission => permission.spec === apiPath);
      if (pendingExactMatch) {
        return PermissionLookupResult.LOADING;
      }

      // If we have a *resolved* and *true* wildcard match, return that our permissions are allowed.
      // We do this because it's possible to fail a wildcard match, but pass an exact match, however, if the
      // wildcard match returns true, then we have permissions so we can skip the specific check.
      const resolvedWildcard = find(matches.wildcard, (permission: ApiPermission) =>
        includes(permission.verbs, requestMethod)
      ) as ApiPermission;
      if (resolvedWildcard) {
        return PermissionLookupResult.ALLOWED;
      }

      // If we have no exact matches and no wildcard permissions and we're not still pending
      // an exact match (as that's handled above), return not allowed.
      if (!unlocked && !matches.exact && !matches.wildcard) {
        return PermissionLookupResult.NOT_ALLOWED;
      }

      return PermissionLookupResult.LOADING;
    }
  );
};

// Checks the permission in the store and returns the current PermissionLookupResult
export const getCachedPermissionResult = (
  state: RootState,
  request: ApiRequest
): PermissionLookupResult => {
  const key = JSON.stringify(request);
  if (!cachedPermissionsSelectorLRU.has(key)) {
    cachedPermissionsSelectorLRU.set(key, buildPermissionSelector(request));
  }
  const selector = cachedPermissionsSelectorLRU.get(key)!;
  return selector(state);
};

// Checks the permission in the store to see if we are allowed to perform the ApiRequest
export const hasCachedPermission = (state: RootState, request: ApiRequest): boolean =>
  getCachedPermissionResult(state, request) === PermissionLookupResult.ALLOWED;
