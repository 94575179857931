// Manage state related to inititialization.

import { createAction } from 'typesafe-actions';
import { MobileApiSession } from '../../mobile-api-types';

export const initialize = createAction('[Initialize] Initialize')();
export const inspectSession = createAction('[Initialize] Inspect Session')();
export const initializeComplete = createAction(
  '[Initialize] Complete',
  (session: MobileApiSession | null) => ({ session })
)();
export const initializePendo = createAction('[Initialize] Pendo')();
export const setInitializeComplete = createAction('[Initialize] Set Complete')();
