// Track and manage all of the Redux form state

import store from '../store';
import { getFormValues, initialize, isDirty } from 'redux-form';
import { RootState } from '../RootReducer';

export function markReduxFormPristine(formId: string, state: RootState = store.getState()) {
  store.dispatch(
    initialize(formId, getFormValues(formId)(state), {
      keepSubmitSucceeded: true,
      updateUnregisteredFields: true,
      keepDirty: false,
      keepValues: false,
    })
  );
}

export function markReduxFormsPristine(
  formIdsToSkip: string[],
  state: RootState = store.getState()
) {
  Object.keys(state.form)
    .filter(formId => !formIdsToSkip.includes(formId))
    .forEach(formId => markReduxFormPristine(formId, state));
}

export function isReduxFormDirty(formId: string, state: RootState = store.getState()): boolean {
  return isDirty(formId)(state);
}

export function hasDirtyReduxForm(formIdsToSkip: string[]): boolean {
  const state = store.getState();
  return Object.keys(state.form)
    .filter(formId => !formIdsToSkip.includes(formId))
    .some(formId => isReduxFormDirty(formId));
}

export default {
  markReduxFormPristine,
  markReduxFormsPristine,
  isReduxFormDirty,
  hasDirtyReduxForm,
};

// TODO - add tests
