export function formatErrorForLogging(e: Error) {
  return { stack: e.stack, name: e.name, message: e.message };
}

export function safeJsonStringify(o: any, replacer?: (key: string, value: any) => any) {
  const cache = new WeakSet();
  return JSON.stringify(o, (key, value) => {
    if (value instanceof Error) {
      return formatErrorForLogging(value);
    } else if (typeof value === 'object' && value !== null) {
      if (cache.has(value)) {
        return '<<__CIRCULAR__>>';
      } else {
        cache.add(value);
      }
    }
    return replacer ? replacer(key, value) : value;
  });
}
