import { useEffect, PropsWithChildren } from 'react';
import { FormProps } from 'redux-form/lib/Form';
import { FormMeta, useSinglewireFormsRegistration } from './SinglewireFormsContext';
import { Form } from 'redux-form';

export interface ExternalProps extends FormMeta {
  formId: string;
}

export function SinglewireForm<FormData = {}, P = {}, ErrorType = string>({
  children,
  formId,
  className = 'form-horizontal',
  noValidate = true,
  skipDirtyFormChecking,
  ...props
}: PropsWithChildren<FormProps<FormData, P, ErrorType> & ExternalProps>) {
  const { registerForm, unregisterForm } = useSinglewireFormsRegistration();
  useEffect(() => {
    registerForm(formId, { skipDirtyFormChecking });
  }, [formId, registerForm, skipDirtyFormChecking]);
  useEffect(() => {
    return () => {
      unregisterForm(formId);
    };
  }, [formId, unregisterForm]);

  return (
    <Form className={className} noValidate={noValidate} {...props}>
      {children}
    </Form>
  );
}
