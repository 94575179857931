import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  protectVisibleDomains,
  endVisibleDomainProtection,
} from '../../../../core/domains/DomainsActions';
import { DomainVisibilityProtector } from './DomainVisibilityProtector';
import { ApiRequest } from '../../../../common-types';
import { RootState } from '../../../../core/RootReducer';
import { isFeatureEnabled } from '../../../../core/utils/session';

export interface DomainVisibilityProtection {
  resourceRequest: ApiRequest;
  recoveryRoute: string;
}

export type DomainVisibilityProtectorProps = DomainVisibilityProtection &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => ({
  isDomainsEnabled: isFeatureEnabled('domains', state.session),
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      protectVisibleDomains,
      endVisibleDomainProtection,
    },
    dispatch
  );

/**
 * A reusable container which adds an invisible component to a page which
 * tells the domain select component that the page can only be displayed
 * when at least one of the specified domains is visible. Changing to a
 * domain which would leave none of them visible will cause the domain
 * select component to navigate to the supplied recovery route, which is
 * generally a list page from which the user can gracefully choose a
 * still-visible resource.
 */
export const DomainVisibilityProtectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DomainVisibilityProtector);
