import { Modal } from 'singlewire-components';
import { common_t } from '../../CommonLocale';
import { InformationRegistryModalProps } from './InformationRegistryModalContainer';

export const InformationRegistryModal = ({
  id,
  title,
  isShown,
  hideModal,
  componentProps,
  children,
  render,
  size,
  isLoading,
}: InformationRegistryModalProps) => (
  <Modal
    id={id}
    isLoading={isLoading}
    secondaryButtonProps={{
      label: common_t(['button', 'close']),
      id: id + '-close-button',
      onClick: () => hideModal(),
    }}
    title={title}
    open={isShown}
    onClose={hideModal}
    size={size as any}>
    {isShown && (render ? render(componentProps) : children)}
  </Modal>
);
