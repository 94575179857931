import { connect } from 'react-redux';
import { RootState } from '../../core/RootReducer';
import {
  startActiveNotificationsPolling,
  stopNotificationsPolling,
  toggleRightSidebarType,
} from '../../core/layout/LayoutActions';
import { showModal } from '../../core/modal/ModalActions';
import { checkPermission } from '../../core/permissions/PermissionsActions';
import { hasCachedPermission } from '../../core/permissions/PermissionsSelectors';
import { searchPagerDestroy, searchPagerRefresh } from '../../core/search-pager/SearchPagerActions';
import { getPager } from '../../core/search-pager/SearchPagerReducer';
import { MobileApiNotification } from '../../mobile-api-types';
import { cancelOrExpireNotification } from '../features/notifications/NotificationsActions';
import { CREATE_NOTIFICATIONS, PAGER_ID, QuickCancel } from './QuickCancel';

export type QuickCancelProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

export const mapStateToProps = (state: RootState) => ({
  notifications: state.layout.notifications,
  rightSidebarOpened: state.layout.rightSidebarOpened,
  rightSidebarType: state.layout.rightSidebarType,
  pager: getPager<MobileApiNotification>(state, PAGER_ID),
  canCreateNotifications: hasCachedPermission(state, CREATE_NOTIFICATIONS),
});

export const mapDispatchToProps = {
  toggleRightSidebarType,
  searchPagerRefresh,
  searchPagerDestroy,
  cancelOrExpireNotification,
  showModal,
  checkPermission,
  stopNotificationsPolling,
  startActiveNotificationsPolling,
};

export const QuickCancelContainer = connect(mapStateToProps, mapDispatchToProps)(QuickCancel);
