import { ComponentClass, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { Box, Divider, Icon, Popover, TriggerButton } from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../../CommonLocale';
import { RootState } from '../../../core/RootReducer';
import { SinglewireForm } from '../../../core/forms/SinglewireForm';
import { noop } from '../../../core/utils/common';
import {
  MobileApiLocationObject,
  MobileApiSite,
  MobileApiSiteBuilding,
  MobileApiSiteBuildingFloor,
  MobileApiSiteBuildingFloorZone,
} from '../../../mobile-api-types';
import { ResolveDataSafely } from '../ResolveDataSafely';
import { shared_t } from '../SharedLocale';
import { getLocationObjectFromLowestLevelPossible } from '../SiteUtils';
import { RemoteSelectContainer } from './redux-form/RemoteSelectContainer';
import { SwitchField } from './redux-form/SwitchField';

export const FORM_ID = 'location-filter-form';

const FormContainer = styled.div`
  min-width: 300px;
  padding: 1rem;
  padding-bottom: 0.5rem;
`;

interface ExternalProps {
  isSelectingAll: boolean;
  onChangeLocationFilter: (
    key: string,
    value: string | null | undefined,
    formValues: Partial<FormState>
  ) => void;
  initialLocation?: MobileApiLocationObject | null;
  options?: {
    allowNoLocation?: boolean;
    label?: string;
    depth?: 'site' | 'building' | 'floor' | 'zone';
  };
}

interface FormState {
  location?: {
    site?: MobileApiSite;
    building?: MobileApiSiteBuilding;
    floor?: MobileApiSiteBuildingFloor;
    zone?: MobileApiSiteBuildingFloorZone;
  };
  siteId: string;
  buildingId: string;
  floorId: string;
  zoneId: string;
  noLocation?: boolean;
}

type LocationFilterFormProps = Pick<InjectedFormProps<FormState>, 'change'> &
  ReturnType<typeof mapFormStateToProps> &
  ExternalProps;

export const LocationFilterForm = ({
  formValues,
  isSelectingAll,
  change,
  onChangeLocationFilter,
  options = { allowNoLocation: false, depth: 'zone' },
}: LocationFilterFormProps) => {
  const clearLocationFilters = useCallback(() => {
    change(`siteId`, null);
    change(`buildingId`, null);
    change(`floorId`, null);
    change(`zoneId`, null);
    change(`location`, null);
    change('noLocation', false);
    onChangeLocationFilter('siteId', undefined, {});
  }, [change, onChangeLocationFilter]);

  const isSelected = useMemo(() => {
    return (
      !!formValues?.location?.site ||
      !!formValues?.location?.building ||
      !!formValues?.location?.floor ||
      !!formValues?.location?.zone
    );
  }, [formValues]);

  return (
    <Box ml="sm">
      <SinglewireForm
        formId={FORM_ID}
        noValidate={true}
        onSubmit={noop}
        skipDirtyFormChecking={() => true}>
        <Popover
          id="location-filter-popover"
          trigger={() => (
            <TriggerButton
              id="location-filter-button"
              label={options?.label ? options.label : common_t(['label', 'location'])}
              selected={isSelected}
              startIcon={<Icon.Filter size="sm" title={shared_t(['table', 'filter'])} />}
              disabled={isSelectingAll}
              variant="default"
              dynamicSizing
            />
          )}
          align="start"
          actionButtonProps={{
            label: common_t(['button', 'clearSelection']),
            onClick: clearLocationFilters,
          }}>
          {options.allowNoLocation && (
            <>
              <FormContainer>
                <Box flex={false}>
                  <Field
                    id="noLocation"
                    name="noLocation"
                    label={shared_t(['location', 'noLocation'])}
                    component={SwitchField}
                    props={{
                      fullWidth: true,
                      md: 12,
                      onChange: (checked: boolean) => {
                        if (checked) {
                          onChangeLocationFilter('siteId', null, {
                            ...formValues,
                            buildingId: undefined,
                            floorId: undefined,
                            zoneId: undefined,
                          });
                        } else {
                          onChangeLocationFilter('siteId', undefined, formValues);
                        }

                        change(`siteId`, null);
                        change(`location.site`, undefined);
                        change(`buildingId`, null);
                        change(`location.building`, undefined);
                        change(`floorId`, null);
                        change(`location.floor`, undefined);
                        change(`zoneId`, null);
                        change(`location.zone`, undefined);
                      },
                    }}
                  />
                </Box>
              </FormContainer>
              <Divider my="none" />
            </>
          )}

          <FormContainer>
            <Field
              id="siteId"
              name="siteId"
              label={common_t(['resource', 'site'])}
              component={RemoteSelectContainer}
              xs={12}
              props={{
                isClearable: false,
                placeholder: common_t(['label', 'select']),
                secondaryModel: `location.site`,
                isMulti: false,
                apiRequest: ['sites', [], 'GET'],
                isDisabled: formValues?.noLocation,
              }}
              onChange={(value: any) => {
                if (value) {
                  onChangeLocationFilter('siteId', value, formValues);
                }
                change(`buildingId`, null);
                change(`location.building`, undefined);
                change(`floorId`, null);
                change(`location.floor`, undefined);
                change(`zoneId`, null);
                change(`location.zone`, undefined);
              }}
            />
            {options.depth !== 'site' && formValues?.siteId && (
              <Field
                id="buildingId"
                name="buildingId"
                label={common_t(['resource', 'siteBuilding'])}
                component={RemoteSelectContainer}
                xs={12}
                props={{
                  isClearable: false,
                  placeholder: common_t(['label', 'select']),
                  secondaryModel: `location.building`,
                  isMulti: false,
                  apiRequest: ['siteBuildings', [formValues.siteId], 'GET'],
                  isDisabled: formValues?.noLocation,
                }}
                onChange={(value: any) => {
                  if (value) {
                    onChangeLocationFilter('buildingId', value, formValues);
                  }
                  change(`floorId`, null);
                  change(`location.floor`, undefined);
                  change(`zoneId`, null);
                  change(`location.zone`, undefined);
                }}
              />
            )}
            {options.depth !== 'building' && formValues?.buildingId && (
              <Field
                id="floorId"
                name="floorId"
                label={common_t(['resource', 'siteFloor'])}
                component={RemoteSelectContainer}
                xs={12}
                props={{
                  isClearable: false,
                  placeholder: common_t(['label', 'select']),
                  secondaryModel: `location.floor`,
                  isMulti: false,
                  apiRequest: [
                    'siteBuildingFloors',
                    [formValues.siteId, formValues.buildingId],
                    'GET',
                  ],
                  isDisabled: formValues?.noLocation,
                }}
                onChange={(value: any) => {
                  if (value) {
                    onChangeLocationFilter('floorId', value, formValues);
                  }
                  change(`zoneId`, null);
                  change(`location.zone`, undefined);
                }}
              />
            )}
            {options.depth !== 'floor' && formValues?.floorId && (
              <Field
                id="zoneId"
                name="zoneId"
                label={common_t(['resource', 'siteZone'])}
                component={RemoteSelectContainer}
                xs={12}
                props={{
                  isClearable: false,
                  placeholder: common_t(['label', 'select']),
                  secondaryModel: `location.zone`,
                  isMulti: false,
                  apiRequest: [
                    'siteBuildingFloorZones',
                    [formValues.siteId, formValues.buildingId, formValues.floorId],
                    'GET',
                  ],
                  isDisabled: formValues?.noLocation,
                }}
                onChange={(value: any) => {
                  if (value) {
                    onChangeLocationFilter('zoneId', value, formValues);
                  }
                }}
              />
            )}
          </FormContainer>
        </Popover>
      </SinglewireForm>
    </Box>
  );
};

export const mapStateToProps = (state: RootState, { initialLocation }: ExternalProps) => {
  if (initialLocation === undefined) {
    return {};
  }
  if (initialLocation === null) {
    return { initialValues: { noLocation: true } };
  }
  const { site, building, floor, zone } = initialLocation;
  const initialValues = {};

  if (site) {
    initialValues['siteId'] = site.id;
  }
  if (building) {
    initialValues['buildingId'] = building.id;
  }
  if (floor) {
    initialValues['floorId'] = floor.id;
  }
  if (zone) {
    initialValues['zoneId'] = zone.id;
  }

  initialValues['location'] = initialLocation;

  return { initialValues };
};

export const mapFormStateToProps = (state: RootState) => ({
  formValues: getFormValues(FORM_ID)(state) as FormState,
});

export const LocationFilterFormContainer = compose<ComponentClass<ExternalProps>>(
  connect(mapStateToProps),
  reduxForm<FormState>({
    form: FORM_ID,
    enableReinitialize: true,
  }),
  connect(mapFormStateToProps)
)(LocationFilterForm);

export const LocationFilterResolveWrapper = (props: {
  isSelectingAll: boolean;
  onChangeLocationFilter: (
    key: string,
    value: string | null | undefined,
    formState: Partial<FormState>
  ) => void;
  initialLocation?: { siteId?: string; buildingId?: string; floorId?: string; zoneId?: string };
  options?: {
    allowNoLocation?: boolean;
    label?: string;
    depth?: 'site' | 'building' | 'floor' | 'zone';
  };
}) => {
  if (!props.initialLocation || Object.keys(props.initialLocation).length === 0) {
    return <LocationFilterFormContainer {...props} initialLocation={undefined} />;
  }

  const { siteId, buildingId, floorId, zoneId } = props.initialLocation;

  if (siteId === null) {
    return <LocationFilterFormContainer {...props} initialLocation={null} />;
  }

  const resolve = {};
  if (zoneId) {
    resolve['zone'] = [
      'siteBuildingFloorZones',
      [siteId, buildingId, floorId, zoneId],
      'GET',
      { params: { includeParentNames: true } },
    ];
  } else if (floorId) {
    resolve['floor'] = [
      'siteBuildingFloors',
      [siteId, buildingId, floorId],
      'GET',
      { params: { includeParentNames: true } },
    ];
  } else if (buildingId) {
    resolve['building'] = [
      'siteBuildings',
      [siteId, buildingId],
      'GET',
      { params: { includeParentNames: true } },
    ];
  } else if (siteId) {
    resolve['site'] = ['sites', [siteId], 'GET'];
  }
  return (
    <ResolveDataSafely
      resolve={resolve}
      render={renderObj => {
        const [siteTyped, buildingTyped, floorTyped, zoneTyped] = [
          renderObj['site'] as MobileApiSite,
          renderObj['building'] as MobileApiSiteBuilding,
          renderObj['floor'] as MobileApiSiteBuildingFloor,
          renderObj['zone'] as MobileApiSiteBuildingFloorZone,
        ];

        const location = getLocationObjectFromLowestLevelPossible(
          siteTyped,
          buildingTyped,
          floorTyped,
          zoneTyped
        );

        return <LocationFilterFormContainer {...props} initialLocation={location} />;
      }}
    />
  );
};
