import { common_t } from '../../../CommonLocale';
import Routes from '../../../core/route/Routes';
import { notFound_t } from './NotFoundLocale';
import { Alert } from '../../shared/components/Alert';
import { Page } from '../../shared/components/Page';
import { NotFoundProps } from './NotFoundContainer';
import { Paper } from '../../shared/components/Paper';

export const NotFound = ({ navigateTo }: NotFoundProps) => (
  <Page pageTitle={notFound_t(['title'])} title={notFound_t(['title'])} id="not-found-page">
    <Paper>
      <Alert
        slim
        color="error"
        primaryButton={{
          label: common_t(['button', 'home']),
          onClick: () => navigateTo(Routes.Home),
        }}>
        {notFound_t(['message'])}
      </Alert>
    </Paper>
  </Page>
);
