import { resolveErrorCleanup } from '../../core/route/RouteActions';
import { connect } from 'react-redux';
import { ResolveError } from './ResolveError';
import { ComponentType } from 'react';

export interface ResolveErrorProps {
  resolveErrorCleanup: any;
  // resolveErrorKey is the interpolated resolve stringified
  resolveErrorKey: string;
  customComponent?: ComponentType<any>;
}

export const ResolveErrorContainer = connect(null, { resolveErrorCleanup })(ResolveError);
