import { Dispatch, bindActionCreators } from 'redux';
import { navigateTo } from '../../../core/navigation/NavigationActions';
import { connect } from 'react-redux';
import { NotFound } from './NotFound';

export type NotFoundProps = ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigateTo,
    },
    dispatch
  );

export const NotFoundContainer = connect(null, mapDispatchToProps)(NotFound);
