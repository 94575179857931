import { ActionType, getType } from 'typesafe-actions';
import * as initialized from './InitializedActions';

export type InitializeAction = ActionType<typeof initialized>;

export type InitializedState = boolean;
const initialInitializedState: InitializedState = false;

export default function initializedReducer(
  state: InitializedState = initialInitializedState,
  action: InitializeAction
): InitializedState {
  switch (action.type) {
    case getType(initialized.setInitializeComplete):
      return true;

    default:
      return state;
  }
}
