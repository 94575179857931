import * as phoneCacheActions from './PhoneCacheAction';
import { ActionType, getType } from 'typesafe-actions';
import { RootState } from '../RootReducer';

export type PhoneCacheAction = ActionType<typeof phoneCacheActions>;

export interface PhoneCacheData {
  message: string;
  type: string;
  state: string;
  name?: string;
  createdAt?: string;
}

export interface PhoneCacheState {
  phoneCacheData: {
    [serverId: string]: {
      lastPhoneCacheUpdate: PhoneCacheData;
      lastPhoneCacheDistribute: PhoneCacheData | null;
    };
  };
  phoneCacheError: boolean;
}

export interface PhoneCacheModuleState extends RootState {
  phoneCache: PhoneCacheState;
}

export const initialPhoneCacheState: PhoneCacheState = {
  phoneCacheData: {},
  phoneCacheError: false,
};

export default function phoneCacheReducer(
  state: PhoneCacheState = initialPhoneCacheState,
  action: PhoneCacheAction
): PhoneCacheState {
  switch (action.type) {
    case getType(phoneCacheActions.setLastPhoneCacheUpdateAndDistribute):
      return {
        ...state,
        phoneCacheData: {
          ...state.phoneCacheData,
          [action.payload.serverId]: {
            lastPhoneCacheDistribute: action.payload.lastPhoneCacheDistribute,
            lastPhoneCacheUpdate: action.payload.lastPhoneCacheUpdate,
          },
        },
      };

    case getType(phoneCacheActions.setPhoneCacheError):
      return {
        ...state,
        phoneCacheError: action.payload.error,
      };
    default:
      return state;
  }
}
