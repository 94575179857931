import { connect } from 'react-redux';
import { Logout } from './Logout';
import { logout } from '../../../core/session/SessionActions';
import { RootState } from '../../../core/RootReducer';

export type LogoutProps = typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => ({ reason: state.login.logoutReason });
export const mapDispatchToProps = { logout };

export const LogoutContainer = connect(mapStateToProps, mapDispatchToProps)(Logout);
