import { Action } from 'redux';
// IDK why ActionsObservable is importable but not exported from this lib.
// @ts-ignore
import { ActionsObservable, combineEpics, Epic } from 'redux-observable';
import { from, Observable, ObservableInput } from 'rxjs';
import { filter, first, startWith, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import {
  LAYOUT_CONFIRM_MODAL_ID,
  LAYOUT_INFO_MODAL_ID,
  LAYOUT_REGISTRY_MODAL_ID,
} from '../../constants';
import { core_t } from '../CoreLocale';
import { RootState } from '../RootReducer';
import { CancellableRef, cancelRequests, ConfirmRef, hasRequestsForRef } from '../utils/api';
import { hasDirtyReduxForm, markReduxFormsPristine } from '../utils/form-manager';
import {
  confirmModal,
  hideModal,
  ModalState,
  ModalTypes,
  showDirtyFormOrActiveOperationModal,
  showGlobalModal,
  showModal,
} from './ModalActions';

/**
 * Provides an easy way to present an info modal while handling the effects for you.
 * @param action$ the action stream to listen for confirmModal and hideModal actions
 * @param showOpts the confirmation modal options (things like title, message, etc)
 * @param successCb optional success callback of additional actions to perform
 */
export const showInfoModal = (
  action$: ActionsObservable<Action>,
  showOpts: Partial<ModalState>,
  successCb?: (() => Observable<Action>) | null
): ObservableInput<Action> =>
  action$.pipe(
    filter(isActionOf([hideModal])),
    first(),
    switchMap((action: { type: string }) =>
      isActionOf(hideModal, action) && successCb ? successCb() : []
    ),
    startWith(
      showModal({
        id: LAYOUT_INFO_MODAL_ID,
        ...showOpts,
      })
    )
  );

/**
 * Provides an easy way to present a confirmation modal while handling the effects for you.
 * @param action$ the action stream to listen for confirmModal and hideModal actions
 * @param showOpts the confirmation modal options (things like title, message, etc)
 * @param successCb optional success callback of additional actions to perform
 */
export const showConfirmationModal = (
  action$: ActionsObservable<Action>,
  showOpts: Partial<ModalState>,
  successCb?: (() => Observable<Action>) | null
): ObservableInput<Action> =>
  action$.pipe(
    filter(isActionOf([confirmModal, hideModal])),
    first(),
    switchMap((action: { type: string }) =>
      isActionOf(confirmModal, action) && successCb ? successCb() : []
    ),
    startWith(
      showModal({
        id: LAYOUT_CONFIRM_MODAL_ID,
        title: showOpts.title,
        message: showOpts.message,
        dontCloseOnNavigate: showOpts.dontCloseOnNavigate,
        textConfirm: showOpts.textConfirm,
        textConfirmLabel: showOpts.textConfirmLabel,
        isDestructive: showOpts.isDestructive,
        confirmTitle: showOpts.confirmTitle,
        triggeringElementId: showOpts.triggeringElementId,
      })
    )
  );

/**
 * Provides an easy way to present a confirmation modal while handling the effects for you.
 * @param action$ the action stream to listen for confirmModal and hideModal actions
 * @param showOpts the confirmation modal options (things like title, message, etc)
 * @param successCb optional success callback of additional actions to perform
 */
export const showRegistryModal = (
  action$: ActionsObservable<Action>,
  showOpts: Partial<ModalState>,
  successCb?: (() => Observable<Action>) | null
): ObservableInput<Action> =>
  action$.pipe(
    filter(isActionOf([confirmModal, hideModal])),
    first(),
    switchMap((action: { type: string }) =>
      isActionOf(confirmModal, action) && successCb ? successCb() : []
    ),
    startWith(
      showModal({
        id: LAYOUT_REGISTRY_MODAL_ID,
        title: showOpts.title,
        rawMessage: showOpts.rawMessage,
        dontCloseOnNavigate: showOpts.dontCloseOnNavigate,
        triggeringElementId: showOpts.triggeringElementId,
      })
    )
  );

export const showGlobalModalEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(showGlobalModal)),
    switchMap(action => {
      const { confirmCb, state, type } = action.payload;
      if (type === ModalTypes.INFO) {
        return showInfoModal(action$, state, () => (confirmCb ? from([...confirmCb()]) : from([])));
      } else if (type === ModalTypes.CONFIRM) {
        return showConfirmationModal(action$, state, () =>
          confirmCb ? from([...confirmCb()]) : from([])
        );
      } else if (type === ModalTypes.REGISTRY) {
        return showRegistryModal(action$, state, () =>
          confirmCb ? from([...confirmCb()]) : from([])
        );
      }
      return [];
    })
  );

export const shouldShowDirtyFormOrActiveOperationModal = (formIdsToSkip: string[]) =>
  hasDirtyReduxForm(formIdsToSkip) || hasRequestsForRef(ConfirmRef);

const getWarningMessagePrefix = (
  hasDirtyForm: boolean,
  hasConfirmableReqs: boolean,
  message: string
) => {
  if (hasDirtyForm && !hasConfirmableReqs) {
    return core_t(['messages', 'dirtyForm'], { message: message });
  } else if (!hasDirtyForm && hasConfirmableReqs) {
    return core_t(['messages', 'cancellableRequests'], { message: message });
  } else {
    return core_t(['messages', 'dirtyFormAndCancellableRequests'], { message: message });
  }
};

// Shows the user a dirty form confirmation modal. If they choose to navigate away/logout, mark the forms as
// pristine and continue.
export const showDirtyFormOrActiveOperationModalEpic: Epic<Action, Action, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(showDirtyFormOrActiveOperationModal)),
    switchMap(action => {
      const { title, message, confirmCb, formIdsToSkip, triggeringElementId } = action.payload;
      const hasDirtyForm = hasDirtyReduxForm(formIdsToSkip);
      const hasConfirmableReqs = hasRequestsForRef(ConfirmRef);
      return showConfirmationModal(
        action$,
        {
          title,
          message: getWarningMessagePrefix(hasDirtyForm, hasConfirmableReqs, message),
          dontCloseOnNavigate: true,
          triggeringElementId: triggeringElementId,
        },
        () => {
          markReduxFormsPristine(formIdsToSkip);
          cancelRequests(ConfirmRef);
          cancelRequests(CancellableRef);
          return from([hideModal(), ...confirmCb()]);
        }
      );
    })
  );

export const confirmModalEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(confirmModal)),
    switchMap(action => {
      const { confirmCb, options } = action.payload;
      if (confirmCb) {
        return confirmCb(options && options.props);
      } else {
        return [];
      }
    })
  );

export default combineEpics(
  showDirtyFormOrActiveOperationModalEpic,
  confirmModalEpic,
  showGlobalModalEpic
);
