import { connect } from 'react-redux';
import { RootState } from '../../core/RootReducer';
import { FailoverEditingBackupInfoBanner } from './FailoverEditingBackupInfoBanner';
import { setIcopServerId } from '../../core/icop-servers/ICOPServersActions';
import { bindActionCreators, Dispatch } from 'redux';

interface ExternalProps {
  noFailoverChangesAllowed?: boolean;
}

export type FailoverEditingBackupInfoBannerProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: RootState) => ({
  selectedICOPServer: state.icopServers.selectedICOPServer,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setIcopServerId,
    },
    dispatch
  );

export const FailoverEditingBackupInfoBannerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FailoverEditingBackupInfoBanner);
