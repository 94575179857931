import { RootState } from '../RootReducer';
import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { filter, switchMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { loginRedirect, logoutRedirect, redirectToApiLogin } from './LoginActions';
import log from '../utils/log';
import { isSelfServiceMode } from '../utils/session';
import { formatOnLoginDestination } from '../utils/route';
import { from } from 'rxjs';
import Routes from '../route/Routes';
import { navigateTo } from '../navigation/NavigationActions';
import { growl } from '../layout/LayoutActions';
import { login_t } from '../../views/features/login/LoginLocale';

export const loginRedirectEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(loginRedirect)),
    withLatestFrom(state$.pipe(map(state => state.login.logoutMessage))),
    switchMap(([action, logoutMessage]) => {
      if (isSelfServiceMode()) {
        const { adminHost, selfServiceContext, location } = window;
        const { destination, providerId } = action.payload;

        const context = selfServiceContext;

        // The destination query param is populated when someone tries to navigate to a route when they're not logged in.
        // We want to make sure that the OAuth2 state is updated with this information, so we can properly redirect after
        // successfully logging in.
        let newLocation = `${adminHost}${context}/login?destination=${encodeURIComponent(
          formatOnLoginDestination(destination)
        )}`;

        // The provider ID will be available for self service users that are given the
        // provider-specific self service context path
        if (providerId) {
          newLocation = `${newLocation}&provider_id=${encodeURIComponent(providerId)}`;
        }

        // Message to display on the login form once logged out (e.g. "You have successfully logged out.")
        if (logoutMessage) {
          newLocation = `${newLocation}&logout_message=${encodeURIComponent(logoutMessage)}`;
        }

        log.info('Navigating to ', newLocation);
        location.replace(newLocation);
        return [];
      } else {
        return [navigateTo(Routes.Login)];
      }
    })
  );

export const redirectToApiLoginEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(redirectToApiLogin)),
    withLatestFrom(state$.pipe(map(state => state.login.logoutMessage))),
    switchMap(([action, logoutMessage]) => {
      const { adminHost, contextPath, selfServiceContext, location } = window;
      const { destination, loginHint, providerId } = action.payload;

      const context = isSelfServiceMode() ? selfServiceContext : contextPath;

      // The destination query param is populated when someone tries to navigate to a route when they're not logged in.
      // We want to make sure that the OAuth2 state is updated with this information, so we can properly redirect after
      // successfully logging in.
      let newLocation = `${adminHost}${context}/login?destination=${encodeURIComponent(
        formatOnLoginDestination(destination)
      )}`;

      // The provider ID will be available for self service users that are given the
      // provider-specific self service context path
      if (providerId) {
        newLocation = `${newLocation}&provider_id=${encodeURIComponent(providerId)}`;
      }

      if (loginHint) {
        newLocation = `${newLocation}&login_hint=${encodeURIComponent(loginHint)}`;
      }

      // Message to display on the login form once logged out (e.g. "You have successfully logged out.")
      if (logoutMessage) {
        newLocation = `${newLocation}&logout_message=${encodeURIComponent(logoutMessage)}`;
      }

      log.info('Navigating to ', newLocation);
      location.replace(newLocation);

      return [];
    })
  );

export const logoutRedirectEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(logoutRedirect)),
    withLatestFrom(state$.pipe(map(state => state.login))),
    switchMap(([action, login]) => {
      const { contextPath, selfServiceContext, location } = window;
      const { destination, providerId } = action.payload;

      const context = isSelfServiceMode() ? selfServiceContext : contextPath;

      // The destination query param is populated when someone tries to navigate to a route when they're not logged in.
      // We want to make sure that the OAuth2 state is updated with this information, so we can properly redirect after
      // successfully logging in.
      let newLocation = `${context}/logout?destination=${encodeURIComponent(
        formatOnLoginDestination(destination)
      )}`;

      // The provider ID will be available for self service users that are given the
      // provider-specific self service context path
      if (providerId) {
        newLocation = `${newLocation}&provider_id=${encodeURIComponent(providerId)}`;
      }

      const loginHint = new URLSearchParams(location.search).get('login_hint');

      // Message to display on the login form once logged out (e.g. "You have successfully logged out.")
      if (login.logoutMessage) {
        newLocation = `${newLocation}&logout_message=${encodeURIComponent(login.logoutMessage)}`;
      }

      if (login.logoutReason) {
        newLocation = `${newLocation}&logout_reason=${encodeURIComponent(login.logoutReason)}`;
      }

      if (loginHint) {
        newLocation = `${newLocation}&login_hint=${encodeURIComponent(loginHint)}`;
      }

      log.info('Logging out via', newLocation);
      return from(
        fetch(newLocation, {
          method: 'GET',
        })
      ).pipe(
        switchMap(resp => {
          if (login.logoutMessage) {
            return [growl(login.logoutMessage!), navigateTo(Routes.Login)];
          } else {
            return [navigateTo(Routes.Login)];
          }
        }),
        catchError(err => {
          console.log(err);
          return [growl(login_t(['logoutFailed']))];
        })
      );
    })
  );

export default combineEpics(loginRedirectEpic, logoutRedirectEpic, redirectToApiLoginEpic);
