import { IcopServerState } from '../icop-servers/ICOPServersReducer';
import {
  FusionServerFeature,
  MobileApiIcopServer,
  MobileApiIcopServerFailoverTypeEnum,
} from '../../mobile-api-types';
import { setIcopServerId } from '../icop-servers/ICOPServersActions';
import store from '../store';
import includes from 'lodash/includes';
import find from 'lodash/find';
import { ICOP_EXTENSION_ID } from '../../constants';
import { getApiUrl } from './api-resources';

/**
 * Checks against a given server if a feature is supported
 * @param {string} soughtFeature the feature to check
 * @param {MobileApiIcopServer} server the server to check against
 * @returns {boolean} whether the server has the feature
 */
export function isFeatureSupportedForServer(
  // Ideally, just `FusionServerFeature`, but it's called w/ string a lot in legacy.
  soughtFeature: FusionServerFeature | string,
  server: MobileApiIcopServer | null
): boolean {
  if (server) {
    return includes(server.features, soughtFeature);
  } else {
    return false;
  }
}

/**
 * Checks against servers to see if a feature is supported for any of them.
 * @param {string} soughtFeature the feature to check
 * @param {MobileApiIcopServer} servers the servers to check against
 * @returns {boolean} whether any of the servers does not support the feature
 */
export function isFeatureSupportedForAnyServer(
  soughtFeature: FusionServerFeature,
  servers: MobileApiIcopServer[] = []
): boolean {
  return servers.some(server => isFeatureSupportedForServer(soughtFeature, server));
}

/**
 * Checks against servers to see if a feature is unsupported for any of them.
 * @param {string} soughtFeature the feature to check
 * @param {MobileApiIcopServer} servers the servers to check against
 * @returns {boolean} whether any of the servers does not support the feature
 */
export function isFeatureUnsupportedForAnyServers(
  soughtFeature: FusionServerFeature,
  servers: MobileApiIcopServer[] = []
): boolean {
  return servers.some(server => !isFeatureSupportedForServer(soughtFeature, server));
}

/**
 * Returns the current icop server state from our store
 */
export function getState(): IcopServerState {
  return store.getState().icopServers;
}

/**
 * Returns the current servers
 * @returns {MobileApiIcopServer[]} the servers
 */
export function getServers(): MobileApiIcopServer[] {
  return getState().servers;
}

/**
 * Returns the selected server
 * @returns {MobileApiIcopServer | null} the selected server
 */
export function getSelectedServer(): MobileApiIcopServer | null {
  return getState().selectedICOPServer;
}

/**
 * Gets the selected server id
 * @returns {string | null} the selected server id
 */
export function getSelectedServerId(): string | null {
  return getState().selectedICOPServerId;
}

/**
 * Gets the selected server name
 * @returns {string | null} the selected server name
 */
export function getSelectedServerName(): string | null {
  const selectedServer = getState().selectedICOPServer;
  return selectedServer && selectedServer.name;
}

/**
 * Gets the server by id
 * @param {string} id the id of the server to get
 * @returns {MobileApiIcopServer} the server
 */
export function getServerById(id: string): MobileApiIcopServer | undefined {
  return find(getServers(), (x: MobileApiIcopServer) => x.id === id);
}

/**
 * Checks against a given server if a feature is supported
 * @param {string} soughtFeature the feature to check
 * @param {MobileApiIcopServer} server the server to check against
 * @returns {boolean} whether the server has the feature
 */
export function isFeatureSupported(
  soughtFeature: string,
  server: MobileApiIcopServer | null = getSelectedServer()
): boolean {
  return isFeatureSupportedForServer(soughtFeature, server);
}

/**
 * Changes the currently selected server and notifies the user of success. This will also redirect the user to
 * `/home`
 * @param {string} id the server id to set
 */
export function setSelectedServerIdWithNotice(id: string): void {
  store.dispatch(setIcopServerId(id, true));
}

/**
 * Builds a proxied url to use when making icop requests
 * @param {string} path the path to hit
 * @param {any} version the version to use
 * @param {any} endpointId the server id to use
 * @returns {string} the proxied url
 */
export function buildProxiedUrl(
  path: string,
  { version = 'V1', endpointId = getSelectedServerId() } = {}
): string {
  return `${getApiUrl()}/extensions/${ICOP_EXTENSION_ID}/endpoints/${endpointId}/proxy/proxy/${version}${path}`;
}

/**
 * Builds a permissions path for a proxied request
 * @param {string} path the path to hit
 * @param {any} version the version to use
 * @param {any} endpointId the server id to use
 * @returns {string} the permisisons path
 */
export function buildPermissionsPath(
  path: string,
  { version = 'V1', endpointId = getSelectedServerId() } = {}
): string {
  return `/extensions/${ICOP_EXTENSION_ID}/endpoints/${endpointId}/proxy/proxy/${version}${path}`;
}

/**
 * Gets the failover type for the selected server.
 * @returns {MobileApiIcopServerFailoverTypeEnum} the failover type, or 'standalone' if it cannot be determined
 */
export function getSelectedServerFailoverType(): MobileApiIcopServerFailoverTypeEnum {
  const server = getSelectedServer();
  return server
    ? server.failoverType || MobileApiIcopServerFailoverTypeEnum.Standalone
    : MobileApiIcopServerFailoverTypeEnum.Standalone;
}

export function getSelectedServerFailoverReadOnly(): boolean {
  return getSelectedServerFailoverType() === MobileApiIcopServerFailoverTypeEnum.Backup;
}

export default {
  isFeatureSupportedForServer,
  getServers,
  getSelectedServer,
  getSelectedServerId,
  getServerById,
  isFeatureSupported,
  setSelectedServerIdWithNotice,
  buildProxiedUrl,
  buildPermissionsPath,
  getSelectedServerFailoverType,
  getSelectedServerFailoverReadOnly,
};
