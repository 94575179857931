// This file contains actions pertaining to the status of a provider's software license.

import { createAction } from 'typesafe-actions';

export interface License {
  expired: boolean;
  valid: boolean;
  admin?: boolean; // Whether the user can make changes to rectify license problems
}

// Handled in: Reducer
// Set license info in store.
export const updateLicense = createAction('[License] Update License', (license: License) => ({
  license,
}))();

// Handled in: Epic
// Signal a licenseChange.
export const licenseChanged = createAction('[License] License Changed')();

// Handled in: Epic
// Signal a checkLicense.
export const checkLicense = createAction('[License] Check License')();
