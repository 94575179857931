import { registerResource } from '../../../core/utils/localization';

export const NotFoundLocale = {
  en: {
    message: 'The page you are looking for does not exist.',
    title: 'Not Found',
  },
};

export default NotFoundLocale;
export const notFound_t = registerResource<typeof NotFoundLocale.en>('notFound', NotFoundLocale);
