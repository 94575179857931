// This component will not render its children until it has fetched (resolved) the given resources.

import pick from 'lodash/pick';
import { ComponentClass, ReactNode } from 'react';
import { connect } from 'react-redux';
// @ts-ignore
import shallowEqual from 'react-redux/lib/utils/shallowEqual';
import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { ApiRequest } from '../../common-types';
import { RootState } from '../../core/RootReducer';
import {
  resolveErrorCleanup,
  resolveRouteCleanup,
  resolveRouteData,
} from '../../core/route/RouteActions';
import { interpolateResolve } from '../../core/utils/route';
import { ResolveData } from './ResolveData';
import { ResolveApiAdvancedConfig } from './ResolveDataSafely';

export interface ExternalProps {
  resolve?: { [key: string]: ApiRequest | ResolveApiAdvancedConfig };
  dontShowLoadingIndicator?: boolean;
  // You may optionally supply a render prop in which we pass the final resolved data
  render?: (data: { [key: string]: any }) => ReactNode;

  renderWhenFailed?: () => ReactNode;
  children?: React.ReactNode;
}

// See if we have the requested data in the store (state) yet.
const getResolveState = (
  state: RootState,
  routeParams: object,
  resolve?: { [key: string]: ApiRequest }
): 'SUCCESS' | 'LOADING' | 'FAILURE' => {
  if (
    state.route.errors &&
    state.route.errors[JSON.stringify(interpolateResolve(resolve, routeParams))]
  ) {
    return 'FAILURE';
  } else if (resolve) {
    return Object.keys(resolve).every(key => Object.keys(state.route.data || {}).includes(key))
      ? 'SUCCESS'
      : 'LOADING';
  } else {
    return 'SUCCESS';
  }
};

type State = ReturnType<typeof mapStateToProps>;

export type ResolveDataProps = State &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<any> &
  ExternalProps;

export const mapStateToProps = (
  state: RootState,
  ownProps: ExternalProps & RouteComponentProps
) => {
  return {
    isLoggedIn: !!state.session,
    resolveState: getResolveState(
      state,
      ownProps.match.params,
      ownProps.resolve
        ? Object.entries(ownProps.resolve)?.reduce(
            (acc, [k, v]) => ({
              ...acc,
              [k]: Array.isArray(v) ? v : v.request,
            }),
            {} as { [key: string]: ApiRequest }
          )
        : undefined
    ),
    routeData: pick(state.route.data, Object.keys(ownProps.resolve || {})),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ resolveRouteData, resolveRouteCleanup, resolveErrorCleanup }, dispatch);

// We're adding our own custom check here as our `pick` above generates a new object each time so this
// will prevent unecessary rerenders while still rerendering on real changes.
const areStatePropsEqual = (nextStateProps: State, prevStateProps: State) =>
  nextStateProps.isLoggedIn === prevStateProps.isLoggedIn &&
  nextStateProps.resolveState === prevStateProps.resolveState &&
  shallowEqual(nextStateProps.routeData, prevStateProps.routeData);

export const ResolveDataContainer = compose<ComponentClass<ExternalProps>>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps, null, { areStatePropsEqual })
)(ResolveData);
