import { combineEpics, Epic } from 'redux-observable';
import { Action, isActionOf } from 'typesafe-actions';
import { RootState } from '../../../../core/RootReducer';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { CancellableRef, req } from '../../../../core/utils/api';
import { PermissionDeniedError } from '../../../../common-types';
import store from '../../../../core/store';
import { resolveRouteUpdateData } from '../../../../core/route/RouteActions';
import log from '../../../../core/utils/log';
import { refreshSitesManagement } from './ImportActions';

export const refreshSitesManagementEpic: Epic<Action, Action, RootState> = action$ =>
  action$.pipe(
    filter(isActionOf(refreshSitesManagement)),
    switchMap(() =>
      req(['sitesManagement', [], 'GET'], store, CancellableRef).pipe(
        switchMap(resp => {
          if (resp && resp.data) {
            return [
              resolveRouteUpdateData({
                sitesMgmt: resp.data,
              }),
            ];
          }
          return [];
        }),
        catchError(error => {
          if (!(error instanceof PermissionDeniedError)) {
            log.error('Failed to get Sites Management data', error);
          }
          return [];
        })
      )
    )
  );

export default combineEpics(refreshSitesManagementEpic);
