// The contents of this file are run when a page first loads.

import { QueryClientProvider } from '@tanstack/react-query';
import { ConnectedRouter } from 'connected-react-router';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'regenerator-runtime/runtime'; // this import needs to be second!
import { hashHistory } from './app/core/RootReducer';
import store, { queryClient } from './app/core/store';
import { hidePageLoader } from './app/core/utils/common';
import { init } from './app/init';
import { AppContainer } from './app/views/AppContainer';
import './styles/app.css';

function checkCookie() {
  // Quick test if browser has cookieEnabled host property
  if (navigator.cookieEnabled) {
    return true;
  }
  // Create cookie
  document.cookie = 'cookietest=1';
  const ret = document.cookie.indexOf('cookietest=') !== -1;
  // Delete cookie
  document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
  return ret;
}

const cookiesEnabled = checkCookie();

if (cookiesEnabled) {
  init();
  store.subscribe(() => (window.store = store.getState()));
} else {
  hidePageLoader();
}

const container = document.getElementById('root')!;
const root = createRoot(container);

// Mount our React application at the 'root' div.
root.render(
  cookiesEnabled ? (
    <Provider store={store}>
      <ConnectedRouter history={hashHistory}>
        <HashRouter>
          <QueryClientProvider client={queryClient}>
            <AppContainer />
          </QueryClientProvider>
        </HashRouter>
      </ConnectedRouter>
    </Provider>
  ) : (
    <div className="lc-block toggled four-zero">
      <h2>Whoops!</h2>
      <small className="break-word">
        Your browser has cookies disabled. Make sure your cookies are enabled and try again.
      </small>
    </div>
  )
);
