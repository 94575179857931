// Ensures that a user has the given permissions before rendering its children.
// If domains are active, and a set of resource domains has been supplied, also
// ensures that at least one of those domains is visible from the acting domain.
// Optionally will show a loading indicator while permissions are being resolved,
// redirect to the access denied on failure, or reverse the semantics so that the
// children are rendered only when access is not available.

import React, { ComponentClass, ReactNode } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { ApiPathMissingIcopServerIdError, ApiRequest } from '../../common-types';
import { RootState } from '../../core/RootReducer';
import { isVisibleFrom } from '../../core/domains/DomainsUtils';
import { updateLoginDestination } from '../../core/login/LoginActions';
import { checkPermission } from '../../core/permissions/PermissionsActions';
import { PermissionLookupResult } from '../../core/permissions/PermissionsReducer';
import {
  getCachedPermissionResult,
  hasCachedPermission,
} from '../../core/permissions/PermissionsSelectors';
import { MobileApiDomain } from '../../mobile-api-types';
import { PermissionCheck, interpolatePermissions } from './PermissionCheck';
import { push } from 'connected-react-router';
import Routes from '../../core/route/Routes';
import store from '../../core/store';
import { growl } from '../../core/layout/LayoutActions';
import { common_t } from '../../CommonLocale';

interface ExternalProps {
  // The list of permissions which the user must possess for children to render
  permissions?: ApiRequest | ReadonlyArray<ApiRequest>;
  // If supplied, and domains are active, at least one of these domains must be
  // visible from the acting domain for children to render
  resourceDomains?: ReadonlyArray<MobileApiDomain>;
  // If supplied, this function will be called to render alernate content when
  // the children are being suppressed because the check failed. Its argument
  // will be true if the failure was due to permissions (rather than domain
  // visibility). If absent, nothing will be rendered when the check fails.
  renderWhenDenied?: (dueToPermissions: boolean) => ReactNode;
  opts?: {
    // show a loading indicator until the permission is resolved
    showLoadingIndicator?: boolean;
    // If we don't have permission redirect to access denied
    redirectToAccessDenied?: boolean;
  };
  children: React.ReactNode;
}

export type PermissionCheckProps = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ExternalProps &
  RouteComponentProps<any>;

export type PermissionCheckResult = 'LOADING' | 'ALLOWED' | 'NOT_ALLOWED';

// Checks whether we are still loading permissions, or if we have all the ones we need.
const getPermissionState = (
  state: RootState,
  allPermissions?: ReadonlyArray<ApiRequest>
): PermissionCheckResult => {
  if (allPermissions) {
    if (
      allPermissions.some(
        perm => getCachedPermissionResult(state, perm) === PermissionLookupResult.LOADING
      )
    ) {
      return 'LOADING';
    } else if (allPermissions.every(perm => hasCachedPermission(state, perm))) {
      return 'ALLOWED';
    } else {
      return 'NOT_ALLOWED';
    }
  } else {
    return 'ALLOWED';
  }
};

// If we are fine from a permissions standpoint, but also need to have access to a
// resource from the acting domain, check for that. (If we are still loading or lack
// raw permissions, we just pass along that result with no further work. Similarly,
// if domains are not active, or we don't have a list of resource domains to check,
// we simply return the raw permission state we were given.)
const considerDomainState = (
  permissionState: PermissionCheckResult,
  state: RootState,
  resourceDomains?: ReadonlyArray<MobileApiDomain>
): PermissionCheckResult => {
  const actingDomain = state.session && (state.session.domain as MobileApiDomain);
  if (permissionState === 'ALLOWED' && resourceDomains && actingDomain) {
    return resourceDomains.some(domain => isVisibleFrom(actingDomain, domain))
      ? 'ALLOWED'
      : 'NOT_ALLOWED';
  } else {
    return permissionState;
  }
};

const mapStateToProps = (state: RootState, ownProps: ExternalProps & RouteComponentProps<any>) => {
  let permissionState: PermissionCheckResult = 'NOT_ALLOWED';
  try {
    permissionState = getPermissionState(
      state,
      interpolatePermissions(ownProps.permissions, ownProps.match.params)
    );
  } catch (error) {
    if (error instanceof ApiPathMissingIcopServerIdError) {
      store.dispatch(push(Routes.Home));
      store.dispatch(
        growl(common_t(['message', 'accessedUnaccessiblePage']), {
          type: 'danger',
          toastId: 'missingSelectedIcopId',
        })
      );
    } else {
      throw error;
    }
  }

  const domainAndPermissionState = considerDomainState(
    permissionState,
    state,
    ownProps.resourceDomains
  );
  return {
    userId: state.session && state.session.userId,
    permissionState,
    domainAndPermissionState,
  };
};

const mapDispatchToProps = { checkPermission, updateLoginDestination };

export const PermissionCheckContainer = compose<ComponentClass<ExternalProps>>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PermissionCheck);

// TODO - test
