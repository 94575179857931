import { createAction } from 'typesafe-actions';
import { SinglewireTab } from '../../common-types';

export interface AdditionalRoutes {
  [key: string]: string[];
}

// Handled in: Reducer
// Update the tab in response to a location change caused by browser navigation
export const setActiveTab = createAction(
  '[Tabs] Set Active Tab',
  (id: string, currentUrl: string) => ({ id, currentUrl })
)();

// Handled in: Reducer
// Update the tab in response to the user selecting a new tab.
export const selectTab = createAction('[Tabs] Select Tab', (id: string, active: number) => ({
  id,
  active,
}))();

// Handled in: Reducer
// Register the tabs state in the store.
export const registerTabs = createAction(
  '[Tabs] Register Tabs',
  (
    id: string,
    baseUrl: string,
    currentUrl: string,
    tabs: SinglewireTab[],
    additionalRoutes?: AdditionalRoutes
  ) => ({ id, baseUrl, currentUrl, tabs, additionalRoutes })
)();

// Handled in: Reducer
// Remove tab state from the store.
export const cleanupTabs = createAction('[Tabs] Cleanup Tabs', (id: string) => ({ id }))();
