import { registerResource } from '../../../core/utils/localization';

export const LoginLocale = {
  en: {
    oauth: {
      error: {
        title: 'Login Failed',
      },
    },
    pageTitle: 'Login',
    logoutFailed: 'Logout Failed',
    termsOfUse: 'By logging in you agree to the InformaCast <0>Terms of Use</0>.',
    noAccount: 'No Account?',
    signUpHere: 'Sign Up Here',
    loginPrompt: 'To begin, please enter the email address you use with InformaCast.',
    promoHeader: "Discover What's New",
    promoText:
      'Find out about new features and enhancements added to InformaCast that improve the user experience and give you more capabilities to keep your people safe and informed.',
    learnMoreButton: 'Learn More',
  },
};

export const login_t = registerResource<typeof LoginLocale.en>('login', LoginLocale);
