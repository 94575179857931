import { createAction } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';

/**
 * Handled in Epic
 * Makes the request used for testing filters in order to determine any
 * logical expression errors before showing the results modal
 */
export const validateLogicalExpression = createAction(
  '[Rule Builder] Validate Logical Expression',
  (apiRequest: ApiRequest, licenseType?: string) => ({ apiRequest, licenseType })
)();

/**
 * Handled in Reducer
 */
export const setRuleFilterErrors = createAction(
  '[Rule Builder] Set Logical Expression Error',
  (errors: Array<{ field: string; fieldType: string; message: string }>) => ({ errors })
)();

/**
 * Handled in Reducer
 */
export const setEditMode = createAction(
  '[Rule Builder] Toggle Edit mode',
  (isEditing: boolean) => ({ isEditing })
)();
