import { registerResource } from '../../../core/utils/localization';

export const IntegrationsLocale = {
  en: {
    label: {
      kontaktFloorPlanLoadFailed: 'Your Kontakt floor plan failed to load.',
      integrationInformation: '{{integration}} Information',
      kontaktIo: 'Kontakt.io',
      badgePressed: 'Badge Pressed',
      gateway: 'Gateway',
      configuration: 'Configuration',
    },
    kontaktio: {
      floorPlan: 'Floor Plan',
      floorPlanSubtitle:
        'View a floor plan of the location where a user pressed the scenario-initiating button on their Kontakt.io badge.',
    },
    connectionTooltips: {
      connect: 'Connect with InformaCast',
      disconnect: 'Disconnect from InformaCast',
      reconnect: 'Reconnect a previous connection with InformaCast',
    },
    resources: {
      campus: 'Campus',
      campusLowercase: 'campus',
      campusesLowercase: 'campuses',
      site: 'Site',
      siteLowercase: 'site',
      sitesLowercase: 'sites',
    },
  },
};

export const integrations_t = registerResource<typeof IntegrationsLocale.en>(
  'integrations',
  IntegrationsLocale
);

export default IntegrationsLocale;
