import { Component, ErrorInfo } from 'react';
import { Redirect } from 'react-router';
import Routes from '../core/route/Routes';
import log from '../core/utils/log';

export interface ErrorBoundaryProps {
  currentPath: string;
  children?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    log.error('Fatal error occurred during React rendering.', error, info);
  }

  componentDidUpdate(
    prevProps: Readonly<ErrorBoundaryProps>,
    prevState: Readonly<ErrorBoundaryState>
  ): void {
    if (prevState.hasError && this.props.currentPath === Routes.Exception) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      // TODO: render error without changing route?
      return <Redirect to={Routes.Exception} />;
    }
    return this.props.children;
  }
}
