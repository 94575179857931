import * as session from './SessionActions';
import { ActionType, getType } from 'typesafe-actions';
import { MobileApiSession } from '../../mobile-api-types';

export type SessionAction = ActionType<typeof session>;

export type SessionState = MobileApiSession | null;

export const initialSessionState: SessionState = null;

export default function sessionReducer(
  state: SessionState = initialSessionState,
  action: SessionAction
): SessionState {
  switch (action.type) {
    case getType(session.updateSession):
      return action.payload.session;

    default:
      return state;
  }
}
