// Flexbox-based page navigation controls for the search pager component.
// Useful for paginated lists that hide page size controls and want
// page controls to be centered.

import { Icon, IconButton } from 'singlewire-components';
import styled from 'styled-components';
import { shared_t } from '../SharedLocale';
import { SearchPaginationControlsProps } from './SearchPaginationControls';

const PageControlDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.3125rem 0 0.625rem 0;
`;

export const FlexPageControls = ({
  onFirstPage,
  onPreviousPage,
  onNextPage,
  onLastPage,
  data,
}: SearchPaginationControlsProps) => (
  <PageControlDiv>
    <IconButton
      id="first-page-button"
      label={shared_t(['pagination', 'firstPage'])}
      onClick={onFirstPage}
      disabled={!data?.previous}
      aria-label={shared_t(['pagination', 'firstPage'])}
      icon={<Icon.FirstPage />}
    />

    <IconButton
      id="previous-page-button"
      label={shared_t(['pagination', 'previousPage'])}
      onClick={onPreviousPage}
      disabled={!data?.previous}
      aria-label={shared_t(['pagination', 'previousPage'])}
      icon={<Icon.PreviousPage />}
    />
    <IconButton
      id="next-page-button"
      label={shared_t(['pagination', 'nextPage'])}
      onClick={onNextPage}
      disabled={!data?.next}
      aria-label={shared_t(['pagination', 'nextPage'])}
      icon={<Icon.NextPage />}
    />
    <IconButton
      id="last-page-button"
      label={shared_t(['pagination', 'lastPage'])}
      onClick={onLastPage}
      disabled={!data?.next}
      aria-label={shared_t(['pagination', 'lastPage'])}
      icon={<Icon.LastPage />}
    />
  </PageControlDiv>
);
