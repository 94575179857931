import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Action } from 'redux';
import { makeReq } from './NetworkActions';
import { RootState } from '../RootReducer';
import { BackgroundRef, req } from '../utils/api';
import store from '../store';

// Simple make request epic with response handlers
export const makeReqEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(makeReq)),
    mergeMap(({ payload: { apiRequest, opts } }) =>
      req(apiRequest, store, (opts && opts.reqRef) || BackgroundRef).pipe(
        switchMap(response => (opts && opts.successActions ? opts.successActions(response) : [])),
        catchError(error => (opts && opts.failureActions ? opts.failureActions(error) : []))
      )
    )
  );

export default combineEpics(makeReqEpic);
