import { PureComponent } from 'react';
import { Combobox, Icon, IconButton } from 'singlewire-components';
import { common_t } from '../../CommonLocale';
import { ApiRequest } from '../../common-types';
import { BrandingContext } from '../../core/branding/BrandingContext';
import Routes from '../../core/route/Routes';
import { getContrastYIQ } from '../../core/utils/color-representation';
import { shared_t } from '../shared/SharedLocale';
import { Button } from '../shared/components/Button';
import { FacilitySelectProps } from './FacilitySelectContainer';
import { layout_t } from './LayoutLocale';

export const VIEW_FACILITIES: ApiRequest = ['facilities', [], 'GET'];
export const VIEW_SITES: ApiRequest = ['sites', [], 'GET'];
export function specificFacilityPermission(id: string): ApiRequest {
  return ['facilities', [id], 'GET'];
}

export class FacilitySelect extends PureComponent<FacilitySelectProps> {
  componentDidMount() {
    this.props.checkPermission(VIEW_FACILITIES);
    this.props.checkPermission(VIEW_SITES);
    this.props.checkPermission(specificFacilityPermission(this.props.actingFacility!.id));
  }

  render() {
    const {
      facilitiesEnabled,
      actingFacility,
      askUserToChangeActingFacility,
      userIsGlobal,
      hasFacilitiesPermissions,
      hasSitesPermissions,
      xSmallScreen,
      navigateTo,
      facilities,
    } = this.props;
    const actingFacilityName = (actingFacility && actingFacility.name) || '';
    const { primaryColor } = this.context as any;

    const ContrastColor = getContrastYIQ(primaryColor);

    return facilities &&
      facilitiesEnabled &&
      actingFacility &&
      !(userIsGlobal && !hasFacilitiesPermissions) &&
      facilities.length > 1 ? (
      <Combobox
        trigger={isOpen =>
          xSmallScreen ? (
            <IconButton
              id="facility-select-menu-button-xs"
              icon={<Icon.Sites color={ContrastColor === '#FFFFFF' ? 'white' : 'black'} />}
              label={actingFacilityName}
              color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
              size="lg"
            />
          ) : (
            <Button
              id="facility-select-menu-button"
              tooltip={common_t(['resource', 'siteAccess'])}
              startIcon={<Icon.Sites color={ContrastColor === '#FFFFFF' ? 'white' : 'black'} />}
              label={actingFacilityName}
              variant="text"
              size="lg"
              color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
              endIcon={
                isOpen ? (
                  <Icon.Collapse size="sm" color="inherit" />
                ) : (
                  <Icon.Expand size="sm" color="inherit" />
                )
              }
            />
          )
        }
        options={facilities.map(facility => ({
          label: facility.name,
          value: facility.id,
          onSelect: () => askUserToChangeActingFacility(facility, false),
          icon: <Icon.FacilityAccess />,
        }))}
        searchPlaceholder={shared_t(['label', 'search'])}
        customLabel={(hasSitesPermissions && layout_t(['facilities', 'label', 'manage'])) as string}
        onCustomSelection={
          (hasSitesPermissions
            ? () =>
                actingFacility.siteId
                  ? navigateTo(
                      Routes.CommandCenter.Sites.SingleSite(actingFacility.siteId).DetailsTab
                    )
                  : navigateTo(Routes.CommandCenter.Sites.ListPane)
            : undefined) as any // this is a real any to allow us to set this to undefined
        }
        showCheckbox={false}
      />
    ) : (
      <></>
    );
  }
}

FacilitySelect.contextType = BrandingContext;
