import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { LAYOUT_INFO_MODAL_ID, ROOT_DOMAIN_ID } from '../../constants';
import {
  DomainedResource,
  DomainedResourceSpec,
  MobileApiDomain,
  MobileApiPageEnvelope,
  MobileApiSession,
} from '../../mobile-api-types';
import { core_t } from '../CoreLocale';
import { growl } from '../layout/LayoutActions';
import { hideModal, showDirtyFormOrActiveOperationModal, showModal } from '../modal/ModalActions';
import {
  shouldShowDirtyFormOrActiveOperationModal,
  showConfirmationModal,
} from '../modal/ModalEpics';
import { navigateTo, reloadCurrentPage } from '../navigation/NavigationActions';
import { refreshPermissions } from '../permissions/PermissionsActions';
import { RootState } from '../RootReducer';
import { refreshSession } from '../session/SessionActions';
import store from '../store';
import { BackgroundRef, CancellableRef, req, reqAll } from '../utils/api';
import { getLocalStorage } from '../utils/common';
import { isFeatureEnabled } from '../utils/session';
import {
  addDomainResource,
  askUserToChangeActingDomain,
  confirmDemoteDomainResource,
  confirmPromoteDomainResource,
  demoteDomainResource,
  disableDomains,
  fetchAllVisibleDomains,
  getAllDomains,
  initializeDomains,
  promoteDomainResource,
  removeDomainResource,
  setActingDomainId,
  setDomainSelectValidity,
  updateActingDomain,
  updateAllDomains,
  updateVisibleDomains,
} from './DomainsActions';
import { DomainAction } from './DomainsReducer';
import { formatDomainNamePath } from './DomainsUtils';

export const LOCAL_STORAGE_DOMAINS_KEY = 'admin-webapp.selectedActingDomainId';

const localStorage = getLocalStorage()!;

/**
 * Moves the session into a different acting domain and reloads the page to reflect the change.
 */
export const updateActingDomainEpic: Epic<DomainAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(updateActingDomain)),
    switchMap(action => {
      const { id, successActions } = action.payload;
      const actions = successActions || [];
      if (id) {
        localStorage.setItem(LOCAL_STORAGE_DOMAINS_KEY, id);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_DOMAINS_KEY);
      }
      return [setActingDomainId(id), ...actions];
    })
  );

/**
 * Turns on the Domains feature by creating the root Domain.
 */
export const initializeDomainsEpic: Epic<DomainAction, Action, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(initializeDomains)),
    // Must initialize domains after the session has been initialized
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([, session]) => {
      if (session && isFeatureEnabled('domains', session)) {
        const storedActingDomainId = localStorage.getItem(LOCAL_STORAGE_DOMAINS_KEY);
        const actingDomainId = session.domain!.id;
        if (storedActingDomainId && storedActingDomainId !== actingDomainId) {
          // When we are initializing, we want the most recent acting domain instead of
          // the default (highest level) domain.
          // (as long as the most recent is not the default)
          return [
            setActingDomainId(storedActingDomainId),
            // have to skip perm check because we haven't primed the perm cache yet and it hasn't been unlocked yet
            refreshSession({ skipPermissionCheck: true }),
          ];
        } else {
          localStorage.setItem(LOCAL_STORAGE_DOMAINS_KEY, actingDomainId);
          return [setActingDomainId(actingDomainId)];
        }
      } else {
        return [];
      }
    })
  );

/**
 * Turns off the Domains feature by deleting the root Domain.
 */
export const disableDomainsEpic: Epic<DomainAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(disableDomains)),
    switchMap(() =>
      req(['domains', [ROOT_DOMAIN_ID], 'DELETE'], store, BackgroundRef).pipe(
        switchMap(() => {
          localStorage.removeItem(LOCAL_STORAGE_DOMAINS_KEY);
          return [
            growl(core_t(['message', 'successfullyDisabledDomains'])),
            updateActingDomain(null, [
              refreshSession({
                successActions: () => [
                  refreshPermissions({
                    cb: error => (error ? [] : [reloadCurrentPage()]),
                  }),
                ],
              }),
            ]),
          ];
        }),
        catchError(error =>
          from([
            showModal({
              id: LAYOUT_INFO_MODAL_ID,
              response: error,
              failureMessage: core_t(['message', 'failedToDisableDomains']),
            }),
          ])
        )
      )
    )
  );

/**
 * Given a domained resource spec, returns truthy if changes to the domains attached
 * to that kind of resource might affect the set of domains that a user can choose as
 * their acting domain, so we can know to invalidate the domain selector cache.
 *
 * @param spec describes the resource whose domains are being adjusted.
 * @param resource is the resource whose domains are being adjusted
 * @param session is the logged in user's session so we can determine their ID
 */
const mightResourceDomainChangeAffectUsableDomains = (
  spec: DomainedResourceSpec,
  resource: DomainedResource,
  session: MobileApiSession
) =>
  (spec.resourceApiKey === 'users' && resource.id === session.userId) ||
  spec.resourceApiKey === 'securityGroups';

/**
 * Add a resource to a domain, then update the page resolved route data. Marks the
 * domain selector cache invalid when it might be impacted.
 */
export const addDomainResourceEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(addDomainResource)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([action, session]) =>
      req(
        [
          action.payload.spec.joinApiKey,
          [action.payload.domain.id],
          'POST',
          { data: { id: action.payload.resource.id } },
        ],
        store,
        BackgroundRef
      ).pipe(
        switchMap(() => {
          const { spec, resource, domain, updateStoreAction } = action.payload;
          const domains = [...resource.domains!, domain];
          const updateAction = updateStoreAction({ ...resource, domains });
          const actions = mightResourceDomainChangeAffectUsableDomains(spec, resource, session!)
            ? [updateAction, setDomainSelectValidity(false)]
            : [updateAction];
          return actions;
        }),
        catchError(error =>
          from([
            showModal({
              id: LAYOUT_INFO_MODAL_ID,
              response: error,
              failureMessage: core_t(['domains', 'addDomainResourceFailedMessage']),
            }),
          ])
        )
      )
    )
  );

/**
 * Remove a resource from a domain, then update the page resolved route data. Marks the
 * domain selector cache invalid when it might be impacted.
 */
export const removeDomainResourceEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(removeDomainResource)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([action, session]) =>
      req(
        [
          action.payload.spec.joinApiKey,
          [action.payload.domain.id, action.payload.resource.id],
          'DELETE',
        ],
        store,
        BackgroundRef
      ).pipe(
        switchMap(() => {
          const { spec, resource, domain, updateStoreAction } = action.payload;
          const domains = resource.domains!.filter(candidate => candidate.id !== domain.id);
          const updateActon = updateStoreAction({ ...resource, domains });
          const actions = mightResourceDomainChangeAffectUsableDomains(spec, resource, session!)
            ? [updateActon, setDomainSelectValidity(false)]
            : [updateActon];
          return actions;
        }),
        catchError(error =>
          from([
            showModal({
              id: LAYOUT_INFO_MODAL_ID,
              response: error,
              failureMessage: core_t(['domains', 'removeDomainResourceFailedMessage']),
            }),
          ])
        )
      )
    )
  );

/**
 * Shows a confirmation modal to verify the user wants to demote
 * a resource out of a domain. If they confirm, perform the API
 * request, then update the page resolved route data, and mark the
 * domain selector cache invalid when it might be impacted.
 */
export const confirmDemoteDomainResourceEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(confirmDemoteDomainResource)),
    switchMap(action => {
      const { resource, spec, fromDomain, toDomain, updateStoreAction } = action.payload;
      return showConfirmationModal(
        action$,
        {
          title: core_t(['domains', 'demoteDomainResource', 'title']),
          message: core_t(['domains', 'demoteDomainResource', 'message'], {
            resourceName: resource.name,
            resourceDescription: spec.singularName,
            fromPath: formatDomainNamePath(fromDomain),
            toPath: toDomain.namePath,
          }),
        },
        () => from([hideModal(), demoteDomainResource(resource, spec, toDomain, updateStoreAction)])
      );
    })
  );

/**
 * Demotes a resource to a domain, then update the page resolved route data, and mark the
 * domain selector cache invalid when it might be impacted.
 */
export const demoteDomainResourceEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(demoteDomainResource)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([action, session]) =>
      req(
        [
          action.payload.spec.joinApiKey,
          [action.payload.toDomain.id],
          'POST',
          {
            data: { id: action.payload.resource.id, demote: true },
          },
        ],
        store,
        BackgroundRef
      ).pipe(
        switchMap(() => {
          const { spec, resource, toDomain, updateStoreAction } = action.payload;
          const remainingDomains = resource.domains!.filter(
            candidate => !toDomain.path.startsWith(candidate.path)
          );
          const domains = [...remainingDomains, toDomain];
          const updatedResource: DomainedResource = { ...resource, domains };
          const updateAction = updateStoreAction(updatedResource);
          const actions = mightResourceDomainChangeAffectUsableDomains(spec, resource, session!)
            ? [updateAction, setDomainSelectValidity(false)]
            : [updateAction];
          return actions;
        }),
        catchError(error =>
          from([
            showModal({
              id: LAYOUT_INFO_MODAL_ID,
              response: error,
              failureMessage: core_t(['domains', 'demoteDomainResource', 'failedMessage']),
            }),
          ])
        )
      )
    )
  );

export const buildPromotionMessage = (
  resource: DomainedResource,
  resourceDescription: string,
  fromDomains: MobileApiDomain[],
  toDomain: MobileApiDomain
): string => {
  switch (fromDomains.length) {
    case 1:
      return core_t(['domains', 'promoteDomainResource', 'message'], {
        resourceName: resource.name,
        resourceDescription,
        fromPath: fromDomains[0].namePath,
        toPath: formatDomainNamePath(toDomain),
      });

    case 2:
      return core_t(['domains', 'promoteDomainResource', 'twoDescendantsMessage'], {
        resourceName: resource.name,
        resourceDescription,
        fromPath1: fromDomains[0].namePath,
        fromPath2: fromDomains[1].namePath,
        toPath: formatDomainNamePath(toDomain),
      });

    default:
      return core_t(['domains', 'promoteDomainResource', 'manyDescendantsMessage'], {
        resourceName: resource.name,
        resourceDescription,
        fromPath1: fromDomains[0].namePath,
        fromPath2: fromDomains[1].namePath,
        toPath: formatDomainNamePath(toDomain),
      });
  }
};

/**
 * Shows a confirmation modal to verify the user wants to promote
 * a resource out of a domain. If they confirm, perform the API
 * request, then update the page resolved route data, and mark the
 * domain selector cache invalid when it might be impacted.
 */
export const confirmPromoteDomainResourceEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(confirmPromoteDomainResource)),
    switchMap(action => {
      const { resource, spec, fromDomains, toDomain, updateStoreAction } = action.payload;
      return showConfirmationModal(
        action$,
        {
          title: core_t(['domains', 'promoteDomainResource', 'title']),
          message: buildPromotionMessage(resource, spec.singularName, fromDomains, toDomain),
        },
        () =>
          from([hideModal(), promoteDomainResource(resource, spec, toDomain, updateStoreAction)])
      );
    })
  );

/**
 * Promotes a resource to a domain, then update the page resolved route data, and mark the
 * domain selector cache invalid when it might be impacted.
 */
export const promoteDomainResourceEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(promoteDomainResource)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([action, session]) =>
      req(
        [
          action.payload.spec.joinApiKey,
          [action.payload.toDomain.id],
          'POST',
          {
            data: { id: action.payload.resource.id, promote: true },
          },
        ],
        store,
        BackgroundRef
      ).pipe(
        switchMap(() => {
          const { spec, resource, toDomain, updateStoreAction } = action.payload;
          const remainingDomains = resource.domains!.filter(
            candidate => !candidate.path.startsWith(toDomain.path)
          );
          const domains = [...remainingDomains, toDomain];
          const updatedResource: DomainedResource = { ...resource, domains };
          const updateAction = updateStoreAction(updatedResource);
          const actions = mightResourceDomainChangeAffectUsableDomains(spec, resource, session!)
            ? [updateAction, setDomainSelectValidity(false)]
            : [updateAction];
          return actions;
        }),
        catchError(error =>
          from([
            showModal({
              id: LAYOUT_INFO_MODAL_ID,
              response: error,
              failureMessage: core_t(['domains', 'promoteDomainResource', 'failedMessage']),
            }),
          ])
        )
      )
    )
  );

// Asks the user to change their currently acting domain
export const askUserToChangeActingDomainEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(askUserToChangeActingDomain)),
    switchMap(action => {
      const callbackActions = [
        refreshSession({
          successActions: () => [
            refreshPermissions({
              cb: error => (error ? [] : [reloadCurrentPage()]),
            }),
          ],
        }),
      ];
      const changeDomainAndStay = [
        shouldShowDirtyFormOrActiveOperationModal([])
          ? showDirtyFormOrActiveOperationModal(
              core_t(['changeDomainWarningConfirmation', 'title']),
              core_t(['changeDomainWarningConfirmation', 'message']),
              [],
              () => [updateActingDomain(action.payload.domain.id, callbackActions)]
            )
          : updateActingDomain(action.payload.domain.id, callbackActions),
      ];
      if (action.payload.protection) {
        // change domain and navigate to recoveryRoute
        const [resource, params, method, opts] = action.payload.protection.resourceRequest;
        return req<any>(
          [resource, params, method, { ...opts, params: { domain: action.payload.domain.id } }],
          store,
          BackgroundRef
        ).pipe(
          switchMap(() => {
            return changeDomainAndStay;
          }),
          catchError((err: any) => {
            if (err.response.status === 404) {
              const recoveryActions = [
                refreshSession({
                  successActions: () => [
                    refreshPermissions({
                      cb: error =>
                        error ? [] : [navigateTo(action.payload.protection!.recoveryRoute)],
                    }),
                  ],
                }),
              ];

              return [
                shouldShowDirtyFormOrActiveOperationModal([])
                  ? showDirtyFormOrActiveOperationModal(
                      core_t(['changeDomainWarningConfirmation', 'title']),
                      core_t(['changeDomainWarningConfirmation', 'message']),
                      [],
                      () => [updateActingDomain(action.payload.domain.id, recoveryActions)]
                    )
                  : updateActingDomain(
                      action.payload.domain && action.payload.domain.id,
                      recoveryActions
                    ),
              ];
            } else {
              return changeDomainAndStay;
            }
          })
        );
      } else {
        return changeDomainAndStay;
      }
    })
  );

export const fetchAllVisibleDomainsEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(fetchAllVisibleDomains)),
    withLatestFrom(state$.pipe(map(s => s.domains.visibleDomains))),
    switchMap(([action, visibleDomains]) => {
      const { start } = action.payload;
      return req<MobileApiPageEnvelope<MobileApiDomain>>(
        ['domains', [], 'GET', { params: { start, limit: 999 } }],
        store,
        CancellableRef
      ).pipe(
        switchMap(response => {
          const aggregatedDomains = [...visibleDomains, ...response.data.data];
          return response.data.next
            ? [updateVisibleDomains(aggregatedDomains), fetchAllVisibleDomains(response.data.next)]
            : [updateVisibleDomains(aggregatedDomains)];
        })
      );
    })
  );

// Check if there are user domains and get them all.
export const getAllDomainsEpic: Epic<Action, Action, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(getAllDomains)),
    withLatestFrom(store$.pipe(map(s => s.session))),
    switchMap(([, session]) => {
      if (session && isFeatureEnabled('domains', session)) {
        return reqAll<MobileApiDomain>(
          [
            'usersDomains',
            [session.userId],
            'GET',
            { params: { transitive: true, compact: true } },
          ],
          store,
          BackgroundRef
        ).pipe(
          switchMap(domains => [updateAllDomains(domains)]),
          catchError(error =>
            from([
              showModal({
                id: LAYOUT_INFO_MODAL_ID,
                response: error,
                failureMessage: core_t(['domains', 'failedGetAllDomains']),
              }),
            ])
          )
        );
      } else {
        return [];
      }
    })
  );

export default combineEpics(
  updateActingDomainEpic,
  initializeDomainsEpic,
  disableDomainsEpic,
  addDomainResourceEpic,
  removeDomainResourceEpic,
  confirmDemoteDomainResourceEpic,
  demoteDomainResourceEpic,
  confirmPromoteDomainResourceEpic,
  promoteDomainResourceEpic,
  askUserToChangeActingDomainEpic,
  fetchAllVisibleDomainsEpic,
  getAllDomainsEpic
);
