import truncate from 'lodash/truncate';
import { interpolate } from 'nucleus-js';
import { Icon, TableCell, TableRow } from 'singlewire-components';
import { common_t } from '../../CommonLocale';
import Routes from '../../core/route/Routes';
import { formatDateTimeString } from '../../core/utils/formatters';
import { getNotificationStatus } from '../../core/utils/notification-status';
import { DomainedResourceSpec, MobileApiNotification } from '../../mobile-api-types';
import { shared_t } from './SharedLocale';
import { LinkIconButton } from './components/Button';
import { Link } from './components/Link';
import { getTypeByInitiationSourceType } from './notification-utils';

/** Used on the domain subresource page for notifications. */
export const DomainNotificationsListItem = ({
  resource,
}: {
  resource: MobileApiNotification;
  spec: DomainedResourceSpec;
}) => <NotificationsListItem isScheduled={false} notification={resource} canViewUsers={false} />;

export const NotificationsListItem = ({
  canViewUsers,
  notification,
  isScheduled,
}: {
  isScheduled: boolean;
  canViewUsers: boolean;
  notification: MobileApiNotification;
}) => {
  const createdAt = new Date(notification.createdAt);
  const subject = interpolate(notification.subject, createdAt);
  const initiatorName = notification.initiationDetails?.initiationDevice
    ? common_t(['resource', 'apiDevice'])
    : notification.initiator?.name ?? shared_t(['notifications', 'defaultInitiatorName']);

  return (
    <TableRow>
      {!isScheduled && <TableCell>{initiatorName}</TableCell>}
      <TableCell title={subject ? subject : undefined}>
        <Link to={Routes.Notifications.Details(notification.id)}>
          {subject && truncate(subject, { length: 80 })}
        </Link>
      </TableCell>
      <TableCell>{getTypeByInitiationSourceType(notification)}</TableCell>
      <TableCell>{getNotificationStatus(notification)}</TableCell>
      <TableCell>{formatDateTimeString(notification.createdAt, 'short')}</TableCell>
      <TableCell width="4rem">
        <LinkIconButton
          id={`notification-details-${notification.id}`}
          color="gray"
          label={common_t(['tooltips', 'viewDetails'])}
          icon={<Icon.Details />}
          to={Routes.Notifications.Details(notification.id)}
        />
      </TableCell>
    </TableRow>
  );
};
