import { Badge, Icon, IconButton, TableCell, TableRow } from 'singlewire-components';
import { common_t } from '../../CommonLocale';
import Routes from '../../core/route/Routes';
import { formatDateTimeString } from '../../core/utils/formatters';
import { aria_t } from './ARIALocale';
import { IncidentsListItemProps } from './IncidentsListItemContainer';
import { shared_t } from './SharedLocale';
import { ActionsCell } from './components/ActionsCell';
import { CancelIconButton } from './components/Button';
import { Link } from './components/Link';

export const IncidentsListItem = ({
  incident,
  canEndIncidents,
  navigateTo,
  showModal,
}: IncidentsListItemProps) => {
  const { id, incidentPlan, createdAt, endedAt } = incident;

  return (
    <TableRow>
      <TableCell>
        <Link to={Routes.CommandCenter.Incidents.Details(id)}>{incidentPlan.name}</Link>
      </TableCell>
      <TableCell>
        <Badge variant={endedAt ? 'success' : 'error'}>
          {shared_t(endedAt ? ['incidents', 'completed'] : ['incidents', 'ongoing'])}
        </Badge>
      </TableCell>
      <TableCell>{incident.site?.name || common_t(['label', 'notAvailableShort'])}</TableCell>
      <TableCell>{formatDateTimeString(createdAt, 'medium')}</TableCell>
      <TableCell>
        {endedAt
          ? formatDateTimeString(endedAt, 'medium')
          : common_t(['label', 'notAvailableShort'])}
      </TableCell>
      <ActionsCell>
        <IconButton
          color="gray"
          id={`viewDetails-${incident.id}`}
          label={common_t(['tooltips', 'viewDetails'])}
          aria-label={aria_t(['button', 'details'], {
            name: incidentPlan.name,

            resource: common_t(['resource', 'incident']),
          })}
          onClick={() => navigateTo(Routes.CommandCenter.Incidents.Details(id))}
          icon={<Icon.Details />}
        />
        {canEndIncidents && !incident.endedAt && (
          <CancelIconButton
            id={`endIncident-${incident.id}`}
            label={common_t(['incidents', 'end'])}
            aria-label={aria_t(['button', 'end'], {
              name: incidentPlan.name,
              resource: common_t(['resource', 'incident']),
            })}
            onClick={() => {
              showModal({ id: `end-incident-${incident.id}` });
            }}
          />
        )}
      </ActionsCell>
    </TableRow>
  );
};
