import { createAction } from 'typesafe-actions';
import { MobileApiTrackingEvent, MobileApiTrackingEventActivity } from '../../mobile-api-types';
import { TrackedUser } from '../../views/shared/tracking-events/TrackingEventUsersList';
import { trackingEvents_t } from '../../views/shared/tracking-events/TrackingEventsLocale';
import { confirmBeforeExecuting } from '../forms/FormActions';

/**
 * Handled in: Epic
 * Initialize Google Map for edit view
 * @param googleMap
 * @param polygonBounds boundary of a custom polygon
 */
export const initializeMap = createAction(
  '[Tracking Events] Initialize Map',
  (googleMap: google.maps.Map | null) => ({ googleMap })
)();

export const centerMapAroundEvent = createAction(
  '[Tracking Events] Center Map Around Events',
  (event: MobileApiTrackingEvent, completed?: boolean) => ({ event, completed })
)();

export const centerMapAroundUser = createAction(
  '[Tracking Events] Center Map Around User',
  (user: TrackedUser) => ({ user })
)();

/**
 * Handled in: Reducer
 * Update the map bounds in the store.
 * @param bounds updated boundary of the google map
 */
export const boundsChanged = createAction(
  '[Tracking Events] Bounds Changed',
  (bounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral) => ({ bounds })
)();

export interface GetTrackingEventsOptions {
  requestParams?: Record<string, unknown>;
  responseFilterPred?: (event: MobileApiTrackingEvent) => boolean;
}

export const getTrackingEvents = createAction(
  '[Tracking Events] Get All Events',
  (completed: boolean, options?: GetTrackingEventsOptions) => ({ completed, options })
)();

export const startMonitoringTrackingEvents = createAction(
  '[Tracking Events] Monitor Active Events'
)();

export const setTrackingEvents = createAction(
  '[Tracking Events] Set Events',
  (events: MobileApiTrackingEvent[]) => ({ events })
)();

export const setActiveTrackingEvents = createAction(
  '[Tracking Events] Set Active Events',
  (events: MobileApiTrackingEvent[]) => ({ events })
)();

export const getAllActivities = createAction(
  '[Tracking Events] Get all activities',
  (eventId: string, userId: string) => ({ eventId, userId })
)();

export const setActivities = createAction(
  '[Tracking Events] Set activities',
  (activities: MobileApiTrackingEventActivity[]) => ({ activities })
)();

export const refreshTrackingEvent = createAction(
  '[Tracking Events] Refresh Event',
  (eventId: string) => ({ eventId })
)();

export const completeTrackingEvent = (
  event: MobileApiTrackingEvent,
  completed: boolean,
  options?: GetTrackingEventsOptions
) =>
  confirmBeforeExecuting(
    '[Tracking Events] Complete Tracking Event',
    ['trackingEvents', [event.id], 'PUT', { data: { state: 'completed' } }],
    {
      title: trackingEvents_t(['endTrackingEvent']),
      message: trackingEvents_t(['endMessage'], { name: event.name }),
      failureMessage: trackingEvents_t(['endFailed'], { name: event.name }),
      successMessage: trackingEvents_t(['endSuccess'], { name: event.name }),
      successActions: () => [getTrackingEvents(completed, options)],
    }
  );

export const endUserTracking = (user: TrackedUser) => {
  const { id: eventId } = user.event;
  return confirmBeforeExecuting(
    '[Tracking Events] Complete Tracking Event',
    [
      'trackingEventActivities',
      [user.event.id],
      'POST',
      {
        data: {
          state: 'terminated',
          userId: user.userId,
          deviceId: user.deviceId,
          trackingEventId: eventId,
          geometryType: 'point',
          geometryValue: {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [user.lng, user.lat],
            },
            properties: {
              bearing: 0,
              speedInMetersPerSec: 0.0,
              accuracyInM: 10,
            },
          },
        },
      },
    ],
    {
      title: trackingEvents_t(['stopTrackingUser']),
      message: trackingEvents_t(['endUserTracking'], { name: user.userName }),
      failureMessage: trackingEvents_t(['endUserTrackingFailed'], { name: user.userName }),
      successMessage: trackingEvents_t(['endUserTrackingSuccess'], { name: user.userName }),
      successActions: () => [refreshTrackingEvent(eventId)],
    }
  );
};
