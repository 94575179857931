import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../core/RootReducer';
import { hideModal } from '../../core/modal/ModalActions';
import { InformationRegistryModal } from './InformationRegistryModal';

interface ExternalProps {
  id: string;
  title: string;
  render?: (props: any) => ReactNode;
  children?: ReactNode;
  primaryLabel?: string;
  size?: 'sm' | 'md' | 'lg';
  isLoading?: boolean;
}

export type InformationRegistryModalProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export const mapStateToProps = (state: RootState, ownProps: ExternalProps) => ({
  isShown: !!state.modal.modal && state.modal.modal.id === ownProps.id,
  componentProps: state.modal.modal && state.modal.modal.componentProps,
});

export const mapDispatchToProps = { hideModal };

export const InformationRegistryModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationRegistryModal);
