import { AlarmType, MobileApiNotification } from '../../mobile-api-types';
import { shared_t } from './SharedLocale';

export const getInitiatedByFooterText = (notification: MobileApiNotification) => {
  switch (notification.initiationSourceType) {
    case 'alarm':
      return shared_t(['initiationType', notification.initiationSourceDescription as AlarmType]);
    default:
      return notification.initiationSourceDescription
        ? shared_t(['initiationType', notification.initiationSourceType]) +
            ' ' +
            notification.initiationSourceDescription
        : shared_t(['initiationType', notification.initiationSourceType]);
  }
};

export const getTypeByInitiationSourceType = (notification: MobileApiNotification) => {
  switch (notification.initiationSourceType) {
    case 'scenario':
      return (
        notification.initiationDetails?.scenario &&
        shared_t([
          'initiationType',
          'scenario',
          notification.initiationDetails?.scenario.type,
          'title',
        ])
      );
    case 'api':
      return notification.initiationSourceClientId
        ? shared_t(['initiationType', notification.initiationSourceClientId, 'title'])
        : shared_t(['initiationType', 'api']);
    case null:
      return notification.initiator?.id && shared_t(['initiationType', 'IcMobile Web', 'title']);
    default:
      return shared_t(['initiationType', notification.initiationSourceType]);
  }
};
