import { ActionType, getType } from 'typesafe-actions';
import * as CallAwareActions from './CallStatusActions';
import { MobileApiLiveCallAwareCallActivity } from '../../mobile-api-types';

// Creates a type from the security group actions.
export type CallAwareAction = ActionType<typeof CallAwareActions>;

export interface CallAwareState {
  liveCallAwareCalls: MobileApiLiveCallAwareCallActivity[];
}

export const initialCallAwareState: CallAwareState = {
  liveCallAwareCalls: [],
};

export default function CallStatus(
  state: CallAwareState = initialCallAwareState,
  action: CallAwareAction
): CallAwareState {
  switch (action.type) {
    case getType(CallAwareActions.updateCallAwareLiveCalls):
      return { ...state, liveCallAwareCalls: action.payload.calls };

    default:
      return state;
  }
}
