/* eslint-disable no-template-curly-in-string */
// Original locales can be found here: https://github.com/jquense/yup/blob/master/src/locale.js

export default {
  en: {
    mixed: {
      default: 'Field is invalid',
      required: 'Field is required',
      oneOf: 'Field must be one of the following values: ${values}',
      notOneOf: 'Field must not be one of the following values: ${values}',
      // By default this shows an ugly error to the user, swallow that in favor of a simple field is required which
      // this is almost always a nullable error
      notType: 'Field is required',
    },
    string: {
      length: 'Field must be exactly ${length} characters',
      min: 'Field must be at least ${min} characters',
      max: 'Field must be at most ${max} characters',
      matches: 'Field does not match the correct format',
      email: 'Field must be a valid email',
      url: 'Field must be a valid URL',
      trim: 'Field must be a trimmed string',
      lowercase: 'Field must be a lowercase string',
      uppercase: 'Field must be a upper case string',
    },
    number: {
      min: 'Field must be greater than or equal to ${min}',
      max: 'Field must be less than or equal to ${max}',
      lessThan: 'Field must be less than ${less}',
      moreThan: 'Field must be greater than ${more}',
      notEqual: 'Field must be not equal to ${notEqual}',
      positive: 'Field must be a positive number',
      negative: 'Field must be a negative number',
      integer: 'Field must be an integer',
    },
    date: {
      min: 'Field must be later than ${min}',
      max: 'Field must be at earlier than ${max}',
    },
    object: {
      noUnknown: 'Field has unknown keys',
    },
    array: {
      min: 'Field must have at least ${min} item(s)',
      max: 'Field must have less than or equal to ${max} items',
    },
    boolean: {},
  },
};
