// This container manages the display of everything in the left sidebar.

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { closeSidebar, setLayout } from '../../core/layout/LayoutActions';
import { navigateTo } from '../../core/navigation/NavigationActions';
import { makeReq } from '../../core/network/NetworkActions';
import { RootState } from '../../core/RootReducer';
import { sendUserToIdp } from '../../core/session/SessionActions';
import { expandElement, refreshSideBar, updateSearch } from '../../core/side-bar/SideBarActions';
import { isFeatureEnabled } from '../../core/utils/session';
import { SideBarNavMenu } from './SideBarNavMenu';

// This is exported so the SideBarNavMenu can see the props
export type SideBarNavMenuProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: RootState) => ({
  signedInUserId: (state.session && state.session.userId) || '',
  providerId: (state.session && state.session.providerId) || '',
  layoutType: state.layout.layoutType,
  leftSidebarOpened: state.layout.leftSidebarOpened,
  activatedRoute: state.sideBar.activatedRoute,
  search: state.sideBar.search,
  menuElements: state.sideBar.menuElements,
  useSpotlightSearch: isFeatureEnabled('uiSpotlightSearch', state.session),
  ...state.branding,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      navigateTo,
      setLayout,
      updateSearch,
      expandElement,
      refreshSideBar,
      closeSidebar,
      makeReq,
      sendUserToIdp,
    },
    dispatch
  );

export const SideBarNavMenuContainer = connect(mapStateToProps, mapDispatchToProps)(SideBarNavMenu);
