import { ActionType, getType } from 'typesafe-actions';
import * as modals from './ModalActions';
import { ModalState } from './ModalActions';

export type ModalAction = ActionType<typeof modals>;

export interface ModalModuleState {
  modal: ModalState | null;
}

// Exported for testing
export const initialModalState: ModalModuleState = {
  modal: null,
};

export default function modalReducer(
  state: ModalModuleState = initialModalState,
  action: ModalAction
): ModalModuleState {
  switch (action.type) {
    case getType(modals.showModal):
      return { ...state, modal: { ...action.payload.state, isSaving: false } };

    case getType(modals.confirmModal):
      return {
        ...state,
        modal: state.modal ? { ...state.modal, isSaving: true } : null,
      };

    case getType(modals.setModalSave):
      return {
        ...state,
        modal: state.modal ? { ...state.modal, isSaving: action.payload.isSaving } : null,
      };

    case getType(modals.hideModal):
      return { ...state, modal: null };

    default:
      return state;
  }
}
