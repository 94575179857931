import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';
import { LAYOUT_INFO_MODAL_ID } from '../../constants';
import { MobileApiFacility } from '../../mobile-api-types';
import {
  buildPagerRefreshActions,
  findRelocationRoute,
} from '../../views/features/facilities/FacilitiesUtils';
import { core_t } from '../CoreLocale';
import { fetchOngoingIncidentsBackground } from '../incidents/IncidentsActions';
import { showDirtyFormOrActiveOperationModal, showModal } from '../modal/ModalActions';
import { shouldShowDirtyFormOrActiveOperationModal } from '../modal/ModalEpics';
import { navigateTo, reloadCurrentPage } from '../navigation/NavigationActions';
import { refreshPermissions } from '../permissions/PermissionsActions';
import { RootState } from '../RootReducer';
import { refreshSession, updateSession } from '../session/SessionActions';
import store from '../store';
import { BackgroundRef, reqAll } from '../utils/api';
import { getLocalStorage } from '../utils/common';
import { isFeatureEnabled } from '../utils/session';
import {
  askUserToChangeActingFacility,
  getAllFacilities,
  initializeFacilities,
  setActingFacilityId,
  updateActingFacility,
  updateFacilities,
} from './FacilitiesActions';
import { FacilityAction } from './FacilitiesReducer';

export const LOCAL_STORAGE_FACILITIES_KEY = (userId: string) =>
  `admin-webapp.${userId}.selectedActingFacilityId`;

const localStorage = getLocalStorage()!;

/**
 * Moves the session into a different acting facility and reloads the page to reflect the change.
 */
export const updateActingFacilitiesEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(updateActingFacility)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([action, session]) => {
      const { id, successActions } = action.payload;
      const actions = successActions || [];
      if (id) {
        localStorage.setItem(LOCAL_STORAGE_FACILITIES_KEY(session?.userId!), id);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_FACILITIES_KEY(session?.userId!));
      }
      return [setActingFacilityId(id), ...actions];
    })
  );

// Asks the user to change their currently acting facility
export const askUserToChangeActingFacilityEpic: Epic<Action, Action, RootState, any> = (
  action$,
  store$
) =>
  action$.pipe(
    filter(isActionOf(askUserToChangeActingFacility)),
    withLatestFrom(
      store$.pipe(
        map(s => ({
          pathname: s.router.location.pathname,
          oldActingFacilityId: s.facilities?.actingFacilityId || '',
          searchPagers: s.searchPagers,
        }))
      )
    ),
    switchMap(([action, { pathname, oldActingFacilityId, searchPagers }]) => {
      const callbackActions = [
        refreshSession({
          successActions: () => [
            refreshPermissions({
              cb: error => {
                const relocateTo =
                  !action.payload.fromGlobalToEdit &&
                  !action.payload.isCopyFrom &&
                  findRelocationRoute(pathname, oldActingFacilityId);
                //Map active searchPager Ids into searchPagerRefresh actions so retained pagers can be refreshed when
                //the acting facility is changed (SW-32882)
                const refreshActivePagers = buildPagerRefreshActions(searchPagers);
                const cbActions = action.payload.isCopyFrom
                  ? [fetchOngoingIncidentsBackground(), ...refreshActivePagers]
                  : [
                      reloadCurrentPage(),
                      fetchOngoingIncidentsBackground(),
                      ...refreshActivePagers,
                    ];
                if (error) {
                  return [...refreshActivePagers];
                } else if (relocateTo) {
                  return [navigateTo(relocateTo), ...cbActions];
                } else {
                  return cbActions;
                }
              },
            }),
          ],
        }),
      ];
      return [
        !action.payload.isCopyFrom && shouldShowDirtyFormOrActiveOperationModal([])
          ? showDirtyFormOrActiveOperationModal(
              core_t(['changeFacilityWarningConfirmation', 'title']),
              core_t(['changeFacilityWarningConfirmation', 'message']),
              [],
              () => [updateActingFacility(action.payload.facility.id, callbackActions)]
            )
          : updateActingFacility(action.payload.facility.id, callbackActions),
      ];
    })
  );

// Check if there are facilities and get them all.
export const getAllFacilitiesEpic: Epic<Action, Action, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(getAllFacilities)),
    withLatestFrom(
      store$.pipe(map(s => (({ session, permissions }) => ({ session, permissions }))(s)))
    ),
    switchMap(([, { session, permissions }]) => {
      if (
        session &&
        isFeatureEnabled('facilitiesEnabled', session) &&
        !isFeatureEnabled('domains', session)
      ) {
        const userCanGetFacilities = permissions.permissions.some(
          perm =>
            (perm.spec === '/facilities/*' || perm.spec === '/**') && perm.verbs.includes('GET')
        );
        const facilitiesRequest: ApiRequest =
          session.userIsGlobal && userCanGetFacilities
            ? ['facilities', [], 'GET']
            : ['usersFacilities', [session.userId], 'GET', { params: { hideGlobal: true } }];
        return reqAll<MobileApiFacility>(facilitiesRequest, store, BackgroundRef).pipe(
          switchMap(facilities => {
            return [
              updateFacilities(facilities),
              updateSession({
                ...session,
                domain: facilities.find(({ id }) => id === session?.domain?.id),
              }),
            ];
          }),
          catchError(error =>
            from([
              showModal({
                id: LAYOUT_INFO_MODAL_ID,
                response: error,
                failureMessage: core_t(['facilities', 'failedGetAllFacilities']),
              }),
            ])
          )
        );
      } else {
        return [];
      }
    })
  );

/**
 * Turns on the Facilities feature.
 */
export const initializeFacilitiesEpic: Epic<FacilityAction, Action, RootState, any> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(isActionOf(initializeFacilities)),
    withLatestFrom(state$.pipe(map(s => s.session))),
    switchMap(([, session]) => {
      if (
        session &&
        isFeatureEnabled('facilitiesEnabled', session) &&
        !isFeatureEnabled('domains', session)
      ) {
        const storedActingFacilityId = localStorage.getItem(
          LOCAL_STORAGE_FACILITIES_KEY(session?.userId!)
        );
        const actingFacilityId = session.domain!.id;
        if (storedActingFacilityId && storedActingFacilityId !== actingFacilityId) {
          return [
            setActingFacilityId(storedActingFacilityId),
            refreshSession({ skipPermissionCheck: true }),
          ];
        } else {
          localStorage.setItem(LOCAL_STORAGE_FACILITIES_KEY(session?.userId!), actingFacilityId);
          return [setActingFacilityId(actingFacilityId)];
        }
      } else {
        return [];
      }
    })
  );

export default combineEpics(
  updateActingFacilitiesEpic,
  askUserToChangeActingFacilityEpic,
  initializeFacilitiesEpic,
  getAllFacilitiesEpic
);
