import { DomainedResourceSpec, MobileApiIncident } from '../../mobile-api-types';
import { IncidentsListItemContainer } from './IncidentsListItemContainer';

/** Used on the domain subresource page for incidents. */
export const DomainIncidentsListItem = ({
  resource,
}: {
  resource: MobileApiIncident;
  spec: DomainedResourceSpec;
}) => <IncidentsListItemContainer incident={resource} />;
