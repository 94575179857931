import * as permissions from './PermissionsActions';
import { ActionType, getType } from 'typesafe-actions';
import { ApiPermission } from '../../common-types';

export type PermissionAction = ActionType<typeof permissions>;
export enum PermissionLookupResult {
  LOADING,
  ALLOWED,
  NOT_ALLOWED,
}

export interface PermissionsState {
  permissions: ApiPermission[];
  unlocked: boolean;
}

// Exported for testing
export const initialPermissionsState: PermissionsState = {
  permissions: [],
  unlocked: false,
};

export default function permissionsReducer(
  state: PermissionsState = initialPermissionsState,
  action: PermissionAction
): PermissionsState {
  switch (action.type) {
    case getType(permissions.updatePermissions):
      return { ...state, permissions: action.payload.permissions };

    case getType(permissions.clearPermissions):
      return { ...state, permissions: [] };

    case getType(permissions.addPermission):
      return { ...state, permissions: [...state.permissions, action.payload.permission] };

    case getType(permissions.unlockPermissions):
      return { ...state, unlocked: true };

    default:
      return state;
  }
}
