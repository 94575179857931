// This component will try to match each route in order, and render the first route that
// matches. The 'Safe' part comes if no routes match, it will redirect to not-found instead
// of displaying an blank page.

import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import { ReactNode } from 'react';
import { NotFoundContainer } from '../features/not-found/NotFoundContainer';

interface SafeSwitchProps {
  children?: ReactNode;
}

export const SafeSwitch = ({ children }: SafeSwitchProps) => (
  <Switch>
    {children}
    <AuthenticatedRoute component={NotFoundContainer} />
  </Switch>
);
