import { connect } from 'react-redux';
import { FORM_ID, Login } from './Login';
import { RootState } from '../../../core/RootReducer';
import { Dispatch, bindActionCreators, compose } from 'redux';
import { InjectedFormProps, getFormValues, reduxForm } from 'redux-form';
import { object, string } from 'yup';
import { ComponentClass } from 'react';
import { syncValidate } from '../../../core/forms/FormUtils';
import { redirectToApiLogin } from '../../../core/login/LoginActions';

export interface FormState {
  email: string;
}

export const loginFormSchema = () =>
  object()
    .shape({
      email: string().required().email().max(254),
    })
    .defined();

export type LoginFormProps = Pick<InjectedFormProps<FormState>, 'submitting' | 'handleSubmit'> &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (
  state: RootState
): {
  isLoggedIn: boolean;
  initialValues: {
    email: string;
  };
  destination?: string;
} => ({
  isLoggedIn: !!state.session,
  initialValues: { email: '' },
  destination: state.login.loginDestination,
});

export const mapFormStateToProps = (state: RootState) => ({
  formValues: getFormValues(FORM_ID)(state) as FormState,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      redirectToApiLogin,
    },
    dispatch
  );

export const LoginContainer = compose<ComponentClass<{}>>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm<FormState>({
    form: FORM_ID,
    validate: vals => syncValidate(loginFormSchema())(vals),
  }),
  connect(mapFormStateToProps)
)(Login);
