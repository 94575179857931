import { Feature, FusionServerFeature, MobileApiSession } from '../../mobile-api-types';
import includes from 'lodash/includes';
import store from '../store';
import { isUUID } from './common';

let longIdleDurationOK = false;

/**
 * Returns the current session or none if there isn't one
 * @returns {MobileApiSession | null} the app session or none if there isn't one
 */
export function getSession(): MobileApiSession | null {
  return store.getState().session;
}

/**
 * We are in self service mode if our locations path contains the context
 */
export function isSelfServiceMode(): boolean {
  return includes((window.location.pathname || '').toLowerCase(), window.selfServiceContext);
}

/**
 * If we are in the provider-specific context, return the provider ID.
 * Otherwise return null.
 * @returns {string | null} the provider ID or null if there isn't one
 */
export function getProviderIdFromRoute(): string | null {
  const tokenizedPath = window.location.pathname.split('/');
  const filtered = tokenizedPath.filter(token => isUUID(token));

  return filtered.length ? filtered[0] : null;
}

/**
 * Sets the long idle duration
 * @param okay {boolean} whether long idle duration is set
 */
export function setLongIdleDurationOK(okay: boolean) {
  const session = getSession();
  if (session) {
    longIdleDurationOK = okay;
  }
}

/**
 * Gets the long idle duration
 * @returns {boolean} the long idle duration
 */
export function isLongIdleDurationOK(): boolean {
  return !!(getSession() && longIdleDurationOK);
}

/**
 * Checks if a specific feature is included in the user's session
 * @param session the session to check against
 * @param feature the feature to check for
 */
export function isFeatureEnabled(
  feature: Feature,
  session: MobileApiSession | null = getSession()
): boolean {
  return !!session?.features?.includes(feature);
}

/**
 * Checks if feature is supported by the provider's canonical version or if it is
 * explicitly within the provider's canonical additional features
 * @param feature Feature being checking if it is enabled
 * @param session where we fetch canonical version and canonical additional features from
 */
export function isServerFeatureSupported(
  feature: FusionServerFeature,
  session: MobileApiSession | null = getSession()
): boolean {
  return !!session?.serverSupportedFeatures?.includes(feature);
}

export default {
  getSession,
  isSelfServiceMode,
  getProviderIdFromRoute,
  setLongIdleDurationOK,
  isLongIdleDurationOK,
  isFeatureEnabled,
};
