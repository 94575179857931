import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  askUserToChangeActingDomain,
  setDomainSelectValidity,
} from '../../core/domains/DomainsActions';
import { navigateTo } from '../../core/navigation/NavigationActions';
import { RootState } from '../../core/RootReducer';
import { MobileApiDomain } from '../../mobile-api-types';
import { DomainSelect } from './DomainSelect';

// This component allows a user to switch between acting domains.
export interface ExternalProps {
  xSmallScreen?: boolean;
}

export type DomainSelectProps = ExternalProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapStateToProps = (state: RootState) => ({
  domainsAvailable: state.session!.isDomainsAvailable,
  userId: state.session!.userId,
  actingDomain: state.session!.domain ? (state.session!.domain as MobileApiDomain) : undefined,
  validData: state.domains.domainSelectValid,
  protection: state.domains.visibilityProtection,
  allDomains: state.domains.allDomains,
});

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { askUserToChangeActingDomain, setDomainSelectValidity, navigateTo },
    dispatch
  );

export const DomainSelectContainer = connect(mapStateToProps, mapDispatchToProps)(DomainSelect);
