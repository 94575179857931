import omit from 'lodash/omit';
import React from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { COLORS, Icon } from 'singlewire-components';
import styled from 'styled-components';

const StyledAnchor = styled.a<{ $underline?: boolean; $inheritColor?: boolean }>`
  color: ${props => (props.$inheritColor ? 'inherit' : COLORS.BLUE_60)};
  &:hover,
  &:focus {
    cursor: pointer;
    text-decoration: underline;
    outline: 2px solid ${COLORS.WHITE};
    box-shadow: inset 0 0 0 2px ${COLORS.BLUE_60};
    border-radius: 0.25rem;
  }
  ${props => (props.$underline ? 'text-decoration: underline;' : '')}
`;

const WrappedRouterLink = ({ inheritColor, ...props }: LinkProps & { inheritColor?: boolean }) => (
  <RouterLink {...props} />
);

const StyledLink = styled(WrappedRouterLink)<{ $underline?: boolean }>`
  color: ${props => (props.inheritColor ? 'inherit' : COLORS.BLUE_60)};
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  ${props => (props.$underline ? 'text-decoration: underline;' : '')}
`;

export const Link = (props: LinkProps & { underline?: boolean; inheritColor?: boolean }) => {
  return <StyledLink {...omit(props, ['underline'])} $underline={props.underline} />;
};

const OpenLinkNewTab = styled(Icon.OpenLinkNewTab)`
  vertical-align: middle;
`;

/**
 * A plain Anchor-tag wrapper that provides correct theme styling, so you don't have to.
 * @param props
 * @constructor
 */
export const Anchor = (
  props: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    showExternalIcon?: boolean;
    underline?: boolean;
    inheritColor?: boolean;
  }
) => {
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return (
    <StyledAnchor
      {...omit(props, ['showExternalIcon', 'underline', 'inheritColor'])}
      $underline={props.underline}
      $inheritColor={props.inheritColor}>
      {props.children}
      {props.showExternalIcon && <OpenLinkNewTab />}
    </StyledAnchor>
  );
};
