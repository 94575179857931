import { ActionType, getType } from 'typesafe-actions';
import * as cloneActions from './CloneActions';

export type CloneAction = ActionType<typeof cloneActions>;

export type CloneState = boolean;
const initialCloneState: CloneState = false;

export default function cloneReducer(
  state: CloneState = initialCloneState,
  action: CloneAction
): CloneState {
  switch (action.type) {
    case getType(cloneActions.startCloning):
      return true;

    case getType(cloneActions.endCloning):
      return false;

    default:
      return state;
  }
}
