import { ActionType, getType } from 'typesafe-actions';
import { MobileApiFacility } from '../../mobile-api-types';
import * as facilities from './FacilitiesActions';

export type FacilityAction = ActionType<typeof facilities>;

interface FacilitiesState {
  actingFacilityId: string | null;
  facilities: MobileApiFacility[] | null;
}

const initialFacilityState: FacilitiesState = {
  actingFacilityId: null,
  facilities: null,
};

export default function facilitiesReducer(
  state: FacilitiesState = initialFacilityState,
  action: FacilityAction
): FacilitiesState {
  switch (action.type) {
    case getType(facilities.setActingFacilityId):
      return { ...state, actingFacilityId: action.payload.id };
    case getType(facilities.updateFacilities):
      return {
        ...state,
        facilities: action.payload.facilities,
      };
    default:
      return state;
  }
}
