import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiRequest } from '../../../../common-types';
import log from '../../../../core/utils/log';
import { parseQueryParams } from '../../../../core/utils/querystring';
import { useDebounce } from '../../hooks/useDebounce';

const LOCAL_STORAGE_FILTERS_KEY = (pagerId: string, userId: string) =>
  `admin-webapp.${userId}.${pagerId}.filters`;
const LOCAL_STORAGE_SEARCH_KEY = (pagerId: string, userId: string) =>
  `admin-webapp.${userId}.${pagerId}.search`;
const LOCAL_STORAGE_COLUMNS_KEY = (pagerId: string, userId: string) =>
  `admin-webapp.${userId}.${pagerId}.columns`;

export interface FilterOption {
  label: string;
  value: string;
  operator?: 'gte' | 'lte' | 'lt' | 'gt';
}

export interface UseDateFilterOptions {
  attribute: string;
  range?: boolean;
  initialRange?: 'month' | 'week';
  disableLastYear?: boolean;
  label?: string;
}

export const updateLocalStorageFilters = (
  pagerId: string,
  userId: string,
  filters: Record<string, Array<FilterOption> | FilterOption | undefined>
) => {
  const localStorageKey = LOCAL_STORAGE_FILTERS_KEY(pagerId, userId);

  const date = moment().toDate().toISOString();

  try {
    if (!isEmpty(filters)) {
      localStorage.setItem(localStorageKey, JSON.stringify({ ...filters, updatedAt: date }));
    } else {
      localStorage.removeItem(localStorageKey);
    }
  } catch (e) {
    log.error('Failed to set local storage value for pager filters', pagerId, filters);
  }
};

const loadLocalStorageFilters = (pagerId: string, userId: string) => {
  const localStorageKey = LOCAL_STORAGE_FILTERS_KEY(pagerId, userId);

  const stringifiedFilters = localStorage.getItem(localStorageKey);

  if (stringifiedFilters) {
    const { updatedAt, ...filters } = JSON.parse(stringifiedFilters);

    // Only honor filters if they are within a day
    const yesterday = moment().subtract(1, 'd');
    if (yesterday.isAfter(moment(updatedAt))) {
      return {};
    }

    return filters;
  }

  return {};
};

export const updateLocalStorageSearch = (pagerId: string, userId: string, search: string) => {
  const localStorageKey = LOCAL_STORAGE_SEARCH_KEY(pagerId, userId);

  const date = moment().toDate().toISOString();

  try {
    if (search) {
      localStorage.setItem(localStorageKey, JSON.stringify({ search, updatedAt: date }));
    } else {
      localStorage.removeItem(localStorageKey);
    }
  } catch (e) {
    log.error('Failed to set local storage value for pager search', pagerId, search);
  }
};

const isJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const loadLocalStorageSearch = (pagerId: string, userId: string) => {
  const localStorageKey = LOCAL_STORAGE_SEARCH_KEY(pagerId, userId);

  const stringifiedSearch = localStorage.getItem(localStorageKey);

  if (stringifiedSearch) {
    if (isJSON(stringifiedSearch)) {
      const { updatedAt, search } = JSON.parse(stringifiedSearch);

      // Only honor search if it is within a day
      const yesterday = moment().subtract(1, 'd');
      if (yesterday.isAfter(moment(updatedAt))) {
        return '';
      }

      return search || '';
    } else {
      const search = stringifiedSearch;

      return search || '';
    }
  }

  return '';
};

export const updateLocalStorageColumns = (
  pagerId: string,
  userId: string,
  columns: Record<string, boolean>
) => {
  const localStorageKey = LOCAL_STORAGE_COLUMNS_KEY(pagerId, userId);

  const date = moment().toDate().toISOString();

  try {
    if (!isEmpty(columns)) {
      localStorage.setItem(localStorageKey, JSON.stringify({ ...columns, updatedAt: date }));
    } else {
      localStorage.removeItem(localStorageKey);
    }
  } catch (e) {
    log.error('Failed to set local storage value for pager columns', pagerId, columns);
  }
};

const loadLocalStorageColumns = (pagerId: string, userId: string) => {
  const localStorageKey = LOCAL_STORAGE_COLUMNS_KEY(pagerId, userId);

  const stringifiedColumns = localStorage.getItem(localStorageKey);

  if (stringifiedColumns) {
    const { updatedAt, ...columns } = JSON.parse(stringifiedColumns);

    // Only honor columns if they are within a day
    const yesterday = moment().subtract(1, 'd');
    if (yesterday.isAfter(moment(updatedAt))) {
      return {};
    }

    return columns;
  }

  return {};
};

export const makeInitialLocationFilterValues = ({
  siteId,
  buildingId,
  floorId,
  zoneId,
}: {
  siteId?: string;
  buildingId?: string;
  floorId?: string;
  zoneId?: string;
}) => {
  const filterValues = {};
  if (siteId) {
    filterValues['siteId'] = { label: undefined, value: siteId };
  }
  if (buildingId) {
    filterValues['buildingId'] = { label: undefined, value: buildingId };
  }
  if (siteId) {
    filterValues['floorId'] = { label: undefined, value: floorId };
  }
  if (siteId) {
    filterValues['zoneId'] = { label: undefined, value: zoneId };
  }

  return filterValues;
};

export const createInitialValueForLocationFilter = ({
  siteId,
  buildingId,
  floorId,
  zoneId,
}: {
  siteId?: string;
  buildingId?: string;
  floorId?: string;
  zoneId?: string;
}): { siteId?: string; buildingId?: string; floorId?: string; zoneId?: string } => {
  let initialValue: { [key: string]: string } = {};

  // Assign values from query parameters if we have them, this will override local storage values
  if (siteId) {
    // Clear initialValue just in case it was set from local storage
    initialValue = {};

    initialValue['siteId'] = siteId;
    if (buildingId) {
      initialValue['buildingId'] = buildingId;
      if (floorId) {
        initialValue['floorId'] = floorId;
        if (zoneId) {
          initialValue['zoneId'] = zoneId;
        }
      }
    }
  }

  return Object.keys(initialValue).length === 0 ? undefined : (initialValue as any);
};

export const makeInitialDateFormValues = ({
  range,
  initialRange = 'week',
}: {
  attribute?: string;
  range?: boolean;
  initialRange?: 'month' | 'week';
}) => {
  if (range) {
    if (initialRange === 'week') {
      return {
        start: moment().startOf('day').subtract(7, 'days').toDate(),
        end: moment().endOf('day').toDate(),
      };
    } else {
      return {
        start: moment().startOf('day').subtract(30, 'days').toDate(),
        end: moment().endOf('day').toDate(),
      };
    }
  } else {
    return {
      start: moment().startOf('day').toDate(),
    };
  }
};

export const makeInitialDateFilterValues = ({
  attribute = 'created-at',
  range,
  start,
  end,
}: {
  attribute?: string;
  range?: boolean;
  start?: Date;
  end?: Date;
}) => {
  let filterValues = {};
  if (range) {
    filterValues = {
      [`${attribute}__gt`]: {
        label: undefined,
        value: start,
      },
      [`${attribute}__lte`]: {
        label: undefined,
        value: end,
      },
    };
  } else {
    filterValues = {
      [attribute]: {
        label: undefined,
        value: start,
      },
    };
  }

  return filterValues;
};

export const usePagerColumns = ({
  request,
  pagerId,
  userId,
  defaultColumnVisibility,
}: {
  request: ApiRequest;
  pagerId: string;
  userId: string;
  defaultColumnVisibility?: Record<string, boolean>;
}) => {
  const initialColumnsValue = useMemo(
    () => ({
      ...defaultColumnVisibility,
      ...loadLocalStorageColumns(pagerId, userId),
    }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [request, userId]
  );

  const [columnVisibility, setColumnVisibility] = useState(initialColumnsValue);
  const debouncedColumnVisibility = useDebounce(columnVisibility, 250);

  return {
    debouncedColumnVisibility,
    setColumnVisibility,
  };
};

export const usePagerSearch = ({
  request,
  userId,
  pagerId,
}: {
  request: ApiRequest;
  userId: string;
  pagerId: string;
}) => {
  const initialSearchValue = useMemo(
    () => loadLocalStorageSearch(pagerId, userId),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [request, userId]
  );
  const [search, setSearch] = useState<string | undefined>(initialSearchValue);
  const debouncedSearch = useDebounce(search, 250);

  return { debouncedSearch, setSearch, initialSearchValue, search };
};

export const usePagerFilters = ({
  request,
  pagerId,
  userId,
  useDateFilterOptions,
  defaultFilterOptions,
}: {
  request: ApiRequest;
  pagerId: string;
  userId: string;
  useDateFilterOptions?: UseDateFilterOptions;
  defaultFilterOptions?: Record<string, Array<FilterOption> | FilterOption | undefined>;
}) => {
  const urlLocation = useLocation();
  const [initialLocation, setInitialLocation] = useState<
    | {
        siteId?: string | undefined;
        buildingId?: string | undefined;
        floorId?: string | undefined;
        zoneId?: string | undefined;
      }
    | undefined
  >(createInitialValueForLocationFilter(parseQueryParams(urlLocation.search.substring(1))));

  const initialLocationFilterValues = initialLocation
    ? makeInitialLocationFilterValues(initialLocation)
    : {};

  const [dateFormValues, setDateFormValues] = useState<{ start?: Date; end?: Date }>(
    !!useDateFilterOptions?.initialRange
      ? makeInitialDateFormValues(useDateFilterOptions || {})
      : {}
  );

  const initialFiltersValue = useMemo(
    () => ({
      ...defaultFilterOptions,
      ...(!!useDateFilterOptions?.initialRange
        ? makeInitialDateFilterValues({ ...dateFormValues, ...useDateFilterOptions })
        : {}),
      ...loadLocalStorageFilters(pagerId, userId),
      // We already load location filters from local storage above, so this should be last so it isn't overriden
      ...initialLocationFilterValues,
    }),
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    [request, userId]
  );

  const [filters, setFilters] =
    useState<Record<string, Array<FilterOption> | FilterOption | undefined>>(initialFiltersValue);
  const debouncedFilters = useDebounce(filters, 250);

  return {
    debouncedFilters,
    setFilters,
    initialFiltersValue,
    dateFormValues,
    setDateFormValues,
    initialLocation,
    setInitialLocation,
  };
};

export const reduceFilters = (
  filters: Record<string, Array<FilterOption> | FilterOption | undefined>
) =>
  Object.entries(filters)
    .map(([k, obj]) => {
      if (isArray(obj)) {
        // This doesn't account for operators, however I don't think we use them with array types
        return { [k]: obj.map(o => o.value) };
      } else {
        if (obj?.operator) {
          return {
            [`${k}__${obj.operator}`]: obj?.value,
          };
        } else {
          return {
            [k]: obj?.value === null ? '' : obj?.value,
          };
        }
      }
    })
    .reduce((acc, m) => ({ ...acc, ...m }), {} as Record<string, any>);
