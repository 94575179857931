// This file contains actions pertaining to the status of a provider's system health.

import { createAction } from 'typesafe-actions';

// Handled in: Epic
// Periodically check if SystemHealth Alarms are Active.
export const startMonitoringSystemHealthAlarms = createAction(
  '[System Health] Start Monitoring System Health'
)();

// Handled in: Reducer
// Set system health in store.
export const updateSystemHealth = createAction(
  '[Sytem Health] Update System Health',
  (data: any) => ({ data })
)();

// Handled in: Epic
// Set system health in store and navigate to system health dashboard
export const updateSystemHealthAndNavigate = createAction(
  '[Sytem Health] Update System Health And Navigate To Dashboard',
  (data: any) => ({ data })
)();

// Handled in: Epic
// Signal a systemHealthChanged.
export const systemHealthChanged = createAction('[System Health] System Health Changed')();
