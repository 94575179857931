// This component pops up a big red banner below the top bar that notifies the user of problematic
// states, like a bad license or a fusion server not responding.
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { REVERSE_SYNCING_FEATURE, SYSTEM_ALARMS_FEATURE } from '../../constants';
import {
  confirmFusionServerChange,
  startMonitoringApplicationHealth,
  startMonitoringLiveCallAwareCalls,
} from '../../core/icop-servers/ICOPServersActions';
import { startMonitoringIncidents } from '../../core/incidents/IncidentsActions';
import { navigateTo } from '../../core/navigation/NavigationActions';
import { checkPermission } from '../../core/permissions/PermissionsActions';
import { hasCachedPermission } from '../../core/permissions/PermissionsSelectors';
import { RootState } from '../../core/RootReducer';
import { startMonitoringSystemHealthAlarms } from '../../core/system-health/SystemHealthActions';
import { startMonitoringTrackingEvents } from '../../core/tracking-events/TrackingEventsActions';
import {
  isFeatureSupportedForAnyServer,
  isFeatureSupportedForServer,
} from '../../core/utils/icop-server';
import { isFeatureEnabled } from '../../core/utils/session';
import {
  DEVICE_COUNTS_REPORT,
  READ_COUNTS_REPORT,
  VIEW_ENDPOINTS,
  WarningBanner,
} from './WarningBanner';

export type WarningBannerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      startMonitoringApplicationHealth,
      startMonitoringLiveCallAwareCalls,
      startMonitoringIncidents,
      startMonitoringSystemHealthAlarms,
      startMonitoringTrackingEvents,
      confirmFusionServerChange,
      checkPermission,
      navigateTo,
    },
    dispatch
  );

export const mapStateToProps = (state: RootState) => ({
  systemAlarmsSupported: isFeatureSupportedForServer(
    SYSTEM_ALARMS_FEATURE,
    state.icopServers.selectedICOPServer
  ),
  sisEnabled: isFeatureEnabled('sisLoader'),
  liveCallAwareCalls: state.callStatus && state.callStatus.liveCallAwareCalls,
  servers: state.icopServers.servers,
  activeTrackingEvents: state.trackingEvents.activeEvents,
  activeAlarms: state.systemHealth.activeAlarms,
  ongoingIncidentsState: state.incidentManagement,
  selectedICOPServer: state.icopServers.selectedICOPServer,
  licenseExpiration: state.session?.providerExpiration,
  isFusion: isFeatureEnabled('fusion', state.session),
  bulkSpeakerManagementEnabled: state.icopServers.speakerMigrationStatus === 'all',
  readGeneralCountsReport: hasCachedPermission(state, READ_COUNTS_REPORT),
  readDeviceCountsReport: hasCachedPermission(state, DEVICE_COUNTS_REPORT),
  viewIcopServers: hasCachedPermission(state, VIEW_ENDPOINTS),
  isReverseSyncingEnabled: isFeatureSupportedForAnyServer(
    REVERSE_SYNCING_FEATURE,
    state.icopServers?.servers
  ),
  shouldShowBulkSpeakerManagementWarning: state.icopServers.speakerMigrationStatus === 'some',
  shouldShowDialcastManagementWarning:
    isFeatureEnabled('cloudDialcast', state.session) &&
    state.icopServers.dialcastMigrationStatus === 'some',
});

export const WarningBannerContainer = connect(mapStateToProps, mapDispatchToProps)(WarningBanner);
