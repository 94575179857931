import { common_t } from '../../../CommonLocale';
import Routes from '../../../core/route/Routes';
import { Alert } from '../../shared/components/Alert';
import { Page } from '../../shared/components/Page';
import { Paper } from '../../shared/components/Paper';
import { AccessDeniedProps } from './AccessDeniedContainer';
import { accessDenied_t } from './AccessDeniedLocale';

export const AccessDenied = ({ navigateTo }: AccessDeniedProps) => (
  <Page
    pageTitle={accessDenied_t(['title'])}
    title={accessDenied_t(['title'])}
    id="access-denied-page">
    <Paper>
      <Alert
        color="error"
        primaryButton={{
          label: common_t(['button', 'home']),
          onClick: () => navigateTo(Routes.Home),
        }}>
        {accessDenied_t(['message'])}
      </Alert>
    </Paper>
  </Page>
);
