import { Action } from 'redux';
import { createAction } from 'typesafe-actions';
import { MobileApiFacility } from '../../mobile-api-types';
/**
 * Handled by: Reducer.
 * Puts the current acting facility ID in the store.
 */

export const setActingFacilityId = createAction(
  '[Facilities] Set Acting Facility Id',
  (id: string | null) => ({ id })
)();

/**
 * Handled by: Epic.
 * Get All Facilities.
 */
export const getAllFacilities = createAction('[Facilities] Get All Facilities')();

// Handled in: Reducer
// Add all facilities to store for facilities select
export const updateFacilities = createAction(
  '[Facilities] Update Facilities',
  (facilities: MobileApiFacility[]) => ({ facilities })
)();

/**
 * Handled in: Epic
 * Asks the user to confirm an acting facility change if there is a dirty form.
 * If facility visibility protection is in effect, and the user has asked to
 * move to a facility where the current page will not work, a recovery action will
 * be posted instead of the reload-current-page action, which will most likely
 * navigate the user to the closest safe page (such as the list page).
 */
export const askUserToChangeActingFacility = createAction(
  '[Facility] Ask to Change Acting Facility',
  (facility: MobileApiFacility, fromGlobalToEdit = false, isCopyFrom = false) => ({
    facility,
    fromGlobalToEdit,
    isCopyFrom,
  })
)();

/**
 * Handled by: Epic.
 * Moves the session into a different acting Facilities and reloads the
 * page to reflect the changes.
 */
export const updateActingFacility = createAction(
  '[Facilities] Handle New Acting Facility',
  (id: string | null, successActions?: Action[]) => ({ id, successActions })
)();

/**
 * Handled by: Epic.
 * Turns on the Facilities feature.
 */
export const initializeFacilities = createAction('[Facilities] Initialize')();
