// Functions for generating and dealing with colors, used in AngularJS

import reduce from 'lodash/reduce';

export const colorPalette = ['#2196F3', '#4CAF50', '#607D8B', '#9E9E9E', '#FFC107'];
export const menuColorPalette = ['#009688', '#2196F3', '#607D8B', '#673AB7', '#795548'];

/**
 * Given a palette type, return the selected palette array
 * @param palette which palette to use
 * @returns {string[]} selected color palette array
 */
export function getPalette(palette: 'default' | 'menu' | string): string[] {
  switch (palette) {
    case 'default':
      return colorPalette;
    case 'menu':
      return menuColorPalette;
    default:
      return colorPalette;
  }
}

/**
 * Given a string, returns the sum of char codes in the string
 * @param str
 * @returns {number} sum of the string's char codes
 */
export function sumChars(str: string): number {
  return reduce(
    str.split(''),
    (sum: number, s: string) => {
      sum += s.charCodeAt(0);
      return sum;
    },
    0
  );
}

/**
 * Given a string, generate a random color from the color palette
 * @param str the string
 * @param palette which palette to use
 * @returns {string} a random color from the color palette
 */
export function getColorForString(str: string, palette = 'default'): string {
  const paletteToUse = getPalette(palette);
  return paletteToUse[sumChars(str) % paletteToUse.length];
}

/**
 * Given an index, return back a color from our color palette
 * @param index the string
 * @param palette which palette to use
 * @returns {string} a color from the color palette
 */
export function getColorForIndex(index: number, palette = 'default'): string {
  const paletteToUse = getPalette(palette);
  return paletteToUse[index % paletteToUse.length];
}

/**
 * Adjusts a hex color by the provided luminous factor
 * @param hex a hex color value such as “#abc” or “#123456” (the hash is optional)
 * @param lum the luminosity factor, i.e. -0.1 is 10% darker, 0.2 is 20% lighter, etc.
 * @returns {string} the new hex value
 */
export function adjustColor(hex: string, lum: number): string {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  let rgb = '#';
  let c: any;
  let i: any;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
}

/**
 * Given a number, return the number's hex code
 * @param c number to convert to hex
 * @returns  {string} hex code to return
 */
export function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

/**
 * Given the numbers (r, g, b), return the hex code
 * @param r Red value in base 10
 * @param g Green value in base 10
 * @param b Blue value in base 10
 * @returns {string} Hex code including the hash
 */
export function rgbToHex(r: number, g: number, b: number): string {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

interface RGB {
  r: number;
  g: number;
  b: number;
}
/**
 * Given a hex string, convert to an RGB object
 * @param hex Hex string
 * @returns RGB value from hex string
 */
export function hexToRgb(hex: string): RGB | null {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * Generate gradient between two hex values
 * @param hex1 Beginning hex value of gradient
 * @param hex2 End hex value of gradient
 * @param steps Steps in the gradient
 * @returns {string[]} Array of hex codes that make up the gradient
 */
export function colorGradient(hex1: string, hex2: string, steps: number): string[] {
  hex1 = String(hex1).replace(/[^#0-9a-f]/gi, '');
  hex2 = String(hex2).replace(/[^#0-9a-f]/gi, '');

  if (steps < 2) {
    steps = 2;
  }

  const rgb1 = hexToRgb(hex1);
  const rgb2 = hexToRgb(hex2);

  if (!rgb1 || !rgb2) {
    return [];
  }

  return Array.from({ length: steps }).map((x, i) => {
    return rgbToHex(
      Math.floor(rgb1.r + (i / (steps - 1)) * (rgb2.r - rgb1.r)),
      Math.floor(rgb1.g + (i / (steps - 1)) * (rgb2.g - rgb1.g)),
      Math.floor(rgb1.b + (i / (steps - 1)) * (rgb2.b - rgb1.b))
    );
  });
}

/**
 * Generates the appropriate contrast color through YIQ
 * @param hexcolor The color to compute the necessary contrast against
 * @returns {string} The computed hex value
 */
export function getContrastYIQ(hexcolor: string | undefined): string {
  if (hexcolor && hexcolor.length === 4) {
    // Expand 3 digit hex representation into 6 digit representation
    const sixDigitHexColor = hexcolor
      .substr(1)
      .split('')
      .map(hexStr => `${hexStr}${hexStr}`)
      .join('');

    return getContrastYIQ(`#${sixDigitHexColor}`);
  } else if (hexcolor && hexcolor.length === 7) {
    const r = parseInt(hexcolor.substr(1, 2), 16);
    const g = parseInt(hexcolor.substr(3, 2), 16);
    const b = parseInt(hexcolor.substr(5, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? '#000000' : '#FFFFFF';
  } else {
    return '#FFFFFF';
  }
}

export default {
  colorPalette,
  menuColorPalette,
  getPalette,
  sumChars,
  getColorForString,
  getColorForIndex,
  adjustColor,
  componentToHex,
  rgbToHex,
  hexToRgb,
  colorGradient,
  getContrastYIQ,
};
