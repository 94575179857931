import { createAction } from 'typesafe-actions';
import { PhoneCacheData } from './PhoneCacheReducer';

/**
 * Handled in Epic
 * Fetches phone cache data by initiating a GET request to fusionExtensionEndpoints.  Polls every 10s
 */
export const fetchPhoneCacheData = createAction(
  '[Device Groups] Fetch Phone Cache Data',
  (pagerId: string) => ({ pagerId })
)();

/**
 * Handled in Epic
 * Stops the fetchPhoneCacheData polling
 */
export const stopFetchPhoneCacheData = createAction(
  '[Device Groups] Stop Fetch Phone Cache Data'
)();

/**
 * Handled in Epic
 * Makes request to update phone cache
 */
export const refreshRequest = createAction(
  '[Device Groups] Refresh Request',
  (serverId: string) => ({ serverId })
)();

/**
 * Handled in Epic
 * Calls refreshRequest action to make request to update phone cache.
 * Also updates local storage and store.
 */
export const refreshPhoneCache = createAction(
  '[Device Groups] Refresh Phone Cache',
  (serverId: string) => ({ serverId })
)();

/**
 * Handled in Reducer
 */
export const setPhoneCacheError = createAction(
  '[Device Groups] Set Phone Cache Error',
  (error: boolean) => ({ error })
)();

/**
 * Handled in Reducer
 */
export const setLastPhoneCacheUpdateAndDistribute = createAction(
  '[Device Groups] Set Last Phone Cache Update',
  (
    serverId: string,
    lastPhoneCacheUpdate: PhoneCacheData,
    lastPhoneCacheDistribute: PhoneCacheData | null
  ) => ({ serverId, lastPhoneCacheUpdate, lastPhoneCacheDistribute })
)();
