import { useCallback } from 'react';
import { Modal } from 'singlewire-components';
import { common_t } from '../../CommonLocale';
import { CustomRegistryModalProps } from './CustomRegistryModalContainer';

export const CustomRegistryModal = ({
  id,
  title,
  subtitle,
  isShown,
  confirmModal,
  confirmCb,
  primaryLabel,
  primaryButtonProps,
  secondaryButtonProps,
  secondaryLabel,
  hidePrimaryButton,
  hideSecondaryButton,
  hideModal,
  onClose,
  modalProps,
  componentProps,
  children,
  render,
  isLoading,
  triggeringElementId,
}: CustomRegistryModalProps) => {
  const focusTriggeringElement = useCallback(() => {
    setTimeout(() => {
      if (triggeringElementId) {
        document.getElementById(triggeringElementId)?.focus();
      }
    }, 0);
  }, [triggeringElementId]);

  return (
    <Modal
      id={id}
      isLoading={isLoading}
      primaryButtonProps={
        hidePrimaryButton
          ? undefined
          : {
              label: primaryLabel ?? common_t(['button', 'ok']),
              id: id + '-ok-button',
              onClick: () => {
                confirmModal(confirmCb);
                focusTriggeringElement();
              },
              ...primaryButtonProps,
            }
      }
      {...(hideSecondaryButton !== true
        ? {
            secondaryButtonProps: {
              label: secondaryLabel ?? common_t(['button', 'cancel']),
              id: id + '-cancel-button',
              onClick: () => {
                focusTriggeringElement();
              },
              ...secondaryButtonProps,
            },
          }
        : {})}
      title={title}
      subtitle={subtitle}
      open={isShown}
      onClose={onClose || hideModal}
      {...modalProps}>
      {isShown && (render ? render(componentProps) : children)}
    </Modal>
  );
};
