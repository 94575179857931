import { createAction } from 'typesafe-actions';
import { ApiRequest } from '../../common-types';

export type ReportType =
  | 'notificationHistoryReport'
  | 'anonymousUsersReport'
  | 'usersReport'
  | 'optOutReport'
  | 'confirmationReport'
  | 'auditEventsReport';

export const getAllReports = createAction(
  '[Reports] Get all reports',
  (reportId: string, request: ApiRequest) => ({ reportId, request })
)();

export const setReportsData = createAction(
  '[Reports] Set reports data',
  (reportId: string, data: any) => ({ reportId, data })
)();

export const deleteAnonymousUsers = createAction(
  '[Reports] Delete Anonymous Users',
  (userIds: string[], cb: () => void, pagerId: string) => ({ userIds, cb, pagerId })
)();
