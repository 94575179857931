import { Action } from 'redux';
import { combineEpics, Epic } from 'redux-observable';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootState } from '../RootReducer';
import store from '../store';
import { CancellableRef, req } from '../utils/api';
import { ttsError, ttsFetching, ttsReady } from './TtsActions';

export const generatePreviewEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(ttsFetching)),
    switchMap(action =>
      req<{ audioHash: string; audioSize: number }>(
        [
          'TTS',
          [],
          'POST',
          { data: action.payload.previewProps, params: { return: 'hash', validateTts: 'length' } },
        ],
        store,
        CancellableRef
      ).pipe(
        switchMap(response => [
          ttsReady(action.payload.previewId, {
            hash: response.data.audioHash,
            size: response.data.audioSize,
          }),
        ]),
        catchError(err => [ttsError(action.payload.previewId, err?.response.data.reason)])
      )
    )
  );

export default combineEpics(generatePreviewEpic);
