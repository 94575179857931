import { registerResource } from './core/utils/localization';

export const CommonLocale = {
  en: {
    resource: {
      activationGroup: 'Activation Group',
      activationGroupLower: 'activation group',
      activationGroups: 'Activation Groups',
      activationGroupsLower: 'activation groups',
      activities: 'Activities',
      activitiesLower: 'activities',
      anonymousUser: 'Anonymous User',
      anonymousUsers: 'Anonymous Users',
      anonymousUsersLower: 'anonymous users',
      aoi: 'Area of Interest',
      aoiLower: 'area of interest',
      aois: 'Areas of Interest',
      apiDevice: 'API Device',
      apiDeviceLower: 'API device',
      apiDevices: 'API Devices',
      apiDevicesLower: 'API devices',
      application: 'Application',
      applicationLower: 'application',
      applications: 'Applications',
      applicationsLower: 'applications',
      applicationSecret: 'Client Secret',
      applicationSecrets: 'Client Secrets',
      applicationSecretLower: 'client secret',
      applicationSecretsLower: 'client secrets',
      publicApplications: 'Application Marketplace',
      backupsLower: 'backups',
      bellSchedule: 'Bell Schedule',
      bellScheduleLower: 'bell schedule',
      bellSchedules: 'Bell Schedules',
      bellSchedulesLower: 'bell schedules',
      brandings: 'Branding Configuration',
      broadcastDialingConfig: 'Broadcast Dialing Configuration',
      boundaryTriggers: 'Boundary Triggers',
      bulkImports: 'Bulk Imports',
      bulkActions: 'Bulk Actions',
      bulkUpload: 'Bulk Upload',
      bulkUploadCommit: 'Bulk Upload Commit',
      bulkUploadLogs: 'Bulk Upload Logs',
      bulkUploadLower: 'bulk upload',
      bulkUploads: 'Bulk Uploads',
      bulkUploadsLower: 'bulk uploads',
      callAware: 'CallAware',
      callAwareCallRedirects: 'CallAware Call Redirects',
      callAwareRoutingRequests: 'CallAware Routing Requests',
      callAwareRoutingRequest: 'CallAware Routing Request',
      callAwareRule: 'CallAware Rule',
      callAwareRuleLower: 'CallAware rule',
      callAwareRules: 'CallAware Rules',
      callAwareRulesLower: 'CallAware rules',
      callHistoryAudio: 'Call History Audio',
      callRedirect: 'Call Redirect',
      callRedirectLower: 'call redirect',
      callRedirects: 'Call Redirects',
      callRedirectsLower: 'call redirects',
      ciscoWebexTeams: 'Cisco Webex',
      ciscoWebexTeamsSpace: 'Cisco Webex Space',
      ciscoWebexTeamsSpaceLower: 'Cisco Webex space',
      ciscoWebexTeamsSpaces: 'Cisco Webex Spaces',
      ciscoWebexTeamsSpacesLower: 'Cisco Webex spaces',
      clearDevicesSchedule: 'Clear Devices Schedule',
      clearDevicesScheduleLower: 'clear devices schedule',
      clearDevicesSchedules: 'Clear Devices Schedules',
      clearDevicesSchedulesLower: 'clear devices schedules',
      clearNotifications: 'Clear Notifications',
      clientCertificate: 'Client Certificate',
      clientCertificateLower: 'client certificate',
      clientCertificates: 'Client Certificates',
      cloudSchoolImports: 'Cloud and School Information Imports',
      cloudUserLoaders: 'Cloud User Loaders',
      commandCenter: 'Command Center',
      commit: 'Commit',
      communication: 'Communication',
      conferenceCall: 'Conference Call',
      conferenceCallPlural: 'Conference Calls',
      confirmationRequest: 'Confirmation Request',
      confirmationRequestLower: 'confirmation request',
      confirmationRequests: 'Confirmation Requests',
      confirmationRequestsLower: 'confirmation requests',
      contactClosure: 'Contact Closure',
      contactClosureLower: 'contact closure',
      contactClosures: 'Contact Closures',
      contactClosuresLower: 'contact closures',
      ctiCertificateLower: 'CTI certificate',
      ctiCertificates: 'CTI Certificates',
      ctiCertificatesLower: 'CTI certificates',
      ctiPorts: 'CTI Ports',
      ctiPortsLower: 'CTI ports',
      ctiRoutePoints: 'CTI Route Points',
      ctiRoutePointsLower: 'CTI route points',
      cucmCluster: 'Cisco Unified CM Cluster',
      cucmClusterGlobalConfig: 'Cluster-specific Settings',
      cucmClusters: 'Cisco Unified CM Clusters',
      cucmClustersLower: 'Cisco Unified CM clusters',
      cucmClustersNonBrandedLower: 'clusters',
      cucmFusionServer: 'Server-specific settings',
      cucmFusionServerConfig: 'Cisco Unified CM On-premises Server Config',
      cucmFusionServers: 'Server-specific settings',
      cucmFusionServersLower: 'server-specific settings',
      dataImports: 'Data Imports',
      desktopNotifier: 'Desktop Notifier',
      desktopNotifierLower: 'desktop notifier',
      desktopNotifiers: 'Desktop Notifiers',
      desktopNotifiersLower: 'desktop notifiers',
      desktops: 'Desktops',
      desktopUsers: 'Desktop Devices',
      device: 'Device',
      deviceLower: 'device',
      devices: 'Devices',
      devicesLower: 'devices',
      deviceType: '{{deviceType}} Device',
      deviceGroup: 'Device Group',
      deviceGroupLower: 'device group',
      deviceGroups: 'Device Groups',
      deviceGroupsLower: 'device groups',
      dialCast: 'DialCast',
      dialCastLegacy: 'DialCast (LEGACY)',
      dialingConfiguration: 'Dialing Configuration',
      dialingConfigurationLower: 'dialing configuration',
      dialingConfigurations: 'Dialing Configurations',
      dialingConfigurationsLower: 'dialing configurations',
      distributedActivation: 'Distributed Activation',
      distributionList: 'Distribution List',
      distributionListLower: 'distribution list',
      distributionListMappings: 'Distribution List Mappings',
      distributionListMemberLower: 'distribution list member',
      distributionLists: 'Distribution Lists',
      distributionListsLower: 'distribution lists',
      dnsSrvRecords: 'List DNS SRV Records',
      domain: 'Domain',
      domainLower: 'domain',
      domains: 'Domains',
      domainsLower: 'domains',
      dynamicReplies: 'Dynamic Replies',
      dynamicRepliesLower: 'dynamic replies',
      dynamicReply: 'Dynamic Reply',
      dynamicReplyLower: 'dynamic reply',
      encryptionKeyRotation: 'Encryption Key Rotation',
      escalationRule: 'Escalation Rule',
      escalationRuleLower: 'escalation rule',
      escalationRules: 'Escalation Rules',
      extensions: 'Extensions',
      facility: 'Site',
      failoverBackup: 'Backup',
      failoverPair: 'Failover Pair',
      failoverPairLower: 'failover pair',
      failoverPairNameAndRole: '{{pairName}} - {{role}}',
      failoverPairs: 'Failover Pairs',
      failoverPairsLower: 'failover pairs',
      failoverPrimary: 'Primary',
      failoverStandalone: 'Stand-alone',
      floorPlan: 'Floor Plan',
      followUpMessageTemplate: 'Follow-up Message Template',
      fusionExtensionEndpoint: 'Endpoint',
      fusionExtensionEndpoints: 'Endpoints',
      fusionServer: 'On-premises Server',
      fusionServerLower: 'On-premises server',
      fusionServers: 'On-premises Servers',
      fusionServerShorthand: 'Server',
      fusionServersLower: 'On-premises servers',
      groupDomains: 'Group Domains',
      groupSites: 'Group Sites Access',
      groupMembers: 'Group Members',
      groups: 'Groups',
      groupsSecurityGroups: 'Groups Security Groups',
      groupsSubscriptions: 'Groups Subscriptions',
      idp: 'Identity Provider',
      idps: 'Identity Providers',
      inboundCap: 'Inbound CAP',
      inboundCapRule: 'Inbound CAP Rule',
      inboundCapRuleLower: 'inbound CAP rule',
      inboundCapRules: 'Inbound CAP Rules',
      inboundCapRulesLower: 'Inbound CAP rules',
      inboundEmail: 'Inbound Email',
      inboundEmailRule: 'Inbound Email Rule',
      inboundEmailRuleLower: 'inbound email rule',
      inboundEmailRules: 'inbound email rules',
      inboundEmailRuleSet: 'Inbound Email Rule Set',
      inboundEmailRuleSetLower: 'inbound email rule set',
      inboundEmailRuleSets: 'Inbound Email Rule Sets',
      inboundRss: 'Inbound RSS',
      inboundRssFeed: 'Inbound RSS Feed',
      inboundRssFeedLower: 'Inbound RSS feed',
      inboundRssFeeds: 'Inbound RSS Feeds',
      inboundRssFeedsLower: 'inbound RSS feeds',
      inboundRssRules: 'Inbound RSS Rules',
      incident: 'Incident',
      incidentDetails: 'Incident Details',
      incidentLower: 'incident',
      incidentPlan: 'Incident Plan',
      incidentPlanLower: 'incident plan',
      incidentPlans: 'Incident Plans',
      incidentPlansLower: 'incident plans',
      incidentResource: 'Incident Resource',
      incidentResourceLower: 'incident resource',
      incidentResources: 'Incident Resources',
      incidentResourcesLower: 'incident resources',
      incidents: 'Incidents',
      incidentsLower: 'incidents',
      inputPort: 'Input Port',
      inputPortLower: 'input port',
      inputPortsLower: 'input ports',
      ipCamera: 'IP Camera',
      ipCameras: 'IP Cameras',
      ipCameraLower: 'IP camera',
      verkadaIntegration: 'Verkada Integration',
      ipCameraVendorConfigs: 'IP Camera Integrations',
      ipSpeaker: 'IP Speaker',
      ipSpeakerLower: 'IP speaker',
      ipSpeakerSIPConfigLower: 'IP speaker SIP configuration',
      ipSpeakers: 'IP Speakers',
      ipSpeakersAndIdns: 'IP Speakers and Desktop Notifiers',
      ipSpeakersAndIdnsLegacy: 'IP Speakers and Desktop Notifiers (LEGACY)',
      ipSpeakersLower: 'IP speakers',
      kontaktio: 'Kontakt.io',
      ldap: 'LDAP',
      licenses: 'Licenses',
      listIncidentPlans: 'List Incident Plans',
      listRegisteredPgs: 'List Registered Paging Gateways',
      loadConfiguration: 'Load Configuration',
      loadRequest: 'Load Request',
      loadRequestLower: 'load request',
      loadRequests: 'Load Requests',
      loadRequestsLower: 'load requests',
      localImports: 'Local Imports',
      logs: 'Logs',
      lpi: 'Legacy Paging Interface',
      m2m: 'M2M',
      m2mSchedule: 'Schedule',
      m2mScheduleLower: 'schedule',
      m2mSchedules: 'Schedules',
      m2mSchedulesLower: 'schedules',
      manual: 'Manual',
      messageTemplate: 'Message Template',
      messageTemplateLower: 'message template',
      messageTemplates: 'Message Templates',
      messageTemplatesLower: 'message templates',
      msteams: 'Microsoft Teams',
      msteamsChannel: 'Microsoft Teams Channel',
      msteamsChannelLower: 'Microsoft Teams channel',
      msteamsChannels: 'Microsoft Teams Channels',
      msteamsChannelsLower: 'Microsoft Teams channels',
      msteamsMeeting: 'Microsoft Teams Meeting',
      msteamsMeetingLower: 'Microsoft Teams meeting',
      msteamsMeetings: 'Microsoft Teams Meetings',
      msteamsMeetingsLower: 'Microsoft Teams meetings',
      multicastStream: 'Multicast Stream',
      multicastStreamRemoteSelectValue: 'Multicast Stream: {{name}}',
      multicastStreaming: 'Multicast Streaming',
      nightBell: 'Night Bell',
      notification: 'Notification',
      sentNotification: 'Sent Notifications',
      sentNotificationLower: 'sent notifications',
      notificationDetails: 'Notification Details',
      notificationHistory: 'Notification History',
      notificationIndex: 'Notification {{index}}',
      notificationLower: 'notification',
      notificationProfile: 'Notification Profile',
      notificationProfileLower: 'notification profile',
      notificationProfiles: 'Notification Profiles',
      notifications: 'Notifications',
      notificationsLower: 'notifications',
      oauthAppsEnabled: {
        serviceAccount: 'Service Account',
        serviceAccounts: 'Service Accounts',
        serviceAccountsLower: 'service accounts',
        serviceAccountLower: 'service account',
        serviceAccountOrUserLower: 'service account or user',
      },
      oauthAppsDisabled: {
        serviceAccount: 'Application',
        serviceAccounts: 'Applications',
        serviceAccountsLower: 'applications',
        serviceAccountLower: 'application',
        serviceAccountOrUserLower: 'application or user',
      },
      onPremisesServer: 'On-premises Server',
      onPremisesServers: 'On-premises Servers',
      onPremisesServerlower: 'on-premises server',
      outboundCap: 'Outbound CAP Endpoint',
      outboundCapLower: 'outbound CAP endpoint',
      outboundCapPlural: 'Outbound CAP Endpoints',
      outboundCapPluralLower: 'outbound CAP endpoints',
      outboundRss: 'Outbound RSS Feed',
      outboundRssLower: 'outbound RSS feed',
      outboundRssPlural: 'Outbound RSS Feeds',
      outboundRssPluralLower: 'outbound RSS feeds',
      outboundSystem: 'Outbound System',
      outboundSystemLower: 'outbound system',
      outboundSystems: 'Outbound Systems',
      outputPort: 'Output Port',
      outputPortLower: 'output port',
      outputPortsLower: 'output ports',
      pagingGateway: 'Paging Gateway',
      pagingGatewayLower: 'paging gateway',
      pagingGateways: 'Paging Gateways',
      pagingGateways2xRegistered: 'Registered 2.x Paging Gateways',
      pagingGatewaysGlobalSettings: 'Paging Gateway Global Settings',
      pagingGatewaysLower: 'paging gateways',
      panicButton: 'Panic Button',
      phoneException: 'Phone Exception',
      phoneExceptionLower: 'phone exception',
      phoneExceptions: 'Phone Exceptions',
      phoneExceptionsLower: 'phone exceptions',
      phoneGatherer: 'Phone Gatherer',
      quickUrl: 'Quick URL',
      quickAccess: 'Quick Access',
      recipient: 'Recipient',
      recipients: 'Recipients',
      recipientsLower: 'recipients',
      reports: 'Reports',
      resourceList: 'Resource List',
      ringList: 'Ring List',
      ringListLower: 'ring list',
      ringLists: 'Ring Lists',
      ringListsLower: 'ring lists',
      role: 'Role',
      roleLower: 'role',
      rollCallMembersLower: 'roll call members',
      rollCallUser: 'Roll Call User',
      rollCallUsers: 'Roll Call Users',
      routingRequest: 'Routing Request',
      routingRequestLower: 'routing request',
      routingRequests: 'Routing Requests',
      routingRequestsLower: 'routing requests',
      ruleAction: 'API Connector',
      ruleActionLower: 'API connector',
      ruleActionResultsLower: 'API connector results',
      ruleActions: 'API Connectors',
      ruleActionResults: 'API Connector Results',
      scenario: 'Scenario',
      scenarioLower: 'scenario',
      scenarios: 'Scenarios',
      scenariosLower: 'scenarios',
      scenarioTrigger: 'Trigger',
      scenarioTriggerLower: 'trigger',
      scenarioTriggers: 'Triggers',
      scenarioTriggersLower: 'triggers',
      scheduledNotification: 'Scheduled Notification',
      scheduledNotificationLower: 'scheduled notification',
      scheduledNotifications: 'Scheduled Notifications',
      scheduledNotificationsLower: 'scheduled notifications',
      schoolInformationImport: 'School Information Import',
      schoolInformationImportLower: 'school information import',
      schoolInformationImports: 'School Information Imports',
      schoolInformationImportsLower: 'school information imports',
      securityGroup: 'Security Group',
      securityGroupLower: 'security group',
      securityGroupMappings: 'Security Group Mappings',
      securityGroupMember: 'Security Group Member',
      securityGroupMemberLower: 'security group member',
      securityGroupMembers: 'Security Group Members',
      securityGroupMembersLower: 'security group members',
      securityGroups: 'Security Groups',
      securityGroupsLower: 'security groups',
      serverCertificates: 'Server Certificate',
      settings: 'Settings',
      sip: 'SIP',
      sipAccess: 'Access',
      sipAccount: 'Account',
      sipAccountLower: 'account',
      sipAccounts: 'Accounts',
      sipAccountsLower: 'accounts',
      sipCertificate: 'SIP Certificate',
      sipCertificateLower: 'SIP certificate',
      sipCertificates: 'SIP Certificates',
      sipCredentials: 'User Credentials',
      sipCredentialsFull: 'User Credentials',
      sipCredentialsLower: 'user credentials',
      sipHostException: 'Host Exception',
      sipHostExceptionLower: 'host exception',
      sipHostExceptions: 'Host Exceptions',
      sipRegistration: 'SIP Registration',
      sipRegistrationLower: 'SIP registration',
      sipRegistrations: 'SIP Registrations',
      sipRegistrationsLower: 'SIP registrations',
      sipServerGroup: 'SIP Server Group',
      sipServerGroupLower: 'SIP server group',
      sipServerGroups: 'SIP Server Groups',
      sipServerGroupsLower: 'SIP server groups',
      sipSpeakerParameters: 'Speaker Parameters',
      site: 'Site',
      siteAccess: 'Site Access',
      siteAccessMappings: 'Site Access Mappings',
      siteBuilding: 'Building',
      siteBuildingLower: 'building',
      siteBuildings: 'Buildings',
      siteBuildingsLower: 'buildings',
      siteFloor: 'Floor',
      siteFloorLower: 'floor',
      siteFloors: 'Floors',
      siteFloorsLower: 'floors',
      siteFloorBoundaryUpper: 'Floor Boundary',
      siteFloorBoundaryLower: 'floor boundary',
      siteFloorPlanImageLower: 'floor plan image',
      siteFloorZoneBoundaryUpper: 'Room Boundary',
      siteBuildingFloorZoneBoundaryLower: 'room boundary',
      siteLower: 'site',
      siteMappedDevices: 'Site Mapped Devices',
      siteRecipient: 'Site Recipient',
      siteRecipientLower: 'site recipient',
      siteRecipients: 'Site Recipients',
      siteRecipientsLower: 'site recipients',
      siteRole: 'Site Role',
      siteRoleLower: 'site role',
      siteRoles: 'Site Roles',
      siteRolesLower: 'site roles',
      sites: 'Sites',
      sitesLower: 'sites',
      siteZone: 'Room',
      siteZoneLower: 'room',
      siteZones: 'Rooms',
      siteZonesLower: 'rooms',
      sslParameters: 'SSL Parameters',
      studentInformationSystem: 'Student Information System',
      studentInformationSystems: 'Student Information Systems',
      support: 'Support',
      system: 'System',
      systemHealth: 'System Health',
      systemHealthAlarms: 'System Health Alarms',
      systemHealthDashboard: 'System Health Dashboard',
      systemHealthLogs: 'System Health Logs',
      allSystemHealthEvents: 'System Health Activity',
      systemHealthLogsLower: 'system health logs',
      telephony: 'Telephony',
      telephonyCUCM: 'Admin Telephony Cisco Unified CM Clusters',
      telephonyECC: 'Admin Telephony Cisco Unified CM External Call Control',
      trackedUser: 'Tracked User',
      trackingEvent: 'Tracking Event',
      trackingEventNotification: 'Tracking Event Notification',
      trackingEventNotificationLower: 'tracking event notification',
      trackingEventNotifications: 'Tracking Event Notifications',
      trackingEvents: 'Tracking Events',
      trackingEventsLower: 'tracking events',
      trackingEventUsersLower: 'tracking event users',
      trigger: 'Trigger',
      triggerLower: 'trigger',
      triggers: 'Triggers',
      triggersLower: 'triggers',
      ipSpeakerSipParametersLower: 'SIP speaker parameters',
      ipSpeakerSipParameters: 'SIP Speaker Parameters',
      twitterAccount: 'Twitter Account',
      twitterAccountLower: 'Twitter account',
      twitterAccounts: 'Twitter Accounts',
      twitterAccountsLower: 'Twitter accounts',
      user: 'User',
      userGroups: 'User Groups',
      userLoader: 'User Loader',
      userLoaderGroups: 'User Loader Groups',
      userLoaderGroupLower: 'user loader group',
      userLoaderGroupsLower: 'user loader groups',
      userLoaderGroupsMixed: 'User loader groups',
      userLoaderLower: 'user loader',
      userLoaders: 'User Loaders',
      userLoadersLower: 'user loaders',
      userLower: 'user',
      users: 'Users',
      usersLower: 'users',
      userType: 'User Type',
      userTypeLower: 'user type',
      userTypeMappings: 'User Type Mappings',
      userTypes: 'User Types',
      userTypesLower: 'user types',
      voice: 'Voice',
      webexCalling: 'Webex Calling',
      webexCallingDevice: 'Webex Calling Device',
      zoom: 'Zoom',
    },
    label: {
      access: 'Access',
      yes: 'Yes',
      no: 'No',
      ipAddress: 'IP Address',
      action: 'Action',
      actions: 'Actions',
      actionResults: 'Action Results',
      tip: 'Tip',
      activities: 'Activities',
      actual: 'Actual',
      advancedSettings: 'Advanced Settings',
      address: 'Address',
      audit: 'View Change History',
      back: 'Back',
      caution: 'Caution',
      channels: 'Channels',
      deviceType: 'Device Type',
      date: 'Date',
      dateTime: 'Date & Time',
      today: 'Today',
      yesterday: 'Yesterday',
      lastSevenDays: 'Last 7 Days',
      lastThirtyDays: 'Last 30 Days',
      lastTwelveMonths: 'Last 12 Months',
      customDate: 'Custom Date',
      customDateRange: 'Custom Date Range',
      asc: 'Ascending',
      desc: 'Descending',
      advanced: 'Advanced',
      info: 'Info',
      initiationInfo: 'Initiation Information',
      all: 'All',
      attachments: 'Attachments',
      attribute: 'Attribute',
      attributes: 'Attributes',
      audio: 'Audio',
      authenticate: 'authenticate',
      basicInfo: 'Basic Information',
      body: 'Body',
      browse: 'Browse',
      cancelRemove: 'Cancel Removal',
      categories: 'Categories',
      category: 'Category',
      centerMap: 'Center Map',
      certificates: 'Certificates',
      channel: 'Channel',
      clear: 'Clear',
      columns: 'Columns',
      collapse: 'Collapse',
      configuration: 'Configuration',
      content: 'Content',
      createdAt: 'Created At',
      credentialExpiration: 'Credential Expirations',
      copy: 'Copy',
      copyToClipboard: 'Successfully Copied to Clipboard',
      cannotCopy: "Can't Copy Item",
      cannotCopyToClipboard: 'Failed to Copy to Cliipboard',
      customSsml: 'Custom text-to-speech SSML',
      customTts: 'Custom Text-to-Speech Text',
      voiceSpeed: 'Voice Speed',
      days: 'Days',
      defunctDeviceError: 'Cannot save with a defunct device.',
      delete: 'Delete',
      demote: 'Demote',
      description: 'Description',
      details: 'Details',
      device: 'Device',
      devices: 'Devices',
      deviceCount: 'Device Count',
      deviceGroup: 'Device Group',
      deviceNotFound: 'Device Not Found',
      distributionList: 'Distribution List',
      disabled: 'Disabled',
      disabledPostfix: ' (Disabled)',
      dnd: 'Do Not Disturb',
      dnsSrvConfigure: 'Use DNS SRV',
      dnsSrvDomain: 'DNS SRV Domain',
      done: 'Done',
      download: 'Download',
      edit: 'Edit',
      email: 'Email',
      end: 'End',
      enabled: 'Enabled',
      enableSipIntercom: 'Enable SIP Intercom',
      enGB: 'English (GB)',
      enUS: 'English (US)',
      esUS: 'Spanish (US)',
      arb: 'Arabic',
      deDE: 'German (DE)',
      frFR: 'French (FR)',
      frCA: 'French (CA)',
      ptBR: 'Portuguese (BR)',
      failed: 'Failed',
      entries: 'Entries',
      every: 'Every',
      exception: 'Exception',
      exceptions: 'Exceptions',
      excludedDevices: 'Excluded Devices',
      excludedDevicesNote:
        'Excludes devices that have been included by a device group or rule filter.',
      expand: 'Expand',
      expiration: 'Expiration',
      expirationTime: 'Expiration Time',
      expirationTimeUnit: 'Expiration Time Unit',
      expired: 'Expired',
      fieldOptional: '{{field}} (Optional)',
      filters: 'Filters',
      filtersSubtitle: 'Add additional devices that satisfy a specified set of restrictions.',
      filterByRules: 'Filter By Rules',
      floorPlan: 'Floor Plan',
      general: 'General',
      generalSettings: 'General Settings',
      hasExpiration: 'Expires',
      hoursWithValue: '{{value}} Hour(s)',
      daysWithValue: '{{value}} Day(s)',
      devicesToInclude: 'Devices to <strong>Include<strong>',
      devicesToExclude: 'Devices to <strong>Exclude<strong>',
      deviceGroupsToInclude: 'Device groups to <strong>Include<strong>',
      individualDevices: 'Individual Devices',
      initiator: 'Initiator',
      icap: {
        categories: {
          Geo: 'Geography',
          Met: 'Weather',
          Safety: 'Safety',
          Security: 'Security',
          Rescue: 'Rescue',
          Fire: 'Fire',
          Health: 'Health',
          Env: 'Environment',
          Transport: 'Transport',
          Infra: 'Infrastructure',
          CBRNE: 'CBRNE',
          Other: 'Other',
        },
      },
      icon: 'Icon',
      image: 'Image',
      instruction: 'Instruction',
      language: 'Language',
      latitude: 'Latitude',
      learnMore: 'Learn More',
      loading: 'Loading...',
      location: 'Location',
      locationSettings: 'Location Settings',
      locationPreview: 'Location Preview',
      lockUser: 'Lock User',
      locked: 'Locked',
      longitude: 'Longitude',
      macAddress: 'MAC Address',
      message: 'Message',
      messageBody: 'message body',
      minutesWithValue: '{{value}} Minute(s)',
      miscellaneous: 'Miscellaneous',
      mode: 'Mode',
      more: 'More',
      name: 'Name',
      next: 'Next',
      noAudio: 'No audio attached',
      noBuildings: 'Building required',
      noBuildingsDescription: 'Select a site that has at least one building. ',
      noComment: 'No Comment',
      noConfRequest: 'No confirmation request selected',
      noIncidentPlan: 'No incident plan selected',
      noExpiration: 'No expiration',
      noImage: 'No image attached',
      noIconSelected: 'No icon selected',
      noImageSelected: 'No image selected',
      noName: 'No Name',
      none: 'None',
      noResults: 'No Results',
      noSearchMatch: 'No {{resourcePlural}} match your search parameters.',
      noFilterMatch: 'No {{resourcePlural}} match your filter parameters.',
      notAvailable: 'Not Available',
      notAvailableShort: 'N/A',
      notificationProfile: 'Notification Profile',
      noUsersSelected: 'No users selected',
      number: 'Number',
      oneNumeric: '1',
      password: 'Password',
      parameters: 'Parameters',
      passwordResetRequired: 'Password Reset Required',
      permissions: 'Permissions',
      pin: 'PIN',
      port: 'Port',
      priority: 'Priority',
      promote: 'Promote',
      previewAudio: "Click 'Play' to preview audio",
      play: 'Play',
      pause: 'Pause',
      radius: 'Radius (meters)',
      refresh: 'Refresh',
      recipientSettings: 'Recipient Settings',
      recipients: 'Recipients',
      record: 'Record',
      recorded: 'Recorded',
      recordedAudioFileName: 'Recorded Audio',
      recording: 'Recording...',
      recordingError: 'Recording Error',
      remove: 'Remove',
      required: 'Required',
      responses: 'Responses',
      restore: 'Restore',
      results: 'Results',
      ringtone: 'Ring Tone',
      rule: 'Rule',
      ruleAction: 'API Connector',
      ruleActionResults: 'API Connector Results',
      ruleActions: 'API Connectors',
      scimEndpointUrl: 'SCIM Endpoint URL',
      secondsWithValue: '{{value}} Second(s)',
      security: 'Security',
      searchBarPlaceholder: 'Enter an address, ZIP code, or place of interest',
      googleSearchPlaceholder: 'Enter an address or click on a location',
      scheduleName: 'Schedule Name',
      select: 'Select...',
      send: 'Send',
      sender: 'Sender',
      settings: 'Settings',
      simple: 'Simple',
      siteSpecific: 'Site-specific Scenarios',
      size: 'Size',
      source: 'Source',
      spec: 'Spec',
      start: 'Start',
      ssml: 'SSML',
      statistics: 'Statistics',
      status: 'Status',
      startRecording: 'Start Recording',
      stopRecording: 'Stop Recording',
      subject: 'Subject',
      subjectBody: 'Subject and Body',
      subscriptionEndDate: 'Subscription End Date',
      success: 'Success',
      syncStatus: 'Synchronization Status',
      test: 'Test',
      testFilters: 'Test Filters',
      testing: 'Testing',
      text: 'Text',
      time: 'Time',
      timeZone: 'Time Zone',
      timeout: 'Timeout',
      timeSent: 'Time Sent',
      tts: 'Text-to-Speech',
      tools: 'Tools',
      verySlow: 'Very Slow',
      slow: 'Slow',
      normal: 'Normal',
      fast: 'Fast',
      veryFast: 'Very Fast',
      type: 'Type',
      types: 'Types',
      unsavedRulesNote: 'Unsaved rules above.',
      unit: 'Unit',
      update: 'Update',
      upload: 'Upload',
      url: 'URL',
      urls: 'URLs',
      username: 'Username',
      userLoaderGroupMembers: 'User Loader Group Members',
      userType: 'User Type',
      value: 'Value',
      version: 'Version',
      view: 'View',
      voice: 'Voice',
      warning: 'Warning!',
      zoomIn: 'Zoom In',
      zoomOut: 'Zoom Out',
      alertTone: 'Alert Tone',
      expire: 'Set Default Expiration',
      expires: 'Expires',
      expireExplanation: 'Leave this field blank to set the notification to not expire.',
      metadata: 'Metadata',
      optOut: 'Opt Out',
      optOutExplanation:
        'Whether or not users can opt out of notifications based on this template.',
      preview: 'Preview',
      imagePreview: 'Image Preview',
      audioPreview: 'Audio Preview',
      smsCountEstimate: 'SMS Count Estimate',
      ttsConfig: 'Text-to-Speech Configuration',
      default: 'Default',
      custom: 'Custom',
      question: 'Question',
      variable: 'Variable',
      responder: 'Responder',
      alertTones: {
        default: 'Default',
        silent: 'Silent',
        accent: 'Accent',
        bright: 'Bright',
        chime: 'Chime',
        chirp: 'Chirp',
        crystal: 'Crystal',
        'fire-alarm': 'Fire Alarm',
        'industrial-alarm': 'Industrial Alarm',
        klaxon: 'Klaxon',
        tone: 'Tone',
        '911-dialed': '911 Dialed',
        'all-clear1': 'All Clear 1',
        'all-clear2': 'All Clear 2',
        bell: 'Bell',
        'class-change1': 'Class Change 1',
        'class-change2': 'Class Change 2',
        'class-change3': 'Class Change 3',
        'class-change4': 'Class Change 4',
        'class-change5': 'Class Change 5',
        'class-change6': 'Class Change 6',
        'code-blue': 'Code Blue',
        'code-call': 'Code Call',
        evacuate1: 'Evacuate 1',
        evacuate2: 'Evacuate 2',
        evacuate3: 'Evacuate 3',
        evacuate4: 'Evacuate 4',
        'internal-emergency1': 'Internal Emergency 1',
        'internal-emergency2': 'Internal Emergency 2',
        'internal-emergency3': 'Internal Emergency 3',
        'location-closing': 'Location Closing',
        'lockdown-1-pulse': 'Lockdown 1 Pulse',
        'lockdown-2-pulses': 'Lockdown 2 Pulses',
        'lockdown-3-pulses': 'Lockdown 3 Pulses',
        lockdown: 'Lockdown',
        'room-turn': 'Room Turn',
        'severe-weather-advisory': 'Severe Weather Advisory',
        'shift-change1': 'Shift Change 1',
        'shift-change2': 'Shift Change 2',
        'shift-change3': 'Shift Change 3',
        'shift-change4': 'Shift Change 4',
        'take-shelter': 'Take Shelter',
        'tornado-siren': 'Tornado Siren',
      },
      inRoughly: 'In roughly',
      unknown: 'Unknown',
      reason: 'Reason: {{reason}}, Code: {{code}}, Message: {{message}}',
      code: 'Code',
      deviceFilters: {
        filterDescriptions: {
          AuthenticationUrl: 'Authentication URL',
          HasDisplay: 'Can Display Text',
          CanListen: 'Can Listen',
          CanPlayHQAudio: 'Can Play High-Quality Audio',
          CanSendHQAudio: 'Can Record High-Quality Audio',
          IsRecordingSource: 'Can Record',
          Description: 'Description',
          DirectoryNumbers: 'Directory Numbers',
          EndUserIdentifier: 'End User Identifier',
          InformaCastDeviceType: 'InformaCast Device Type',
          IPAddress: 'IP Address',
          IsDesktopNotifier: 'Is Desktop Notifier',
          IsRegistered: 'Is Registered',
          Location: 'Location',
          MACAddress: 'MAC Address',
          Name: 'Name',
          PartitionNames: 'Partition Names',
          ProfileDescription: 'Profile Description',
          ReportedIPv4Address: 'Reported IPv4 Address',
          ReportedName: 'Reported Name',
          ScrollSpeedAdjustment: 'Scroll Speed Adjustment',
          StatusUrl: 'Status URL',
          CallManagerCSS: 'Cisco Unified CM Calling Search Space',
          CallManagerClusterAxlName: 'Cisco Unified CM Cluster AXL Name',
          CallManagerClusterName: 'Cisco Unified CM Cluster Description',
          CallManagerDevicePool: 'Cisco Unified CM Device Pool',
          CallManagerDeviceType: 'Cisco Unified CM Device Type',
          Volume: 'Volume',
          SerialNumber: 'Poly Phone Serial Number',
          ObiNumber: 'OBi Number',
          PDMSId: 'PDMS ID',
          firmware: 'Firmware',
          ptt_address: 'Multicast Address',
          ptt_port: 'Multicast Port',
          deviceIdentifier: 'Device Identifier',
          'Descriptive Text': 'Descriptive Text',
          activationGroupId: 'Activation Group ID',
          ContactClosureName: 'Contact Closure Name',
          ContactClosureDescription: 'Contact Closure Description',
          ContactClosureIPAddress: 'Contact Closure IP Address',
          NetworkLocation: 'Network Location',
        },
      },
      pagingGroupChannel: 'Paging Group Channel',
      pagingGroupChannel_plural: 'Paging Group Channels',
      lineLabel: 'Line {{n}} Label',
      usersHelpText:
        '<0>Users</0> are people who can view, create, send, and receive notifications. They can be added to InformaCast by an administrator, add themselves through Self Service, or be uploaded to InformaCast through a user loader.',
      uploadedGroupsHelpText:
        "Uploaded groups are collections of <0>cloud-uploaded </0> or <1>school information import</1> users who exist both in InformaCast and externally within your organization's structure.",
      cloudUploaded: 'cloud-uploaded',
      anonymousHelpText:
        "<0>Anonymous users</0> are people added to InformaCast through a distribution list campaign. They can only receive text notifications concerning their specific campaign's activities.",
      applicationHelpText:
        '<0>Applications</0> are a way for external programs to make requests of InformaCast and receive information.',
      domainsHelpText:
        '<0>Domains</0> allow you to view resources, e.g. users, distribution lists, etc., through a hierarchical architecture, and grant permissions to those resources at each level.',
      transportProtocols: {
        udp: 'UDP',
        tcp: 'TCP',
        tls: 'TLS',
      },
      selectRecipients: 'Select at least one recipient type',
    },
    incidents: {
      incidentManagement: 'Incident Management',
      scenarioIncidentManagementSubtitle:
        'Select an incident plan (or create a new one) to provide your organization with an agenda to follow when this scenario is initiated.',
      createIncidentPlan: 'Create New Incident Plan',
      end: 'End Incident',
      initiateIncident: 'Initiate Incident',
    },
    facilities: {
      global: 'Global',
      shared: 'Shared',
      notShared: 'Not Shared',
      globalOnly: 'Global Only',
      facilityStatus: 'Site Status',
      facilityAccess: 'Facility Access',
      manageSiteAccess: 'Manage Site Access',
      managingSite: 'Managing Site',
      sharedStatus: 'Shared Status',
      facilityNameTag: ' ({{name}})',
      nearbyDevicesList: { title: 'Nearby Devices' },
    },
    confirmationRequests: {
      createConfirmationRequest: 'Create New Confirmation Request',
    },
    notifications: {
      notifications: 'Notifications',
      details:
        'View the details of the notification, its delivery results, the confirmation responses, ' +
        'the number of recipients who have read your notification, and their activity in relation to the notification.',
      userDetails: 'View the details of the received notification.',
      sentNotificationDetails: 'View the details of the sent notification.',
      conferenceCallInfo:
        '<span>{{name}}</span> Conference Call: To join <span>{{name}}</span>, please dial <link>{{number}}</link> and when prompted, enter {{joinPin}}.',
      joinConferenceCall:
        'To join the <0>{{name}}</0> Conference Call, please dial <1>{{number}}</1> and when prompted, enter <2></2>.',
      button: {
        showLess: 'Show Less',
        showMore: 'Show More',
      },
      urlCopied: 'URL copied to the clipboard',
      urlFailedCopy: 'Failed to copy URL. Please try again.',
      noPermissions: '[No Permissions]',
      requestFailed: '[Request Failed]',
    },
    subheader: {
      basicInfo: 'Basic Information',
      settings: 'Settings',
    },
    button: {
      apply: 'Apply',
      add: 'Add',
      addResource: 'Add {{resource}}',
      addNotification: 'Add a Notification',
      addQuestion: 'Add a Question',
      adjustVolume: 'Adjust Volume',
      addStep: 'Add a Step',
      addVariable: 'Add a Variable',
      back: 'Back',
      bulkAdd: 'Bulk Add',
      cancel: 'Cancel',
      clear: 'Clear',
      clearSelection: 'Clear Selection',
      clearFilters: 'Clear Filters',
      close: 'Close',
      confirm: 'Confirm',
      copy: 'Copy',
      continue: 'Continue',
      create: 'Create',
      delete: 'Delete',
      disable: 'Disable',
      download: 'Download',
      downloadCsv: 'Download CSV',
      edit: 'Edit',
      help: 'Help',
      home: 'Home',
      hide: 'Hide',
      hidePassword: 'Hide Password',
      login: 'Log In',
      ok: 'OK',
      profile: 'Profile',
      reconnect: 'Reconnect',
      reboot: 'Reboot',
      remove: 'Remove',
      return: 'Return',
      replace: 'Replace',
      save: 'Save',
      saveResource: 'Save {{resource}}',
      selectAll: 'Select All',
      send: 'Send',
      update: 'Update',
      uploadResource: 'Upload {{resource}}',
      replaceResource: 'Replace {{resource}}',
      undo: 'Undo',
      upload: 'Upload',
      verify: 'Verify',
      view: 'View',
      viewPassword: 'View Password',
      changePassword: 'Change Password',
      chooseFile: 'Choose File',
      volume: 'Volume',
      openIntegration: 'Open Integration',
    },
    tooltips: {
      accept: 'Accept',
      add: 'Add',
      cancel: 'Cancel',
      create: 'Create',
      delete: 'Delete',
      edit: 'Edit',
      restart: 'Restart',
      manage: 'Manage',
      followUp: 'Follow-Up',
      filter: 'Filter',
      learnMore: 'Learn More',
      sendNotification: 'Send Notification',
      update: 'Update',
      upload: 'Upload',
      view: 'View',
      viewDetails: 'View Details',
      playAudio: 'Play Audio',
      copyUrl: 'Copy URL',
      openLink: 'Open Link',
      customUrl: 'Reset to Default',
      initiateScenario: 'Initiate Scenario',
      location: 'Show Location',
      editLocation: 'Edit Location',
      moveUp: 'Move up',
      moveDown: 'Move down',
      moveToTop: 'Move to top',
      moveToBottom: 'Move to bottom',
      noFileSelected: 'No File Selected',
      install: 'Install',
      uninstall: 'Uninstall',
      enable: 'Enable',
      disable: 'Disable',
    },
    resetSection: {
      modalTitle: 'Reset {{section}} to Default Settings',
      modalBody:
        'You have chosen to reset your {{section}} configuration to its default settings. Any changes you made will be lost. Do you want to continue?',
      resetToDefaults: 'Reset to Defaults',
      resetToDefault: 'Reset to Default',
      reset: 'Reset',
    },
    resourceTitle: {
      add: 'Add {{resource}}',
      copy: 'Copy {{resource}}',
      create: 'Create {{resource}}',
      edit: 'Edit {{resource}}',
      delete: 'Delete {{resource}}',
      details: '{{resource}} Details',
      nameDetails: '{{resource}} Details: {{name}}',
      preferences: '{{resource}} Preferences',
      receivedDetails: 'Received {{resource}} Details',
      sentDetails: 'Sent {{resource}} Details',
      list: 'List {{resource}}',
      listInContext: 'List {{resource}} ({{context}})',
      setup: '{{resource}} Setup',
      type: '{{resource}} Type',
      entries: '{{resource}} Entries',
      search: 'Search {{resource}}',
    },
    delete: {
      confirm:
        'You have chosen to delete the {{name}} {{resource}}. Deleting this {{resource}} will permanently remove it from InformaCast.',
      confirmPlural:
        'You have chosen to delete the {{name}} {{resource}}. Deleting these {{resource}} will permanently remove them from InformaCast.',
      failure:
        'The {{name}} {{resource}} has not been removed. Please try again. If problems continue, contact your system administrator.',
      failurePlural:
        'The {{name}} {{resource}} have not been removed. Please try again. If problems continue, contact your system administrator.',
      success: 'You have successfully deleted the {{name}} {{resource}}.',
      textConfirm: 'Enter DELETE and click the OK button to confirm deleting.',
      deleteInstructions:
        'Enter DELETE and click the OK button to confirm deleting {{name}} {{resource}}.',
      confirmDelete: 'delete',
    },
    clone: {
      failure:
        'The {{name}} {{resource}} has not been copied. Please try again. If problems continue, contact your system administrator.',
      success: 'You have successfully copied the {{name}} {{resource}}.',
    },
    message: {
      subject:
        'Immediately visible on most notification recipients, Subject text is a summary of your notification.',
      body: 'Not always immediately visible, Body text provides more details about your notification and requires user interaction, e.g. opening a notification.',
      operationFailed:
        'The operation failed. Please try again. If problems continue, contact your system administrator.',
      willOrphan: 'Cannot remove {{resourceType}} from its only domain',
      domainOutsider: 'Cannot adjust domain',
      expirationMustBeInRange:
        'Expiration must be more than 1 minute and less than 30 days from now.',
      invalidSchedule: 'Invalid schedule',
      mappingLookupFailed:
        'Failed to find resource mapping. Please try again. If problems continue, contact your system administrator.',
      success: 'Success',
      noLocations: 'No Locations',
      atLeastOneRecipientRequired: 'At least one recipient required',
      genericFailure:
        'An unknown error occurred. Please try again. If problems continue, contact your system administrator.',
      invalidPhoneNumber: 'Please enter a valid phone number',
      ttsInput: 'Text-to-speech content must not exceed 3,000 characters.',
      ttsSpeed: 'Speed at which text-to-speech content will be spoken.',
      pageNotAvailableOnFailoverBackup:
        'This page is inaccessible from your backup on-premises server while it is in standby mode. Please select your primary on-premises server to access this page.',
      accessedUnaccessiblePage:
        'InformaCast encountered an error and redirected you to the Dashboard.',
    },
    validations: {
      fileSize: 'The selected file(s) cannot exceed {{max}}.',
      fileType: 'The selected file(s) is not a valid type. Valid types are {{validTypes}}.',
      invalidEndDate: 'End date must be set in the future',
      invalidSubscribeTag: 'Tag must be a valid Twitter style hash tag (e.g. #ParentsDay)',
      willOrphan:
        "You must add the {{resourceType}} '{{resource}}' to another domain before you can remove it from the '{{domain}}' domain.",
      domainOutsider:
        "You cannot adjust domains of the {{resourceType}} '{{resource}}' while acting in the '{{domain}}' domain. It exists only in domains not visible from your current domain, but you can see it because of a specific permission.",
      malformedJSON: 'JSON not well-formed.',
      invalidExpirationOneAnd30:
        'Expiration must be between 1 minute and 30 days, or blank to never expire.',
      ipAddress: 'Invalid IP Address',
      passwordMatch: 'Password does not match the one provided for the above field',
      colorFormatMessage: 'The color must be a three- or six-character hex value, like #abc.',
      colorFormatMessage6: 'The color must be a six-character hex value, like #abc123.',
    },
    days: {
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
    },
    import: {
      import: 'Import',
      resourceImport: '{{resource}} Imports',
      importNow: 'Import Now',
    },
    devices: {
      attributes: {
        Description: 'Description',
        DirectoryNumbers: 'Directory Numbers',
        IPAddress: 'IP Address',
        IsDesktopNotifier: 'Is Desktop Notifier',
        NetworkLocation: 'Network Location',
        MACAddress: 'MAC Address',
        Name: 'Name',
        Port: 'Port',
        ContactClosureName: 'Contact Closure Name',
        ContactClosureDescription: 'Contact Closure Description',
        ContactClosureIPAddress: 'Contact Closure IP Address',
      },
    },
    unsupportedRules: {
      title: 'Remove Unsupported Rule Parameters',
      messagePart1: 'One or more rules use unsupported parameters:',
      messagePart2: 'These rules will be removed when you save your configuration.',
      filterMessage: '{{attribute}} {{complement}} {{comparison}} "{{value}}" ({{caseSensitive}})',
      does: 'does',
      doesNot: 'does not',
      caseSensitive: 'case sensitive',
      caseInsensitive: 'ignore case',
      comparison: {
        BEGINS_WITH: 'begin with',
        CONTAINS: 'contain',
        CONTAINS_IN: 'contain in',
        ENDS_WITH: 'end with',
        EQUALS: 'equal',
        MATCHES_REGEX: 'match expression',
      },
    },
    header: {
      moreOptions: 'Header More Options',
    },
  },
};

export default CommonLocale;
export const common_t = registerResource<typeof CommonLocale.en>('common', CommonLocale);
