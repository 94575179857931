import truncate from 'lodash/truncate';
import { PureComponent } from 'react';
import { Combobox, Icon } from 'singlewire-components';
import Routes from '../../core/route/Routes';
import { FusionEndpointSelectProps } from './FusionEndpointSelectContainer';
import { shared_t } from './SharedLocale';
import { Button } from './components/Button';

export class FusionEndpointSelect extends PureComponent<FusionEndpointSelectProps> {
  render() {
    const { servers, selectedICOPServer, confirmFusionServerChange, navigateTo } = this.props;

    return servers && servers.length > 0 && selectedICOPServer ? (
      <>
        <Combobox
          trigger={isOpen => (
            <Button
              id="fusion-server-select-dropdown-button"
              color="gray"
              variant="contained"
              label={truncate(selectedICOPServer.name)}
              startIcon={<Icon.ServerSelect color="inherit" />}
              disabled={servers.length === 1}
              endIcon={
                servers.length === 1 ? null : isOpen ? (
                  <Icon.Collapse size="sm" color="inherit" />
                ) : (
                  <Icon.Expand size="sm" color="inherit" />
                )
              }
            />
          )}
          options={servers.map(server => ({
            label: server.name,
            value: server.id,
            subtitle:
              (server.config && server.config.description) ||
              shared_t(['fusionServerSelect', 'label', 'noDescription']),
            onSelect: () => confirmFusionServerChange(server.id),
            icon: selectedICOPServer.id === server.id ? <Icon.Save size="sm" /> : <></>,
          }))}
          searchPlaceholder={shared_t(['label', 'search'])}
          customLabel={shared_t(['fusionServerSelect', 'label', 'manageServers'])}
          onCustomSelection={() => navigateTo(Routes.Servers.Index)}
          showCheckbox={false}
        />
      </>
    ) : null;
  }
}
