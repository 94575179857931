// This file kicks off initializing everything we need before we start React.
import { initLocalizations } from './core/utils/localization';
import { initGeneral } from './init/general';
import { initGoogleTagManager } from './init/google-tag-manager';
import { initLoggingInterceptor } from './init/logging-interceptor';
import { initPendo } from './init/pendo'; // This needs to be loaded last as it includes our final catch all route

export function init() {
  initGoogleTagManager();
  initPendo();
  initGeneral();
  initLoggingInterceptor();
  initLocalizations();
}
