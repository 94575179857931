import { createAction } from 'typesafe-actions';

export interface MakeActiveParams {
  gridId: string;
  activeKey: string | null;
}

export const makeActive = createAction(
  '[Grid] Make Active',
  (makeActiveParams: MakeActiveParams) => ({ ...makeActiveParams })
)();
