import { registerResource } from '../../../core/utils/localization';

export const TrackingEventsLocale = {
  en: {
    accuracy: 'Accuracy (m)',
    active: 'Active',
    activeTime: 'Active {{time}}',
    loggedOut: 'Logged Out {{time}}',
    centerMapAroundEvent: 'Center Map Around Event',
    centerMapAroundUser: 'Center Map Around User',
    completed: 'Completed',
    createdAt: 'Created at: {{time}}',
    directionsToUser: 'Directions to User',
    endFailed:
      'The {{name}} tracking event has not ended. Please try again. If problems continue, contact your system administrator.',
    endMessage:
      'You have chosen to end the {{name}} tracking event. Once tracking is ended it cannot be restarted, however the event is still viewable by checking "Show Completed".',
    endSuccess: 'You have successfully ended the {{name}} tracking event.',
    endTracking: 'End Tracking',
    endTrackingEvent: 'End Tracking Event',
    endUserTracking:
      'You have chosen to stop tracking {{name}}. Once stopped, tracking cannot be restarted, however the user information is still viewable by checking "Show Untracked".',
    endUserTrackingFailed: '',
    endUserTrackingSuccess: '',
    event: 'Event',
    eventDisplay: 'Event Display',
    followUp: 'Follow Up',
    followUpWithAll: 'Follow Up With All',
    list:
      'Lists events (by notification subject and confirmation request name) where tracking was initiated.',
    listUsers: 'List Tracking Event Users',
    noTrackedUsersFound: 'No tracked users. Use filtering to show untracked users.',
    noTrackingEventsFound: 'No tracking events. Use filtering to show completed tracking events.',
    phone: 'Phone',
    showCompleted: 'Show Completed',
    stopTrackingUser: 'Stop Tracking User',
    showUntracked: 'Show Untracked',
    trackedUser: 'Tracked User Details',
    trackedUsers: 'Tracked Users',
    tracking: 'Tracking',
    trackingEventDetails: 'Tracking Event Details',
    untracked: 'Untracked',
    userDetails:
      'View a user who has initiated tracking, their plotted locations, and their date, time, and accuracy (in meters).',
    userDisplay: 'User Display',
  },
};

export default TrackingEventsLocale;
export const trackingEvents_t = registerResource<typeof TrackingEventsLocale.en>(
  'trackingEvents',
  TrackingEventsLocale
);
