import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form/lib/Field';
import {
  GridItemSizing,
  Switch as RegistrySwitch,
  SwitchField as RegistrySwitchField,
} from 'singlewire-components';

export interface SwitchProps extends GridItemSizing {
  input: WrappedFieldInputProps;
  meta: WrappedFieldMetaProps;
  id: string;
  name: string;
  label: string;
  onChange?: (checked: boolean) => void;
  checked?: boolean;
  disabled?: boolean;

  // This prop lets you have the switch on for fields that are false and off for fields that are true
  invert?: boolean;
}

export interface SwitchFieldProps extends SwitchProps {
  label: string;
}

export const Switch = ({ input, meta, ...rest }: SwitchProps) => (
  <RegistrySwitch {...rest} onChange={input.onChange} checked={input.value} />
);

export const SwitchField = ({ input, meta, onChange, md, invert, ...rest }: SwitchFieldProps) => (
  <RegistrySwitchField
    {...rest}
    md={md || 6}
    onChange={checked => {
      if (onChange) {
        onChange(invert ? !checked : checked);
      }
      input.onChange(invert ? !checked : checked);
    }}
    checked={invert ? !input.value : input.value || false}
  />
);
