// Functions for transforming outgoing request headers.omit, join,
// @ts-ignore
import join from 'lodash/join';
// @ts-ignore
import omit from 'lodash/omit';
import { AxiosRequestConfig } from 'axios';
import { ApiRequest } from '../../common-types';

export const FEATURES_HEADER = 'X-Singlewire-Features';
export const SKIP_SESSION_EXTENSION_HEADER = 'Skip-Session-Extension';
export const IGNORE_LOADING_BAR_HEADER = 'Ignore-Loading-Bar';
export const IGNORE_RATE_LIMITS_HEADER = 'Ignore-Rate-Limits';
export const IGNORE_APP_USER_LIMITS_HEADER = 'Ignore-App-User-Limits';

export interface HttpHeaders {
  [header: string]: string | string[];
}

/**
 * Utility function to parse feature headers from the HttpHeaders object
 * @param {HttpHeaders} headers the headers object
 * @returns {Set<string>} a collection of all the feature requested
 */
export function parseFeaturesHeader(headers: HttpHeaders): Set<string> {
  const featuresHeader = headers[FEATURES_HEADER];
  return featuresHeader
    ? new Set(
        (Array.isArray(featuresHeader) ? featuresHeader : featuresHeader.split(',')).map(x =>
          x.trim()
        )
      )
    : new Set<string>();
}

/**
 * A utility function to check if any header has a contained feature header
 * @param {string} feature the feature to check for
 * @param headers the headers to check against
 * @returns {boolean} whether the feature is contained in the headers
 */
export function containsFeatureHeader(feature: string, headers: HttpHeaders): boolean {
  return parseFeaturesHeader(headers).has(feature);
}

/**
 * Adds or removes a feature header from the supplied headers
 * @param headers the headers to augment
 * @param {string[]} features the features to add/remove
 * @param {"append" | "remove"} op whether we are adding or removing a feature header
 * @returns {HttpHeaders} the headers
 */
export function augmentFeatureHeader(
  headers: HttpHeaders,
  features: string[],
  op: 'append' | 'remove'
): HttpHeaders {
  const newFeatures = features.reduce((acc, feature) => {
    if (op === 'append') {
      return acc.add(feature);
    } else {
      acc.delete(feature);
      return acc;
    }
  }, parseFeaturesHeader(headers));
  if (newFeatures.size === 0) {
    return omit(headers, [FEATURES_HEADER]);
  } else {
    return { ...headers, [FEATURES_HEADER]: join(Array.from(newFeatures), ',') };
  }
}

/**
 * Apply this transform if you would like your request to NOT extend the user's session. This is useful if a component
 * makes a request on an interval and you would still like to sign the user out after a certain period of inactivity. If
 * you don't use this, the application may never sign out.
 * @param {AxiosRequestConfig} options the current request options
 * @param {ApiRequest} req the request
 * @returns {AxiosRequestConfig} the new request options
 */
export function IgnoreSessionExtendTransform(
  options: AxiosRequestConfig,
  req: ApiRequest
): AxiosRequestConfig {
  return {
    ...options,
    headers: augmentFeatureHeader(
      options.headers as HttpHeaders,
      [SKIP_SESSION_EXTENSION_HEADER],
      'append'
    ),
  };
}

/**
 * Apply this transform if you would like your request to NOT show loading bar progress. This is useful if a component
 * makes a request on an interval and you'd like to not show constant activity ot the user especially if they wouldn't
 * care.
 * @param {AxiosRequestConfig} options the current request options
 * @param {ApiRequest} req the request
 * @returns {AxiosRequestConfig} the new request options
 */
export function IgnoreLoadingBarTransform(
  options: AxiosRequestConfig,
  req: ApiRequest
): AxiosRequestConfig {
  return {
    ...options,
    headers: augmentFeatureHeader(
      options.headers as HttpHeaders,
      [IGNORE_LOADING_BAR_HEADER],
      'append'
    ),
  };
}

/**
 * Apply this transform if you would like your request to ignore app user limit. Only used in QA
 * environments.
 * @param {AxiosRequestConfig} options the current request options
 * @param {ApiRequest} req the request
 * @returns {AxiosRequestConfig} the new request options
 */
export function IgnoreAppUserLimitsTransform(
  options: AxiosRequestConfig,
  req: ApiRequest
): AxiosRequestConfig {
  return {
    ...options,
    headers: augmentFeatureHeader(
      options.headers as HttpHeaders,
      [IGNORE_APP_USER_LIMITS_HEADER],
      'append'
    ),
  };
}
