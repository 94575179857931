import * as sideBar from './SideBarActions';
import startsWith from 'lodash/startsWith';
import { ActionType, getType } from 'typesafe-actions';
import { ElementLink, ElementMenu } from '../utils/side-bar';

export type SideBarAction = ActionType<typeof sideBar>;

export interface SideBarState {
  profileInfoExpanded: boolean;
  activatedRoute: string;
  search: string;
  menuElements: any[];
}

export const initialSideBarState: SideBarState = {
  profileInfoExpanded: false,
  activatedRoute: '',
  search: '',
  menuElements: [],
};

// Differentiate between ElementMenu and ElementLink by testing for a property unique to ElementMenu.
export const isMenuElement = (element: any): boolean => !!element.children;

export const elementLinkMatchesActivatedRoute = (
  element: ElementLink,
  activatedRoute: string
): boolean => startsWith(activatedRoute, element.routerLink);

export const updateActivatedRoute = (elements: any[], activatedRoute: string): any[] => {
  return elements.map(element => {
    if (isMenuElement(element)) {
      const menu: ElementMenu = element;
      return { ...menu, children: updateActivatedRoute(menu.children, activatedRoute) };
    } else {
      const link: ElementLink = element;
      return { ...link, isActive: elementLinkMatchesActivatedRoute(link, activatedRoute) };
    }
  });
};

export const updateExpands = (
  elements: any[],
  expandAllMenus: boolean,
  activatedRoute: string
): any[] => {
  return elements.map(element => {
    if (isMenuElement(element)) {
      const menu: ElementMenu = element;
      const newChildren = updateExpands(menu.children, expandAllMenus, activatedRoute);
      const isExpanded =
        expandAllMenus || newChildren.some(child => child.isActive || child.isExpanded);
      return { ...menu, children: newChildren, isExpanded };
    } else {
      const link: ElementLink = element;
      return { ...link, isActive: elementLinkMatchesActivatedRoute(link, activatedRoute) };
    }
  });
};

export const toggleMenuElement = (elements: any[], elementToToggle: ElementMenu): any => {
  return elements.map(element => {
    if (isMenuElement(element)) {
      const menu: ElementMenu = element;
      const newChildren = toggleMenuElement(menu.children, elementToToggle);
      const isExpanded = element.id === elementToToggle.id ? !menu.isExpanded : menu.isExpanded;
      return { ...menu, children: newChildren, isExpanded };
    } else {
      return element;
    }
  });
};

export default function sideBarReducer(
  state: SideBarState = initialSideBarState,
  action: SideBarAction
): SideBarState {
  switch (action.type) {
    case getType(sideBar.toggleProfileInfoExpanded):
      return { ...state, profileInfoExpanded: !state.profileInfoExpanded };

    case getType(sideBar.setMenuElements):
      return {
        ...state,
        menuElements: updateExpands(action.payload.elements, !!state.search, state.activatedRoute),
      };

    case getType(sideBar.updateActiveRoute):
      const activatedRoute = action.payload.url;
      return {
        ...state,
        activatedRoute,
        menuElements: updateActivatedRoute(state.menuElements, activatedRoute),
      };

    case getType(sideBar.updateSearch):
      return { ...state, search: action.payload.query };

    case getType(sideBar.expandElement):
      return {
        ...state,
        menuElements: toggleMenuElement(state.menuElements, action.payload.menuElement),
      };

    default:
      return state;
  }
}
