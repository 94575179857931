// @ts-ignore
import isObject from 'lodash/isObject';

const convertToURLSearchParams = (
  init?: string[][] | Record<string, string> | string | URLSearchParams | Record<string, string[]>
) => {
  const sanitizedParams = isObject(init)
    ? Object.entries(init).reduce((acc, [k, v]) => {
        // turn our (key, value) pairs into a sequence of name-value string pairs e.g. [['name1', 'value1'], ['name2', 'value2']] per the URLSearchParams spec: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/URLSearchParams
        // values which are arrays are transformed into a sequence of name-value pairs with the same names but different values so {foo: [bar1, bar2]} becomes [['foo', 'bar1'], ['foo', 'bar2']]
        // URLSearchParams is then smart enough to turn that into ?foo=bar1&foo=bar2 which is the correct format for "list" query params
        if (v === null || v === undefined) {
          return acc;
        }

        if (!Array.isArray(v)) {
          return [...acc, [k, v]];
        } else {
          return [...acc, ...v.map(oneValue => [k, oneValue])];
        }
      }, [] as any)
    : init;
  return new URLSearchParams(sanitizedParams);
};

export const stringifyQueryParams = (
  init?: string[][] | Record<string, string> | string | URLSearchParams | Record<string, string[]>
) => {
  return convertToURLSearchParams(init).toString();
};

export const parseQueryParams = (
  init?: string[][] | Record<string, string> | string | URLSearchParams
): { [k: string]: string | string[] } => {
  const result = {} as any;
  const searchParams = convertToURLSearchParams(init);
  // @ts-ignore
  const keys = searchParams.keys();
  let key = keys.next();
  while (!key.done) {
    const values = searchParams.getAll(key.value);
    if (values.length === 1) {
      result[key.value] = values[0]; // string: string
    } else {
      result[key.value] = values; // string: string[]
    }
    key = keys.next();
  }
  return result;
};
