// This is a route that wraps permission check wrapper and the resolve data wrapper.
// It provides a handy api for specifying "prerequisites" necessary to render a route.

import { Route, RouteComponentProps, RouteProps } from 'react-router';
import { ComponentType, PureComponent } from 'react';
import { ApiRequest } from '../../common-types';
import { PermissionCheckContainer } from './PermissionCheckContainer';
import { ResolveDataContainer } from './ResolveDataContainer';
import { Feature } from '../../mobile-api-types';
import { FeatureCheckContainer } from './FeatureCheckContainer';
import { connect } from 'react-redux';
import { RootState } from '../../core/RootReducer';
import { interpolateResolve } from '../../core/utils/route';
import { ResolveErrorContainer } from './ResolveErrorContainer';
import { ResolveApiAdvancedConfig } from './ResolveDataSafely';

interface ExternalProps extends RouteProps {
  permissions?: ApiRequest | ReadonlyArray<ApiRequest>;
  resolve?: { [key: string]: ApiRequest | ResolveApiAdvancedConfig };
  featuresRequired?: Feature[];
  componentProps?: { [key: string]: any };
  // component to render when resolve fails
  customResolveErrorComponent?: ComponentType<any>;
  showLoadingIndicator?: boolean;
}

type AuthRouteProps = ExternalProps & ReturnType<typeof mapStateToProps>;
export const routeInnerRender = (
  {
    render,
    resolve,
    resolveErrors,
    permissions,
    featuresRequired,
    component,
    customResolveErrorComponent,
    componentProps,
    showLoadingIndicator,
  }: AuthRouteProps,
  routeProps: RouteComponentProps<any>
) => {
  const Component = component!;
  const CustomResolveErrorComponent = customResolveErrorComponent;
  const interpolateResolveValue = JSON.stringify(
    interpolateResolve(resolve, routeProps.match.params)
  );
  return (
    <FeatureCheckContainer features={featuresRequired} redirectToAccessDenied={true}>
      {resolveErrors && resolveErrors[interpolateResolveValue] ? (
        <ResolveErrorContainer
          resolveErrorKey={interpolateResolveValue}
          customComponent={CustomResolveErrorComponent}
        />
      ) : (
        <PermissionCheckContainer
          permissions={permissions}
          opts={{
            showLoadingIndicator: showLoadingIndicator ?? true,
            redirectToAccessDenied: true,
          }}>
          <ResolveDataContainer
            resolve={resolve}
            dontShowLoadingIndicator={!showLoadingIndicator ?? false}>
            {component ? (
              <Component {...routeProps} {...componentProps} />
            ) : render ? (
              render(routeProps)
            ) : (
              <></>
            )}
          </ResolveDataContainer>
        </PermissionCheckContainer>
      )}
    </FeatureCheckContainer>
  );
};
export class AuthenticatedRouteComponent extends PureComponent<AuthRouteProps> {
  render() {
    const {
      resolve,
      resolveErrors,
      permissions,
      featuresRequired,
      component,
      customResolveErrorComponent,
      componentProps,
      showLoadingIndicator,
      ...routeParams
    } = this.props;
    return (
      <Route
        {...routeParams}
        render={(routeProps: RouteComponentProps<any>) => routeInnerRender(this.props, routeProps)}
      />
    );
  }
}

export const mapStateToProps = (state: RootState) => ({
  resolveErrors: state.route.errors,
});

export const AuthenticatedRoute = connect(mapStateToProps)(AuthenticatedRouteComponent);
