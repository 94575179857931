// This is a container for all our where we house our modals. We hide or show them as necessary.

import { connect } from 'react-redux';
import { Modals } from './Modals';
import { RootState } from '../../core/RootReducer';

export type ModalsProps = ReturnType<typeof mapStateToProps>;

export const mapStateToProps = (state: RootState) => ({
  globalModal: state.modal.modal,
});

export const ModalsContainer = connect(mapStateToProps)(Modals);
