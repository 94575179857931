// A component to make it's children sticky - they will stay on the screen as the user scrolls

import forEach from 'lodash/forEach';
import { PropsWithChildren, PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import { Subscription, timer } from 'rxjs';

interface ExternalProps {
  mode?: StickyElementMode;
  stickyElementMq?: string;
  stickyElementOffset?: number;
  padComponentContainer?: boolean;
}

const DEFAULT_CLASS = 'sticky-element';
const STICKY_CLASS = 'sticky-element--sticky';
const VISIBLE_CLASS = 'sticky-content--visible';
const FORM_BUTTONS_CLASS = 'sticky-form-buttons';
const CARD_HEADER_CLASS = 'sticky-card-header';
const BOTTOM = 'bottom';
const TOP = 'top';
const POLLING_DELAY = 200;
const DEFAULT_CARD_STICKY_ELEMENT_OFFSET = 70;
const DEFAULT_FORM_BUTTON_STICKY_ELEMENT_OFFSET = 10;
const SMALL_BREAKPOINT_WIDTH = 480;

export type StickyElementMode = 'card' | 'header' | 'form-buttons' | 'top' | 'bottom';
type EventListener = [string, () => void];

// If you want to do something custom, set the mode to 'top' and specify a custom stickyElementOffset
export class StickyWrapper extends PureComponent<PropsWithChildren<ExternalProps>> {
  initialized = false;
  element: HTMLElement = document.createElement('div');
  stickTo: string | null = null;
  intervalID: Subscription | null = null;
  lastBodyHeight: number | null = null;
  elPos: number | null = null;
  isOnStickyMode = false;
  body: HTMLElement | null = null;
  offset: number | null = null;
  listeners: EventListener[] = [];

  componentDidMount() {
    const domNode = findDOMNode(this) as HTMLElement;
    this.init(domNode);
  }

  componentDidUpdate() {
    if (this.initialized) {
      const domNode = findDOMNode(this) as HTMLElement;
      this.destroy();
      this.init(domNode);
    }
  }

  componentWillUnmount(): void {
    this.destroy();
  }

  render() {
    return <>{this.props.children}</>;
  }

  init(el: HTMLElement) {
    this.initialized = true;
    if (!this.props.mode) {
      return;
    }
    this.element = el;
    this.element.classList.add(DEFAULT_CLASS);
    this.stickTo =
      this.props.mode === 'card' || this.props.mode === 'header'
        ? TOP
        : this.props.mode === 'form-buttons'
          ? BOTTOM
          : this.props.mode;
    if (this.props.mode === 'header' || this.props.mode === 'card') {
      this.element.classList.add(CARD_HEADER_CLASS);
    } else if (this.props.mode === 'form-buttons') {
      this.element.classList.add(FORM_BUTTONS_CLASS);
    }
    this.body = window.document.body;
    this.lastBodyHeight = this.body.offsetHeight;
    this.offset =
      this.props.mode === 'card' || this.props.mode === 'header'
        ? DEFAULT_CARD_STICKY_ELEMENT_OFFSET
        : this.props.mode === 'form-buttons'
          ? DEFAULT_FORM_BUTTON_STICKY_ELEMENT_OFFSET
          : this.props.stickyElementOffset || 0;

    this.listeners = [
      ['blur', () => this.stopPollingContentHeight()],
      ['focus', () => this.startPollingContentHeight()],
      ['scroll', () => this.updateState()],
      ['resize', () => this.updateState()],
    ];

    forEach(this.listeners, ([event, listener]: EventListener) => {
      window.addEventListener(event, listener);
    });

    // wait first directives to be rendered so we can get proper position values:
    setTimeout(() => {
      // make the element temporarily visible:
      this.element.classList.add(VISIBLE_CLASS);
      this.updateState();
      this.element.classList.remove(VISIBLE_CLASS);
    }, 0);
  }

  destroy() {
    this.stopPollingContentHeight();
    forEach(this.listeners, ([event, listener]: EventListener) => {
      window.removeEventListener(event, listener);
    });
  }

  startPollingContentHeight() {
    this.intervalID = timer(POLLING_DELAY, POLLING_DELAY).subscribe(() =>
      this.checkContentHeightAndUpdate()
    );
  }

  stopPollingContentHeight() {
    if (this.intervalID) {
      this.intervalID.unsubscribe();
    }
  }

  checkContentHeightAndUpdate() {
    if (this.body && this.lastBodyHeight !== this.body.offsetHeight) {
      this.lastBodyHeight = this.body.offsetHeight;
      this.updateState();
    }
  }

  updateState() {
    if (this.element.offsetWidth === 0 || this.element.offsetHeight === 0) {
      return;
    }

    this.clearStickiness();

    if (this.props.stickyElementMq && !window.matchMedia(this.props.stickyElementMq).matches) {
      return;
    }

    // Sticky elements cover too much of the screen when the width is very small
    // We are removing stickyness at small screen sizes here
    if (window.innerWidth < SMALL_BREAKPOINT_WIDTH) {
      return;
    }

    this.calculateElementPosition();

    if (this.isStickyState()) {
      this.addStickiness();
    }
  }

  calculateElementPosition() {
    this.elPos =
      this.stickTo === TOP
        ? window.pageYOffset + this.element.getBoundingClientRect().top
        : window.pageYOffset + this.element.getBoundingClientRect().top + this.element.offsetHeight;
    return this.elPos;
  }

  isStickyState() {
    return this.stickTo === TOP
      ? window.pageYOffset > (this.elPos || 0) - (this.offset || 0)
      : window.pageYOffset + window.innerHeight < (this.elPos || 0) + (this.offset || 0);
  }

  clearStickiness() {
    if (this.isOnStickyMode) {
      this.isOnStickyMode = false;

      if (this.element.classList.contains('sticky-card-header')) {
        (this.element.nextElementSibling as HTMLElement).style['padding-top'] = null;
        this.element.style.right = '';
        this.element.style.left = '';
        this.element.style.width = '';
      }

      if (this.element.classList.contains('sticky-card')) {
        (this.element.nextElementSibling as HTMLElement).style['margin-top'] = null;
      }

      this.element.classList.remove(STICKY_CLASS);
      if (this.stickTo) {
        this.element.style[this.stickTo] = '';
      }
    }
  }

  addStickiness() {
    if (!this.isOnStickyMode) {
      this.isOnStickyMode = true;
      if (this.element.classList.contains('sticky-card-header')) {
        const shouldforceStickyHeaderWidth =
          this.body &&
          !this.body.classList.contains('left-nav-toggled') &&
          !this.body.classList.contains('right-nav-toggled') &&
          (this.element.nextElementSibling as HTMLElement).offsetWidth >= 1140;

        this.element.style.right = shouldforceStickyHeaderWidth ? 'auto' : '';
        this.element.style.left = shouldforceStickyHeaderWidth ? 'auto' : '';
        this.element.style.width = shouldforceStickyHeaderWidth ? '1140px' : '';

        // compute the current card padding so we can add correct padding-top style when
        // adding stickiness
        const currentCardPadding = parseFloat(
          window.getComputedStyle(this.element.nextElementSibling as HTMLElement).padding || '0'
        );

        if (this.props.padComponentContainer) {
          (this.element.nextElementSibling as HTMLElement).style['padding-top'] = `${
            this.element.offsetHeight + currentCardPadding
          }px`;
        }
      }

      if (this.element.classList.contains('sticky-card')) {
        const computedStyles = window.getComputedStyle(this.element) as any;
        const margins =
          parseFloat(computedStyles.marginTop) + parseFloat(computedStyles.marginBottom);
        (this.element.nextElementSibling as HTMLElement).style['margin-top'] = `${2 * margins}px`;
      }

      this.element.classList.add(STICKY_CLASS);
      if (this.stickTo) {
        this.element.style[this.stickTo] = `${this.offset}px`;
      }
    }
  }
}

// TODO - test
