// This component will not render its children until it has fetched (resolved) the given resources.

import { useEffect } from 'react';
import { flushSync } from 'react-dom';
import { Redirect } from 'react-router';
import Routes from '../../core/route/Routes';
import { interpolateResolve } from '../../core/utils/route';
import { LogoutContainer } from '../features/logout/LogoutContainer';
import { LoadingIndicator } from './LoadingIndicator';
import { ResolveDataProps } from './ResolveDataContainer';

export const ResolveData = ({
  resolve,
  match,
  isLoggedIn,
  resolveState,
  children,
  renderWhenFailed,
  resolveRouteData,
  resolveRouteCleanup,
  resolveErrorCleanup,
  dontShowLoadingIndicator,
  render,
  routeData,
}: ResolveDataProps): any => {
  const interpolatedResolve = resolve && interpolateResolve(resolve, match.params);

  // Regular resolve updates, when the resolve changes, also does cleanup
  useEffect(() => {
    if (isLoggedIn && interpolatedResolve) {
      setTimeout(() => {
        flushSync(() => {
          resolveRouteData(interpolatedResolve);
        });
      }, 0);
    }

    return () => {
      if (interpolatedResolve) {
        setTimeout(() => {
          flushSync(() => {
            resolveRouteCleanup(Object.keys(interpolatedResolve));
          });
        }, 0);
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [JSON.stringify(interpolatedResolve), isLoggedIn, resolveRouteData, resolveRouteCleanup]);

  // Cleanup when component unmounts and there is an error for this interpolated resolve in the state
  useEffect(() => {
    return () => {
      if (interpolatedResolve && resolveState === 'FAILURE') {
        // Only cleanup error if it wasn't handled by a parent component
        // resolveState will be FAILURE only if this is the case
        resolveErrorCleanup(JSON.stringify(interpolatedResolve));
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [JSON.stringify(interpolatedResolve), resolveState, resolveErrorCleanup]);

  if (!isLoggedIn) {
    return <LogoutContainer />;
  } else if (resolveState === 'LOADING') {
    if (dontShowLoadingIndicator) {
      return <></>;
    } else {
      return <LoadingIndicator circleSize="lg" showLoadingString={true} dontCenter={false} />;
    }
  } else if (resolveState === 'FAILURE') {
    if (renderWhenFailed) {
      return renderWhenFailed();
    }

    return <Redirect to={Routes.Exception} />;
  } else {
    if (render) {
      return render(routeData);
    } else {
      return children;
    }
  }
};
