// Ensures that a user has the given permissions before rendering its children.

import { PropsWithChildren, PureComponent } from 'react';
import { Redirect } from 'react-router';
import { ApiRequest } from '../../common-types';
import Routes from '../../core/route/Routes';
import { isArrayOfPermissions } from '../../core/utils/permissions';
import { interpolateApiRequest } from '../../core/utils/route';
import { isSelfServiceMode } from '../../core/utils/session';
import { LogoutContainer } from '../features/logout/LogoutContainer';
import { LoadingIndicator } from './LoadingIndicator';
import { PermissionCheckProps } from './PermissionCheckContainer';

export const interpolatePermissions = (
  permissions: ApiRequest | ReadonlyArray<ApiRequest> | undefined,
  routeParams: { [key: string]: any } | undefined
): ReadonlyArray<ApiRequest> | undefined =>
  permissions &&
  (isArrayOfPermissions(permissions) ? permissions : [permissions]).map(perm =>
    interpolateApiRequest(perm, routeParams || {})
  );

export class PermissionCheck extends PureComponent<PropsWithChildren<PermissionCheckProps>> {
  /**
   * If our permissions have changed and we are in a LOADING state, dispatch new actions to check
   * for those permissions.
   * @param prevProps optional previous props to determine if we need to update
   */
  reloadPermissionsIfNecessary(prevProps?: Readonly<PermissionCheckProps>) {
    const previousPerms =
      prevProps && interpolatePermissions(prevProps.permissions, prevProps.match.params);
    const newPerms = interpolatePermissions(this.props.permissions, this.props.match.params);

    if (
      JSON.stringify(previousPerms) !== JSON.stringify(newPerms) &&
      newPerms &&
      this.props.permissionState === 'LOADING'
    ) {
      newPerms.forEach(perm => this.props.checkPermission(perm));
    }
  }

  componentDidMount() {
    this.reloadPermissionsIfNecessary();
  }

  componentDidUpdate(prevProps: Readonly<PermissionCheckProps>) {
    this.reloadPermissionsIfNecessary(prevProps);
  }

  /**
   * If we don't have a session, update our login destination if necessary
   */
  componentWillUnmount() {
    if (!this.props.userId) {
      this.props.updateLoginDestination(this.props.location.pathname);
    }
  }

  render() {
    const { userId, permissionState, domainAndPermissionState, children, renderWhenDenied, opts } =
      this.props;
    if (!userId) {
      return <LogoutContainer />;
    } else {
      const selfServiceEditPath = Routes.Users.Edit(userId);
      const selfServiceNotificationsPath = Routes.Users.Notifications(userId, '');
      const currPath = this.props.location.pathname;
      if (
        isSelfServiceMode() &&
        !(currPath.includes(selfServiceEditPath) || currPath.includes(selfServiceNotificationsPath))
      ) {
        return <Redirect to={selfServiceEditPath} />;
      } else if (permissionState === 'LOADING') {
        // Refresh permissions
        const perms = interpolatePermissions(this.props.permissions, this.props.match.params);
        if (perms) {
          perms.forEach(perm => this.props.checkPermission(perm));
        }
        if (opts && opts.showLoadingIndicator) {
          return <LoadingIndicator circleSize="lg" showLoadingString={true} dontCenter={false} />;
        } else {
          return <></>;
        }
      } else if (domainAndPermissionState === 'ALLOWED') {
        return children;
      } else if (
        domainAndPermissionState === 'NOT_ALLOWED' &&
        opts &&
        opts.redirectToAccessDenied
      ) {
        return <Redirect to={Routes.AccessDenied} />;
      } else {
        return renderWhenDenied ? renderWhenDenied(permissionState !== 'ALLOWED') : <></>;
      }
    }
  }
}

// TODO - test
