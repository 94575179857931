import { createAction } from 'typesafe-actions';
import { SearchPagerId } from '../search-pager/SearchPagerActions';

/**
 * Handled in: Epic
 * Polls fusion servers for resource sync state by periodically refreshing the search pager
 */
export const pollFusionSyncState = createAction(
  '[Sync State] Poll Sync State',
  (pagerId: SearchPagerId) => ({ pagerId })
)();

/**
 * Handled in: Epics and Reducer
 *
 * Stops polling sync state and remove it from the store
 */
export const stopPollFusionSyncState = createAction('[Sync State] Stop Polling Sync State')();
