import { DomainVisibilityProtectorProps } from './DomainVisibilityProtectorContainer';
import { PureComponent } from 'react';

export class DomainVisibilityProtector extends PureComponent<DomainVisibilityProtectorProps> {
  componentDidMount() {
    if (this.props.isDomainsEnabled) {
      this.props.protectVisibleDomains(this.props.resourceRequest, this.props.recoveryRoute);
    }
  }

  componentWillUnmount() {
    if (this.props.isDomainsEnabled) {
      this.props.endVisibleDomainProtection();
    }
  }

  render() {
    return null;
  }
}
