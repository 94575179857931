// Functions for correctly displaying notifications.

import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { MobileApiNotification } from '../../mobile-api-types';
import { core_t } from '../CoreLocale';

export function getNotificationState(notification: MobileApiNotification | null) {
  if (!notification) {
    return 'UNKNOWN';
  } else if (notification.abortedAt) {
    return 'ABORTED';
  } else if (
    notification.expiresAt &&
    moment(notification.expiresAt).isSameOrBefore(moment().add(2, 'seconds'))
  ) {
    return 'EXPIRED';
  } else if (
    notification.progress &&
    notification.progress.extensions &&
    isEmpty(notification.progress.extensions)
  ) {
    return 'INITIALIZING';
  } else if (notification.progress && notification.progress.percentComplete >= 100) {
    return 'COMPLETE';
  } else if (notification.progress) {
    return 'IN_PROGRESS';
  } else {
    return 'UNKNOWN';
  }
}

export function getNotificationStatus(notification: MobileApiNotification | null) {
  switch (getNotificationState(notification)) {
    case 'ABORTED':
      return core_t(['label', 'canceled']);
    case 'EXPIRED':
      return core_t(['label', 'expired']);
    case 'INITIALIZING':
      return core_t(['label', 'initializing']);
    case 'COMPLETE':
      return core_t(['label', 'complete']);
    case 'IN_PROGRESS':
      return core_t(['label', 'inProgress']);
    default:
      return core_t(['label', 'unknown']);
  }
}

export function getNotificationStateDisplayString(notification: MobileApiNotification | null) {
  switch (getNotificationState(notification)) {
    case 'ABORTED':
      return `(${core_t(['label', 'canceled'])})`;
    case 'EXPIRED':
      return `(${core_t(['label', 'expired'])})`;
    case 'INITIALIZING':
      return `(${core_t(['label', 'inProgressInitializing'])})`;
    case 'COMPLETE':
      return `(${core_t(['label', 'complete'])})`;
    case 'IN_PROGRESS':
      return `(${core_t(['label', 'inProgressPercent'], {
        percent: notification ? notification.progress.percentComplete : 0,
      })})`;
    default:
      return '';
  }
}

export const classForNotificationState = (notification: MobileApiNotification | null) =>
  includes(['ABORTED', 'EXPIRED'], getNotificationState(notification)) ? 'c-red' : 'c-teal';

export default {
  getNotificationState,
  getNotificationStateDisplayString,
  classForNotificationState,
};
