import { createAction } from 'typesafe-actions';
import { LogoutReason } from './LoginReducer';

// Handled in: Epic
// Redirects user to a specified destination during login.
export const loginRedirect = createAction(
  '[Login] Redirect',
  (destination: string, providerId: string | null) => ({
    destination,
    providerId,
  })
)();

// Handled in: Epic
// Redirects user to /logout (which then redirects to the destination provided)
export const logoutRedirect = createAction(
  '[Logout] Redirect',
  (destination: string, providerId: string | null) => ({
    destination,
    providerId,
  })
)();

// Handled in: Epic
// Redirects user to /login on the REST API
export const redirectToApiLogin = createAction(
  '[Login] Redirect to API Login',
  (destination: string, loginHint: string, providerId: string | null) => ({
    destination,
    loginHint,
    providerId,
  })
)();

// Handled in: Reducer
// Sets logout destination and message information in state.
export const setLogoutInfo = createAction(
  '[Login] Set Logout Message',
  (logoutReason: LogoutReason, message: string, loginDestination?: string) => ({
    logoutReason,
    message,
    loginDestination,
  })
)();

// Handled in: Reducer
// Sets logout destination if our logout agent was auto or not defined
export const updateLoginDestination = createAction(
  '[Login] Set Login Destination',
  (loginDestination: string) => ({ loginDestination })
)();
