import { combineEpics, Epic } from 'redux-observable';
import { Action } from 'redux';
import { isActionOf } from 'typesafe-actions';
import { filter, withLatestFrom, map, switchMap } from 'rxjs/operators';
import { TabsState } from '../tabs/TabsReducer';
import { RootState } from '../RootReducer';
import { setManualScrollRestoration, restoreScroll } from './ScrollActions';
import { EMPTY } from 'rxjs';

export const setScrollPolicyEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(setManualScrollRestoration)),
    switchMap(() => {
      if (window.history.scrollRestoration === 'auto') {
        window.history.scrollRestoration = 'manual';
      }
      return EMPTY;
    })
  );

const includesTabRoute = (tabs: TabsState, currentLocation: string) => {
  const mountedTabRoutes = Object.keys(tabs).reduce(
    (acc, key) => [...acc, ...Object.keys(tabs[key].lookup)],
    [] as string[]
  );
  return mountedTabRoutes.includes(currentLocation);
};

export const restoreScrollEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(restoreScroll)),
    withLatestFrom(state$.pipe(map(s => s.tabs))),
    switchMap(([action, tabs]) => {
      if (!includesTabRoute(tabs, action.payload.location)) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
      return EMPTY;
    })
  );

export default combineEpics(setScrollPolicyEpic, restoreScrollEpic);
