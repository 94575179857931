import * as license from './LicenseActions';
import { ActionType, getType } from 'typesafe-actions';
import { License } from './LicenseActions';

export type LicenseAction = ActionType<typeof license>;

export type LicenseState = License | null;

const initialLicenseState: LicenseState = null;

export default function licenseReducer(
  state: LicenseState = initialLicenseState,
  action: LicenseAction
): LicenseState {
  switch (action.type) {
    case getType(license.updateLicense):
      return { ...state, ...action.payload.license };

    default:
      return state;
  }
}
