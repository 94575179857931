import { PureComponent } from 'react';
import { Box, Paper } from 'singlewire-components';
import { common_t } from '../../CommonLocale';
import Routes from '../../core/route/Routes';
import { ResolveErrorProps } from './ResolveErrorContainer';
import { shared_t } from './SharedLocale';
import { Alert } from './components/Alert';
import { LinkButton } from './components/Button';
import { Page } from './components/Page';

export const ResolveErrorComponent = () => (
  <Page
    id="resolve-error-component"
    title={shared_t(['exception', 'title'])}
    pageTitle={shared_t(['exception', 'title'])}>
    <Paper>
      <Alert color="error">
        {shared_t(['exception', 'message'])}
        <Box align="start" mt="md">
          <LinkButton
            id="go-to-home-button"
            color="error"
            variant="contained"
            label={common_t(['button', 'home'])}
            to={Routes.Home}
            showIcon={false}
          />
        </Box>
      </Alert>
    </Paper>
  </Page>
);

export class ResolveError extends PureComponent<ResolveErrorProps> {
  componentWillUnmount() {
    this.props.resolveErrorCleanup(this.props.resolveErrorKey);
  }

  render() {
    const CustomComponent = this.props.customComponent;
    return CustomComponent ? <CustomComponent /> : <ResolveErrorComponent />;
  }
}
