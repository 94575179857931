// Display an icon and properly link to wherever the help page is hosted.

import styled from 'styled-components';
import { refLookup } from '../../constants';
import { MobileApiSession } from '../../mobile-api-types';
import { HelpIconProps } from './HelpIconContainer';
import { shared_t } from './SharedLocale';
import { SinglewireIcon } from './SinglewireIcon';
import { Anchor } from './components/Link';

export type ModeType = keyof typeof refLookup;

// Get the right base url for the environment, then construct the link.
export const getLink = (
  session: MobileApiSession | null,
  ref?: string,
  mode: ModeType = 'fusion'
): string => {
  if (refLookup[mode] === 'External' && ref) {
    return ref;
  }

  const baseUrlKeySuffix =
    window.docsEnv && window.docsEnv === 'prod' ? 'BaseUrl' : 'BaseUrlStaging';

  return ref
    ? `${window[`fusionDocs${baseUrlKeySuffix}`]}/${ref}`
    : window[`fusionDocs${baseUrlKeySuffix}`];
};

const HelpLink = styled.a`
  margin-left: 5px;
`;

export const HelpIcon = ({ session, value, mode }: HelpIconProps) => {
  return (
    <HelpLink
      href={getLink(session, value, mode)}
      target="_blank"
      className="help-link-icon"
      title={shared_t(['tooltip', 'help'])}>
      <SinglewireIcon icon="help" />
    </HelpLink>
  );
};

export const HelpLabel = ({ session, value, mode, label }: HelpIconProps & { label: string }) => {
  return (
    <Anchor
      href={getLink(session, value, mode)}
      target="_blank"
      title={shared_t(['tooltip', 'help'])}
      underline>
      {label}
    </Anchor>
  );
};
