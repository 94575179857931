import { PureComponent } from 'react';
import {
  Box,
  COLORS,
  Dropdown,
  DropdownMenuCheckboxItem,
  DropdownMenuItem,
  Hidden,
  Icon,
  IconButton,
  Typography,
} from 'singlewire-components';
import styled from 'styled-components';
import { common_t } from '../../CommonLocale';
import { ApiRequest } from '../../common-types';
import { BrandingContext, defaultBranding } from '../../core/branding/BrandingContext';
import { RightSidebarType, SidebarType } from '../../core/layout/LayoutActions';
import Routes from '../../core/route/Routes';
import { getContrastYIQ } from '../../core/utils/color-representation';
import { isSelfServiceMode } from '../../core/utils/session';
import { ForceReloadLinkContainer } from '../shared/ForceReloadLinkContainer';
import { Button } from '../shared/components/Button';
import { Link } from '../shared/components/Link';
import { DomainSelectContainer } from './DomainSelectContainer';
import { FacilitySelectContainer } from './FacilitySelectContainer';
import { HeaderProps } from './HeaderContainer';
import { layout_t } from './LayoutLocale';
import SkipLink from './SkipLink';

export const VIEW_MESSAGE_TEMPLATES: ApiRequest = ['messageTemplates', [], 'GET'];
export const CREATE_NOTIFICATIONS: ApiRequest = ['notifications', [], 'POST'];
export const VIEW_NOTIFICATIONS: ApiRequest = ['notifications', [], 'GET'];

const FloatingActionsContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  right: 1rem;
  top: 50%;
  z-index: 20;
`;

// Change hover and focus effect depending on the header color to still be able to tell where a user is on a screen and match focus effect
const StyledLink = styled(Link)`
  border: 2px solid transparent;

  &:focus,
  &:hover {
    border-radius: 25px;
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  &:focus {
    outline: 1px solid ${COLORS.BLUE_60};
    border: 2px solid #fff;
  }
`;

const StyledAnchor = styled.a`
  color: black;
`;

const StyledHeader = styled.header<{ $primary?: string; $textColor: string }>`
  ${props =>
    props.$primary
      ? `
  background-color: ${props.$primary};
  color: ${props.$textColor};
`
      : ''}
`;

export class Header extends PureComponent<HeaderProps> {
  state = { providerType: '' };

  toggleLeftSidebar = () => this.props.toggleSidebar(SidebarType.Left);

  componentDidMount() {
    this.props.checkPermission(VIEW_MESSAGE_TEMPLATES);
    this.props.checkPermission(CREATE_NOTIFICATIONS);
    this.props.checkPermission(VIEW_NOTIFICATIONS);
    this.props.isFacilitiesEnabled && this.props.getAllFacilities();
    this.props.isDomainsEnabled && this.props.getAllDomains();

    // as componentDidMount is only called once when the page is initially rendered,
    // use this as a hook to fetch the user's provider type.
    this.props.makeReq(
      ['users', [this.props.signedInUserId], 'GET', { params: { includeIdp: true } }],
      {
        successActions: response => {
          this.setState({ providerType: response.data.providerIdp.type });
          return [];
        },
      }
    );
  }

  doCloseSideBar = () => {
    this.props.closeSidebar(SidebarType.Left);
  };

  render() {
    const {
      sidebarToggleLeft,
      sidebarRightType,
      closeSidebar,
      askUserToLogOut,
      createNotifications,
      viewNotifications,
      viewMessageTemplates,
      helpLink,
      brandingLoaded,
      isOverlay,
      startNotificationsPolling,
      stopNotificationsPolling,
      hasActiveNotification,
      signedInUserId,
      signedInUserName,
      showResourceCenter,
      toggleResourceCenter,
      sendUserToIdp,
      supportCommunityLink,
      hireExpertLink,
      isResourceCenterEnabled,
      isFacilitiesEnabled,
      openApiEnabled,
    } = this.props;

    const { primaryColor, logoPath, logoAltText } = this.context as any;
    const shouldApplyIconContrast = primaryColor !== defaultBranding.primaryColor;
    const menuIconColor = shouldApplyIconContrast ? getContrastYIQ(primaryColor) : undefined;
    const isSelfService = isSelfServiceMode();
    const isFullHeader = !isSelfService && !isOverlay;

    const ContrastColor = getContrastYIQ(primaryColor);

    const handleRightSideBarToggle = (type: RightSidebarType) => {
      if (sidebarRightType !== type) {
        this.props.toggleRightSidebarType(type);

        setTimeout(() => {
          if (type === RightSidebarType.SEND) {
            document.getElementById('close-quick-send')?.focus();
          }
          if (type === RightSidebarType.CANCEL) {
            document.getElementById('close-quick-cancel')?.focus();
          }
        }, 0);
      } else {
        this.props.toggleRightSidebarType(RightSidebarType.NONE);
      }
    };

    return (
      <>
        <FloatingActionsContainer>
          {showResourceCenter && (
            <IconButton
              id="resource-center-button"
              icon={<Icon.ResourceCenter />}
              label={layout_t(['header', 'resourceCenter'])}
              variant="contained"
              color="primary"
              size="lg"
            />
          )}
        </FloatingActionsContainer>

        <StyledHeader
          id="header"
          className="clearfix"
          data-current-skin="blue"
          $primary={primaryColor}
          $textColor={menuIconColor || COLORS.WHITE}>
          <SkipLink skipTo="#content">
            <Button
              id="skip-to-content-button"
              label={layout_t(['header', 'skipToContent'])}
              variant="outlined"
            />
          </SkipLink>
          <SkipLink skipTo="#sidebar">
            <Button
              id="skip-to-navigation-button"
              label={layout_t(['header', 'skipToNavigation'])}
              variant="outlined"
            />
          </SkipLink>
          <Box align="between" mt="xs">
            <Box>
              {isFullHeader && (
                <Box mt="sm">
                  {!sidebarToggleLeft ? (
                    <IconButton
                      id="open-left-sidebar"
                      label={layout_t(['header', 'openLeft'])}
                      onClick={this.toggleLeftSidebar}
                      color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                      icon={<Icon.LeftNavOpen size="md" color="inherit" />}
                      size="lg"
                    />
                  ) : (
                    <IconButton
                      id="close-left-sidebar"
                      label={layout_t(['header', 'closeLeft'])}
                      onClick={this.toggleLeftSidebar}
                      color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                      icon={<Icon.CollapseSidebar size="md" color="inherit" />}
                      size="lg"
                    />
                  )}
                </Box>
              )}

              {brandingLoaded && (
                <div className="logo">
                  <Box mt="xs" mb="sm">
                    <StyledLink
                      to={Routes.Home}
                      onClick={this.doCloseSideBar}
                      aria-label={layout_t(['header', 'informacastDashboard'])}>
                      <img
                        src={
                          // If an end user uploads a unique logo we will use it,
                          // if not we want to meet color contrast for black and white versions of our logo
                          logoPath === 'assets/images/Singlewire-InformaCast-White.svg' &&
                          menuIconColor === COLORS.BLACK
                            ? 'assets/images/Singlewire-InformaCast-Black.svg'
                            : logoPath
                        }
                        alt={logoAltText}
                        id="logoHeader"
                      />
                    </StyledLink>
                  </Box>
                </div>
              )}
            </Box>

            <Box align="end" mt="sm">
              {isFullHeader && (
                <>
                  <Hidden smDown={true}>
                    <div onClick={() => closeSidebar(SidebarType.Left)}>
                      <DomainSelectContainer />
                    </div>
                  </Hidden>
                  <Hidden mdUp={true}>
                    <div onClick={() => closeSidebar(SidebarType.Left)}>
                      <DomainSelectContainer xSmallScreen={true} />
                    </div>
                  </Hidden>
                </>
              )}
              {isFullHeader && isFacilitiesEnabled && (
                <>
                  <Hidden smDown={true}>
                    <div onClick={() => closeSidebar(SidebarType.Left)}>
                      <FacilitySelectContainer />
                    </div>
                  </Hidden>
                  <Hidden mdUp={true}>
                    <div onClick={() => closeSidebar(SidebarType.Left)}>
                      <FacilitySelectContainer xSmallScreen={true} />
                    </div>
                  </Hidden>
                </>
              )}
              {isFullHeader && viewMessageTemplates && createNotifications && (
                <Hidden smDown={true}>
                  <IconButton
                    id="quick-send-trigger"
                    label={layout_t(['header', 'quickSendMenu'])}
                    color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                    icon={<Icon.Send color="inherit" />}
                    onClick={() => handleRightSideBarToggle(RightSidebarType.SEND)}
                    size="lg"
                    aria-expanded={sidebarRightType === RightSidebarType.SEND}
                  />
                </Hidden>
              )}

              {isFullHeader && viewNotifications && (
                <Hidden smDown={true}>
                  <IconButton
                    id="quick-cancel-trigger"
                    label={layout_t(['header', 'activeNotificationsMenu'])}
                    color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                    badge={hasActiveNotification}
                    icon={<Icon.QuickCancel color="inherit" />}
                    size="lg"
                    onClick={() => {
                      sidebarRightType === RightSidebarType.CANCEL
                        ? stopNotificationsPolling()
                        : startNotificationsPolling();
                      handleRightSideBarToggle(RightSidebarType.CANCEL);
                    }}
                    aria-expanded={sidebarRightType === RightSidebarType.CANCEL}
                  />
                </Hidden>
              )}

              {isFullHeader && (
                <Hidden smDown={true}>
                  <Dropdown
                    id="header-help"
                    trigger={() => (
                      <IconButton
                        id="header-help-button"
                        color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                        icon={<Icon.Help color={ContrastColor === '#FFFFFF' ? 'white' : 'black'} />}
                        label={common_t(['button', 'help'])}
                        size="lg"
                      />
                    )}>
                    <DropdownMenuItem
                      id="help-label"
                      variant="label"
                      label={common_t(['button', 'help'])}
                    />
                    <DropdownMenuItem id="help-divider" variant="divider" />

                    {openApiEnabled && (
                      <StyledAnchor
                        href={window.openApiDocumentationUrl}
                        target="_blank"
                        rel="noopener noreferrer">
                        <DropdownMenuItem
                          id="openapi-explorer"
                          variant="action"
                          icon={<Icon.API />}
                          label={layout_t(['header', 'openApiExplorer'])}
                        />
                      </StyledAnchor>
                    )}

                    <StyledAnchor href={helpLink} target="_blank" rel="noopener noreferrer">
                      <DropdownMenuItem
                        id="help-documentation"
                        variant="action"
                        icon={<Icon.Help />}
                        label={layout_t(['header', 'userGuide'])}
                      />
                    </StyledAnchor>

                    <StyledAnchor
                      href={supportCommunityLink}
                      target="_blank"
                      rel="noopener noreferrer">
                      <DropdownMenuItem
                        id="support-community"
                        variant="action"
                        icon={<Icon.SupportCommunity />}
                        label={layout_t(['header', 'supportCommunity'])}
                      />
                    </StyledAnchor>

                    <StyledAnchor href={hireExpertLink} target="_blank" rel="noopener noreferrer">
                      <DropdownMenuItem
                        id="hire-expert"
                        variant="action"
                        icon={<Icon.HireExpert />}
                        label={layout_t(['header', 'hireExpert'])}
                      />
                    </StyledAnchor>

                    <DropdownMenuItem
                      id="tools-label"
                      variant="label"
                      label={common_t(['label', 'tools'])}
                    />
                    <DropdownMenuItem id="tools-divider" variant="divider" />

                    {isResourceCenterEnabled && (
                      <DropdownMenuCheckboxItem
                        id="resource-center"
                        checked={showResourceCenter}
                        onCheckedChange={toggleResourceCenter}>
                        <Typography variant="body">
                          {layout_t(['header', 'resourceCenter'])}
                        </Typography>
                      </DropdownMenuCheckboxItem>
                    )}
                  </Dropdown>
                </Hidden>
              )}

              {!isOverlay && (
                <Hidden smDown={true}>
                  <Dropdown
                    id="header-profile"
                    trigger={() => (
                      <IconButton
                        id="header-profile-button"
                        color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                        icon={
                          <Icon.Profile color={ContrastColor === '#FFFFFF' ? 'white' : 'black'} />
                        }
                        label={common_t(['button', 'profile'])}
                        size="lg"
                      />
                    )}>
                    <DropdownMenuItem id="profile-label" variant="label" label={signedInUserName} />
                    <DropdownMenuItem id="profile-divider" variant="divider" />

                    {!isSelfService && (
                      <ForceReloadLinkContainer
                        to={Routes.Users.Edit(signedInUserId)}
                        onClick={() => closeSidebar(SidebarType.Left)}>
                        <DropdownMenuItem
                          id="profile-details"
                          variant="action"
                          icon={<Icon.ProfileDetails />}
                          label={layout_t(['header', 'profile'])}
                        />
                      </ForceReloadLinkContainer>
                    )}

                    {this.state.providerType === 'cirrus-idp' && (
                      <DropdownMenuItem
                        id="profile-idp-logout-link"
                        key="profile-idp-logout-link"
                        onClick={sendUserToIdp}
                        variant="action"
                        icon={<Icon.LockUser />}
                        label={layout_t(['header', 'manageAccount'])}
                      />
                    )}

                    <DropdownMenuItem
                      id="profile-logout-link"
                      key="profile-logout-link"
                      onClick={askUserToLogOut}
                      variant="action"
                      icon={<Icon.LogOut />}
                      label={layout_t(['header', 'logout'])}
                    />
                  </Dropdown>
                </Hidden>
              )}

              <Hidden mdUp={true}>
                {!isOverlay && (
                  <Dropdown
                    id="header-more-options"
                    trigger={() => (
                      <IconButton
                        id="header-more-button"
                        color={ContrastColor === '#FFFFFF' ? 'white' : 'black'}
                        icon={<Icon.More color={ContrastColor === '#FFFFFF' ? 'white' : 'black'} />}
                        label={common_t(['header', 'moreOptions'])}
                        size="lg"
                      />
                    )}
                    fixedMaxHeight={false}>
                    <DropdownMenuItem
                      id="xs-quick-send"
                      variant="action"
                      icon={<Icon.Send />}
                      label={common_t(['label', 'send'])}
                      onClick={() => handleRightSideBarToggle(RightSidebarType.SEND)}
                    />

                    <DropdownMenuItem
                      id="xs-quick-cancel"
                      variant="action"
                      icon={<Icon.QuickCancel />}
                      label={common_t(['resource', 'notifications'])}
                      onClick={() => {
                        sidebarRightType === RightSidebarType.CANCEL
                          ? stopNotificationsPolling()
                          : startNotificationsPolling();
                        handleRightSideBarToggle(RightSidebarType.CANCEL);
                      }}
                    />

                    <DropdownMenuItem
                      id="help-label"
                      variant="label"
                      label={layout_t(['header', 'help'])}
                    />
                    <DropdownMenuItem id="help-divider" variant="divider" />

                    {openApiEnabled && (
                      <DropdownMenuItem
                        id="openapi-explorer"
                        variant="action"
                        icon={<Icon.API />}
                        label={layout_t(['header', 'openApiExplorer'])}
                        onClick={() => window.open(window.openApiDocumentationUrl, '_blank')}
                      />
                    )}

                    <DropdownMenuItem
                      id="help-link"
                      variant="action"
                      icon={<Icon.Help />}
                      label={layout_t(['header', 'userGuide'])}
                      onClick={() => window.open(helpLink, '_blank')}
                    />

                    <DropdownMenuItem
                      id="support-community"
                      variant="action"
                      icon={<Icon.SupportCommunity />}
                      label={layout_t(['header', 'supportCommunity'])}
                      onClick={() => window.open(supportCommunityLink, '_blank')}
                    />

                    <DropdownMenuItem id="profile-label" variant="label" label={signedInUserName} />
                    <DropdownMenuItem id="profile-divider" variant="divider" />

                    <ForceReloadLinkContainer
                      to={Routes.Users.Edit(signedInUserId)}
                      onClick={() => closeSidebar(SidebarType.Left)}>
                      <DropdownMenuItem
                        id="profile-details"
                        variant="action"
                        icon={<Icon.ProfileDetails />}
                        label={layout_t(['header', 'profile'])}
                      />
                    </ForceReloadLinkContainer>

                    {this.state.providerType === 'cirrus-idp' && (
                      <DropdownMenuItem
                        id="profile-idp-logout-link"
                        key="profile-idp-logout-link"
                        onClick={sendUserToIdp}
                        variant="action"
                        icon={<Icon.LockUser />}
                        label={layout_t(['header', 'manageAccount'])}
                      />
                    )}

                    <DropdownMenuItem
                      id="logout-link"
                      key="logout-link"
                      onClick={askUserToLogOut}
                      variant="action"
                      icon={<Icon.LogOut />}
                      label={layout_t(['header', 'logout'])}
                    />
                  </Dropdown>
                )}
              </Hidden>
            </Box>
          </Box>
        </StyledHeader>
      </>
    );
  }
}

Header.contextType = BrandingContext;
