import { AxiosError } from 'axios';
import every from 'lodash/every';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import update from 'lodash/update';
import { Action } from 'redux';
import { Schema, TestContext, ValidateOptions, ValidationError, mixed } from 'yup';
import { common_t } from '../../CommonLocale';
import { MobileApiValidationError } from '../../mobile-api-types';
import { growl } from '../layout/LayoutActions';
import log from '../utils/log';

/**
 * A utility function to check if an error from the server conforms to 400 validation error response
 * @param validationError the error
 * @returns {boolean} whether the validation error is valid
 */
export const isValidValidationError = (validationError: any): boolean => {
  return (
    isObject(validationError) &&
    isArray((validationError as any).reason) &&
    !isEmpty((validationError as any).reason) &&
    every(
      (validationError as any).reason,
      (reason: any) =>
        isObject(reason) &&
        (isString((reason as any).field) || (reason as any).field === null) &&
        isString((reason as any).message) &&
        isString((reason as any).type)
    )
  );
};

/**
 * Given some bytes, convert it to a human readable string
 * @param bytes the bytes
 * @param precision the precision level (decimals)
 */
export const bytesToHumanString = (bytes: number, precision = 1) => {
  if (isNaN(bytes) || !isFinite(bytes)) {
    return '-';
  }
  const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
  const num = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, Math.floor(num))).toFixed(precision)} ${units[num]}`;
};

export const sanitizeInputName = (inputName?: string) =>
  inputName ? inputName.replace(/[.[\]]/g, '') : '';

/** Given a form and an input field, generates the ID for the error element.*/
export const getErrorContainerId = (form: string, inputName?: string): string => {
  // When updating this function, make sure to update getErrorSource() so it knows
  // how to find the source input element that caused the error.
  return `${form}.errors.${sanitizeInputName(inputName)}`;
};

/** Given an error container, returns the input element that caused the error
 *  to appear. */
export const getErrorSource = (errorContainer: HTMLElement): HTMLInputElement | null => {
  // This code is tightly coupled with getErrorContainerId, and assumes the container
  // has an ID generated by that function.
  const sourceId = errorContainer.id.split('.').pop();
  if (sourceId) return document.getElementById(sourceId) as HTMLInputElement;
  return null;
};

export const getAllErrorContainers = (formId: string): string[] =>
  Array.from(document.querySelectorAll('.field-errors-container'))
    .filter(element => element.id && element.id.startsWith(getErrorContainerId(formId)))
    .map(element => element.id.replace(getErrorContainerId(formId), ''));

export const getFirstOnScreenErrorContainer = (formId: string): Element | undefined =>
  Array.from(document.querySelectorAll('.has-error .field-errors-container')).find(
    element => !!(element.id && element.id.startsWith(getErrorContainerId(formId)))
  );

export interface RemoteFieldErrors {
  [field: string]: [{ [type: string]: { message: string; field: string } }];
}

/**
 * Loops through each error in the response. If the error maps to a field, we'll return a mapped error
 * accordingly. If it doesn't map to a field, we'll growl the error to the user.
 * @param {string} formId the form to set the errors on
 * @param {any} formData the form data to check errors against
 * @param {AxiosError | any} error the error response
 * @param {string} genericFailureMessage growled if we get an error we don't know how to parse
 * @param {(error: MobileApiValidationError) => boolean} unmappedErrorsCustomActions predicate that returns true for
 * validation errors that should not be processed
 */
export const processError = (
  formId: string,
  formData: any,
  error: AxiosError | any,
  genericFailureMessage: string,
  unmappedErrorsCustomActions?: (error: MobileApiValidationError) => Action | null
): { errors?: RemoteFieldErrors; actions: Action[] } => {
  log.error('Request failed', error);
  if (error && error.response && error.response.status === 400) {
    const validationError = error.response.data;

    if (isValidValidationError(validationError)) {
      const reasons: MobileApiValidationError[] = validationError.reason;
      // This is a hack.
      // In this specific case, we are getting a field error on the below field in CUCM. This isn't
      // a real field, so we know that the user is attempting to update CUCM in a situation that
      // JTAPI would need to restart.
      // Since we have a confirmation modal in that case, we want to suppress the toast so that we
      // don't have redundant error messages. Sarah and Jacob don't really like this solution, but
      // it's the best we have for now.
      if (
        reasons[0].field === 'allowRestartForJtapiUpdate' ||
        reasons[0].field === 'allowCcfsDeletion'
      ) {
        return { actions: [] };
      }

      const onScreenErrors = getAllErrorContainers(formId);

      const mappedErrors = reasons.filter(reason =>
        onScreenErrors.includes(sanitizeInputName(reason.field))
      );
      const unmappedErrors = reasons.filter(
        reason => !onScreenErrors.includes(sanitizeInputName(reason.field))
      );

      return {
        errors: mappedErrors.reduce(
          (acc: RemoteFieldErrors, reason: MobileApiValidationError, idx) =>
            update(acc, reason.field, (value: any) => ({
              ...(value || {}),
              [`${reason.type}_${idx}`]: {
                message: reason.message,
                field: reason.field,
              },
            })),
          {}
        ),
        actions: unmappedErrors.map(
          reason =>
            unmappedErrorsCustomActions?.(reason) || growl(reason.message, { type: 'danger' })
        ),
      };
    } else {
      log.error('Invalid validation error format. Using default message', error);
      return { actions: [growl(genericFailureMessage, { type: 'danger' })] };
    }
  } else if (error?.response?.status === 429) {
    log.error('Encountered rate limit (429) response.', error);
    return {
      actions: [
        growl(`${error.response.data.message}: ${error.response.data.reason[0].message}`, {
          type: 'danger',
        }),
      ],
    };
  } else {
    return { actions: [growl(genericFailureMessage, { type: 'danger' })] };
  }
};

/**
 * A custom file size validator
 * @param {number} maxBytes the max number of bytes to accept
 * @returns a file size validator function
 */
export const maxFileSizeValidator = (
  maxBytes: number
): [string, () => string, (value: any) => boolean] => [
  'maxFileSize',
  () => common_t(['validations', 'fileSize'], { max: bytesToHumanString(maxBytes) }),
  (files: FileTypes) => {
    if (!isFileSelected(files)) {
      return true;
    }
    const size = Array.from(files).reduce((acc, f) => acc + f.size, 0);
    return size <= maxBytes;
  },
];

/**
 * Yup file schema type
 */
export const file = () => mixed().transform(f => (f === NO_FILE ? undefined : f));

interface WithRootContext<T> {
  rootValue: T;
}

/**
 * Validates the schema synchronously
 * @param schema the schema to validate
 * @param options any override options
 * @param errorObjectKeys Keys in the error object to include in the error output default is the message
 */
export const syncValidate =
  <T>(
    schema: Schema<T>,
    options: ValidateOptions = { abortEarly: false },
    errorObjectKeys: string[] = ['message']
  ) =>
  (values: any) => {
    try {
      schema.validateSync(values, {
        ...options,
        context: { ...(options.context || {}), rootValue: values },
      });
    } catch (errors) {
      if (errors instanceof ValidationError) {
        return (errors as ValidationError).inner.reduce(
          (acc, error) =>
            error.path
              ? update(acc, error.path, (value: any) => ({
                  ...(value || {}),
                  [error.type || 'invalid']: pick(error, errorObjectKeys),
                }))
              : acc,
          {} as RemoteFieldErrors
        );
      }
    }
    return {};
  };

/**
 * We always put the full rootValue in the context of the validation so it can be accessed anytime.
 * This helper function creates a test function that can be use with lambdas to destructure out the
 * `this` arguments. https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
 *
 * If you need the root value, use this function to do so rather than plucking it out yourself.
 * @param cb the callback to invoke with arguments that include rootValue and the `this` arg
 */
export const withRootValue = <ValueType, RootValueType>(
  cb: (
    value: ValueType,
    rootValue: RootValueType,
    testContext: TestContext
  ) => boolean | ValidationError
) => {
  return function (this: TestContext, value: ValueType) {
    const context = this.options.context as WithRootContext<RootValueType>;
    return cb(value, context.rootValue, this);
  };
};

/**
 * Represents when no file is selected. If you wish to preserve a file use the
 * PRESERVE_FILE constant below or set it to a File[] type. This will cause
 * multipart form posts to remove the file.
 */
export const NO_FILE = '';
export type NoFileType = typeof NO_FILE;

/**
 * Represents when you wish to preserve a file. If you wish to remove a file
 * use the NO_FILE constant below or set it to a File[] type. This will cause
 * multipart form posts to omit the field thus keeping the previous value on
 * the server. Note, this is a sort of hacky constant to make Redux form happy
 * as toggling between undefined/null/'' is tricky and dirty form checking doesn't
 * work particularly great in those cases no does triggering component re-rendering.
 */
export const PRESERVE_FILE: ['PRESERVE_FILE'] = ['PRESERVE_FILE'];
export type PreserveFileType = typeof PRESERVE_FILE;

/**
 * All File inputs in the system should be one of these three values and nothing else.
 * This will ensure the files are correctly uploaded to the server to either remove,
 * preserve, or replace an existing file.
 */
export type FileTypes = File[] | NoFileType | PreserveFileType;

export const isPreserveFile = (obj: FileTypes): obj is PreserveFileType =>
  Array.isArray(obj) && obj.length === 1 && obj[0] === PRESERVE_FILE[0];

export const isFileSelected = (obj: FileTypes): obj is File[] =>
  Array.isArray(obj) && obj.length > 0 && obj[0] instanceof File;

export const isNoFileSelected = (obj: FileTypes): obj is NoFileType => obj === NO_FILE;

/**
 * A utility function to build a FormData object from a form.  This is useful for forms
 * that require complex objects (File, Date, Nested, etc)
 * @param formData the FormData object
 * @param data the data from the original form
 * @param parentKey specified for a nested object
 */
export const buildFormData = (formData: FormData, data: any, parentKey?: string) => {
  switch (true) {
    case data instanceof Date:
      formData.append(parentKey!, data.toISOString());
      break;

    case isPreserveFile(data):
      break;

    case data instanceof Blob:
      formData.append(parentKey!, data);
      break;

    case data instanceof FileList || (isArray(data) && data.length > 0 && data[0] instanceof File):
      formData.append(parentKey!, data[0]);
      break;

    case data instanceof File:
      formData.append(parentKey!, data);
      break;

    case data instanceof Array:
      if (data.length > 0) {
        data.forEach((elem: any) => {
          buildFormData(formData, elem, `${parentKey}[]`);
        });
      } else {
        buildFormData(formData, '', parentKey);
      }
      break;

    case data instanceof Object:
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
      break;

    default:
      const value = data === null ? '' : data;
      if (value !== undefined) {
        formData.append(parentKey!, value);
      }
  }
};

/**
 * A utility function that handles calling the buildFormData function and returning
 * the new FormData object
 * @param data the data from the original form
 */
export const jsonToFormData = (data: object) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

/**
 * Higher order function intended for use in constructing a `responseDataFn` for
 * search pagers and remote select components. Given a server ID and on-prem ID key,
 * the resulting function will map the sync states of the cloud records to objects
 * containing the on-prem IDs of the cloud records in a format fit for use in a search
 * pager. Records without an on-prem ID for the selected ICOP server will be filtered out.
 * @param selectedICOPServerId
 * @param key the key of the icop id
 */
export const onPremIdsFromSyncStateFn = (key: string) => (data: any[]) => {
  return data
    .map(obj => ({
      id: obj.endpointSyncState?.[key],
      name: obj.name,
    }))
    .filter(obj => obj.id);
};
