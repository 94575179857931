import { ComponentClass } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { hideModal } from '../../core/modal/ModalActions';
import { CancelOrExpireForm, FORM_ID } from './CancelOrExpireForm';

interface ExternalProps {
  type: 'abort' | 'expire';
  onSubmit: (formId: string, formData: any) => void;
  onCancel?: () => void;
}

export type CancelOrExpireFormProps = ExternalProps &
  Pick<InjectedFormProps<FormState>, 'submitting' | 'handleSubmit'> &
  ReturnType<typeof mapDispatchToProps>;

export interface FormState {
  comment: string;
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ hideModal }, dispatch);

export const CancelOrExpireFormContainer = compose<ComponentClass<ExternalProps>>(
  connect(null, mapDispatchToProps),
  reduxForm<FormState>({
    form: FORM_ID,
    initialValues: {
      comment: '',
    },
  })
)(CancelOrExpireForm);
