import { Footer as RegistryFooter } from 'singlewire-components';
import { layout_t } from './LayoutLocale';

interface FooterProps {
  /** Hides the footer id to fix styling issues */
  hideId?: boolean;
}

export const Footer = ({ hideId }: FooterProps) => {
  return (
    <RegistryFooter
      id={hideId ? undefined : 'footer'}
      copyright={layout_t(['footer', 'copyright'])}
      links={[
        { label: layout_t(['footer', 'terms']), href: 'https://www.singlewire.com/legal-terms' },
        { label: layout_t(['footer', 'privacy']), href: 'https://www.singlewire.com/privacy' },
        {
          label: layout_t(['footer', 'support']),
          href: 'https://www.singlewire.com/support-tools',
        },
        { label: layout_t(['footer', 'status']), href: 'https://status.singlewire.com/' },
      ]}
    />
  );
};
