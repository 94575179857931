import classNames from 'classnames';

import { common_t } from '../../CommonLocale';

interface LoadingIndicatorProps {
  dontCenter?: boolean;
  pullRight?: boolean;
  circleSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  className?: string;
  showLoadingString?: boolean;
  loadingString?: string;
}

export const LoadingIndicator = ({
  dontCenter = false,
  pullRight = true,
  circleSize = 'lg',
  showLoadingString = false,
  loadingString = '',
  className = 'loading-indicator',
}: LoadingIndicatorProps) => (
  <div
    className={classNames(
      dontCenter ? (pullRight ? 'pull-right' : '') : 'preloader-wrapper-center',
      className
    )}>
    <div className={circleSize === 'md' ? 'preloader' : 'preloader pl-' + circleSize}>
      <svg className="pl-circular" viewBox="25 25 50 50">
        <circle className="plc-path" cx="50" cy="50" r="20" />
      </svg>
    </div>
    {showLoadingString && (
      <div>
        <em>{loadingString || common_t(['label', 'loading'])}</em>
      </div>
    )}
  </div>
);
