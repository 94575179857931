import { useCallback, useState } from 'react';

export const useLiveRegion = (text: string) => {
  // State and setters for debounced value
  const [liveRegionText, setLiveRegionText] = useState<string | null>(null);

  const Component = (
    <div aria-live="polite" role="status" className="tw-sr-only">
      {liveRegionText}
    </div>
  );

  const updateLiveRegion = useCallback(() => {
    setLiveRegionText(text);
    setTimeout(() => {
      setLiveRegionText(null);
    }, 500);
  }, [text]);

  return { Component, updateLiveRegion };
};
